const arrayUtils = {

  /** Filter unique object in array */
  getUnique: (arr, comp) => {
    return arr.map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e]).map(e => arr[e])
  },

}

export default arrayUtils
