// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import ViewToggleComponent from '../../components/ViewToggleComponent/ViewToggleComponent'
import PageContentWrapper from '../../components/PageContentWrapper'
import PageResult from '../../components/PageResult'
import FilesGridView from '../../components/FilesGridView'
import { SectionHeading } from '../../components/FilesGridView/FilesGridViewStyled'
import { SortButton } from '../../components/CommonStyled/CommonStyled'
import FilesListView from '../../components/FilesListView'
import { Col, Dropdown, Menu, message, Row, Tooltip } from 'antd'
import { Helmet } from 'react-helmet/es/Helmet'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { CONTEXT_MENU_ACTION } from '../../utils/constant'
import DataInfoModal from '../../components/DataInfoModal/DataInfoModal'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'

const OfflineFilesPage = props => {

  const { selectionStore, commonStore, offlineStore, profileStore } = props
  const { viewByGridMode } = commonStore
  const { myProfile } = profileStore
  const { offlineDataList, sortBy, sortAsc } = offlineStore

  const { selectionData, selectionIds, firstSelectionIndex, lastSelectionIndex, clickSessionId } = selectionStore

  const [fileList, setFileList] = useState([])
  const [showDataInfoModal, setShowDataInfoModal] = useState(false)
  const { t } = useTranslation()

  const handleCallbackAction = action => {
    switch (action) {
      case CONTEXT_MENU_ACTION.viewInfo:
        setShowDataInfoModal(true)
        return
      case CONTEXT_MENU_ACTION.trash:
        let newData = [...offlineDataList]
        newData = newData.filter(x => !selectionStore.selectionIds.includes(x.id))
        offlineStore.setOfflineDataList(newData)
        localStorage.setItem('offlineData', JSON.stringify(newData))
        selectionStore.clearSelectionIndex()
        return
      default:
        return
    }
  }
  const handleCloseDataInfoModal = () => {
    setShowDataInfoModal(false)
    selectionStore.clearSelectionIndex()
  }

  const sortMenu = (
    <Menu className={'sort-menu'} selectedKeys={offlineStore.sortBy.toString()}>
      <Menu.Item onClick={() => offlineStore.setSortBy(2)} key={'2'}>
        {t('i0547')}
      </Menu.Item>
      <Menu.Item onClick={() => offlineStore.setSortBy(1)} key={'1'}>
        {t('i0548')}
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    offlineDataList.forEach((item, index) => {
      item.realIndex = index
    })
    setFileList(offlineDataList)
  }, [offlineDataList])
  useEffect(() => {
    const lastSession = localStorage.lastSession
    const currentUser = myProfile?.id
    if (!currentUser) return
    if (!lastSession) {
      offlineStore.clearOfflineData()
      return
    }
    if (lastSession === currentUser) return
    offlineStore.clearOfflineData()
  }, [myProfile])
  useEffect(() => {
    return () => {
      selectionStore.clearSelectionIndex()
    }
  }, [])
  const deleteFunction = (event) => {
    if (selectionStore.selectionIds?.length > 0) {
      if (event.keyCode === 46) {
        commonStore.setVisibleModalDelete(true)
      }
    }
  }
  const handleConfirmDelete = () => {
    handleCallbackAction(CONTEXT_MENU_ACTION.trash, undefined)
    commonStore.setVisibleModalDelete(false)
  }
  useEffect(() => {
    document.addEventListener('keydown', deleteFunction, false)
    return () => {
      document.removeEventListener('keydown', deleteFunction, false)
    }
  }, [selectionStore.selectionIds])

  useEffect(() => {
    if (firstSelectionIndex === null || lastSelectionIndex === null) return
    let fileShiftSelect = fileList.filter(item => item.realIndex >= firstSelectionIndex && item.realIndex <= lastSelectionIndex)
    let newSelectionItems = [...fileShiftSelect]
    selectionStore.setSelectionData(newSelectionItems)

  }, [firstSelectionIndex, lastSelectionIndex, fileList, clickSessionId])
  useEffect(() => {
    return () => {
      selectionStore.setLastSelectionIndex(null)
      selectionStore.setFirstSelectionIndex(null)
      selectionStore.clearSelectionIndex()
    }
  }, [])
  return (
    <>
      <Helmet>
        <title>{t('i0007')}</title>
      </Helmet>
      <ViewToggleComponent
        heading={[{ name: t('i0549'), id: null }]}
        onClickBreadcrumb={() => null}
      />
      <PageContentWrapper>
        {
          fileList?.length === 0
            ? <PageResult type={'empty-offline-files'} />
            : viewByGridMode
            ? (
              <>
                <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                     justify={'space-between'} align={'middle'}>
                  <Col>
                    <SectionHeading style={{ marginBottom: 0 }}>{t('i0534')}</SectionHeading>
                  </Col>
                  <Col>
                    <Row type={'flex'} gutter={15} justify={'space-between'} align={'middle'}>
                      <Col>
                        <Dropdown overlay={sortMenu} trigger={['click']}>
                          <SortButton>
                            {
                              sortBy === 1
                                ? t('i0552')
                                : t('i0553')
                            }
                          </SortButton>
                        </Dropdown>
                      </Col>
                      <Col onClick={() => offlineStore.setSortAsc(!sortAsc)}>
                        <SortButton>
                          {
                            sortAsc
                              ? <Tooltip title={t('i0701')} placement={'bottom'}>
                                <ArrowUpOutlined />
                              </Tooltip>
                              : <Tooltip title={t('i0700')} placement={'bottom'}>
                                <ArrowDownOutlined />
                              </Tooltip>
                          }
                        </SortButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <FilesGridView fileList={fileList} callbackAction={handleCallbackAction} />
              </>
            ) : <FilesListView fileList={fileList} callbackAction={handleCallbackAction} />
        }
      </PageContentWrapper>
      <DataInfoModal visible={showDataInfoModal} onClose={handleCloseDataInfoModal} />
      <ModalConfirmDelete onOk={handleConfirmDelete} />
    </>
  )
}

OfflineFilesPage.propTypes = {}

export default inject(
  'myDriveStore',
  'commonStore',
  'offlineStore',
  'profileStore',
  'selectionStore',
)(observer(OfflineFilesPage))
