import styled from 'styled-components'

export const DropdownBreadcrumbIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 4px;
  &:hover {
    background: #ccc;    
  }
`
export const DropdownBreadcrumbIconDot = styled.div`
  width: 3px;
  height: 3px;
  background: #313131;
  border-radius: 50%;
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
`