// import PropTypes from 'prop-types'
import React from 'react'
import { ConfigPageWrapper, StyledTabs } from './ConfigpageStyled'
import { Tabs } from 'antd'
import { Helmet } from 'react-helmet/es/Helmet'
import { PageHeading, PageHeadingWrapper } from '../../components/CommonStyled/CommonStyled'
import PageContentWrapper from '../../components/PageContentWrapper'
import CommonConfigTabPane from '../../components/CommonConfigTabPane'
import PhoneBookTabPane from '../../components/PhoneBookTabPane'
import GroupManageTabPane from '../../components/GroupManageTabPane'
import SyncDataTabPane from '../../components/SyncDataTabPane'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

const ConfigPage = props => {
  const { match } = props
  const { activeTab } = match.params

  const { t } = useTranslation()

  return (
    <ConfigPageWrapper>
      <Helmet>
        <title>{t('i0481')}</title>
      </Helmet>
      <PageHeadingWrapper>
        <PageHeading>
          {t('i0482')}
        </PageHeading>
      </PageHeadingWrapper>
      <PageContentWrapper>
        <StyledTabs defaultActiveKey={['1', '2', '3', '4'].includes(activeTab) ? activeTab : '1'}>
          <TabPane forceRender tab={t('i0483')} key='1'>
            <CommonConfigTabPane />
          </TabPane>
          <TabPane forceRender tab={t('i0484')} key='2'>
            <PhoneBookTabPane />
          </TabPane>
          <TabPane forceRender tab={t('i0485')} key='3'>
            <GroupManageTabPane />
          </TabPane>
          <TabPane forceRender tab={t('i0486')} key='4'>
            <SyncDataTabPane />
          </TabPane>
        </StyledTabs>
      </PageContentWrapper>
    </ConfigPageWrapper>
  )
}

ConfigPage.propTypes = {}

export default ConfigPage
