import styled from 'styled-components'

export const SyncDataTitle = styled.div`
  color: #313131;
  font-size: 1.4rem;
`
export const SyncDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 320px;
  margin: 16px 0;
  button {
    min-width: 172px;
  }
`