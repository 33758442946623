const numberUtils = {
  /** Add commas for separate thousand in number */
  thousandSeparator: num => {
    // let parts = num.toString().split('.')
    // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    // return parts.join(',')
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
}

export default numberUtils
