import { APP_NAME } from '../utils/constant'

const vi = {
  'i0001': 'Thêm mới',
  'i0002': 'Dữ liệu của tôi',
  'i0003': 'Đồng bộ dữ liệu',
  'i0004': 'Đã chia sẻ với tôi',
  'i0005': 'Đã mở gần đây',
  'i0006': 'Yêu thích',
  'i0007': 'Ngoại tuyến',
  'i0008': 'Thùng rác',
  'i0009': 'Lưu lượng',
  'i0010': 'Đã sử dụng',
  'i0011': 'Mua lưu lượng',
  'i0012': 'Giới thiệu',
  'i0013': 'Điều khoản sử dụng',
  'i0014': 'Chính sách bảo mật',
  'i0015': 'Tải lên từ',
  'i0016': 'Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ admin.',
  'i0017': 'Định dạng',
  'i0018': 'Tất cả định dạng',
  'i0019': 'Người sở hữu',
  'i0020': 'Tất cả',
  'i0021': 'Tôi sở hữu',
  'i0022': 'Người khác sở hữu',
  'i0023': 'Lưu trữ',
  'i0024': 'Đã chia sẻ với tôi',
  'i0025': 'Yêu thích',
  'i0026': 'Ngoại tuyến',
  'i0027': 'Thùng rác',
  'i0028': 'Ngày tạo/Ngày tải lên',
  'i0029': 'Tất cả các ngày',
  'i0030': 'Hôm nay',
  'i0031': '7 ngày trước',
  'i0032': '1 tháng trước',
  'i0033': 'Tùy chỉnh',
  'i0034': 'Từ',
  'i0035': 'Chọn ngày',
  'i0036': 'Đến',
  'i0037': 'Chọn ngày',
  'i0038': 'Ngày chỉnh sửa',
  'i0039': 'Tất cả các ngày',
  'i0040': 'Hôm nay',
  'i0041': '7 ngày trước',
  'i0042': '1 tháng trước',
  'i0043': 'Tùy chỉnh',
  'i0044': 'Từ',
  'i0045': 'Chọn ngày',
  'i0046': 'Đến',
  'i0047': 'Chọn ngày',
  'i0048': 'Đã chia sẻ với',
  'i0049': 'Nhập tên hoặc số điện thoại',
  'i0050': 'Đặt lại',
  'i0051': 'Tìm kiếm',
  'i0052': 'Cập nhật thành công',
  'i0053': 'Lưu lượng',
  'i0054': 'Đã sử dụng',
  'i0055': 'Mua lưu lượng',
  'i0056': 'Ngôn ngữ',
  'i0057': 'Tiếng Việt',
  'i0058': 'Tiếng Anh',
  'i0059': 'Nhận thông báo tất cả những thay đổi liên quan nội dung trên',
  'i0060': 'trên trình duyệt',
  'i0061': 'Nhận thông báo tất cả những thay đổi liên quan nội dung trên',
  'i0062': 'qua email',
  'i0063': 'Cập nhật',
  'i0064': 'Chia sẻ',
  'i0065': 'Sao chép đường dẫn',
  'i0066': 'Di chuyên',
  'i0067': 'Yêu thích',
  'i0068': 'Sửa tên',
  'i0069': 'Khóa tệp',
  'i0070': 'Xem Thông tin',
  'i0071': 'Tải xuống',
  'i0072': 'Xóa',
  'i0073': 'Đã sao chép',
  'i0074': 'Sao chép',
  'i0075': 'Chưa có quyền',
  'i0076': 'Thông tin',
  'i0077': 'Loại',
  'i0078': 'Thư mục',
  'i0079': 'Người sở hữu',
  'i0080': 'Ngày tạo',
  'i0081': 'Người cập nhật',
  'i0082': 'Ngày cập nhật',
  'i0083': 'Những người có quyền truy cập',
  'i0084': 'Hoạt động',

  'i0085': 'Chia sẻ',
  'i0086': 'Sao chép đường dẫn',
  'i0087': 'Di chuyển',
  'i0088': 'Yêu thích',
  'i0089': 'Bỏ khỏi mục yêu thích',
  'i0090': 'Khóa',
  'i0091': 'Mở khóa',
  'i0092': 'Sửa tên',
  'i0093': 'Xem thông tin',
  'i0094': 'Tải xuống',
  'i0095': 'Xóa',
  'i0096': 'Đồng bộ từ Google Drive',
  'i0097': 'Khôi phục',
  'i0098': 'Xóa vĩnh viễn',
  'i0099': 'Bạn có thể tải tệp và thư mục lên bằng cách',
  'i0100': 'kéo thả vào đây.',
  'i0101': 'Cập nhật thông tin tài khoản thành công',
  'i0102': 'Vui lòng sử dụng ảnh định dạng png, jpg',
  'i0103': 'Họ và tên',
  'i0104': 'Nhập họ tên',
  'i0105': 'Số điện thoại',
  'i0106': 'Nhập số điện thoại',
  'i0107': 'Nhập email',
  'i0108': 'Cập nhật',
  'i0109': 'Cập nhật ảnh đại diện',
  'i0110': 'Lưu',
  'i0111': 'Hủy',
  'i0112': 'Thay ảnh',
  'i0113': 'Bạn có chắc chắn muốn xóa những nội dung này',
  'i0114': 'Có, xóa ngay',
  'i0115': 'Bạn có chắc chắn muốn xóa nội dung này',
  'i0116': 'Có, xóa ngay',
  'i0117': 'Cập nhật',
  'i0118': 'Bạn có chắc chắn muốn khoá nội dung này? Những người đã được chia sẻ sẽ không thể nhìn thấy nội dung này nữa.',
  'i0119': 'Có, khóa ngay',
  'i0120': 'Bạn có chắc chắn muốn xóa nội dung này',
  'i0121': 'Có, xóa ngay',
  'i0122': 'Tất cả những người có quyền truy cập',
  'i0123': 'đều có thể thấy tệp này. Bạn có chắc chắn muốn di chuyển?',
  'i0124': 'Di chuyển',
  'i0125': 'Sửa tên tệp',
  'i0126': 'Vui lòng nhập tên tệp',
  'i0127': 'Tên tệp không vượt quá 255 ký tự',
  'i0128': 'Nhập tên tệp',
  'i0129': 'Bạn có chắc chắn muốn xóa những nội dung này',
  'i0130': 'Có, xóa ngay',
  'i0131': 'Bạn chắc chắn muốn thực hiện ?',
  'i0132': 'Xác nhận',
  'i0133': 'Bạn có chắc chắn muốn xóa thư mục này',
  'i0134': 'Có, xóa ngay',
  'i0135': 'Cập nhật',
  'i0136': 'Bạn có chắc chắn muốn khoá nội dung này? Những người đã được chia sẻ tệp sẽ không thể nhìn thấy nội dung này nữa.',
  'i0137': 'Có, khóa ngay',
  'i0138': 'Bạn có chắc chắn muốn xóa thư mục này',
  'i0139': 'Có, xóa ngay',
  'i0140': 'Tất cả những người có quyền truy cập',
  'i0141': 'đều có thể thấy tệp này. Bạn có chắc chắn muốn di chuyển?',
  'i0142': 'Di chuyển',
  'i0143': 'Tên',
  'i0144': 'Tên',
  'i0145': 'Ngày cập nhật',
  'i0146': 'Ngày cập nhật',
  'i0147': 'Người sở hữu',
  'i0148': 'Người cập nhật',
  'i0149': 'Dung lượng',
  'i0150': 'Sửa tên thư mục',
  'i0151': 'Vui lòng nhập tên thư mục',
  'i0152': 'Tên thư mục không vượt quá 40 ký tự',
  'i0153': 'Nhập tên thư mục',
  'i0154': 'Hủy',
  'i0155': 'Tải tệp lên',
  'i0156': 'Thư mục số',
  'i0157': 'Bạn chắc chắn muốn thực hiện ?',
  'i0158': 'Xác nhận',
  'i0159': 'Hủy',
  'i0160': 'Bạn có chắc chắn muốn xóa những nội dung này',
  'i0161': 'Có, xóa ngay',
  'i0162': 'Bạn có chắc chắn muốn xóa thư mục này',
  'i0163': 'Có, xóa ngay',
  'i0164': 'Cập nhật',
  'i0165': 'Bạn có chắc chắn muốn khoá nội dung này? Những người đã được chia sẻ sẽ không thể nhìn thấy nội dung này nữa.',
  'i0166': 'Có, khóa ngay',
  'i0167': 'Bạn có chắc chắn muốn xóa thư mục này',
  'i0168': 'Có, xóa ngay',
  'i0169': 'Tất cả những người có quyền truy cập',
  'i0170': 'đều có thể thấy tệp này. Bạn có chắc chắn muốn di chuyển?',
  'i0171': 'Di chuyển',
  'i0172': 'Sửa tên thư mục',
  'i0173': 'Vui lòng nhập tên thư mục',
  'i0174': 'Tên thư mục tối thiểu 1 ký tự',
  'i0175': 'Nhập tên thư mục',
  'i0176': 'Ngày cập nhật',
  'i0177': 'Tên',
  'i0178': 'Tên',
  'i0179': 'Ngày cập nhật',
  'i0180': `Để đồng bộ tệp/thư mục từ Google Drive lên ${APP_NAME}, bạn hãy cài đặt tính năng đồng bộ`,
  'i0181': 'tại đây',
  'i0182': 'Thư mục',
  'i0183': 'Vui lòng kết nối đến Google drive mà bạn muốn đồng bộ',
  'i0184': 'Đồng ý',
  'i0185': 'Hủy',
  'i0186': 'Tạo nhóm thành công',
  'i0187': 'Bạn có chắc chắn muốn xoá nhóm này?',
  'i0188': 'Có, xóa ngay',
  'i0189': 'Không',
  'i0190': 'Bạn có chắc chắn muốn xoá những nhóm này?',
  'i0191': 'Có, xóa ngay',
  'i0192': 'Không',
  'i0193': 'Xóa nhiều nhóm thành công!',
  'i0194': 'Xóa nhóm thành công!',
  'i0195': 'TÊN NHÓM',
  'i0196': 'THÀNH VIÊN',
  'i0197': 'Tạo nhóm',
  'i0198': 'Xóa',
  'i0199': 'Thông tin người dùng',
  'i0200': 'Lịch sử đăng nhập',
  'i0201': 'Đăng xuất',
  'i0202': 'Trợ giúp',
  'i0203': 'Đăng nhập',
  'i0204': 'Giới thiệu',
  'i0205': 'Trợ giúp',
  'i0206': 'Điều khoản sử dụng',
  'i0207': 'Chính sách bảo mật',
  'i0208': 'NGÀY GIỜ',
  'i0209': 'THIẾT BỊ',
  'i0210': 'VỊ TRÍ',
  'i0211': 'Thêm thành viên',
  'i0212': 'Nhập số điện thoại',
  'i0213': 'Người dùng',
  'i0214': 'Hủy',
  'i0215': 'Thêm',
  'i0216': 'Tạo thư mục thành công',
  'i0217': 'Tạo thư mục thất bại',
  'i0218': 'Tạo thư mục',
  'i0219': 'Vui lòng nhập tên thư mục',
  'i0220': 'Tên thư mục tối thiểu 1 ký tự',
  'i0221': 'Nhập tên thư mục',
  'i0222': 'Hủy',
  'i0223': 'Tạo',
  'i0224': 'Vui lòng nhập tên nhóm',
  'i0225': 'Nhập tên nhóm',
  'i0226': 'Hủy',
  'i0227': 'Tạo',
  'i0228': 'Thêm mới danh bạ',
  'i0229': 'Vui lòng nhập số điện thoại',
  'i0230': 'Nhập số điện thoại',
  'i0231': 'Hủy',
  'i0232': 'Tạo',
  'i0233': 'Thông tin người dùng',
  'i0234': 'Lịch sử đăng nhập',
  'i0235': 'Đăng xuất',
  'i0236': 'Tìm kiếm',
  'i0237': 'Trợ giúp',
  'i0238': 'Cài đặt',
  'i0239': 'Người dùng',
  'i0240': 'Tải tệp lên',
  'i0241': 'Tải thư mục lên',
  'i0242': 'Tạo thư mục',
  'i0243': 'Không có bản xem trước nào',
  'i0244': 'Tải xuống',
  'i0245': 'Thông tin người dùng',
  'i0246': 'Lịch sử đăng nhập',
  'i0247': 'Đăng xuất',
  'i0248': 'Xem kiểu lưới',
  'i0249': 'Xem kiểu danh sách',
  'i0250': 'Dữ liệu của tôi',
  'i0251': 'Đồng bộ dữ liệu',
  'i0252': 'Đã chia sẻ với tôi',
  'i0253': 'Đã mở gần đây',
  'i0254': 'Yêu thích',
  'i0255': 'Ngoại tuyến',
  'i0256': 'Thùng rác',
  'i0257': 'Lưu lượng',
  'i0258': 'Đã sử dụng',
  'i0259': 'Mua lưu lượng',
  'i0260': 'Tất cả',
  'i0261': 'Chưa có quyền',
  'i0262': 'Thông tin',
  'i0263': 'Người sở hữu',
  'i0264': 'Ngày tạo',
  'i0265': 'Người cập nhật',
  'i0266': 'Ngày cập nhật',
  'i0267': 'Những người có quyền truy cập',
  'i0268': 'Chưa chia sẻ',
  'i0269': 'Hoạt động',
  'i0270': 'Không thể tải lên tệp có dung lượng quá lớn',
  'i0271': 'Tải thư mục lên',
  'i0272': 'Máy tính',
  'i0273': 'Đang tải',
  'i0274': 'thư mục lên',
  'i0275': 'tệp lên',
  'i0276': 'Trong số',
  'i0277': 'Tạo thư mục',
  'i0278': 'thất bại',
  'i0279': 'Vui lòng nhập tên thư mục',
  'i0280': 'Tên thư mục không vượt quá 40 ký tự',
  'i0281': 'Nhập tên thư mục',
  'i0282': 'Lưu',
  'i0283': 'Thư mục/tệp của tôi',
  'i0284': 'Chưa có tệp nào trong thư mục này',
  'i0285': 'Tạo thư mục trong',
  'i0286': 'thư mục/tệp của tôi',
  'i0287': 'Dữ liệu của tôi',
  'i0288': 'Đồng bộ dữ liệu',
  'i0289': 'Đã chia sẻ với tôi',
  'i0290': 'Yêu thích',
  'i0291': 'Tạo thư mục trong',
  'i0292': 'thư mục/tệp của tôi',
  'i0293': 'Di chuyển',
  'i0294': 'Không có thông báo',
  'i0295': 'Ngày cập nhật',
  'i0296': 'Tên',
  'i0297': 'Ngày cập nhật',
  'i0298': 'Tên',
  'i0299': `Để đồng bộ tệp/thư mục từ OneDrive lên ${APP_NAME}, bạn hãy cài đặt tính năng đồng bộ`,
  'i0300': 'tại đây',
  'i0301': 'Vui lòng kết nối đến One-drive mà bạn muốn đồng bộ',
  'i0302': 'Tên gói cước',
  'i0303': 'Dung lượng',
  'i0304': 'Cước phí',
  'i0305': 'Thời hạn',
  'i0306': 'Chưa có thông tin gói cước',
  'i0307': 'Chưa có thông tin ngày hết hạn',
  'i0308': 'Chưa có thông tin dung lượng',
  'i0309': 'Chưa có thông tin giá cước',
  'i0310': 'Hủy gói cước',
  'i0311': 'Bạn có thể tải tệp và thư mục lên bằng cách kéo thả vào đây.',
  'i0312': 'Bạn có thể tìm tệp/thư mục được người khác chia sẻ cho bạn tại đây.',
  'i0313': 'Bạn chưa mở tệp nào gần đây.',
  'i0314': 'Chọn yêu thích tệp/thư mục sẽ giúp bạn dễ dàng tìm thấy khi cần.',
  'i0315': 'Thùng rác lưu trữ tệp/thư mục bạn đã xoá. Sau',
  'i0316': 'ngày, tệp/thư mục sẽ tự động bị xoá vĩnh viễn.',
  'i0317': `Để đồng bộ tệp/thư mục từ Google Drive lên ${APP_NAME}, bạn hãy cài đặt tính năng đồng bộ tại đây.`,
  'i0318': `Để đồng bộ tệp/thư mục từ OneDrive lên ${APP_NAME}, bạn hãy cài đặt tính năng đồng bộ tại đây.`,
  'i0319': 'Chưa có tệp nào trên thiết bị này. Chọn vào “Có thể sử dụng khi không có mạng" trong chi tiết của mỗi tệp để có thể mở tệp đó khi thiết bị của bạn không có kết nối mạng.',
  'i0320': 'Không tìm thấy kết quả phù hợp với từ khoá của bạn.',
  'i0321': 'Không tìm thấy kết quả phù hợp với nội dung tìm kiếm của bạn.',
  'i0322': 'Bạn chưa thêm ai vào danh bạ',
  'i0323': 'Bạn chưa tạo nhóm nào',
  'i0324': 'Chưa có thành viên nào trong nhóm',
  'i0325': 'Tên',
  'i0326': 'Xóa thành công!',
  'i0327': 'Thêm mới danh bạ thành công',
  'i0328': 'Thêm bạn bè',
  'i0329': 'Xóa',
  'i0330': 'Có, xóa ngay',
  'i0331': 'Không',
  'i0332': 'Bạn có chắc chắn muốn xóa người này ra khỏi danh bạ?',
  'i0333': 'Thay đổi',
  'i0334': 'Xóa phân quyền',
  'i0335': 'Chọn quyền',
  'i0336': 'Chưa chia sẻ với ai',
  'i0337': 'Đã chia sẻ với',
  'i0338': 'và',
  'i0339': 'người khác',
  'i0340': 'Đã copy link chia sẻ vào clipboard',
  'i0341': 'Chỉ những người được chia sẻ mới có thể truy cập đường dẫn này.',
  'i0342': 'Bất kỳ ai có đường liên kết này đều có thể',
  'i0343': 'bất cứ lúc nào',
  'i0344': 'đến',
  'i0345': 'Vui lòng chọn quyền',
  'i0346': 'Đã chia sẻ thành công',
  'i0347': 'Đã hủy chia sẻ thành công',
  'i0348': 'Email không đúng định dạng',
  'i0349': 'Giới hạn',
  'i0350': 'Công khai',
  'i0351': 'Không có kết quả',
  'i0352': 'Email không tồn tại trong hệ thống.',
  'i0353': 'Nhấn Enter để xác nhận chia sẻ.',
  'i0354': 'Chia sẻ với mọi người',
  'i0355': 'Thêm người, nhóm người, email, số điện thoại',
  'i0356': 'Người dùng',
  'i0357': 'Người dùng',
  'i0358': 'Người dùng ngoài hệ thống',
  'i0359': 'Chưa chia sẻ với người dùng khác',
  'i0360': 'Cập nhật',
  'i0361': 'Phân quyền',
  'i0362': 'Thêm lời nhắn cho người được chia sẻ',
  'i0363': 'Lời nhắn',
  'i0364': 'Hủy',
  'i0365': 'Gửi',
  'i0366': 'Chia sẻ đường liên kết',
  'i0367': 'Thay đổi',
  'i0368': 'Sao chép',
  'i0369': 'Giới hạn',
  'i0370': 'Công khai',
  'i0371': 'Sử dụng mật khẩu chia sẻ',
  'i0372': 'Người được chia sẻ cần nhập mật khẩu để truy cập được đường liên kết.',
  'i0373': 'Tạo mật khẩu',
  'i0374': 'Cập nhật',
  'i0375': 'Thời gian chia sẻ',
  'i0376': 'Thay đổi',
  'i0377': 'Người được chia sẻ có thể truy cập đường liên kết',
  'i0378': 'Vô thời hạn',
  'i0379': 'Giới hạn thời gian',
  'i0380': 'Vui lòng chọn thời gian',
  'i0381': 'Chọn thời gian',
  'i0382': 'Cập nhật',
  'i0383': 'Đồng bộ GoogleDrive thành công',
  'i0384': 'Đồng bộ GoogleDrive thất bại',
  'i0385': 'Đồng bộ OneDrive thành công',
  'i0386': 'Đồng bộ OneDrive thất bại',
  'i0387': 'Không lấy được thông tin OneDrive của bạn',
  'i0388': `Kết nối tài khoản Google Drive, OneDrive của bạn tới tài khoản trên ${APP_NAME} để đồng bộ dữ liệu.`,
  'i0389': 'Đóng kết nối',
  'i0390': 'Kết nối & đồng bộ',
  'i0392': 'Người xem',
  'i0393': 'Người chỉnh sửa',
  'i0394': 'Người nhận xét',
  'i0395': 'Người xem',
  'i0396': 'Người nhận xét',
  'i0397': 'Người chỉnh sửa',
  'i0398': 'Đặt làm người sở hữu',
  'i0399': 'Xoá phân quyền',
  'i0400': 'Cập nhật',
  'i0401': 'Họ tên',
  'i0402': 'Số điện thoại',
  'i0403': 'Email',
  'i0404': 'Chưa có thông tin',
  'i0405': 'Đối mật khẩu',
  'i0406': 'Chỉnh sửa thông tin',
  'i0407': 'Tất cả các quyền được bảo lưu',
  'i0408': 'Điều khoản sử dụng',
  'i0409': 'Chính sách bảo mật',
  'i0410': 'Thay đổi mật khẩu thành công, vui lòng đăng nhập',
  'i0411': 'Số điện thoại',
  'i0412': 'Vui lòng nhập số điện thoại',
  'i0413': 'Nhập số điện thoại',
  'i0414': 'Tiếp tục',
  'i0415': 'Nhập mã xác thực vừa được gửi tới số',
  'i0416': 'Vui lòng nhập mã xác thực',
  'i0417': 'Nhập mã xác thực',
  'i0418': 'Gửi lại mã',
  'i0419': 'Mật khẩu mới',
  'i0420': 'Vui lòng nhập mật khẩu mới',
  'i0421': 'Nhập mật khẩu mới',
  'i0422': `Nhập lại mật khẩu mới`,
  'i0423': 'Vui lòng nhập lại mật khẩu mới',
  'i0424': 'Mật khẩu nhập lại không trùng khớp',
  'i0425': 'Nhập lại mật khẩu mới',
  'i0426': 'Tiếp tục',
  'i0427': 'Xin chào',
  'i0428': 'Số điện thoại',
  'i0429': 'Vui lòng nhập số điện thoại',
  'i0430': 'Nhập số điện thoại',
  'i0431': 'Mật khẩu',
  'i0432': 'Quên mật khẩu',
  'i0433': 'Vui lòng nhập mật khẩu',
  'i0434': 'Nhập mật khẩu',
  'i0435': 'Đăng nhập',
  'i0436': 'Bạn chưa có tài khoản',
  'i0437': 'Đăng ký',
  'i0438': 'Số điện thoại',
  'i0439': 'Vui lòng nhập số điện thoại',
  'i0440': 'Nhập số điện thoại',
  'i0441': 'Họ tên',
  'i0442': 'Vui lòng nhập họ và tên',
  'i0443': 'Họ tên không được vượt quá 40 ký tự',
  'i0444': 'Nhập họ và tên',
  'i0445': 'Vui lòng nhập email',
  'i0446': 'Email không đúng định dạng',
  'i0447': 'Nhập địa chỉ email',
  'i0448': 'Mật khẩu',
  'i0449': 'Vui lòng nhập mật khẩu',
  'i0450': 'Nhập mật khẩu',
  'i0451': 'Nhập lại mật khẩu',
  'i0452': 'Vui lòng nhập lại mật khẩu mật khẩu',
  'i0453': 'Mật khẩu nhập lại không trùng khớp',
  'i0454': 'Nhập lại mật khẩu ',
  'i0455': 'Đăng ký',
  'i0456': 'Quên mật khẩu',
  'i0457': 'Đăng nhập',
  'i0458': 'Đăng ký',
  'i0459': 'Đổi mật khẩu thành công',
  'i0460': 'Đổi mật khẩu',
  'i0461': 'Tài khoản',
  'i0462': 'Đổi mật khẩu',
  'i0463': 'Mật khẩu cũ',
  'i0464': 'Vui lòng nhập mật khẩu cũ',
  'i0465': 'Nhập mật khẩu cũ',
  'i0466': 'Mật khẩu mới',
  'i0467': 'Vui lòng nhập mật khẩu mới',
  'i0468': 'Nhập mật khẩu mới',
  'i0469': 'Nhập lại mật khẩu mới',
  'i0470': 'Vui lòng nhập lại mật khẩu mới',
  'i0471': 'Mật khẩu nhập lại không trùng khớp',
  'i0472': 'Nhập lại mật khẩu mới',
  'i0473': 'Cập nhật',
  'i0474': 'Chi tiết câu hỏi',
  'i0475': 'Danh sách câu hỏi',
  'i0476': 'Chúng tôi có thể giúp gì cho bạn?',
  'i0477': 'Trợ giúp',
  'i0478': 'Nhập vấn đề bạn cần hỗ trợ',
  'i0479': 'Kết quả tìm kiếm',
  'i0480': 'Câu hỏi',
  'i0481': 'Cài đặt',
  'i0482': 'Cài đặt',
  'i0483': 'Cài đặt chung',
  'i0484': 'Danh bạ',
  'i0486': 'Đồng bộ dữ liệu',
  'i0487': 'Đã hủy cập nhật sửa tên {fileTypeName} thành công',
  'i0488': 'Đã hoàn tác',
  'i0489': 'Đã hoàn tác',
  'i0490': 'Đã hoàn tác',
  'i0491': 'Đã hoàn tác',
  'i0492': 'Đã xóa thành công',
  'i0493': 'Đã di chuyển',
  'i0494': 'sang',
  'i0495': 'thành công',
  'i0496': 'Đã xóa thành công',
  'i0497': 'Đã thêm tệp vào danh sách yêu thích',
  'i0498': 'Đã bỏ tệp khỏi danh sách yêu thích',
  'i0499': 'Đã di chuyển',
  'i0500': 'sang',
  'i0501': 'thành công',
  'i0502': 'thư mục',
  'i0503': 'tệp',
  'i0504': 'Đã cập nhật sửa tên',
  'i0505': 'thành công',
  'i0507': 'Đã mở khóa',
  'i0508': 'Ngày cập nhật',
  'i0509': 'Tên',
  'i0510': 'Bạn được chia sẻ quyền',
  'i0511': 'NGÀY GIỜ',
  'i0512': 'THIẾT BỊ',
  'i0513': 'VỊ TRÍ',
  'i0514': 'Lịch sử đăng nhập',
  'i0515': 'Giới thiệu',
  'i0516': 'Đã chuyển vào "Thùng rác"',
  'i0517': 'Đã di chuyển',
  'i0518': 'sang',
  'i0519': 'thành công',
  'i0520': 'Đã chuyển vào "Thùng rác"',
  'i0521': 'Đã thêm tệp vào danh sách yêu thích',
  'i0522': 'Đã bỏ tệp khỏi danh sách yêu thích',
  'i0523': 'Đã di chuyển',
  'i0524': 'sang',
  'i0525': 'thành công',
  'i0526': 'thư mục',
  'i0527': 'tệp',
  'i0528': 'Đã cập nhật sửa tên',
  'i0529': 'thành công',
  'i0530': 'Đã khóa',
  'i0531': 'Đã mở khóa',
  'i0532': 'Bạn có thể tải tệp và thư mục lên bằng cách kéo thả vào đây.',
  'i0533': 'Thư mục',
  'i0534': 'Tệp',
  'i0535': 'Ngày cập nhật',
  'i0536': 'Tên',
  'i0537': 'Ngày cập nhật',
  'i0538': 'Tên',
  'i0539': 'Dữ liệu của tôi',
  'i0540': 'Quyền truy cập',
  'i0541': 'Bạn không có quyền truy cập nội dung này',
  'i0542': 'Quay lại trang trước',
  'i0543': 'Quay lại trang chủ',
  'i0544': 'Trang bạn tìm kiếm không tồn tại!',
  'i0545': 'Quay lại trang trước',
  'i0546': 'Quay lại trang chủ',
  'i0547': 'Ngày cập nhật',
  'i0548': 'Tên',
  'i0549': 'Ngoại tuyến',
  'i0550': 'Tên',
  'i0551': 'Ngày cập nhật',
  'i0552': 'Tên',
  'i0553': 'Ngày cập nhật',
  'i0554': 'Xin chào',
  'i0555': 'Vui lòng nhập mã OTP',
  'i0556': 'Chính sách bảo mật',
  'i0557': 'Cập nhật thông tin tài khoản',
  'i0558': 'Tài khoản',
  'i0559': 'Chỉnh sửa thông tin',
  'i0560': 'Thông tin tài khoản',
  'i0561': 'Tài khoản',
  'i0562': 'Thông tin tài khoản',
  'i0563': 'Lịch sử đăng nhập',
  'i0564': 'Đã chuyển vào "Thùng rác"',
  'i0565': 'Đã xóa thành công',
  'i0566': 'Đã thêm tệp vào danh sách yêu thích',
  'i0567': 'Đã bỏ tệp khỏi danh sách yêu thích',
  'i0568': 'Đã di chuyển',
  'i0569': 'sang',
  'i0570': 'thành công',
  'i0571': 'thư mục',
  'i0572': 'tệp',
  'i0573': 'Đã cập nhật sửa tên',
  'i0574': 'thành công',
  'i0575': 'Đã khóa',
  'i0576': 'Đã mở khóa',
  'i0577': 'Hôm nay',
  'i0578': 'Hôm qua',
  'i0579': '7 ngày trước',
  'i0580': 'Tên',
  'i0581': 'Ngày xem',
  'i0582': 'Ngày cập nhật',
  'i0583': 'Đã mở gần đây',
  'i0584': 'Kết quả tìm kiếm',
  'i0585': 'Đã chuyển vào "Thùng rác"',
  'i0586': 'Đã xóa thành công',
  'i0587': 'Đã thêm tệp vào danh sách yêu thích',
  'i0588': 'Đã bỏ tệp khỏi danh sách yêu thích',
  'i0589': 'Đã di chuyển',
  'i0590': 'sang',
  'i0591': 'thành công',
  'i0592': 'thư mục',
  'i0593': 'tệp',
  'i0594': 'Đã cập nhật sửa tên',
  'i0595': 'thành công',
  'i0596': 'Đã khóa',
  'i0597': 'Đã mở khóa',
  'i0598': 'Ngày chia sẻ',
  'i0599': 'Tên',
  'i0600': 'Ngày cập nhật',
  'i0601': 'Ngày chia sẻ',
  'i0602': 'Tên',
  'i0603': 'Ngày cập nhật',
  'i0604': 'Thư mục',
  'i0605': 'Tệp',
  'i0606': 'Đã chia sẻ với tôi',
  'i0607': 'Đồng bộ dữ liệu',
  'i0608': 'Điều khoản sử dụng',
  'i0609': 'Tên',
  'i0610': 'Thời gian xóa file',
  'i0611': 'Tên',
  'i0612': 'Thời gian cập nhật',
  'i0613': 'Thời gian  xóa file',
  'i0614': 'Để xem thư mục này, bạn sẽ cần phải khôi phục thư mục từ thùng rác của mình.',
  'i0615': 'Bạn có chắc chắn muốn xoá thư mục này?',
  'i0616': 'Bạn sẽ không thể khôi phục lại thư mục sau khi đã xoá vĩnh viễn.',
  'i0617': 'Bạn có chắc chắn muốn xoá tệp này?',
  'i0618': 'Bạn sẽ không thể khôi phục lại tệp sau khi đã xoá vĩnh viễn.',
  'i0619': 'Bạn có chắc chắn muốn xoá nội dung này?',
  'i0620': 'Bạn sẽ không thể khôi phục lại nội dung sau khi đã xoá vĩnh viễn.',
  'i0621': 'Bạn có chắc chắn muốn xoá nội dung này?',
  'i0622': 'Bạn sẽ không thể khôi phục lại nội dung sau khi đã xoá vĩnh viễn.',
  'i0623': 'Bạn có chắc chắn muốn xoá những tệp/thư mục này?',
  'i0624': 'Bạn sẽ không thể khôi phục lại chúng sau khi đã xoá vĩnh viễn.',
  'i0625': 'Thùng rác',
  'i0626': 'Thư mục/tệp trong thùng rác sẽ tự động bị xoá vĩnh viễn sau',
  'i0627': 'ngày',
  'i0628': 'Xoá tất cả trong thùng rác',
  'i0629': 'Hủy',
  'i0630': 'Khôi phục',
  'i0631': 'Xoá vĩnh viễn',
  'i0632': 'Bạn có chắc chắn muốn xoá người này ra khỏi nhóm?',
  'i0633': 'Có, xóa ngay',
  'i0634': 'Không',
  'i0635': 'Không',
  'i0636': 'Xóa thành viên trong nhóm thành công',
  'i0637': 'Bạn có chắc chắn muốn xoá những người này ra khỏi nhóm?',
  'i0638': 'Có, xóa ngay',
  'i0639': 'Không',
  'i0640': 'Thêm mới thành viên vào nhóm thành công',
  'i0641': 'Cập nhật nhóm thành công',
  'i0642': 'Cập nhật nhóm',
  'i0643': 'Quản lý nhóm',
  'i0644': 'Cập nhật nhóm',
  'i0645': 'Tên nhóm',
  'i0646': 'Nhập tên nhóm',
  'i0647': 'Cập nhật',
  'i0648': 'Danh sách thành viên',
  'i0649': 'Thêm thành viên',
  'i0485': 'Nhóm',
  'i0650': 'Xóa',
  'i0651': 'Xóa phân quyền',
  'i0652': 'Cập nhật thành công',

  'i0653': 'Để hủy gói cước vui lòng soạn tin nhắn',
  'i0654': 'gửi',
  'i0655': 'Vui lòng soạn tin nhắn để mua thêm lưu lượng',
  'i0656': 'Vui lòng hủy gói cước hiện tại để đăng ký gói cao hơn',
  'i0657': 'Đồng bộ từ Google Drive',
  'i0658': 'Đồng bộ từ One Drive',
  'i0659': 'Chế độ xem',
  'i0660': 'Chế độ xem kiểu lưới',
  'i0661': 'Chế độ xem kiểu danh sách',
  'i0662': 'Kết nối tài khoản Google Drive, OneDrive của bạn tới tài khoản trên',
  'i0663': 'để đồng bộ dữ liệu.',
  'i0664': 'Vui lòng nhập mật khẩu',
  'i0665': 'Họ tên tối thiểu 3 ký tự',
  'i0666': 'Chưa kết nối đến Google Drive. Vui lòng kết nối trước',
  'i0667': 'Chưa kết nối đến One Drive. Vui lòng kết nối trước',
  'i0668': 'Không thể xóa nội dung của người khác chia sẻ với bạn',
  'i0669': 'Thông báo',
  'i0670': '1 tệp được nén',
  'i0671': 'Thêm mới nhóm',
  'i0672': 'Thông báo',
  'i0673': 'Tìm kiếm',
  'i0674': 'Vui lòng nhập lời nhắn cho người được chia sẻ',
  'i0675': 'thành viên',
  'i0676': 'Đã hủy tải lên',
  'i0677': 'đã hủy',
  'i0678': 'Người dùng đã hủy',
  'i0679': 'Hủy tải lên',
  'i0680': 'Tải lên của bạn chưa hoàn tất. Bạn có muốn hủy tải lên không?',
  'i0681': 'Tiếp tục tải lên',
  'i0682': 'Tải lại',
  'i0683': 'Đăng nhập',
  'i0684': 'Không có bản xem trước nào',
  'i0685': 'Tải xuống',
  'i0686': 'Đã xảy ra lỗi',
  'i0687': 'Vui lòng nhập mật khẩu để xem tệp',
  'i0688': 'Chưa chia sẻ',
  'i0689': 'Tạo thư mục mới',
  'i0690': 'Đang chuẩn bị tải xuống',
  'i0691': 'Đã huỷ tải xuống',
  'i0692': 'Không có nội dung bên trong folder',
  'i0693': 'Không có dữ liệu',
  'i0694': 'Thư mục',
  'i0695': 'Kích thước',
  'i0696': 'Đồng bộ dữ liệu thành công,',
  'i0697': 'thư mục',
  'i0698': 'tệp đã được đồng bộ',
  'i0699': 'Tìm kiếm nâng cao',
  'i0700': 'Sắp xếp giảm dần',
  'i0701': 'Sắp xếp tăng dần',
  'i0702': 'Tải xuống',
  'i0703': 'Độ dài lời nhắn vượt quá 200 ký tự',
  'i0704': 'Đăng nhập',
  'i0705': 'Đã hủy nén',
  'i0706': 'Đã tải lên thành công',
  'i0707': 'Đang đồng bộ',
  'i0708': 'tệp',
  'i0709': 'Tôi đồng ý với',
  'i0710': 'điều khoản sử dụng',
  'i0711': 'Vui lòng chọn đồng ý điều khoản sử dụng',
  'i0712': 'Đăng nhập',
  'i0713': 'Tài khoản',
  'i0714': 'Gói cước',
  'i0715': 'Xóa thành công',
  'i0716': 'Đã huỷ tải lên',
  'i0717': 'Đã hết dung lượng',
  'i0718': 'Bạn không có quyền truy cập nội dung này',
  'i0719': 'Dung lượng tối đa ảnh là 6Mb',
  'i0720': 'Vui lòng nhập tên nhóm',
  'i0721': 'Tải ứng dụng mobiCloud',
  'i0722': 'Sản phẩm chỉ áp dụng cho thuê bao MobiFone',
}
export default vi






















