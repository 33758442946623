import React from 'react'
import SVGIcon from '../components/SVGIcon'
import { toJS } from 'mobx'

const fileUtils = {

  renderFileIcon: fileTypeId => {
    switch (fileTypeId) {
      case 0:
        return <SVGIcon name={'folder'} />
      case 1:
        return <SVGIcon name={'pdf'} />
      case 2:
        return <SVGIcon name={'archive'} />
      case 3:
        return <SVGIcon name={'image'} />
      case 4:
        return <SVGIcon name={'movie'} />
      case 5:
        return <SVGIcon name={'music'} />
      case 6:
        return <SVGIcon name={'excel'} />
      case 7:
        return <SVGIcon name={'word'} />
      default:
        return null
    }
  },

  getFileExtension: (filename) => {
    let ext = /^.+\.([^.]+)$/.exec(filename)
    return ext == null ? '' : `.${ext[1].toLowerCase()}`
  },

  reduceFiles: (key, keyGet, array) => {
    let obj = {}
    array.reduce(function(rv, x) {
      obj[x[key]] = x[keyGet]
      return rv
    }, [])

    return obj
  },

  getFileTypeFromMimetype: (mimetype, fileTypesArr) => {
    const result = fileTypesArr.find(item => item.mimetype === mimetype)
    if (result && result.name) return result.name
    return 'Unknown type'
  },

  findObjects:(obj, targetProp, targetValue, finalResults) => {
    /*
     With reference to  https://stackoverflow.com/questions/15523514/find-by-key-deep-in-a-nested-object
    */
    function getObject(theObject) {
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
          let val = theObject[i]
          getObject(val);
        }
      }
      else {
        for (let prop in theObject) {
          if (theObject.hasOwnProperty(prop)) {
            let values = theObject[prop]
            if (prop === targetProp) {
              if ((values === targetValue) ||
                (targetValue === null)) {
                finalResults.push(theObject);
              }
            }
            if (values instanceof Object || values instanceof Array) {
              getObject(values);
            }
          }
        }
      }
    }
    getObject(obj);
  }


}

export default fileUtils
