import styled, { css } from 'styled-components'

export const SectionHeading = styled.h2`
  color: #313131;
  font-size: 16px;
  line-height: 18.75px;
  margin-bottom: 16px;
`
export const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
`
export const GridItem = styled.li`
  @media screen and (min-width: 1441px) {
    width: 15.53%;
    margin-right: 1.364%;
    margin-bottom: 1.364%;
    &:nth-last-child(-n+6) {
      margin-bottom: 0;
    }

    &:nth-child(6n) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1440px) and (min-width: 1025px) {
    width: 23.5%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-last-child(-n+4) {
      margin-bottom: 0;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 48.5%;
    margin-right: 3%;
    margin-bottom: 3%;
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  border: 1px solid transparent;
  position: relative;
  ${props => !props.isGridMode && css`
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      border-radius: 0;
      &:nth-last-child(-n+2) {
        margin-bottom: 3%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .file-thumbnail {
        display: none !important;
      }
    }
  `}
`
export const FileContainer = styled.div`
  border: 1px solid #E2E2E2;
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  user-select: none;

  &.selected {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }

  .file-thumbnail {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 17px;
    background-color: #F9F9F9;
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      object-fit: cover;
      object-position: center;
    }
  }

  .file-info {
    display: flex;
    align-items: center;

    .svgicon {
      margin-right: 8px;
      position: relative;
      bottom: 1.5px;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 30px;
    }
  }
`
export const GridItemMobileHandler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .anticon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 1.7rem;
    color: #999999;
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`
