import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { message, Table } from 'antd'
import { useTranslation } from 'react-i18next'

const HistoryLoginTab = props => {
  const { profileStore } = props
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)

  const { t } = useTranslation()

  const tableColumns = [
    {
      title: t('i0208'),
      render: record => <span>{record.created_at}</span>,
    },
    {
      title: t('i0209'),
      ellipsis: true,
      render: record => <span>{record.device_name}</span>,
    },
    {
      title: t('i0210'),
      render: record => <span>{record.location}</span>,
    },
  ]

  useEffect(() => {
    profileStore.getHistoryLogin(page, perPage).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }, [])

  return (
    <Table dataSource={profileStore.historyLoginList || []} columns={tableColumns} rowKey={record => record.id}
      pagination={false} />
  )
}

export default inject(
  'profileStore',
)(observer(HistoryLoginTab))