import styled, { keyframes } from 'styled-components'
import { SettingOutlined } from '@ant-design/icons'

export const SectionHeading = styled.h2`
  color: #313131;
  font-size: 16px;
  line-height: 18.75px;
  margin-bottom: 16px;
`

export const slideDownIn = () => keyframes`
  0% {
    -webkit-transform: scaleY(.8);
    transform: scaleY(.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0
  }
  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1
  }
`
export const slideUpIn = () => keyframes`
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1
  }
  to {
    -webkit-transform: scaleY(.8);
    transform: scaleY(.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0
  }
`

export const PageHeading = styled.div`
  font-size: 20px;
  color: #313131;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
  }
  svg {
    height: 16px;   
  }
`
export const PageHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`
export const BackToViewMode = styled.span`
  cursor: pointer;
`
export const ProfileHeading = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  color: #313131;
`
export const ProfileInfoWrapper = styled.div`
  display: flex;
`
export const ProfileInfoLabel = styled.div`
  display: flex;
  flex-direction: column;
  color: #8C8C8C;
  font-size: 1.4rem;

  span {
    padding: 8px 0;
  }
`
export const ProfileInfoData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  color: #313131;
  font-size: 1.4rem;

  span, a {
    padding: 8px 0;
  }
`
export const SortButton = styled.div`
  cursor: pointer;
`
export const ConfigIcon = styled(SettingOutlined)`
  font-size: 1.9rem;
  color: #313131;
`
export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`
export const ModalTitle = styled.div`
  font-weight: 500;
  text-align: center;
  color: #313131;
  font-size: 1.6rem;
  margin-bottom: 16px;
`
export const CustomNotificationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  line-height: 23px;
`
export const CustomCustomNotificationMessage = styled.div`
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  padding-right: 16px;
`
export const CustomCustomNotificationLink = styled.a`
  font-size: 1.4rem;
  font-weight: 400;
`
export const OtpTitle = styled.h1`
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 2rem;
  color: #313131;
`
export const FileListViewNameWrapper = styled.div`
  display: flex;
  align-items: center;
  .favorite-star {
    margin-bottom: 5px;
  }
  .data-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
    overflow: hidden;
    margin-left: 15px;
    user-select: none;
    margin-right: 10px;
  }
`
export const ScrollLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 100%;
`
export const SimpleArticle = styled.article`
  padding: 19px 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;

  h1 {
    font-weight: 500;
    color: #313131;
    font-size: 20px;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 16px;
    color: #313131;
    font-size: 14px;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
`
