import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Button, Form, Input, message, Tooltip } from 'antd'
import {
  CreatingContent, FolderName,
  MoveBodyItem,
  MoveItemMenuBody,
  MoveItemMenuFooter,
  MoveItemMenuHeader,
  MoveItemMenuWrapper,
  MoveRootItem,
} from './MoveItemMenuDropdownStyled'
import validator from '../../validator'
import { APP_NAME, CONTENT_TYPE } from '../../utils/constant'
import { useTranslation } from 'react-i18next'

const MoveItemMenuDropdown = props => {
  const {
    moveMenuVisible,
    onClose,
    selectionStore,
    moveItemStore,
    myDriveStore,
    callbackMoveItem,
    itemToMove,
    isLastItem,
    x,
    y,
  } = props
  const wrapperRef = useRef(null)
  const [form] = Form.useForm()
  const [isCreatingFolder, setIsCreatingFolder] = useState(false)
  const [creatingFolderName, setCreatingFolderName] = useState('')
  const [isSuccessCreateFolder, setIsSuccessCreateFolder] = useState(false)
  const [isRootFolder, setIsRootFolder] = useState(false)

  const { t } = useTranslation()
  useOutsideAlerter(wrapperRef)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose()
          selectionStore.clearSelectionIndex()
          moveItemStore.clearMoveItem()
          setIsCreatingFolder(false)
          setCreatingFolderName('')
          setIsRootFolder(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleClose = () => {
    onClose()
    moveItemStore.clearMoveItem()
    setIsCreatingFolder(false)
    setCreatingFolderName('')
    selectionStore.clearSelectionIndex()
    setIsRootFolder(false)
  }
  const handleSelectTargetFolder = (folder) => {
    moveItemStore.setSelectedTargetFolder(folder)
  }
  const handleSubmitMove = () => {
    if (!moveItemStore.selectedTargetFolder) {
      callbackMoveItem(itemToMove, null)
    } else {
      callbackMoveItem(itemToMove, moveItemStore.selectedTargetFolder)
    }
    onClose()
    moveItemStore.clearMoveItem()
    setIsCreatingFolder(false)
    setCreatingFolderName('')
    setIsRootFolder(false)
  }
  const handleGoBackToFolder = () => {
    if (!moveItemStore.currentFolder?.parent_id) {
      moveItemStore.setCurrentFolder(null)
    } else {
      moveItemStore.getDetailParentFolder(moveItemStore.currentFolder?.parent_id)
        .then(response => {
          if (response.error_code === 0) {
            let parent_id = response.data?.parent?.id
            moveItemStore.setCurrentFolder({ ...response.data, parent_id })
            moveItemStore.setSelectedTargetFolder(response.data)
          } else {
            message.error(response.message)
          }
        })
        .catch(error => error?.response?.status != 401 && message.error(error.message))
    }
  }
  const handleGoRootFolder = () => {
    setIsRootFolder(true)
  }
  const handleGoToFolder = (folder) => {
    moveItemStore.setCurrentFolder(folder)
    moveItemStore.setSelectedTargetFolder(folder)
  }
  const handleChangeCreatingFolderName = (e) => {
    setCreatingFolderName(e.currentTarget.value)
  }
  const handleCancelCreateFolder = () => {
    setIsCreatingFolder(false)
    setCreatingFolderName('')
    setIsSuccessCreateFolder(false)
    moveItemStore.setSelectedTargetFolder(null)
    // setIsRootFolder(false)
  }
  const handleSubmitCreateFolder = (formData) => {
    let data = {
      name: formData.folderName,
    }
    if (moveItemStore.currentFolder) {
      data = { ...data, parent_id: moveItemStore.currentFolder.id }
    }
    myDriveStore.createFolder(data).then(response => {
      if (response.error_code === 0) {
        moveItemStore.setSelectedTargetFolder(response.data)
        setIsSuccessCreateFolder(true)
        setIsRootFolder(false)
        let payload = {
          parent_id: myDriveStore.myDriveCurrentNode?.id,
          content_type: CONTENT_TYPE.myDrive,
          page: 1,
          per_page: 1000,
          sort: myDriveStore.myDriveSort,
          sort_by: myDriveStore.myDriveSortBy,
        }
        myDriveStore.getMyDriveList(payload).then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
      } else {
        message.error(response.message)
      }
    }).catch(error => {
      message.error(`${t('i0277')} ${formData.folderName} ${t('i0278')}`)
    }).finally(() => form.resetFields())
  }
  const handleSubmitCreateFolderFail = (error) => {
    message.error(error.errorFields[0]?.errors[0])
    console.log(error)
  }

  useEffect(() => {
    if (moveMenuVisible) {
      moveItemStore.getFolderList(moveItemStore.currentFolder?.id, itemToMove)
        .then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
    }
  }, [moveMenuVisible, moveItemStore.currentFolder?.id, isSuccessCreateFolder])
  useEffect(() => {
    moveItemStore.setCurrentFolder(myDriveStore.myDriveCurrentNode)
  }, [moveMenuVisible])


  return (
    moveMenuVisible &&
    <MoveItemMenuWrapper
      isLastItem={isLastItem}
      ref={wrapperRef} x={x} y={y}
      className={'context-menu'}>
      <MoveItemMenuHeader>

        {
          isCreatingFolder
            ?
            <>
              <img onClick={handleCancelCreateFolder}
                   src={`${process.env.PUBLIC_URL}/assets/icons/left.svg`} alt={'icon'} />
              <Form onFinish={handleSubmitCreateFolder}
                    onFinishFailed={handleSubmitCreateFolderFail}
                    layout={'inline'}>
                <Form.Item name={'folderName'}
                           noStyle={true}
                           rules={[
                             { required: true, message: t('i0279') },
                             { validator: validator.validateInputStringFolderName },
                             { max: 40, message: t('i0280') },
                           ]}>
                  <Input onChange={handleChangeCreatingFolderName}
                         size={'large'}
                         style={{ borderRadius: '8px', width: '206px' }}
                         placeholder={t('i0281')} />
                </Form.Item>
                <Form.Item style={{ marginRight: '0' }}>
                  <Button htmlType={'submit'}
                          type={'link'}
                          size={'large'}
                          disabled={creatingFolderName.trim().length === 0 || isSuccessCreateFolder}>
                    {t('i0282')}
                  </Button>
                </Form.Item>
              </Form>
            </>
            :
            <>
              {
                moveItemStore.currentFolder
                  ?
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/left.svg`}
                       style={{ cursor: 'pointer' }}
                       onClick={handleGoBackToFolder}
                       alt={'icon'} />
                  :
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/left.svg`}
                       style={{ cursor: 'pointer' }}
                       onClick={handleGoRootFolder}
                       alt={'icon'} />
              }

              {
                moveItemStore.currentFolder
                  ?
                  <Tooltip title={moveItemStore.currentFolder.name} mouseEnterDelay={0.3}>
                    <FolderName>{moveItemStore.currentFolder.name}</FolderName>
                  </Tooltip>
                  :
                  isRootFolder
                    ?
                    <span>{APP_NAME}</span>
                    :
                    <span>{t('i0283')}</span>
              }
              <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel.svg`} alt={'icon'}
                   style={{ cursor: 'pointer' }}
                   onClick={handleClose} />
            </>
        }

      </MoveItemMenuHeader>
      <MoveItemMenuBody>
        {
          isCreatingFolder
            ?
            <CreatingContent>
              {
                isSuccessCreateFolder
                  ? t('i0284')
                  : `${t('i0285')} ${moveItemStore.currentFolder ? moveItemStore.currentFolder.name : t('i0286')}`
              }

            </CreatingContent>
            :
            isRootFolder
              ?
              <>
                <MoveRootItem onClick={() => setIsRootFolder(false)}>
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tablet.png`} alt={''} />
                  <span>{t('i0287')}</span>
                </MoveRootItem>
                <MoveRootItem isDisable={true}>
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/sync-solid1.png`} alt={''} />
                  <span>{t('i0288')}</span>
                </MoveRootItem>
                <MoveRootItem isDisable={true}>
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/users.png`} alt={''} />
                  <span>{t('i0289')}</span>
                </MoveRootItem>
                <MoveRootItem isDisable={true}>
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/star.png`} alt={''} />
                  <span>{t('i0290')}</span>
                </MoveRootItem>
              </>
              :
              moveItemStore.currentFolderNestedFolderList?.length > 0
                ?
                moveItemStore.currentFolderNestedFolderList.map(item =>
                  <MoveBodyItem
                    key={item.id}
                    className={moveItemStore.selectedTargetFolder === item && 'active'}
                    onDoubleClick={() => handleGoToFolder(item)}
                    onClick={() => handleSelectTargetFolder(item)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/folder.svg`}
                      alt={'icon'}
                      width={22}
                      height={22} />
                    <Tooltip title={item.name} mouseEnterDelay={0.3}>
                      <span className={'folderName'}>{item.name}</span>
                    </Tooltip>
                    {
                      moveItemStore.selectedTargetFolder === item &&
                      <img onClick={() => handleGoToFolder(item)}
                           src={`${process.env.PUBLIC_URL}/assets/icons/backright.svg`} />
                    }
                  </MoveBodyItem>,
                )
                :
                <CreatingContent>
                  {`${t('i0291')} ${moveItemStore.currentFolder ? moveItemStore.currentFolder.name : t('i0292')}`}
                </CreatingContent>
        }

      </MoveItemMenuBody>
      <MoveItemMenuFooter>
        <Button type={'link'}
                disabled={isSuccessCreateFolder}
                onClick={() => setIsCreatingFolder(true)}>
          {
            isSuccessCreateFolder
              ?
              <img src={`${process.env.PUBLIC_URL}/assets/icons/folder-simple-plus2.svg`} style={{ opacity: '0.4' }}
                   alt={'icon'} />
              :
              <img src={`${process.env.PUBLIC_URL}/assets/icons/folder-simple-plus2-blue.svg`} alt={'icon'} />
          }

          <span>{t('i0689')}</span>
        </Button>
        <Button type={'primary'} style={{ borderRadius: '8px' }}
                disabled={(isCreatingFolder && !isSuccessCreateFolder) || isRootFolder}
                onClick={handleSubmitMove}>
          {t('i0293')}
        </Button>
      </MoveItemMenuFooter>
    </MoveItemMenuWrapper>
  )
}

MoveItemMenuDropdown.propTypes = {
  moveMenuVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callbackMoveItem: PropTypes.func.isRequired,
  itemToMove: PropTypes.array,
  isLastItem: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
}

export default inject('moveItemStore', 'myDriveStore', 'selectionStore')(observer(MoveItemMenuDropdown))