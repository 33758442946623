import styled from 'styled-components'

export const InsertFolderSimpleModalWrapper = styled.div`

`
export const InsertFolderSimpleTitle = styled.div`
  font-weight: 500;
  text-align: center;
  color: #313131;
  font-size: 1.6rem;
  margin-bottom: 16px;
`