import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Button, Col, Row } from 'antd'
import { ProfileHeading, ProfileInfoData, ProfileInfoLabel, ProfileInfoWrapper } from '../CommonStyled/CommonStyled'
import { useTranslation } from 'react-i18next'
import miscUtils from '../../utils/miscUtils'

const ViewAccountInfoRow = props => {
  const { onClickEdit, profileStore } = props
  const history = useHistory()
  const { t } = useTranslation()

  const handleClickEdit = () => {
    onClickEdit()
  }
  const handleClickChangePassword = () => {
    history.push('/change-password')
  }

  return (
    profileStore.myProfile &&
    <Row>
      <Col xxl={3} xl={3} lg={24} md={24} sm={24} xs={24}>
        <ProfileHeading>{t('i0713')}</ProfileHeading>
      </Col>
      <Col xxl={15} xl={13} lg={12} md={24} sm={24} xs={24}>
        <ProfileInfoWrapper>
          <ProfileInfoLabel>
            <span>{t('i0401')}</span>
            <span>{t('i0402')}</span>
            <span>{t('i0403')}</span>
          </ProfileInfoLabel>
          <ProfileInfoData>
            <span>{profileStore.myProfile.name || t('i0404')}</span>
            <span>{miscUtils.phoneNumberReplace(profileStore.myProfile.username) || t('i0404')}</span>
            <a>{profileStore.myProfile.email || t('i0404')}</a>
          </ProfileInfoData>
        </ProfileInfoWrapper>
      </Col>
      <Col xxl={6} xl={8} lg={12} md={24} sm={24} xs={24}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Button type={'default'}
                    style={{ borderRadius: '8px' }}
                    block
                    onClick={handleClickChangePassword}>
              {t('i0405')}
            </Button>
          </Col>
          <Col span={12}>
            <Button type={'primary'}
                    style={{ borderRadius: '8px' }}
                    block
                    onClick={handleClickEdit}>
              {t('i0406')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

ViewAccountInfoRow.propTypes = {
  onClickEdit: PropTypes.func.isRequired,
}

export default inject('profileStore')(observer(ViewAccountInfoRow))