import axios from 'axios'
import config from '../config'
import authenticationStore from '../stores/authenticationStore'
const {apiUrl} = config
const source = axios.CancelToken.source()

export const UploadRequest = {

  cancelRequest: () => {
    source.cancel()
  },

  getUploadLink: (payload) => {
    return axios({
      method: 'post',
      baseURL:apiUrl,
      url: '/api/nodes/get-upload-link',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
      disableSpinner: true
    })
  },

  uploadFiles: (url, payload, onUploadProgress) => {
    return axios({
      method: 'put',
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgress,
      data: payload,
      disableSpinner: true,
      cancelToken:payload.cancelSource.token
    });
  },

  activeUploadFiles: (payload) => {
    return axios({
      method: 'post',
      baseURL:apiUrl,
      url: `/api/nodes/${payload.node_id}/active`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
      disableSpinner: true
    })
  },

  createFolderUpload: (payload,cancelSource) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/folders/create`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      disableSpinner: true,
      data: payload,
      cancelToken:cancelSource.token
    })
  },

  cancelUploadFolder:(payload)=>{
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/trash`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        list_id: payload,
      },
      disableSpinner: true,
    })
  },

  syncToMobifoneDrive: (payload,onUploadProgress) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/social-storage/sync`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        access_token: payload.access_token,
        file_id: payload.file_id || payload.id,
        driver: payload.driver,
        cmc_parent_id: payload.cmc_parent_id || null,
      },
      onUploadProgress: onUploadProgress,
      disableSpinner: true,
      cancelToken:payload.cancelSource.token
    })
  },


}
