import axios from 'axios'
import config from '../config'
import authenticationStore from '../stores/authenticationStore'
import { CONTENT_TYPE } from '../utils/constant'

const { apiUrl } = config

const source = axios.CancelToken.source()

export const FilePreviewRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  checkShareLinkFile: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      url: `/api/nodes/${payload.node_id}/share-from-link`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      notRedirectLogin: true,
    })
  },

  getPublicLinkDownloadFile: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/public/nodes/${payload.node_id}/get-download-link`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

  checkPasswordFileDownload: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/public/nodes/${payload.node_id}/get-download-link`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

  checkFilePermission:(payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/public/nodes/${payload.node_id}/check-permission`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

  getFileUnauthenticated:(payload)=>{
    return axios({
      method: 'post',
      baseURL: apiUrl,
      url: `/api/public/nodes/list`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data:{
        ...payload
      }
    })
  },

  getDetailFileUnauthenticated:(payload)=>{
    return axios({
      method: 'post',
      baseURL: apiUrl,
      url: `/api/public/nodes/detail`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data:{
        ...payload
      }
    })
  },

  downloadPublicFolder: (node_id, cancelSource, onDownloadProgress) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/public/nodes/${node_id}/get-download-link`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      onDownloadProgress: onDownloadProgress,
      cancelToken: cancelSource.token,
      disableSpinner: true,
    })
  },
}