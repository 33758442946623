import { action, observable } from 'mobx'
import { PrivacyPolicyRequest } from '../requests/PrivacyPolicyRequest'

class PrivacyPolicyStore {

  @observable privacyPolicyContent = {}

  @action cancelRequest = () => {
    PrivacyPolicyRequest.cancelRequest()
  }

  @action getContentPrivacyPolicyPage = () => {
    return new Promise((resolve,reject) => {
      PrivacyPolicyRequest.getContentPrivacyPolicyPage().then(response => {
        this.privacyPolicyContent = response.data?.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default new PrivacyPolicyStore()