import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1620px;
  padding: 20px 15px;
  margin-left: 150px;
  margin-right: 150px;
`
export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    color: #313131;
    font-size: 20px;
    font-weight: 500;
  }
`