import styled, { css } from 'styled-components'
import { DatePicker, Input, Select } from 'antd'

export const SharingModalWrapper = styled.div`
`

export const StyledInput = styled(Input)`
  background: #F9FAFC;
  border: 1px solid #F1F1F5;
  border-radius: 8px;
  padding: 13px 11px;

  &::placeholder {
    color: #A7A7A7;
  }
`
export const StyledInputPassword = styled(Input.Password)`
  background: #F9FAFC;
  border: 1px solid #F1F1F5;
  border-radius: 8px;
  padding: 13px 11px;

  &::placeholder {
    color: #A7A7A7;
  }

  .ant-input {
    background-color: transparent;
  }
`
export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    background-color: #237BD3;
    border-radius: 4px;
  }
`
export const ModalHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  h2 {
    margin-bottom: 0;
    color: #313131;
    font-size: 1.6rem;
  }
`
export const MemberSharingSection = styled.section`
  background-color: #fff;
  padding: 16px 18px;
  border-radius: 10px;
  margin-bottom: 16px;
`
export const UserList = styled.ul`
  ${props => props.showScroll && css`
    height: 250px;
  `}
`
export const UserListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`
export const UserAvatar = styled.a`
  display: block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;

  img {
    width: 100%;
    object-position: center;
    object-fit: cover;
  }
`
export const UserInfo = styled.div`
  margin-right: auto;
  margin-left: 8px;

  p {
    margin-bottom: 0;

    &:first-child {
      color: #313131;
      font-size: 1.4rem;
    }

    &:last-child {
      color: #8C8C8C;
      font-size: 1.2rem;
    }
  }
`
export const UserRole = styled.div`
  cursor: pointer;
  color: #313131;
  transition: all ease .3s;
  padding: 4px 10px 4px 15px;
  border-radius: 8px;

  &:hover {
    transition: all ease .3s;
    background-color: #EDF6FF;
  }

  .anticon {
    margin-left: 12px;
  }
`
export const UserOwned = styled.p`
  color: #8C8C8C;
  margin-bottom: 0;
  padding-right: 10px;
`
export const LinkSharingSection = styled(MemberSharingSection)`
  p {
    color: #313131;
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
`
export const SharingLinkInput = styled(StyledInput)``
export const Divider = styled.hr`
  height: 1px;
  border: none;
  border-bottom: 1px solid #E2E2E2;
  margin: 16px 0 13px;
  padding: 0;
  position: relative;
  left: -18px;
  width: calc(100% + 36px);
`
export const SharingNotice = styled.em`
  display: block;
  font-style: italic;
  color: #313131;
  font-size: 1.2rem;
  margin-top: 11px;
`
export const TimeSharingSection = styled(MemberSharingSection)`
  p {
    color: #313131;
    font-size: 1.4rem;
  }
`
export const EditSharingLinkPermissionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: -5px;

  .col-left {
    width: 60%;
  }

  .col-right {
    width: 40%;
    text-align: right;
  }
`
export const PrivateSharingDropdown = styled(UserRole)`
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
  font-weight: 500;

  .anticon {
    margin-left: 0;
  }

  .svgicon {
    margin-bottom: 3px;
  }

  span {
    margin-left: 5px;
    margin-right: 6px;
  }
`
export const AudienceRole = styled(UserRole)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 10px;
`
export const StyledDatePicker = styled(DatePicker)`
  background: #F9FAFC;
  border: 1px solid #F1F1F5;
  border-radius: 8px;
  padding: 13px 11px;
  width: 100%;

  .ant-picker-input {
    flex-direction: row-reverse;

    .ant-picker-suffix {
      margin-left: 0;
      margin-right: 8px;
    }

    input {
      line-height: 20px;
      height: 20px;
      padding: 0 15px 0 0;
    }
  }

  &::placeholder {
    color: #A7A7A7;
  }
`
export const StyledTagsSelect = styled.div`
  position: relative;

  .custom-tags-select {
    background: #F9FAFC;
    border: 1px solid #F1F1F5;
    border-radius: 8px;

    &::placeholder {
      color: #A7A7A7;
    }

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      padding: 0 50px 0 5px;
      min-height: 50px;

      .ant-select-selection-item {
        padding: 5px 10px;
        height: auto;
        line-height: normal;
        margin: 4px;
        border: none;
        border-radius: 40px;
        background-color: #E8E8E8;
        min-height: 42px;
        align-items: center;

        p:last-of-type {
          display: none;
        }

        .ant-select-selection-item-content {
          margin-right: 10px;
        }

        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          color: black;

          * {
            line-height: 0;
          }
        }
      }
    }
  }

  .ant-spin {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`
export const SharingUserInfo = styled.div`
  display: flex;
  align-items: center;
`
export const NewUserSharingSection = styled.section`
  //background-color: #fff;
  //padding: 16px 18px;
  //border-radius: 10px;
  //margin-bottom: 16px;

  .permission-setting-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      color: #313131;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .send-inform-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 20px;

    > p {
      color: #313131;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .ant-btn {
      border-radius: 8px;
      margin: 0 8px;
      height: 38px;
      line-height: 38px;
      padding: 0 30px;
    }

    .ant-btn-default {
      background-color: #E2E2E2;
      border-color: #E2E2E2;
      color: #313131;
    }

    .ant-btn-primary {
      &:not[disabled] {
        background-color: #237BD3;
        border-color: #237BD3;
        color: white;
      }
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;
    color: #313131;
    font-weight: 500;
  }
`