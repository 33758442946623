import { action, observable } from 'mobx'
import { MyDriveRequest } from '../requests/MyDriveRequest'
import { PAGE_NAME, PAGE_SIZE } from '../utils/constant'

class MyDriveStore {
  @observable myDriveList = []
  @observable myDriveBreadcrumbData = []
  @observable myDriveCurrentNode = null
  @observable itemRollbackRename = null
  @observable itemRollbackTrash = null
  @observable myDriveSort = 'desc' // desc | asc
  @observable myDriveSortBy = 'updated_at' // name | updated_at

  @observable page = 1
  @observable perPage = PAGE_SIZE
  @observable hasNextPage = true

  @action setPage = (page) => {
    this.page = page
  }

  @action setMyDriveSort = (sort) => {
    this.myDriveSort = sort
  }
  @action setMyDriveSortBy = (sortBy) => {
    this.myDriveSortBy = sortBy
  }

  @action setItemRollbackRename = (itemDrive) => {
    this.itemRollbackRename = itemDrive
  }
  @action setItemRollbackTrash = (itemDrive) => {
    this.itemRollbackTrash = itemDrive
  }

  @action clearMyDriveList = () => {
    this.myDriveList.length = 0
    this.myDriveCurrentNode = null
    this.myDriveBreadcrumbData = []
  }

  @action getBreadcrumb = (folder_id) => {
    return new Promise((resolve, reject) => {
      MyDriveRequest.getBreadcrumb(folder_id).then(response => {
        this.myDriveCurrentNode = response.data[response?.data?.length - 1] || null
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
  // { id: null, name: 'Dữ liệu của tôi' }
  @action setBreadcrumb = (root, data) => {
    this.myDriveBreadcrumbData = [root, ...data]
  }
  @action getMyDriveListOnScroll = (payload) => {
    return new Promise((resolve, reject) => {
      MyDriveRequest.getMyDriveListScroll(payload).then(response => {
        this.myDriveList = [...this.myDriveList, ...response.data?.data]
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getMyDriveList = (payload) => {
    return new Promise((resolve, reject) => {
      MyDriveRequest.getMyDriveList(payload).then(response => {
        this.myDriveList = response.data?.data
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action getMyDriveListUploadDone = (payload) => {
    return new Promise((resolve, reject) => {
      MyDriveRequest.getMyDriveListUploadDone(payload).then(response => {
        this.myDriveList = response.data?.data
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action createFolder = (payload) => {
    return new Promise((resolve, reject) => {
      MyDriveRequest.createFolder(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action trashDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      MyDriveRequest.trashDriveItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action renameDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      MyDriveRequest.renameDriveItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action cancelRequest = () => {
    MyDriveRequest.cancelRequest()
  }
}

export default new MyDriveStore()
