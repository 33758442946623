import axios from 'axios'
import config from '../config'
import authenticationStore from '../stores/authenticationStore'

const { apiUrl } = config


const source = axios.CancelToken.source()

export const CommonQuestionRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getCommonQuestionList: () => {
    return axios({
      method: 'get',
      baseURL:apiUrl,
      url: `/api/news/guides`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },
}