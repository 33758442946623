import React, { useEffect, useState } from 'react'
import { Col, Dropdown, Menu, Row, message, Modal, Button, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import PageResult from '../PageResult'
import { ButtonGroup, SectionHeading } from '../../pages/TrashPage/TrashPageStyled'
import FoldersGridView from '../FoldersGridView'
import FilesGridView from '../FilesGridView/FilesGridView'
import FilesListView from '../FilesListView/FilesListView'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../SyncDataTabPane/OneDriveConfig/OneDriveAuthen'
import { useHistory } from 'react-router-dom'
import { ResultWrapper } from '../PageResult/PageResultStyled'
import profileStore from '../../stores/profileStore'
import { SortButton } from '../CommonStyled/CommonStyled'
import { useMediaQuery } from 'react-responsive/src'
import MobileViewDetail from '../MobileViewDetail/MobileViewDetail'
import { useTranslation } from 'react-i18next'
import selectionStore from '../../stores/selectionStore'
import { toJS } from 'mobx'
import { CONTEXT_MENU_ACTION } from '../../utils/constant'

const OneDriveTab = props => {
  const { commonStore, synchronizationStore, selectionStore } = props
  const { viewByGridMode } = commonStore
  const { listFileOneDrive } = synchronizationStore
  const { selectionData, selectionIds } = selectionStore
  const { instance, accounts } = useMsal()
  let history = useHistory()
  const { t } = useTranslation()

  // 1: Tên, 2: Ngày cập nhật
  const [files, setFiles] = useState([])
  const [folders, setFolders] = useState([])
  const [isVisibleModal, setIsVisibleModal] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const handleClickFolderOrBreadcrumb = folder => {
    synchronizationStore.setBreadcrumbDataItems({ id: folder.id, name: folder.name })
    synchronizationStore.onedriveGetChildrenByFolderId(synchronizationStore.accessTokenOneDrive, folder.id)
    selectionStore.clearSelectionIndex()
  }

  const handleCallbackAction = (action) => {
    switch (action) {
      case CONTEXT_MENU_ACTION.syncFromOneDrive:
        let params = {
          access_token: synchronizationStore.accessTokenOneDrive,
          file_id: selectionData[0].id,
          driver: 'one_drive',
          cmc_parent_id: null,
        }
        synchronizationStore.syncToMobifoneDrive(params).then(res => {
          if (res.error_code === 0) {
            message.success(res?.message)
          } else {
            message.error(res?.message)
          }
        }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        break
      default:
        break
    }
  }

  const sortMenu = (
    <Menu>
      <Menu.Item onClick={() => synchronizationStore.setOneDriveSortBy('lastModifiedDateTime')}>
        {t('i0295')}
      </Menu.Item>
      <Menu.Item onClick={() => synchronizationStore.setOneDriveSortBy('name')}>
        {t('i0296')}
      </Menu.Item>
    </Menu>
  )

  const sortableTools = () => {
    return (
      <Col>
        <Row type={'flex'} gutter={15} justify={'space-between'} align={'middle'}>
          <Col>
            <Dropdown overlay={sortMenu} trigger={['click']}>
              <SortButton>
                {
                  synchronizationStore.oneDriveSortBy === 'name'
                    ? t('i0538')
                    : t('i0537')
                }
              </SortButton>
            </Dropdown>
          </Col>
          <Col>

            <SortButton>
              {
                synchronizationStore.oneDriveSort === 'asc'
                  ? <Tooltip title={t('i0701')} placement={'bottom'}>
                    <ArrowUpOutlined onClick={() => synchronizationStore.setOneDriveSort('desc')}/>
                  </Tooltip>
                  : <Tooltip title={t('i0700')} placement={'bottom'}>
                    <ArrowDownOutlined onClick={() => synchronizationStore.setOneDriveSort('asc')}/>
                  </Tooltip>
              }
            </SortButton>

          </Col>
        </Row>
      </Col>
    )
  }

  const onHandleCloseModal = () => {
    setIsVisibleModal(false)
  }
  const onConfirmAction = () => {
    setIsVisibleModal(false)
    history.push('/config-page')
  }

  useEffect(() => {
    if (!synchronizationStore.oneDriveSortBy || !synchronizationStore.oneDriveSort) return
    synchronizationStore.onedriveGetChildrenByFolderId(synchronizationStore.accessTokenOneDrive, synchronizationStore.currentNode?.id || 'root')
      .catch(error => {
        if (error?.response?.status !== 401) {
          message.error(error.message)
        } else {
          synchronizationStore.handleAuthenOneDrive(undefined)
        }
      })
  }, [synchronizationStore.oneDriveSortBy, synchronizationStore.oneDriveSort])

  useEffect(() => {
    if (!listFileOneDrive) return
    const tempFolder = [], tempFile = []
    listFileOneDrive.forEach(record => {
      if (record.type === 1) tempFolder.push(record)
      if (record.type === 2) tempFile.push(record)
    })
    setFiles(tempFile)
    setFolders(tempFolder)
  }, [listFileOneDrive])

  useEffect(() => {
    return () => {
      setFiles([])
      setFolders([])
    }
  }, [])

  return (
    <>
      {folders.length === 0 && files.length === 0 ?
        <ResultWrapper>
          <img
            src={`${process.env.PUBLIC_URL}/assets/imgs/onedrive-group.png`}
            alt='' width={'50%'}
          />
          {
            synchronizationStore.accessTokenOneDrive
              ?
              <p>
                {t('i0693')}
              </p>
              :
              <p>
                {t('i0299')} <a onClick={() => history.push('/config-page/4')}>{t('i0181')}</a>.
              </p>
          }
        </ResultWrapper>
        : viewByGridMode || isMobile ?
          (
            <>
              {folders?.length > 0 && (
                <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                     justify={'space-between'} align={'middle'}>
                  <Col>
                    <SectionHeading style={{ marginBottom: 0 }}>{t('i0533')}</SectionHeading>
                  </Col>
                  <Col>
                    {sortableTools()}
                  </Col>
                </Row>
              )
              }
              <FoldersGridView
                folderList={folders}
                onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                callbackAction={handleCallbackAction}
                callbackMobileAction={() => {
                }}
              />
              {
                files?.length > 0 &&
                <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                     justify={'space-between'} align={'middle'}>
                  <Col>
                    <SectionHeading style={{ marginBottom: 0 }}>{t('i0534')}</SectionHeading>
                  </Col>
                  {
                    folders?.length === 0 && sortableTools()
                  }
                </Row>
              }

              <FilesGridView fileList={files}
                             callbackMobileAction={() => {
                             }}
                             callbackAction={handleCallbackAction}/>
            </>
          ) : !isMobile && <FilesListView fileList={[...folders, ...files]} callbackAction={handleCallbackAction}
                                          onDoubleClickFolder={handleClickFolderOrBreadcrumb}/>

      }
      <Modal
        onCancel={() => setIsVisibleModal(false)}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal'}
        visible={isVisibleModal}
        maskClosable={false}
      >

        <p>
          {t('i0301')}
        </p>
        <ButtonGroup>
          <Button type={'default'} onClick={onHandleCloseModal}>Huỷ</Button>
          <Button type={'primary'}
                  onClick={onConfirmAction}>Đồng ý </Button>
        </ButtonGroup>
      </Modal>

    </>
  )

}

OneDriveTab.propTypes = {}

export default inject(
  'authenticationStore',
  'synchronizationStore',
  'commonStore',
  'selectionStore',
)(observer(OneDriveTab))
