import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import { PhoneBookTable } from './PhoneBookTableViewStyled'
import { PhoneBookAvatar, PhoneBookInfo, PhoneBookItem, PhoneBookName, PhoneBookPhone } from '../PhoneBookTabPane/PhoneBookTabPaneStyled'
import { useTranslation } from 'react-i18next'

const PhoneBookTableView = props => {
  const { dataSource, callbackDelete, callbackSelected, selectedPhoneBookIds } = props
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const { t } = useTranslation()

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      callbackSelected(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.id,
    }),
  }
  const tableColumns = [
    {
      title: t('i0325'),
      render: record => (
        <PhoneBookItem>
          <PhoneBookAvatar>
            {
              record.avatar
                ?
                <img src={record.avatar} alt={'avatar'} width={48} height={48} />
                :
                <Avatar size={48}>{record?.name?.charAt(0)?.toUpperCase()}</Avatar>
            }

          </PhoneBookAvatar>
          <PhoneBookInfo>
            <PhoneBookName>{record.name}</PhoneBookName>
            <PhoneBookPhone>{record.phone}</PhoneBookPhone>
          </PhoneBookInfo>
        </PhoneBookItem>
      ),
    },
    {
      title: '',
      width: '10%',
      render: record => (
        <a onClick={() => callbackDelete(record.id)}>
          <img src={`${process.env.PUBLIC_URL}/assets/icons/trash-alt1.svg`} />
        </a>
      ),
    },
  ]

  useEffect(() => {
    setSelectedRowKeys(selectedPhoneBookIds)
  }, [selectedPhoneBookIds])

  return (
    <PhoneBookTable
      rowSelection={{ ...rowSelection }}
      dataSource={dataSource}
      columns={tableColumns}
      rowKey={record => record.id}
      pagination={false} />
  )
}

PhoneBookTableView.propTypes = {
  dataSource: PropTypes.array,
  callbackDelete: PropTypes.func,
  callbackSelected: PropTypes.func,
  selectedPhoneBookIds: PropTypes.array,
}

export default PhoneBookTableView