import { action, observable } from 'mobx'
import { SynchronizationRequest } from '../requests/SynchronizationRequest'
import profileStore from './profileStore'
import commonStore from './commonStore'

class SynchronizationStore {

  @observable accessTokenGoogleDrive = localStorage.getItem(`google_drive_token`)
  @observable filesInGoogleDrive = []
  @observable breadcrumbDataItems = []
  @observable currentNode = null
  @observable accessTokenOneDrive = localStorage.getItem(`one_drive_token`)
  @observable listFileOneDrive = []

  @observable oneDriveSort = 'desc' // asc || desc
  @observable oneDriveSortBy = 'name' // name || lastModifiedDateTime
  @observable googleDriveSort = 'asc' // asc || desc
  @observable googleDriveSortBy = 'name' // name || modifiedTime

  @action setOneDriveSort = e => {
    this.oneDriveSort = e
  }
  @action setOneDriveSortBy = e => {
    this.oneDriveSortBy = e
  }
  @action setGoogleDriveSort = e => {
    this.googleDriveSort = e
  }
  @action setGoogleDriveSortBy = e => {
    this.googleDriveSortBy = e
  }

  @action resetBreadcrumbDataItems = () => {
    this.currentNode = null
  }

  @action setBreadcrumbDataItems = (item) => {
    let index = (this.breadcrumbDataItems).indexOf(item)
    let newBreadcrumbDataItems = []
    if (index >= 0) {
      newBreadcrumbDataItems = this.breadcrumbDataItems.slice(0, index + 1)
    } else {
      newBreadcrumbDataItems = [...this.breadcrumbDataItems, item]
    }
    this.breadcrumbDataItems = newBreadcrumbDataItems
    this.currentNode = item
  }

  @action getFileListOnGoogleDrive = (parentId, pageSize) => {
    return new Promise((resolve, reject) => {
      SynchronizationRequest.getFileListOnGoogleDrive(parentId, pageSize, this.googleDriveSortBy, this.googleDriveSort).then(response => {
        let itemArray = []
        itemArray = response.data.files.map(item => {
          if (item.mimeType === 'application/vnd.google-apps.folder') {
            item.type = 1
          } else {
            item.type = 2
            item.usage_capacity = item.size
          }
          if (item.thumbnailLink) {
            item.thumbnail = { '2x': item.thumbnailLink }
          }
          return item
        })
        this.filesInGoogleDrive = itemArray
        resolve(response.data)
      }).catch(error => {
        if (error?.response?.status === 401) {
          localStorage.removeItem(`google_drive_token`)
        }
        reject(error)
      })
    })
  }

  @action getParentFile = (fileArray) => {
    console.log('fileArray', fileArray)
    let array = []
    fileArray && fileArray.forEach(item => {
      if (item.parents === undefined || item.parents === null || item.parents.length < 1) {
        array.push({
          ...item,
          children: this.groupFiles(item.id, fileArray),
        })
      }
    })
    return array
  }

  @action groupFiles = (parentId, fileArray) => {
    let array = []
    fileArray.forEach(item => {
      if (item.parents && item.parents[0] === parentId) {
        array.push({
          ...item,
          children: this.groupFiles(item.id, fileArray),
        })
      }
    })
    return array
  }

  @action onedriveGetChildrenByFolderId = (token, folderId) => {
    return new Promise((resolve, reject) => {
      SynchronizationRequest.onedriveGetChildrenByFolderId(token, folderId, this.oneDriveSortBy, this.oneDriveSort)
        .then(response => {
          let itemArray = []
          itemArray = response.data.value.map(item => {
            if (item.folder) {
              item.type = 1
              item.mimetype = 'folder'
            } else {
              item.type = 2
              item.mimetype = item.file.mimeType
              item.usage_capacity = item.size
            }
            return item
          })
          this.listFileOneDrive = itemArray
          resolve(response.data)
        }).catch(error => {
        reject(error)
      })
    })
  }
  @action syncToMobifoneDrive = (payload) => {
    return new Promise((resolve, reject) => {
      SynchronizationRequest.syncToMobifoneDrive(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action handleAuthenGoogleDrive = (data) => {
    return new Promise((resolve, reject) => {
      this.accessTokenGoogleDrive = data?.access_token
      if (data?.access_token) {
        localStorage.setItem('google_drive_token', data?.access_token)
      } else {
        localStorage.removeItem('google_drive_token')
      }
      resolve()
    })
  }

  @action handleAuthenOneDrive = (accessToken) => {
    return new Promise((resolve, reject) => {
      this.accessTokenOneDrive = accessToken
      if (accessToken) {
        localStorage.setItem('one_drive_token', accessToken)
      } else {
        localStorage.removeItem('one_drive_token')
      }

      resolve()
    })
  }

  @action clearFilesOnLeave = () => {
    this.filesInGoogleDrive.length = 0
    this.listFileOneDrive.length = 0
    this.breadcrumbDataItems = []
    commonStore.setSyncTab('google-drive')
  }
}

export default new SynchronizationStore()
