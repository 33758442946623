import { observable, action, autorun } from 'mobx'
import { AppConfigRequest } from '../requests/AppConfigRequest'
import { message } from 'antd'
import fileUtils from '../utils/fileUtils'

class appConfigStore {
  constructor() {
    autorun(() => {
      if (this.fileIcons?.length > 0) {
        this.iconsByMimeTypeObj = fileUtils.reduceFiles('mimetype', 'icon', this.fileIcons)
        this.thumbnailByMimeTypeObj = fileUtils.reduceFiles('mimetype', 'thumbnail', this.fileIcons)
      }
      if (this.fileTypes?.length > 0) {
        this.mimeTypesByExtension = fileUtils.reduceFiles('ext', 'mimetype', this.fileTypes)
      }
      if (this.fileTypes?.length > 0) {
        this.groupIdsByMimetype = fileUtils.reduceFiles('mimetype', 'group_id', this.fileTypes)
      }
    })
  }


  @observable fileIcons = JSON.parse(localStorage.getItem('appConfig'))?.file_icons || [] // Hiển thị icon của file
  @observable hotline = undefined
  @observable iconsByMimeTypeObj = {}
  @observable thumbnailByMimeTypeObj = {}
  @observable fileTypes = JSON.parse(localStorage.getItem('appConfig'))?.file_types || [] // Map mimetype khi upload
  @observable mimeTypesByExtension = {}
  @observable groupIdsByMimetype = {}
  @observable dataSharingPermission = []
  @observable mimetypeGroups = JSON.parse(localStorage.getItem('appConfig'))?.mimetype_groups || []
  @observable systemConfig = JSON.parse(localStorage.getItem('appConfig'))?.system_config || undefined

  @action getNameByMimeType = (mimeType) => {
    let result = this.fileTypes.filter(item => item.mimetype === mimeType)
    if (result.length > 0) {
      return result[0].name
    } else {
      return ''
    }
  }

  @action getAppConfig = () => {
    return new Promise((resolve, reject) => {
      AppConfigRequest.getAppConfig()
        .then(response => {
          if (response.data.error_code === 0) {
            this.fileIcons = response.data.data.file_icons
            this.fileTypes = response.data.data.file_types
            this.hotline = response.data.data.hotline
            this.mimetypeGroups = response.data.data.mimetype_groups
            this.dataSharingPermission = response.data.data.permissions
            this.systemConfig = response.data.data.system_config
            localStorage.setItem('appConfig', JSON.stringify(response?.data?.data))
          } else {
            message.error(response.data.message)
          }
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
          console.error(error)
        })
    })
  }
}

export default new appConfigStore()