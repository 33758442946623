// import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import ViewToggleComponent from '../../components/ViewToggleComponent'
import PageContentWrapper from '../../components/PageContentWrapper'
import { Helmet } from 'react-helmet/es/Helmet'
import FoldersGridView from '../../components/FoldersGridView'
import FilesGridView from '../../components/FilesGridView'
import FilesListView from '../../components/FilesListView'
import { Col, Dropdown, Menu, message, notification, Row, Spin, Tooltip } from 'antd'
import { CONTENT_TYPE, CONTEXT_MENU_ACTION, MOBILE_ACTION, PAGE_NAME } from '../../utils/constant'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import SharingModal from '../../components/SharingModal'
import FileDownload from 'js-file-download'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import {
  CustomCustomNotificationLink,
  CustomCustomNotificationMessage,
  CustomNotificationContent, SectionHeading, SortButton,
} from '../../components/CommonStyled/CommonStyled'
import DragAndDropUpload from '../../components/DragAndDropUpload'
import { toJS } from 'mobx'
import PageResult from '../../components/PageResult'
import DataInfoModal from '../../components/DataInfoModal/DataInfoModal'
import InfiniteScroll from 'react-infinite-scroller'
import { useMediaQuery } from 'react-responsive/src'
import MobileViewDetail from '../../components/MobileViewDetail/MobileViewDetail'
import MobileMask from '../../components/MobileMask/MobileMask'
import { useTranslation } from 'react-i18next'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'


const FavoritePage = props => {

  const {
    favoriteStore, commonStore, moveItemStore,
    downloadStore, previewOverlayStore, itemDriveStore,
    selectionStore, blockStore, match, profileStore, offlineStore, sharedWithMeStore,
  } = props
  const { viewByGridMode } = commonStore
  const { selectionData, selectionIds, firstSelectionIndex, lastSelectionIndex, clickSessionId } = selectionStore
  const { page, perPage, hasNextPage } = favoriteStore
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const { t } = useTranslation()
  const history = useHistory()
  const { parentId } = match.params

  const [files, setFiles] = useState([])
  const [folders, setFolders] = useState([])
  const [showSharingModal, setShowSharingModal] = useState(false)
  const [sharingModalType, setSharingModalType] = useState(1)
  const [sharingItemDrive, setSharingItemDrive] = useState(null)
  const [showDataInfoModal, setShowDataInfoModal] = useState(null)
  const [isLoadingScroll, setIsLoadingScroll] = useState(false)
  const [visibleMobileMask, setVisibleMobileMask] = useState(false)
  const [itemDriveMobile, setItemDriveMobile] = useState(null)
  const [visibleMobileDetail, setVisibleMobileDetail] = useState(false)

  const handleOpenDataInfoModal = () => {
    setShowDataInfoModal(true)
  }
  const handleCloseDataInfoModal = () => {
    setShowDataInfoModal(false)
    selectionStore.clearSelectionIndex()
  }

  const handleClickFolderOrBreadcrumb = folder => {
    history.push(`/favorite/${folder?.id || 'root'}`)
    selectionStore.clearSelectionIndex()
  }
  const initialLoadBreadcrumb = (folder) => {
    favoriteStore.getBreadcrumb(folder?.id)
      .then((response) => {
        favoriteStore.setBreadcrumb({ id: null, name: t(PAGE_NAME.FAVORITE) }, response.data)
        favoriteStore.setPage(1)
        let payload = {
          parent_id: folder?.id,
          page: 1,
          per_page: perPage,
          sort: favoriteStore.favoriteSort,
          sort_by: favoriteStore.favoriteSortBy,
        }
        favoriteStore.getFavoriteList(payload)
          .then(response => {
            if (response?.error_code === 403) {
              history.push('/not-permission')
            } else if (response.error_code !== 0) {
              message.error(response.message)
            }
          }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }
  const reloadDataAtCurrentNode = () => {
    selectionStore.clearSelectionIndex()
    favoriteStore.setPage(1)
    let payload = {
      parent_id: favoriteStore.favoriteCurrentNode?.id,
      page: 1,
      per_page: perPage,
      sort: favoriteStore.favoriteSort,
      sort_by: favoriteStore.favoriteSortBy,
    }
    favoriteStore.getFavoriteList(payload).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  // const handleRollbackRename = (fileTypeName) => {
  //   notification.close('successRename')
  //   favoriteStore.renameDriveItem({
  //     node_id: favoriteStore.itemRollbackRename.id,
  //     name: favoriteStore.itemRollbackRename.name,
  //   })
  //     .then(response => {
  //       if (response.error_code === 0) {
  //         reloadDataAtCurrentNode()
  //         notification.info({
  //           className: 'custom-notification-share',
  //           closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
  //                           height={15} />,
  //           icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
  //                      height={20} />,
  //           message: <CustomNotificationContent>
  //             <CustomCustomNotificationMessage>
  //               {t('i0487')}
  //             </CustomCustomNotificationMessage>
  //           </CustomNotificationContent>,
  //           description: null,
  //           placement: 'bottomLeft',
  //           duration: 6,
  //           key: 'successRollbackRename',
  //         })
  //       } else {
  //         message.error(response.message)
  //       }
  //     })
  //     .catch(error => {
  //       error?.response?.status != 401 && message.error(error.message)
  //     })
  // }
  // const handleRollbackTrash = () => {
  //   notification.close('successTrash')
  //   trashStore.restoreFile({ node_id: favoriteStore.itemRollbackTrash.id })
  //     .then(response => {
  //       if (response.error_code === 0) {
  //         reloadDataAtCurrentNode()
  //         notification.info({
  //           className: 'custom-notification-share',
  //           closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
  //                           height={15} />,
  //           icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
  //                      height={20} />,
  //           message: <CustomNotificationContent>
  //             <CustomCustomNotificationMessage>
  //               {t('i0488')}
  //             </CustomCustomNotificationMessage>
  //           </CustomNotificationContent>,
  //           description: null,
  //           placement: 'bottomLeft',
  //           duration: 6,
  //           key: 'successRollbackTrash',
  //         })
  //       } else {
  //         message.error(response.message)
  //       }
  //     })
  //     .catch(error => {
  //       error?.response?.status != 401 && message.error(error.message)
  //     })
  // }
  // const handleRollbackFavorite = (itemDriveId) => {
  //   notification.close('successFavorite')
  //   favoriteStore.removeFavoriteItemDrive(itemDriveId)
  //     .then(response => {
  //       if (response.error_code === 0) {
  //         reloadDataAtCurrentNode()
  //         notification.info({
  //           className: 'custom-notification-share',
  //           closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
  //                           height={15} />,
  //           icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
  //                      height={20} />,
  //           message: <CustomNotificationContent>
  //             <CustomCustomNotificationMessage>
  //               {t('i0489')}
  //             </CustomCustomNotificationMessage>
  //           </CustomNotificationContent>,
  //           description: null,
  //           placement: 'bottomLeft',
  //           duration: 6,
  //           key: 'successRollbackFavorite',
  //         })
  //       } else {
  //         message.error(response.message)
  //       }
  //     })
  //     .catch(error => {
  //       error?.response?.status != 401 && message.error(error.message)
  //     })
  // }
  // const handleRollbackRemoveFavorite = (itemDriveId) => {
  //   notification.close('successRemoveFavorite')
  //   favoriteStore.favoriteItemDrive(itemDriveId)
  //     .then(response => {
  //       if (response.error_code === 0) {
  //         reloadDataAtCurrentNode()
  //         notification.info({
  //           className: 'custom-notification-share',
  //           closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
  //                           height={15} />,
  //           icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
  //                      height={20} />,
  //           message: <CustomNotificationContent>
  //             <CustomCustomNotificationMessage>
  //               {t('i0490')}
  //             </CustomCustomNotificationMessage>
  //           </CustomNotificationContent>,
  //           description: null,
  //           placement: 'bottomLeft',
  //           duration: 6,
  //           key: 'successRollbackRemoveFavorite',
  //         })
  //       } else {
  //         message.error(response.message)
  //       }
  //     })
  //     .catch(error => {
  //       error?.response?.status != 401 && message.error(error.message)
  //     })
  // }
  // const handleRollbackLock = (itemDriveId) => {
  //   notification.close('successBlock')
  //   blockStore.unBlockItemDrive(itemDriveId)
  //     .then(response => {
  //       if (response.error_code === 0) {
  //         selectionStore.clearSelectionIndex()
  //         reloadDataAtCurrentNode()
  //         notification.info({
  //           className: 'custom-notification-share',
  //           closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
  //                           height={15} />,
  //           icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/unlock-white.svg`} alt={''} width={20}
  //                      height={20} />,
  //           message: <CustomNotificationContent>
  //             <CustomCustomNotificationMessage>
  //               {t('i0491')}
  //             </CustomCustomNotificationMessage>
  //           </CustomNotificationContent>,
  //           description: null,
  //           placement: 'bottomLeft',
  //           duration: 6,
  //           key: 'successRollbackBlock',
  //         })
  //       } else {
  //         message.error(response.message)
  //       }
  //     })
  //     .catch(error => error?.response?.status != 401 && message.error(error.message))
  // }
  const handleCallbackAction = (action, itemTarget) => {
    console.log(action)
    if (selectionIds.length > 1) {
      switch (action) {
        case CONTEXT_MENU_ACTION.trash:

          sharedWithMeStore.deleteItemSharedWithMe(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0492')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackTrash()}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successTrash',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => {
              error?.response?.status != 401 && message.error(error.message)
            })
          break
        case CONTEXT_MENU_ACTION.move:
          let payload = {
            parent_id: itemTarget?.id,
            list_node_id: selectionIds,
          }
          moveItemStore.moveDriveItem(payload)
            .then(response => {
                if (response.error_code === 0) {
                  selectionStore.clearSelectionIndex()
                  reloadDataAtCurrentNode()
                  notification.info({
                    className: 'custom-notification-share',
                    closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                    width={15}
                                    height={15}/>,
                    icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                               height={20}/>,
                    message: <CustomNotificationContent>
                      <CustomCustomNotificationMessage>
                        {`${t('i0493')} ${itemTarget?.name ? ` ${t('i0494')} ` + itemTarget?.name : t('i0495')}`}
                      </CustomCustomNotificationMessage>
                      {/*<CustomCustomNotificationLink onClick={() => handleRollbackRemoveFavorite(selectionData[0].id)}>*/}
                      {/*  Hủy*/}
                      {/*</CustomCustomNotificationLink>*/}
                    </CustomNotificationContent>,
                    description: null,
                    placement: 'bottomLeft',
                    duration: 6,
                    key: 'successMoveDrive',
                  })
                } else {
                  message.error(response.message)
                }
              },
            )
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.lock:
          blockStore.blockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/lock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0530')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink*/}
                    {/*  onClick={() => handleRollbackLock(selectionData[0].id)}*/}
                    {/*>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.unlock:
          blockStore.unBlockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/unlock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0507')}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successUnBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        default:
          break
      }
    } else {
      switch (action) {
        case CONTEXT_MENU_ACTION.share:
          handleOpenSharingModal(selectionData[0], action)
          break
        case CONTEXT_MENU_ACTION.copyLink:
          handleOpenSharingModal(selectionData[0], action)
          break
        case CONTEXT_MENU_ACTION.trash:
          sharedWithMeStore.deleteItemSharedWithMe([selectionData[0].id])
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0496')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackTrash()}>Hủy</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successTrash',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => {
              error?.response?.status != 401 && message.error(error.message)
            })
          break
        case CONTEXT_MENU_ACTION.download:
          Promise.all([
            downloadStore.getDownloadLink(selectionData[0].id),
            itemDriveStore.getItemDriveDetail(selectionData[0].id),
          ]).then(([downloadRes, itemDetailRes]) => {
            // Handle download
            if (downloadRes.error_code === 0) {
              selectionStore.clearSelectionIndex()
              if (downloadRes.data?.url) {
                axios.get(downloadRes.data.url, {
                  responseType: 'blob',
                }).then(res => {
                  FileDownload(res.data, selectionData[0]?.type === 1 ? `${selectionData[0].name}.zip` : selectionData[0].name)
                })
              }
            } else {
              message.error(downloadRes.message)
            }
            // Handle save offline data
            if (downloadRes.error_code === 0 && itemDetailRes.error_code === 0) {
              offlineStore.saveDataForOfflineView(selectionData, itemDetailRes.data)
            }
          }).catch(([downloadErr, itemDetailRes]) => {
            message.error(downloadErr.message)
            message.error(itemDetailRes.message)
          })
          break
        case CONTEXT_MENU_ACTION.favorite:
          favoriteStore.favoriteItemDrive(selectionData[0].id)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0497')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink*/}
                    {/*  onClick={() => handleRollbackFavorite(selectionData[0].id)}*/}
                    {/*>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successFavorite',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.removeFavorite:
          favoriteStore.removeFavoriteItemDrive(selectionData[0].id)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0498')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink*/}
                    {/*  onClick={() => handleRollbackRemoveFavorite(selectionData[0].id)}*/}
                    {/*>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successRemoveFavorite',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.move:
          let payload = {
            parent_id: itemTarget?.id,
            list_node_id: [selectionData[0].id],
          }
          moveItemStore.moveDriveItem(payload)
            .then(response => {
                if (response.error_code === 0) {
                  selectionStore.clearSelectionIndex()
                  reloadDataAtCurrentNode()
                  message.success(`${t('i0499')} ${itemTarget?.name ? ` ${t('i0500')} ` + itemTarget?.name : t('i0501')}`)
                } else {
                  message.error(response.message)
                }
              },
            )
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.rename:
          console.log('renaming')
          let fileTypeName = ''
          switch (selectionData[0].type) {
            case 1:
              fileTypeName = t('i0502')
              break
            case 2:
              fileTypeName = t('i0503')
              break
            default:
              fileTypeName = ''
              break
          }
          favoriteStore.renameDriveItem({ node_id: selectionData[0].id, name: selectionData[0].name })
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {`${t('i0504')} ${fileTypeName} ${t('i0505')}`}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink*/}
                    {/*  onClick={() => handleRollbackRename(fileTypeName)}*/}
                    {/*>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successRename',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => {
              error?.response?.status != 401 && message.error(error.message)
            })
          break
        case CONTEXT_MENU_ACTION.viewInfo:
          handleOpenDataInfoModal()
          break
        case CONTEXT_MENU_ACTION.lock:
          blockStore.blockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/lock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0530')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink*/}
                    {/*  onClick={() => handleRollbackLock(selectionData[0].id)}*/}
                    {/*>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.unlock:
          blockStore.unBlockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/unlock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0507')}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successUnBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        default:
          break
      }
    }
  }
  const handleOpenSharingModal = (itemDrive, actionType) => {
    setSharingModalType(actionType)
    setShowSharingModal(true)
    setSharingItemDrive(itemDrive)
  }
  const handleCloseSharingModal = () => {
    setShowSharingModal(false)
    setSharingItemDrive(null)
  }
  const handleClearSelection = event => {
    if (event.target.closest('.selectable-item')) return
    if (event.target.closest('.context-menu')) return
    selectionStore.clearSelectionIndex()
  }

  const handleChangeSort = (sortCurrent) => {
    favoriteStore.setFavoriteSort(sortCurrent)
  }
  const handleChangeSortBy = ({ key }) => {
    favoriteStore.setFavoriteSortBy(key)
  }
  const handleCallbackMobileAction = (item, action) => {
    switch (action) {
      case MOBILE_ACTION.click:
        if (item.type === 1) {
          handleClickFolderOrBreadcrumb(item)
        } else {
          setItemDriveMobile(item)
          setVisibleMobileMask(true)
        }
        break
      case MOBILE_ACTION.viewInfo:
        setItemDriveMobile(item)
        setVisibleMobileDetail(true)
        break
      default:
        break
    }
  }
  const handleCloseMobileMask = () => {
    setVisibleMobileMask(false)
    setItemDriveMobile(null)
  }
  const handleCloseMobileDetail = () => {
    setVisibleMobileDetail(false)
    setItemDriveMobile(null)
  }
  const renderScrollList = () => {
    return <div onClick={event => handleClearSelection(event)}>
      <PageContentWrapper>
        {
          favoriteStore.favoriteList.length === 0
            ? <PageResult type={'empty-favorite'}/>
            : viewByGridMode || isMobile
            ? (
              <>
                {
                  folders?.length > 0 && (
                    <>
                      <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                           justify={'space-between'} align={'middle'}>
                        <Col>
                          <SectionHeading style={{ marginBottom: 0 }}>{t('i0533')}</SectionHeading>
                        </Col>
                        {sortableTools()}
                      </Row>
                      <FoldersGridView
                        folderList={folders}
                        onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                        callbackMobileAction={handleCallbackMobileAction}
                        callbackAction={handleCallbackAction}
                      />
                    </>
                  )
                }
                {
                  files?.length > 0 &&
                  <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                       justify={'space-between'} align={'middle'}>
                    <Col>
                      <SectionHeading style={{ marginBottom: 0 }}>{t('i0534')}</SectionHeading>
                    </Col>
                    {
                      folders?.length === 0 && sortableTools()
                    }
                  </Row>
                }
                <FilesGridView
                  fileList={files}
                  callbackMobileAction={handleCallbackMobileAction}
                  callbackAction={handleCallbackAction}
                />
              </>
            ) : !isMobile &&
            <FilesListView fileList={[...folders, ...files]} onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                           callbackAction={handleCallbackAction}/>
        }
        {
          isLoadingScroll &&
          <div style={{ display: 'flex', justifyContent: 'center', margin: '2% auto 0' }}>
            <Spin/>
          </div>
        }
      </PageContentWrapper>
      <MobileMask itemDrive={itemDriveMobile} visible={visibleMobileMask} onClose={handleCloseMobileMask}/>
      <MobileViewDetail visible={visibleMobileDetail} onClose={handleCloseMobileDetail}
                        itemDriveId={itemDriveMobile?.id}/>
    </div>
  }

  const handleCallbackSharingModal = () => {
    reloadDataAtCurrentNode()
  }

  const handleLoadMore = () => {
    if (isLoadingScroll) return
    if (hasNextPage) {
      setIsLoadingScroll(true)
      let nextPage = page + 1
      favoriteStore.setPage(nextPage)
      let payload = {
        parent_id: favoriteStore.favoriteCurrentNode?.id,
        page: nextPage,
        per_page: perPage,
        sort: favoriteStore.favoriteSort,
        sort_by: favoriteStore.favoriteSortBy,
      }
      favoriteStore.getFavoriteListOnScroll(payload)
        .then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        .finally(() => setIsLoadingScroll(false))
    }
  }

  const sortMenu = (
    <Menu onClick={handleChangeSortBy} selectedKeys={favoriteStore.favoriteSortBy} className={'sort-menu'}>
      <Menu.Item key="updated_at">
        {t('i0508')}
      </Menu.Item>
      <Menu.Item key="name">
        {t('i0509')}
      </Menu.Item>
    </Menu>
  )
  const sortableTools = () => {
    return (
      <Col>
        <Row type={'flex'} gutter={15} justify={'space-between'} align={'middle'}>
          <Col>
            <Dropdown overlay={sortMenu} trigger={['click']}>
              <SortButton>
                {
                  favoriteStore.favoriteSortBy === 'name'
                    ? t('i0509')
                    : t('i0508')
                }
              </SortButton>
            </Dropdown>
          </Col>
          <Col>
            <SortButton>
              {
                favoriteStore.favoriteSort === 'asc'
                  ? <Tooltip title={t('i0701')} placement={'bottom'}>
                    <ArrowUpOutlined onClick={() => handleChangeSort('desc')}/>
                  </Tooltip>
                  : <Tooltip title={t('i0700')} placement={'bottom'}>
                    <ArrowDownOutlined onClick={() => handleChangeSort('asc')}/>
                  </Tooltip>
              }
            </SortButton>
          </Col>
        </Row>
      </Col>
    )
  }

  useEffect(() => {
    return () => {
      favoriteStore.clearFavoriteList()
    }
  }, [])

  useEffect(() => {
    if (!profileStore.appLanguage) return
    initialLoadBreadcrumb({ id: parentId })
  }, [
    favoriteStore.favoriteSort,
    favoriteStore.favoriteSortBy,
    profileStore.appLanguage,
    parentId])
  useEffect(() => {
    if (!favoriteStore.favoriteList) return
    const tempFolder = [], tempFile = []
    favoriteStore.favoriteList.forEach(record => {
      if (record.type === 1) tempFolder.push(record)
      if (record.type === 2) tempFile.push(record)
    })
    tempFolder.forEach((item, index) => {
      item.realIndex = index
    })
    tempFile.forEach((item, index) => {
      item.realIndex = index + tempFolder.length
    })
    // Set data to state
    setFiles(tempFile)
    setFolders(tempFolder)
  }, [favoriteStore.favoriteList])

  useEffect(() => {
    return () => {
      selectionStore.clearSelectionIndex()
      const listNotify = [
        'successRollbackRename',
        'successRollbackTrash',
        'successRollbackFavorite',
        'successRollbackRemoveFavorite',
        'successRollbackBlock',
        'successTrash',
        'successFavorite',
        'successRemoveFavorite',
        'successRename',
        'successBlock',
        'successUnBlock',
      ]
      listNotify.map(item => {
        notification.close(item)
      })
    }
  }, [])

  const deleteFunction = (event) => {
    if (selectionIds?.length > 0 && !previewOverlayStore.visible) {
      if (event.keyCode === 46) {
        commonStore.setVisibleModalDelete(true)
      }
    }
  }
  const handleConfirmDelete = () => {
    handleCallbackAction(CONTEXT_MENU_ACTION.trash, undefined)
    commonStore.setVisibleModalDelete(false)
  }
  useEffect(() => {
    document.addEventListener('keydown', deleteFunction, false)
    return () => {
      document.removeEventListener('keydown', deleteFunction, false)
    }
  }, [selectionIds])

  useEffect(() => {
    if (firstSelectionIndex === null || lastSelectionIndex === null) return
    let fileShiftSelect = files.filter(item => item.realIndex >= firstSelectionIndex && item.realIndex <= lastSelectionIndex)
    let folderShiftSelect = folders.filter(item => item.realIndex >= firstSelectionIndex && item.realIndex <= lastSelectionIndex)
    let newSelectionItems = [...folderShiftSelect, ...fileShiftSelect]
    selectionStore.setSelectionData(newSelectionItems)
  }, [firstSelectionIndex, lastSelectionIndex, files, folders, clickSessionId])
  useEffect(() => {
    return () => {
      selectionStore.setLastSelectionIndex(null)
      selectionStore.setFirstSelectionIndex(null)
      selectionStore.clearSelectionIndex()
    }
  }, [])
  return (
    <>
      <Helmet>
        <title>{t('i0006')}</title>
      </Helmet>
      <ViewToggleComponent
        heading={favoriteStore.favoriteBreadcrumbData}
        onClickBreadcrumb={handleClickFolderOrBreadcrumb}
      />
      <InfiniteScroll
        initialLoad={false}
        pageStart={page} threshold={1}
        loadMore={handleLoadMore}
        hasMore={hasNextPage}
        useWindow={true}
      >
        {renderScrollList()}
      </InfiniteScroll>

      <SharingModal
        actionType={sharingModalType}
        modalVisible={showSharingModal}
        onCancel={handleCloseSharingModal}
        itemDrive={sharingItemDrive}
        callbackSharingModal={handleCallbackSharingModal}
      />
      <DataInfoModal visible={showDataInfoModal} onClose={handleCloseDataInfoModal}/>
      <ModalConfirmDelete onOk={handleConfirmDelete}/>
    </>
  )
}

FavoritePage.propTypes = {}

export default inject(
  'favoriteStore',
  'moveItemStore',
  'commonStore',
  'downloadStore',
  'trashStore',
  'favoriteStore',
  'selectionStore',
  'blockStore',
  'profileStore',
  'itemDriveStore',
  'offlineStore',
  'sharedWithMeStore',
  'previewOverlayStore',
)(observer(FavoritePage))
