import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()
export const AppConfigRequest = {

  getAppConfig: () => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/app/config`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

}