import styled from 'styled-components'

export const QuestionContainer = styled.section`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
  padding: 20px 15px 45px;
`
export const BreadcrumbsHeading = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  a, span {
    font-size: 20px;
    font-weight: 500;
  }
  
  .anticon {
    font-size: 14px;
    margin: 0 10px;
  }

  a {
    color: #595959;
  }

  span {
    color: #313131;
  }
`
export const MainQuestionContent = styled.article`
  h1 {
    color: #313131;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  p {
    color: #313131;
    font-size: 14px;
    margin-bottom: 24px;
  }
  
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }
`