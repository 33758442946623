import PropTypes from 'prop-types'
import React from 'react'
import { FileTitleWrapper, PageWrapper, FileName, FilePreviewWrapper } from './MobileMaskStyled'
import { Button, message } from 'antd'
import { inject, observer } from 'mobx-react'
import SVGIcon from '../../components/SVGIcon'
import { Helmet } from 'react-helmet/es/Helmet'
import { CloseOutlined } from '@ant-design/icons'
import axios from 'axios'
import FileDownload from 'js-file-download'
import { useTranslation } from 'react-i18next'

const MobileMask = props => {
  const { visible, onClose, itemDrive, downloadStore } = props
  const { t } = useTranslation()

  const handleDownload = () => {
    downloadStore.getDownloadLink(itemDrive?.id)
      .then(response => {
        if (response.error_code === 0) {
          if (response.data?.url) {
            axios.get(response.data.url, {
              responseType: 'blob',
            })
              .then((res) => {
                FileDownload(res.data, itemDrive?.name)
              })
          }
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }
  return (
    <>
      {
        visible &&
        <PageWrapper>
          <FileTitleWrapper>
            <CloseOutlined style={{ color: '#fff !important' }}
              onClick={() => onClose()} />
          </FileTitleWrapper>
          <FilePreviewWrapper>
            <p>{t('i0243')}</p>
            <Button type={'primary'} onClick={handleDownload}>
              {t('i0244')}
            </Button>
          </FilePreviewWrapper>
        </PageWrapper>
      }

    </>
  )
}

MobileMask.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  itemDrive: PropTypes.any,
}

export default inject('downloadStore')(observer(MobileMask))