import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import profileStore from '../../stores/profileStore'
import { Link, useHistory } from 'react-router-dom'
import {
  AppDownload,
  Container,
  FeatureList,
  FeatureListItem,
  Jumbotron,
  MainSection,
  PageContainer,
  PageFooter, PageHeader,
} from './HomePageStyled'
import miscUtils from '../../utils/miscUtils'
import { Button } from 'antd'
import { APP_NAME } from '../../utils/constant'
import { useTranslation } from 'react-i18next'

const HomePage = () => {

  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (profileStore.myProfile?.id) {
      history.push('/my-drive')
    }
  }, [profileStore.myProfile])

  return (
    <PageContainer>
      <PageHeader>
        <div className='container'>
          <a href='' className={'logo'}>
            <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_home.png`} alt='' height={60} />
            <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_home_trimmed.png`} alt='' height={60} />
          </a>
          <div className='button-group'>
            <Button type={'default'} onClick={() => history.push('/auth/login')}>
              Đăng nhập
            </Button>
            <Button type={'primary'} onClick={() => history.push('/auth/register')}>
              Đăng ký
            </Button>
          </div>
        </div>
      </PageHeader>

      <Jumbotron bg={`${process.env.PUBLIC_URL}/assets/imgs/landing-bg1.png`}>
        <Container>
          <div className='jumbotron-content'>
            <div className='text'>
              <h1>Giải pháp lưu trữ dữ liệu trên nền tảng điện toán đám mây</h1>
              <p>
                mobiCloud là giải pháp lưu trữ trên nền tảng điện toán đám mây cho người dùng cá nhân được cung cấp bởi
                MobiFone.
              </p>
              <p>
                Ứng dụng cho phép người dùng có thể lưu trữ dữ liệu và truy cập chúng ở bất kì đâu từ các thiết bị điện
                thoại thông minh, máy tính bảng hoặc laptop, PC. Đồng thời người dùng có thể chia sẻ dữ liệu cho người
                thân, bạn bè và đồng nghiệp một cách dễ dàng, tiện lợi.
              </p>
            </div>
            <div className='img'>
              <img src={`${process.env.PUBLIC_URL}/assets/imgs/ios_5.5_21.png`} alt='' />
            </div>
          </div>
        </Container>
      </Jumbotron>

      <MainSection>Tính năng của mobiCloud</MainSection>
      <Container>
        <FeatureList>
          <FeatureListItem>
            <h3 className='title'>Quản lý tệp</h3>
            <p>
              Upload tệp tin từ thiết bị (Android/iOS). Tải về tập tin và thư mục. Hỗ trợ đa dạng tập tin.
            </p>
          </FeatureListItem>
          <FeatureListItem>
            <h3 className='title'>Chia sẻ</h3>
            <p>
              Cho phép thiết lập cụ thể các khả năng thao tác như chỉ chỉnh sửa, chỉ xem hoặc theo đường link truy cập
              bằng email
            </p>
          </FeatureListItem>
          <FeatureListItem>
            <h3 className='title'>Quản trị</h3>
            <p>
              Lưu trữ và chuyển dời tệp trong môi trường bảo mật, an toàn
            </p>
          </FeatureListItem>
          <FeatureListItem>
            <h3 className='title'>Đa nền tảng</h3>
            <p>
              Trên web, thiết bị di động (iOS & Android)
            </p>
          </FeatureListItem>
          <FeatureListItem>
            <h3 className='title'>Tích hợp</h3>
            <p>
              Tích hợp đến các các nhà cung cấp Lưu trữ đám mây khác (Google, Microsoft...), quản lý tập trung các kho
              lưu trữ dữ liệu.
            </p>
          </FeatureListItem>
        </FeatureList>
      </Container>

      <MainSection>Tải mobiCloud cho Mobile</MainSection>
      <Container>
        <p style={{ textAlign: 'center' }}>Mang theo dữ liệu của bạn đi mọi nơi với mobiCloud cho Android hoặc iOS.</p>
        <AppDownload>
          {
            miscUtils.detectMobileOperatingSystem() !== 'Android' && (
              <a href='https://apps.apple.com/us/app/mobifone-cloud/id1587835285' target={'_blank'}>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/appstore.png`} alt='' height={40} />
              </a>
            )
          }
          {
            miscUtils.detectMobileOperatingSystem() !== 'iOS' && (
              <a href='https://play.google.com/store/apps/details?id=com.mobifone.mobicloud' target={'_blank'}>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/playstore.png`} alt='' height={40} />
              </a>
            )
          }
        </AppDownload>
      </Container>

      <PageFooter>
        <p className={'copyright'}>
          &copy; 2021 CMCTelecom. {t('i0407')}.
        </p>
        <ul>
          <li>
            <Link to={'/term-of-use'}>{t('i0408')}</Link>
          </li>
          <li>
            <Link to={'/privacy-policy'}>{t('i0409')}</Link>
          </li>
        </ul>
      </PageFooter>
    </PageContainer>
  )
}

export default inject(
  'profileStore',
)(observer(HomePage))
