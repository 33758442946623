const merge = require('lodash/merge')

const config = {
  all: {
    env: process.env.NODE_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_URL,
    isBrowser: typeof window !== 'undefined',
    googleDriveConfig: {
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      clientSecret: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_SECRET,
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      scope: 'https://www.googleapis.com/auth/drive',
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    },
    oneDriveConfig: {
      clientId: process.env.REACT_APP_ONE_DRIVE_CLIENT_ID,
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: process.env.REACT_APP_ONE_DRIVE_REDIRECT_URL,
    },
  },
  development: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
}

module.exports = merge(config.all, config[config.all.env])
