import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const OneDriveLogout = props => {
  const history = useHistory()
  useEffect(() => {
    history.push('/config-page/4')
  }, [])
  return (
    <>

    </>
  )
}

OneDriveLogout.propTypes = {}

export default OneDriveLogout