import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import {
  CONTEXT_MENU_ACTION,
  CONTEXT_MENU_FAVORITE,
  CONTEXT_MENU_FOLDER_PREVIEW,
  CONTEXT_MENU_MY_DRIVE,
  CONTEXT_MENU_OFFLINE_FILES,
  CONTEXT_MENU_RECENTLY_OPENED,
  CONTEXT_MENU_SHARE_WITH_ME,
  CONTEXT_MENU_SYNC,
  CONTEXT_MENU_TRASH,
} from '../../utils/constant'
import { Dropdown, Menu } from 'antd'
import {
  ContextMenuDropdownWrapper,
  ContextMenuItem,
  ContextMenuText,
  CustomOverlayMenu,
} from './ContextMenuDropdownStyled'
import selectionStore from '../../stores/selectionStore'
import { useTranslation } from 'react-i18next'

const ContextMenuDropdown = props => {
  const { children, callbackAction, commonStore } = props
  const handleClickMenu = (action) => {
    callbackAction(action)
  }

  const { t } = useTranslation()

  const renderMenuItem = () => {
    let result = []
    switch (commonStore.pageName) {
      case 'my-drive':
        result = [...CONTEXT_MENU_MY_DRIVE]
        break
      case 'synchronization':
        result = [...CONTEXT_MENU_SYNC]
        let folder = selectionStore.selectionData?.filter(item => item.type === 1)
        if (folder?.length > 0) {
          result = []
        } else {
          switch (commonStore.syncTab) {
            case 'google-drive':
              result = result.filter(item => item.action === CONTEXT_MENU_ACTION.syncFromGoogleDrive)
              break
            case 'one-drive':
              result = result.filter(item => item.action === CONTEXT_MENU_ACTION.syncFromOneDrive)
              break
            default:
              break
          }
        }
        break
      case 'recently-opened':
        result = [...CONTEXT_MENU_RECENTLY_OPENED]
        break
      case 'favorite':
        result = [...CONTEXT_MENU_FAVORITE]
        break
      case 'offline-files':
        result = [...CONTEXT_MENU_OFFLINE_FILES]
        break
      case 'trash':
        result = [...CONTEXT_MENU_TRASH]
        break
      case 'shared-with-me':
        result = [...CONTEXT_MENU_SHARE_WITH_ME]
        break
      case 'folder-preview':
        result = [...CONTEXT_MENU_FOLDER_PREVIEW]
        break
      case 'search':
        if (selectionStore.selectionData[0]?.status === 1) {
          result = [...CONTEXT_MENU_TRASH]
          break
        } else if (selectionStore.selectionData[0]?.is_mine) {
          result = [...CONTEXT_MENU_MY_DRIVE]
          break
        } else {
          result = [...CONTEXT_MENU_SHARE_WITH_ME]
          break
        }
      default:
        break
    }
    let favorited = selectionStore.selectionData?.filter(item => item.favorited === true)
    let notFavorited = selectionStore.selectionData?.filter(item => item.favorited !== true)
    let locked = selectionStore.selectionData?.filter(item => item.status === 2)
    let notLocked = selectionStore.selectionData?.filter(item => item.status !== 2)
    // Nếu toàn bộ là favorited thì hiện menu bỏ yêu thích, không thì hiện menu yêu thích
    if (favorited?.length > 0 && !(notFavorited?.length > 0)) {
      result = result.filter(item => item.action !== CONTEXT_MENU_ACTION.favorite)
    } else {
      result = result.filter(item => item.action !== CONTEXT_MENU_ACTION.removeFavorite)
    }
    // Nếu toàn bộ là locked thì hiện menu bỏ bỏ khóa, không thì hiện menu khóa
    if (locked?.length > 0 && !(notLocked?.length > 0)) {
      result = result.filter(item => item.action !== CONTEXT_MENU_ACTION.lock)
    } else {
      result = result.filter(item => item.action !== CONTEXT_MENU_ACTION.unlock)
    }
    // Nếu chọn nhiều thì chỉ hiện những item multiSelectVisible true
    if (selectionStore.selectionIds?.length > 1) {
      result = result.filter(item => item.multiSelectVisible === true)
    }
    // Nếu là folder thì không có download
    // if (selectionStore.selectionData[0]?.type === 1) {
    //   result = result.filter(item => item.action !== CONTEXT_MENU_ACTION.download)
    // }
    return result
  }
  const menu = () => {
    return (
      <CustomOverlayMenu>
        {
          renderMenuItem().map(item =>
            <Menu.Item key={item.id} onClick={() => handleClickMenu(item.action)}>
              <ContextMenuItem>
                <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/${item.icon}`} width={20} height={20}
                     alt={''} />
                <ContextMenuText>{t(item.text)}</ContextMenuText>
              </ContextMenuItem>
            </Menu.Item>,
          )
        }
      </CustomOverlayMenu>
    )
  }

  return (
    <ContextMenuDropdownWrapper>
      <Dropdown overlayClassName={'context-menu'}
                overlay={menu} trigger={['contextMenu']}>
        {children}
      </Dropdown>
    </ContextMenuDropdownWrapper>
  )
}

ContextMenuDropdown.propTypes = {
  callbackAction: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default inject('commonStore')(observer(ContextMenuDropdown))
