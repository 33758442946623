import React, { useEffect, useState } from 'react'
import { Button, Drawer, Dropdown, Menu } from 'antd'
import {
  AvatarWrapper,
  DrawerContent,
  HeaderWrapper,
  Hotline,
  LogoWrapper,
  UserMenu,
} from './HelpHeaderBarLayoutStyled'
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive/src'
import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'
import DefaultAvatar from '../DefaultAvatar'

const HelpHeaderBarLayout = (props) => {
  const { commonStore, appConfigStore, authenticationStore, profileStore } = props
  const { hotline } = appConfigStore
  const { myProfile } = profileStore

  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: 768 })
  const history = useHistory()
  const location = useLocation()

  const [drawerVisible, setDrawerVisible] = useState(false)

  const onCloseDrawer = () => {
    setDrawerVisible(false)
  }
  const handleNavigate = menu => {
    history.push(`/${menu.key}`)
    onCloseDrawer()
  }
  const handleClickProfileHistory = (tab) => {
    if (commonStore.pageName === 'my-profile') {
      commonStore.setActiveTabProfile(tab)
    } else {
      commonStore.setActiveTabProfile(tab)
      history.push('/my-profile')
    }
  }
  const handleLogout = () => {
    authenticationStore.userLogout()
      .then(() => {
        history.push('/auth/login')
      })
  }

  const menuUserProps = (
    <Menu>
      <Menu.Item onClick={() => handleClickProfileHistory('1')}>
        {t('i0199')}
      </Menu.Item>
      <Menu.Item onClick={() => handleClickProfileHistory('2')}>
        {t('i0200')}
      </Menu.Item>
      <Menu.Item danger onClick={handleLogout}>
        {t('i0201')}
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (isMobile) return
    onCloseDrawer()
  }, [isMobile])
  useEffect(() => {
    const segment = location.pathname.split('/').filter(item => item !== '')
    switch (segment[0]) {
      case 'introduction':
        commonStore.setPageName('introduction')
        return
      case 'term-of-use':
        commonStore.setPageName('term-of-use')
        return
      case 'privacy-policy':
        commonStore.setPageName('privacy-policy')
        return
      case 'common-question':
        commonStore.setPageName('common-question')
        return
    }
  }, [location.pathname])

  return (
    <HeaderWrapper id={'public-header'}>
      {
        !isMobile ? (
          <>
            <LogoWrapper>
              <Link to={'/'} className={'logo'}>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_home.png`} alt='' />
              </Link>
              {commonStore.pageName === 'folder-preview' ? <></> : <Link to={'/common-question'}>{t('i0202')}</Link>}
            </LogoWrapper>
          </>
        ) : (
          <MenuOutlined
            className={'menu-toggle'}
            onClick={() => setDrawerVisible(true)}
          />
        )
      }
      {
        commonStore.pageName === 'folder-preview' ? <></> : hotline && <Hotline href={`tel:${hotline}`}>Hotline: {hotline}</Hotline>
      }
      {
        myProfile
          ? (
            <Dropdown overlay={menuUserProps}>
              <UserMenu>
                <AvatarWrapper>
                  {
                    profileStore.myProfile?.avatar
                      ?
                      <img src={profileStore.myProfile?.avatar} alt='' width={32} height={32} />
                      :
                      <DefaultAvatar username={profileStore.myProfile?.name} />
                  }

                </AvatarWrapper>
                <p>{profileStore.myProfile?.name || profileStore.myProfile?.phone || 'Người dùng'}</p>
                <CaretDownOutlined />
              </UserMenu>
            </Dropdown>
          )
          : <Button type={'primary'} style={{ marginLeft: 20 }} onClick={() => history.push('/auth/login')}>{t('i0712')}</Button>
      }
      <Drawer
        getContainer={() => document.getElementById('public-header')}
        placement={'left'}
        closable={false}
        onClose={onCloseDrawer}
        visible={drawerVisible}>
        <DrawerContent>
          <LogoWrapper to={'/'} onClick={() => setDrawerVisible(false)}>
            <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_web.png`} alt='' width={150} />
          </LogoWrapper>
          <Menu selectedKeys={commonStore.pageName} onClick={handleNavigate} mode='inline'>
            <Menu.Item key={'introduction'}>
              <span>{t('i0204')}</span>
            </Menu.Item>
            <Menu.Item key={'common-question'}>
              <span>{t('i0205')}</span>
            </Menu.Item>
            <Menu.Item key={'term-of-use'}>
              <span>{t('i0206')}</span>
            </Menu.Item>
            <Menu.Item key={'privacy-policy'}>
              <span>{t('i0207')}</span>
            </Menu.Item>
          </Menu>
        </DrawerContent>
      </Drawer>
    </HeaderWrapper>
  )
}
HelpHeaderBarLayout.propTypes = {}

export default inject(
  'commonStore',
  'authenticationStore',
  'appConfigStore',
  'profileStore',
)(observer(HelpHeaderBarLayout))
