import React from 'react'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory } from 'react-router-dom'
import { Divider, Tabs } from 'antd'
import { PageHeading, PageHeadingWrapper } from '../../components/CommonStyled/CommonStyled'
import PageContentWrapper from '../../components/PageContentWrapper'
import ViewAccountInfoRow from '../../components/ViewAccountInfoRow/ViewAccountInfoRow'
import PackOfDataRow from '../../components/PackOfDataRow'
import HistoryLoginTab from '../../components/HistoryLoginTab'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

const ProfilePage = props => {
  const { profileStore, commonStore } = props
  const history = useHistory()
  const { t } = useTranslation()

  const handleClickEditProfile = () => {
    history.push('/my-profile-edit')
  }

  return (
    <>
      <Helmet>
        <title>{t('i0560')}</title>
      </Helmet>
      <PageHeadingWrapper>
        <PageHeading>
          {t('i0561')}
        </PageHeading>
      </PageHeadingWrapper>

      <PageContentWrapper>
        <Tabs activeKey={commonStore.activeTabProfile}  onChange={(e) => commonStore.setActiveTabProfile(e)}>
          <TabPane tab={t('i0562')} key="1" forceRender={true}>
            <ViewAccountInfoRow onClickEdit={handleClickEditProfile} />
            <Divider />
            <PackOfDataRow />
          </TabPane>
          <TabPane tab={t('i0563')} key="2" forceRender={true}>
           <HistoryLoginTab />
          </TabPane>
        </Tabs>
      </PageContentWrapper>
    </>
  )
}

ProfilePage.propTypes = {}

export default inject('profileStore', 'commonStore')(observer(ProfilePage))