import styled from 'styled-components'
import { Drawer } from 'antd'
import { Link } from 'react-router-dom'

export const MobileMenuWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: #fff;
  border-bottom: 1px solid #E2E2E2;
  z-index: 1000;
  padding: 0 15px;

  .menu-toggle {
    cursor: pointer;
    font-size: 24px;
  }

  .page-name {
    margin-right: auto;
    font-size: 20px;
    font-weight: 500;
    color: #313131;
    margin-bottom: 0;
    margin-left: 15px;
    line-height: 1;
  }

  .ant-drawer-body {
    padding: 20px 0;
  }
`
export const DrawerWrapper = styled(Drawer)`
  z-index: 9999;
`
export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .copyright {
    margin-top: 20px;
    padding: 0 20px;
    margin-bottom: 0;
  }
`
export const LogoWrapper = styled(Link)`
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 20px;
`
export const DownloadAppBanner = styled.a`
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: rgb(226 226 226 / 60%);
`