import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  AvatarWrapper,
  HeaderWrapper,
  NotificationMenu,
  QuickSearchResult,
  QuickSearchResultItem,
  SearchBarWrapper, Toolbox,
  UserMenu,
} from './MainHeaderBarStyled'
import { Dropdown, Input, Menu, message, Tooltip, Form } from 'antd'
import SvgIcon from '../SVGIcon'
import SVGIcon from '../SVGIcon'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import AdvanceFilterDropdown from '../AdvanceFilterDropdown'
import { ConfigIcon } from '../CommonStyled/CommonStyled'
import NotificationBellDropdown from '../NotificationBellDropdown'
import { debounce } from 'lodash-es/function'
import { DEBOUNCE_DELAY } from '../../utils/constant'
import { useMediaQuery } from 'react-responsive/src'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import miscUtils from '../../utils/miscUtils'
import DefaultAvatar from '../DefaultAvatar'

const { Search } = Input

const MainHeaderBar = props => {

  const {
    authenticationStore, commonStore, profileStore, uploadStore,
    searchStore, appConfigStore, downloadStore,
  } = props

  const [searchForm] = Form.useForm()
  const { iconsByMimeTypeObj } = appConfigStore
  const { temporarySearchList } = searchStore
  const { isMenubarHover } = commonStore

  const isMobile = useMediaQuery({ maxWidth: 768 })
  const history = useHistory()
  const wrapperRef = useRef(null)
  const [visibleAdvanceFilter, setVisibleAdvanceFilter] = useState(false)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [visibleQuickSearchResult, setVisibleQuickSearchResult] = useState(false)

  const { t } = useTranslation()
  const query = new URLSearchParams(useLocation().search)
  const keyword = query.get('q')

  const handleLogout = () => {
    authenticationStore.userLogout()
      .then(() => {
        downloadStore.resetAllStore(false)
        uploadStore.resetAllStore(false)
        history.push('/auth/login')
      })
      .catch(() => {
        message.error('Có lối xảy ra khi đăng xuất!')
      })
  }
  const handleClickProfileHistory = (tab) => {
    if (commonStore.pageName === 'my-profile') {
      commonStore.setActiveTabProfile(tab)
    } else {
      commonStore.setActiveTabProfile(tab)
      history.push('/my-profile')
    }
  }
  const handleToggleAdvanceFilter = () => {
    setVisibleAdvanceFilter(!visibleAdvanceFilter)
  }

  const menuUserProps = (
    <Menu>
      <Menu.Item onClick={() => handleClickProfileHistory('1')}>
        {t('i0233')}
      </Menu.Item>
      <Menu.Item onClick={() => handleClickProfileHistory('2')}>
        {t('i0234')}
      </Menu.Item>
      <Menu.Item danger onClick={handleLogout}>
        {t('i0235')}
      </Menu.Item>
    </Menu>
  )

  const debounceDropDown = useCallback(debounce((nextValue) => {
    handleLoadQuickSearch(nextValue)
  }, DEBOUNCE_DELAY), [])

  const handleLoadQuickSearch = nextValue => {
    setIsSearchLoading(true)
    searchStore.setSearchKeyword(nextValue)
    searchStore.quickSearch(nextValue)
      .then(response => {
        if (response.error_code === 0) {
          setVisibleQuickSearchResult(true)
        }
      })
      .finally(() => setIsSearchLoading(false))
  }
  const handleClickQuickSearch = () => {
    setVisibleAdvanceFilter(false)
    setVisibleQuickSearchResult(true)
    const searchVal = searchForm.getFieldValue('searchKeyword')
    searchVal && handleLoadQuickSearch(searchVal)
  }
  const handleQuickSearch = e => {
    if (e.target.value === '') {
      searchStore.clearTemporarySearchList()
      return
    }
    debounceDropDown(e.target.value)
  }
  const handleNormalSearch = keyword => {
    if (keyword && keyword.trim() !== '') {
      searchStore.getSearchItemList(keyword)
        .then(() => {
          searchStore.clearTemporarySearchList()
          history.push(`/search?q=${keyword}`)
        })
    }
  }
  const handleClickSearchSuggestion = item => {
    history.push(`/search?q=${item.name}`)
    searchStore.getSearchItemList(item.name)
      .then(response => console.log(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (event.target.closest('.quick-search-result')?.classList) return
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) setVisibleQuickSearchResult(false)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])
  useEffect(() => {
    if (commonStore.pageName !== 'search') {
      searchForm.setFieldsValue({
        searchKeyword: null,
      })
    }
  }, [commonStore.pageName])
  useEffect(() => {
    if (!visibleQuickSearchResult) {
      searchStore.clearTemporarySearchList()
    }
  }, [visibleQuickSearchResult])
  useEffect(() => {
    searchForm.setFieldsValue({
      searchKeyword: keyword,
    })
  }, [keyword])

  return (
    <HeaderWrapper isMenubarHover={isMenubarHover}>
      <SearchBarWrapper>
        {
          !isMobile && (
            <AdvanceFilterDropdown
              visible={visibleAdvanceFilter}
              defaultKeyword={keyword || searchStore.searchKeyword}
              onClose={() => setVisibleAdvanceFilter(false)}>
              {
                isSearchLoading
                  ? <LoadingOutlined style={{ fontSize: 20 }} />
                  : <SvgIcon name={'search'} width={20.4} height={20} />
              }
              <Form
                initialValues={{
                  searchKeyword: query.get('q') || searchStore.searchKeyword,
                }}
                form={searchForm}
                layout={'vertical'}
                style={{ flex: 1 }}>
                <Form.Item name={'searchKeyword'} noStyle>
                  <Search
                    maxLength={400}
                    onClick={() => handleClickQuickSearch()}
                    enterButton={false} placeholder={t('i0236')}
                    onChange={handleQuickSearch}
                    onSearch={handleNormalSearch}
                  />
                </Form.Item>
              </Form>
              <Tooltip title={t('i0699')} placement={'bottom'}>
                <a onClick={handleToggleAdvanceFilter}>
                  <SvgIcon name={'search-filter'} width={18} height={16.5} />
                </a>
              </Tooltip>
            </AdvanceFilterDropdown>
          )
        }
        {
          temporarySearchList.length !== 0 && visibleQuickSearchResult && (

            <QuickSearchResult className={'quick-search-result'} ref={wrapperRef}>
              <PerfectScrollbar>
                {
                  temporarySearchList.map(result => (
                    <QuickSearchResultItem key={result.id} onClick={() => handleClickSearchSuggestion(result)}>
                      {result.mimetype === 'folder' ? <SVGIcon name={'folder'} width={18} height={18} /> :
                        <img
                          src={iconsByMimeTypeObj[result.mimetype] || iconsByMimeTypeObj['default']}
                          alt='' className={'icon'}
                        />
                      }
                      <a className='desc'>
                        <p className='file-name'>
                          {result.name}
                        </p>
                        <p className={'file-owner'}>
                          {result.owner_name}
                        </p>
                      </a>
                    </QuickSearchResultItem>
                  ))
                }
              </PerfectScrollbar>
            </QuickSearchResult>
          )
        }
      </SearchBarWrapper>
      <Toolbox>
        <Tooltip title={t('i0237')} placement={'bottom'}>
          <Link to={'/common-question'}>
            <SVGIcon name={'question-circle'} width={21} height={21} />
          </Link>
        </Tooltip>
        <Tooltip title={t('i0238')} placement={'bottom'}>
          <Link to={'/config-page'} style={{ marginLeft: '16px' }}>
            <ConfigIcon />
          </Link>
        </Tooltip>
        <NotificationMenu id={'notification-menu'}>
          <NotificationBellDropdown />
        </NotificationMenu>
      </Toolbox>
      <Dropdown overlay={menuUserProps}>
        <UserMenu>
          <AvatarWrapper>
            {
              profileStore.myProfile?.avatar
                ? <img src={profileStore.myProfile?.avatar} alt='' width={32} height={32} />
                : <DefaultAvatar username={profileStore.myProfile?.name} defaultTheme />
            }
          </AvatarWrapper>
          <p>
            {
              miscUtils.phoneNumberReplace(profileStore.myProfile?.name) ||
              miscUtils.phoneNumberReplace(profileStore.myProfile?.phone) ||
              t('i0239')
            }
          </p>
          <CaretDownOutlined />
        </UserMenu>
      </Dropdown>
    </HeaderWrapper>
  )
}

MainHeaderBar.propTypes = {}

export default inject(
  'authenticationStore',
  'commonStore',
  'profileStore',
  'searchStore',
  'appConfigStore',
  'offlineStore',
  'downloadStore',
  'uploadStore',
)(observer(MainHeaderBar))
