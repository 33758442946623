import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { DefaultAvatarWrapper } from './DefaultAvatarStyled'

const DefaultAvatar = props => {
  const { username, defaultTheme } = props

  const themeGen = useCallback((min = 1, max = 12) => {
    const result = Math.floor(Math.random() * (max - min)) + min
    return defaultTheme ? `theme-default` : `theme-${result}`
  }, [defaultTheme])

  const contentGen = useCallback(() => {
    if (!username) return null
    return (
      <DefaultAvatarWrapper className={themeGen()}>
        {username && username.charAt(0).toUpperCase()}
      </DefaultAvatarWrapper>
    )
  }, [username])

  return contentGen()
}

DefaultAvatar.propTypes = {
  username: PropTypes.string,
  defaultTheme: PropTypes.bool,
}

export default memo(DefaultAvatar)