import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { message, Drawer, Tooltip, Empty, Avatar } from 'antd'
import { ExtraSharedList, InfoDetail, InfoHeader, ModalHeader, SharingDetail } from './MobileViewDetailStyled'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import SVGIcon from '../SVGIcon/SVGIcon'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'

const MobileViewDetail = props => {
  const {
    visible, onClose, itemDriveId,
    selectionStore, appConfigStore, itemDriveStore,
  } = props

  const { iconsByMimeTypeObj, dataSharingPermission } = appConfigStore
  const { selectionData } = selectionStore
  const { itemDriveInfo } = itemDriveStore

  const { t } = useTranslation()

  const handleCloseModal = () => {
    onClose()
  }
  const renderPermission = (bit_val) => {
    let permission = dataSharingPermission.filter(item => item.bit_val === bit_val)[0]
    if (permission) {
      return permission.label
    } else {
      return t('i0261')
    }
  }
  const renderExtraSharedUser = () => {
    const listToRender = itemDriveInfo?.shared_users.slice(6)
    return (
      <PerfectScrollbar>
        <ExtraSharedList>
          {
            listToRender.map(item => (
              <li key={item?.user?.id}>
                <figure border={item?.user?.avatar?.length > 0 ? '1px solid #E2E2E2' : 'none'}>
                  {
                    item?.user?.avatar
                      ?
                      <img src={item?.user?.avatar} alt='' />
                      :
                      <Avatar size={32}>
                        {
                          item?.user?.name?.charAt(0)?.toUpperCase()
                        }
                      </Avatar>
                  }
                </figure>
                <span>{item?.user?.name}: {renderPermission(item?.permission)}</span>
              </li>
            ))
          }
        </ExtraSharedList>
      </PerfectScrollbar>
    )
  }

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        itemDriveStore.clearItemDriveDetail()
      }, 300)
      return
    }
    itemDriveStore.getItemDriveDetail(itemDriveId)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }, [visible])

  return (
    itemDriveInfo &&
    <Drawer
      title={null} footer={null}
      visible={visible}
      placement={'right'}
      width={'90%'}
      onClose={handleCloseModal}>
      <ModalHeader>{t('i0262')}</ModalHeader>
      <InfoHeader>
        {
          (itemDriveInfo.mimetype === 'folder' ||
            itemDriveInfo.mimeType === 'application/vnd.google-apps.folder')
            ? <SVGIcon name={'folder'} />
            : (
              <SVGIcon
                url={
                  iconsByMimeTypeObj[itemDriveInfo.mimetype] ||
                  iconsByMimeTypeObj['default']
                }
                width={18} height={18} />
            )
        }
        <Tooltip title={itemDriveInfo.name}>
          <span>{itemDriveInfo.name}</span>
        </Tooltip>
      </InfoHeader>
      <InfoDetail>
        <dt>Loại</dt>
        <dd>
          {itemDriveInfo.type === 1 ? 'Thư mục' : itemDriveInfo.mimetype}
        </dd>
        <dt>{t('i0263')}:</dt>
        <dd>
          {itemDriveInfo.owner_name}
        </dd>
        <dt>{t('i0264')}:</dt>
        <dd>
          {itemDriveInfo.created_at}
        </dd>
        <dt>{t('i0265')}:</dt>
        <dd>
          {itemDriveInfo.updated_name}
        </dd>
        <dt>{t('i0266')}:</dt>
        <dd>
          {itemDriveInfo.updated_at}
        </dd>
      </InfoDetail>
      <SharingDetail>
        <div className='heading'>{t('i0267')}</div>
        {
          itemDriveInfo?.shared_users?.length > 0
            ?
            <ul className={'sharing-list'}>
              {
                itemDriveInfo?.shared_users.slice(0, itemDriveInfo?.shared_users?.length > 7 ? 6 : 7).map((item, index) =>
                  <Tooltip
                    title={`${item?.user?.name}: ${renderPermission(item.permission)}`}
                    key={item.user_id}
                    placement={index === 0 ? 'bottomLeft' : 'bottom'}>
                    <li>
                      {
                        item?.user?.avatar
                          ?
                          <img src={item?.user?.avatar} alt='' />
                          :
                          <Avatar size={57}>
                            {
                              item?.user?.name?.charAt(0)?.toUpperCase()
                            }
                          </Avatar>
                      }

                    </li>
                  </Tooltip>,
                )
              }
              {
                itemDriveInfo?.shared_users?.length > 7 && (
                  <Tooltip
                    overlayClassName={'renderExtraSharedUser'}
                    title={renderExtraSharedUser}
                    placement={'bottomRight'}>
                    <li className={'counter'}>
                      +{itemDriveInfo?.shared_users?.length - 6}
                    </li>
                  </Tooltip>
                )
              }
            </ul>
            :
            <Empty description={t('i0268')} />
        }

        <div className='heading'>{t('i0269')}</div>
        <ul className='sharing-action' style={{ height: '200px' }}>
          <PerfectScrollbar>
            {
              itemDriveInfo.logs?.length > 0 && itemDriveInfo.logs.map(item =>
                <li key={item.id}>
                  <figure>
                    {
                      item?.avatar
                        ?
                        <img src={item?.avatar} alt='' />
                        :
                        <Avatar size={57}>
                          {
                            item?.fullname?.charAt(0)?.toUpperCase()
                          }
                        </Avatar>
                    }
                  </figure>
                  <div className='desc'>
                    <p><strong>{item.fullname}</strong> {item.action_log}</p>
                    <time>{item.created_at}</time>
                  </div>
                </li>,
              )
            }
          </PerfectScrollbar>
        </ul>
      </SharingDetail>
    </Drawer>
  )
}

MobileViewDetail.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  itemDriveId: PropTypes.string,
}

export default inject(
  'selectionStore',
  'appConfigStore',
  'itemDriveStore',
)(observer(MobileViewDetail))
