import axios from 'axios'
import config from '../config'
import authenticationStore from '../stores/authenticationStore'
const { apiUrl } = config;

const source = axios.CancelToken.source()

export const PrivacyPolicyRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getContentPrivacyPolicyPage:()=> {
    return axios({
      method:'get',
      baseURL:apiUrl,
      url:'api/news/find-by-type?type=3',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      }
    })
  }
}
