import { action, observable } from 'mobx'
import { FilePreviewRequest } from '../requests/FilePreviewRequest'


class FilePreviewStore {

  @observable filePreviewList = []
  @observable filePreviewBreadcrumbData = []
  @observable filePreviewCurrentNode = null
  @observable filePreviewSortBy = 'created_at'
  @observable filePreviewSort = 'desc'

  @action setFilePreviewSort = (sort) => {
    this.filePreviewSort = sort
  }
  @action setFilePreviewSortBy = (sortBy) => {
    this.filePreviewSortBy = sortBy
  }


  @action resetFilePreviewBreadcrumbData = () => {
    this.filePreviewBreadcrumbData = [{ id: null, name: 'Dữ liệu của tôi' }]
    this.filePreviewCurrentNode = null
  }
  @action setFilePreviewBreadcrumbData = (item) => {
    let index = (this.filePreviewBreadcrumbData).indexOf(item)
    let newFilePreviewBreadcrumbData = []
    if (index >= 0) {
      newFilePreviewBreadcrumbData = this.filePreviewBreadcrumbData.slice(0, index + 1)
    } else {
      newFilePreviewBreadcrumbData = [...this.filePreviewBreadcrumbData, item]
    }
    this.filePreviewBreadcrumbData = newFilePreviewBreadcrumbData
    this.filePreviewCurrentNode = item
  }


  @action checkShareLinkFile = (payload) => {
    return new Promise((resolve, reject) => {
      FilePreviewRequest.checkShareLinkFile(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action getPublicLinkDownloadFile = (payload) => {
    return new Promise((resolve, reject) => {
      FilePreviewRequest.getPublicLinkDownloadFile(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action checkPasswordFileDownload = (payload) => {
    return new Promise((resolve, reject) => {
      FilePreviewRequest.checkPasswordFileDownload(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action checkFilePermission = (payload) => {
    return new Promise((resolve, reject) => {
      FilePreviewRequest.checkFilePermission(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action getFileUnauthenticated = (payload) => {
    return new Promise((resolve, reject) => {
      FilePreviewRequest.getFileUnauthenticated(payload)
        .then(response => {
          this.filePreviewList = response.data.data
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action getDetailFileUnauthenticated = (payload) => {
    return new Promise((resolve, reject) => {
      FilePreviewRequest.getDetailFileUnauthenticated(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action downloadPublicFolder = (folder) => {
    return new Promise((resolve, reject) => {
      FilePreviewRequest.downloadPublicFolder(folder.id, folder.cancelSource, (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
export default new FilePreviewStore()