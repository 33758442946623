import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import HelpHeaderBarLayout from '../../components/HelpHeaderBar'
import { Link } from 'react-router-dom'
import { ContentWrapper, HelpFooterBarWrapper, LayoutWrapper } from './PublicLayoutStyled'
import { inject, observer } from 'mobx-react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { APP_NAME } from '../../utils/constant'

const PublicLayout = props => {
  const { children, appConfigStore, profileStore, authenticationStore } = props
  const { t } = useTranslation()

  useEffect(() => {
    appConfigStore.getAppConfig()
    if (!authenticationStore.accessToken) return
    profileStore.getMyProfile()
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }, [authenticationStore.accessToken])

  return (
    <LayoutWrapper>
      <HelpHeaderBarLayout />
      <ContentWrapper>
        {children}
      </ContentWrapper>
      <HelpFooterBarWrapper>
        <p className={'copyright'}>
          &copy; 2021 CMCTelecom. {t('i0407')}.
        </p>
        <ul>
          <li>
            <Link to={'/term-of-use'}>{t('i0408')}</Link>
          </li>
          <li>
            <Link to={'/privacy-policy'}>{t('i0409')}</Link>
          </li>
        </ul>
      </HelpFooterBarWrapper>
    </LayoutWrapper>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node,
}

export default inject(
  'appConfigStore',
  'profileStore',
  'authenticationStore',
)(observer(PublicLayout))