import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { PhoneBookTabPaneWrapper } from './PhoneBookTabPaneStyled'
import { Button, message } from 'antd'
import ConfirmModal from '../ConfirmModal'
import InsertPhoneBookModal from '../InsertPhoneBookModal'
import PhoneBookTableView from '../PhoneBookTableView'
import PageResult from '../PageResult'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'react-i18next'

const PhoneBookTabPane = props => {
  const { contactStore } = props
  const { contactList, page, perPage, hasNextPage } = contactStore
  const [deletingPhoneBookId, setDeletingPhoneBookId] = useState(null)
  const [selectedPhoneBookIds, setSelectedPhoneBookIds] = useState([])
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [insertModalVisible, setInsertModalVisible] = useState(false)
  const [isDeleteMultiple, setIsDeleteMultiple] = useState(false)
  const [isLoadingScroll, setIsLoadingScroll] = useState(false)

  const { t } = useTranslation()

  const handleLoadMore = useCallback(() => {
    if (isLoadingScroll) return
    if (hasNextPage) {
      setIsLoadingScroll(true)
      let nextPage = page + 1
      contactStore.setPage(nextPage)
      let payload = {
        page: nextPage,
        per_page: perPage,
      }
      contactStore.getContactListOnScroll(payload)
        .then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        .finally(() => setIsLoadingScroll(false))
    }
  }, [hasNextPage, isLoadingScroll])

  const handleClickDeletePhoneBook = id => {
    setDeletingPhoneBookId(id)
    setDeleteModalVisible(true)
  }
  const handleClickDeleteMultiplePhoneBook = () => {
    setDeleteModalVisible(true)
    setIsDeleteMultiple(true)
  }
  const handleCancelDelete = () => {
    setDeletingPhoneBookId(null)
    setIsDeleteMultiple(false)
    setSelectedPhoneBookIds([])
    setDeleteModalVisible(false)
  }
  const handleConfirmDelete = () => {

    const contactToDelete = selectedPhoneBookIds.length !== 0
      ? selectedPhoneBookIds
      : [deletingPhoneBookId]

    contactStore.deleteContact(contactToDelete)
      .then(response => {
        if (response.error_code === 0) {
          setSelectedPhoneBookIds([])
          initialLoadData()
          setDeleteModalVisible(false)
          message.success(t('i0326'))
        } else {
          message.error(response.message)
        }
      })
      .catch(error => {
        error?.response?.status != 401 && message.error(error.message)
      })

  }
  const handleConfirmInsert = (phoneNumber) => {
    contactStore.addContact({ phone_contact: phoneNumber })
      .then(response => {
        if (response.error_code === 0) {
          setInsertModalVisible(false)
          initialLoadData()
          message.success(t('i0327'))
        } else {
          setInsertModalVisible(false)
          message.error(response.message)
        }
      })
      .catch(error => {
        setInsertModalVisible(false)
        error?.response?.status != 401 && message.error(error.message)
      })
  }
  const handleSelectPhoneBook = (rowKeys, rows) => {
    setSelectedPhoneBookIds(rowKeys)
  }
  const initialLoadData = () => {
    contactStore.setPage(1)
    let payload = {
      page: 1,
      per_page: contactStore.perPage,
    }
    contactStore.getContactList(payload)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }

  useEffect(() => {
    initialLoadData()
    return () => {
      contactStore.clearContactList()
    }
  }, [])

  return (
    <PhoneBookTabPaneWrapper>
      <Button
        onClick={() => setInsertModalVisible(true)}
        className={'submit-config-btn'}
        type={'primary'}
        style={{ marginBottom: '16px' }}>
        {t('i0328')}
      </Button>

      {
        selectedPhoneBookIds && selectedPhoneBookIds.length > 0 &&
        <Button style={{ marginLeft: '24px' }} className={'default-config-btn'}
          onClick={handleClickDeleteMultiplePhoneBook}>
          {t('i0329')}
        </Button>
      }

      <InfiniteScroll
        initialLoad={false}
        pageStart={page} threshold={1}
        loadMore={handleLoadMore}
        hasMore={hasNextPage}
        useWindow={true}
      >
        {
          contactList && contactList.length > 0
            ?
            <div style={{ width: '80%' }}>
              <PhoneBookTableView
                selectedPhoneBookIds={selectedPhoneBookIds}
                dataSource={contactList}
                callbackDelete={handleClickDeletePhoneBook}
                callbackSelected={handleSelectPhoneBook} />
            </div>
            :
            <PageResult type={'contact_list_empty'} />
        }
      </InfiniteScroll>

      <ConfirmModal
        visible={deleteModalVisible}
        onClose={handleCancelDelete}
        callback={handleConfirmDelete}
        okText={t('i0330')}
        cancelText={t('i0331')}
        title={t('i0332')}
      />

      <InsertPhoneBookModal
        visible={insertModalVisible}
        onClose={() => setInsertModalVisible(false)}
        callback={handleConfirmInsert}
      />

    </PhoneBookTabPaneWrapper>
  )
}

PhoneBookTabPane.propTypes = {}

export default inject('contactStore')(observer(PhoneBookTabPane))