import { action, observable } from 'mobx'
import { TermOfUseRequest } from '../requests/TermOfUseRequest'

class TermOfUseStore {

  @observable termOfUseContent = {}

  @action cancelRequest = () => {
    TermOfUseRequest.cancelRequest()
  }

  @action getContentTermOfUsePage = () => {
    return new Promise((resolve,reject) => {
      TermOfUseRequest.getContentTermOfUsePage().then(response => {
        this.termOfUseContent = response.data?.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default new TermOfUseStore()