import styled from 'styled-components'
import { Tabs } from 'antd'

export const ConfigPageWrapper = styled.div`
  .ant-tabs-tab {
    justify-content: center;
    padding: 12px;
  }
`
export const StyledTabs = styled(Tabs)`
  overflow: visible;
  .ant-tabs-nav:before {
    left: -18px;
    width: calc(100% + 36px);
  }
`
