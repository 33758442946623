import styled from 'styled-components'
import { Menu } from 'antd'
import { slideDownIn, slideUpIn } from '../CommonStyled/CommonStyled'

export const OverlayWrapper = styled.div`
  left: ${props => props.x}px;
  padding: 0;
  position: absolute;
  top: ${props => props.y}px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 14%);
  min-width: 240px;

  &.slide-down {
    animation: ${slideDownIn} 0.2s ease-in-out;
  }

  &.slide-up {
    animation: ${slideUpIn} 0.2s ease-in-out;
  }
`
export const CustomOverlayMenu = styled(Menu)`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.14);
  border: 1px solid #f0f0f0 !important;
`
export const ContextMenuItem = styled.div`
  display: flex;
  align-items: center;
`
export const ContextMenuText = styled.div`
  font-size: 1.6rem;
  margin-left: 8px;
`
