import styled from 'styled-components'
import { Menu } from 'antd'

export const ContextMenuDropdownWrapper = styled.div`

`
export const CustomOverlayMenu = styled(Menu)`
  padding: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.14);
  min-width: 230px;
`
export const ContextMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
`
export const ContextMenuText = styled.div`
  font-size: 1.6rem;
  margin-left: 8px;
`