import styled from 'styled-components'

export const DefaultAvatarWrapper = styled.span`
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid transparent;

  &.theme-default, &.theme-1 {
    color: #096dd9;
    background: #e6f7ff;
    border-color: #91d5ff;
  }

  &.theme-2 {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2;
  }

  &.theme-3 {
    color: #cf1322;
    background: #fff1f0;
    border-color: #ffa39e;
  }

  &.theme-4 {
    color: #d4380d;
    background: #fff2e8;
    border-color: #ffbb96;
  }

  &.theme-5 {
    color: #d46b08;
    background: #fff7e6;
    border-color: #ffd591;
  }

  &.theme-6 {
    color: #d48806;
    background: #fffbe6;
    border-color: #ffe58f;
  }

  &.theme-7 {
    color: #7cb305;
    background: #fcffe6;
    border-color: #eaff8f;
  }

  &.theme-8 {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
  }

  &.theme-9 {
    color: #08979c;
    background: #e6fffb;
    border-color: #87e8de;
  }

  &.theme-10 {
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff;
  }

  &.theme-11 {
    color: #531dab;
    background: #f9f0ff;
    border-color: #d3adf7;
  }
`