// import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet/es/Helmet'
import ViewToggleComponent from '../../components/ViewToggleComponent/ViewToggleComponent'
import { Button, Col, Dropdown, Menu, message, Modal, notification, Row, Spin, Tooltip } from 'antd'
import PageContentWrapper from '../../components/PageContentWrapper'
import FilesGridView from '../../components/FilesGridView'
import FilesListView from '../../components/FilesListView'
import PageResult from '../../components/PageResult'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { CONTEXT_MENU_ACTION, MOBILE_ACTION } from '../../utils/constant'
import {
  CustomCustomNotificationMessage,
  CustomNotificationContent,
  SectionHeading, SortButton,
} from '../../components/CommonStyled/CommonStyled'
import axios from 'axios'
import FileDownload from 'js-file-download'
import DataInfoModal from '../../components/DataInfoModal/DataInfoModal'
import SharingModal from '../../components/SharingModal/SharingModal'
import { ButtonGroup } from '../TrashPage/TrashPageStyled'
import { useMediaQuery } from 'react-responsive/src'
import FoldersGridView from '../../components/FoldersGridView/FoldersGridView'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import MobileMask from '../../components/MobileMask/MobileMask'
import MobileViewDetail from '../../components/MobileViewDetail/MobileViewDetail'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'

const SearchPage = props => {
  const {
    searchStore, commonStore, selectionStore,
    blockStore, myDriveStore, moveItemStore,
    offlineStore, downloadStore, favoriteStore,
    trashStore,
    profileStore, previewOverlayStore,
  } = props
  const { viewByGridMode } = commonStore
  let { searchList, page, perPage, hasNextPage, searchPageSortBy } = searchStore
  const { selectionData, selectionIds, firstSelectionIndex, lastSelectionIndex, clickSessionId } = selectionStore

  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const history = useHistory()

  const [isLoadingScroll, setIsLoadingScroll] = useState(false)
  const [sharingModalType, setSharingModalType] = useState(1)
  const [showSharingModal, setShowSharingModal] = useState(false)
  const [sharingItemDrive, setSharingItemDrive] = useState(null)
  const [showDataInfoModal, setShowDataInfoModal] = useState(null)
  const [contentModal, setContentModal] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [files, setFiles] = useState([])
  const [folders, setFolders] = useState([])
  const [actionKeyModal, setActionKeyModal] = useState('deleteFile')
  const [itemDriveMobile, setItemDriveMobile] = useState(null)
  const [visibleMobileDetail, setVisibleMobileDetail] = useState(false)
  const [visibleMobileMask, setVisibleMobileMask] = useState(false)
  const [nodeId, setNodeId] = useState()

  const query = new URLSearchParams(useLocation().search)
  const keyword = query.get('q')
  const mimetype_group_id = query.get('mimetype_group_id')
  const storage_type = query.get('storage_type')
  const from_date = query.get('from_date')
  const to_date = query.get('to_date')
  const from_update_date = query.get('from_update_date')
  const to_update_date = query.get('to_update_date')
  const share_with = query.get('share_with')
  const owner = query.get('owner')

  const deleteFunction = (event) => {
    if (selectionIds?.length > 0 && !previewOverlayStore.visible) {
      if (event.keyCode === 46) {
        commonStore.setVisibleModalDelete(true)
      }
    }
  }
  const handleConfirmDelete = () => {
    handleCallbackAction(CONTEXT_MENU_ACTION.trash, undefined)
    commonStore.setVisibleModalDelete(false)
  }
  useEffect(() => {
    document.addEventListener('keydown', deleteFunction, false)
    return () => {
      document.removeEventListener('keydown', deleteFunction, false)
    }
  }, [selectionIds])

  const handleCloseMobileMask = () => {
    setVisibleMobileMask(false)
    setItemDriveMobile(null)
  }
  const handleCloseMobileDetail = () => {
    setVisibleMobileDetail(false)
    setItemDriveMobile(null)
  }
  const handleCallbackSharingModal = () => {
    reloadDataAtCurrentNode()
  }
  const handleCloseSharingModal = () => {
    setShowSharingModal(false)
    setSharingItemDrive(null)
  }
  const handleCloseDataInfoModal = () => {
    setShowDataInfoModal(false)
    selectionStore.clearSelectionIndex()
  }
  const handleOpenSharingModal = (itemDrive, actionType) => {
    setSharingModalType(actionType)
    setShowSharingModal(true)
    setSharingItemDrive(itemDrive)
  }
  const handleOpenDataInfoModal = () => {
    setShowDataInfoModal(true)
  }
  const reloadDataAtCurrentNode = () => {
    return new Promise((resolve, reject) => {
      searchStore.getSearchItemList(
        keyword,
        mimetype_group_id,
        storage_type,
        owner,
        from_date,
        to_date,
        from_update_date,
        to_update_date,
        share_with,
        page,
        perPage,
      )
        .then((response) => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
          resolve(response)
        })
        .catch((error) => {
          error?.response?.status != 401 && message.error(error.message)
          reject(error)
        })
    })
  }
  const handleCallbackAction = (action, itemTarget) => {
    if (selectionIds.length > 1) {
      switch (action) {
        case CONTEXT_MENU_ACTION.lock:
          blockStore.blockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/lock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0530')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackLock(selectionData[0].id)}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.unlock:
          blockStore.unBlockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/unlock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0531')}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successUnBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.trash:
          myDriveStore.trashDriveItem(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0516')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackTrash()}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successTrash',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => {
              error?.response?.status != 401 && message.error(error.message)
            })
          break
        case CONTEXT_MENU_ACTION.move:
          let payload = {
            parent_id: itemTarget?.id,
            list_node_id: selectionIds,
          }
          moveItemStore.moveDriveItem(payload)
            .then(response => {
                if (response.error_code === 0) {
                  selectionStore.clearSelectionIndex()
                  reloadDataAtCurrentNode()
                  notification.info({
                    className: 'custom-notification-share',
                    closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                    width={15}
                                    height={15}/>,
                    icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                               height={20}/>,
                    message: <CustomNotificationContent>
                      <CustomCustomNotificationMessage>
                        {`${t('i0517')} ${itemTarget?.name ? ` ${t('i0518')} ` + itemTarget?.name : t('i0519')}`}
                      </CustomCustomNotificationMessage>
                      {/*<CustomCustomNotificationLink onClick={() => handleRollbackRemoveFavorite(selectionData[0].id)}>*/}
                      {/*  Hủy*/}
                      {/*</CustomCustomNotificationLink>*/}
                    </CustomNotificationContent>,
                    description: null,
                    placement: 'bottomLeft',
                    duration: 6,
                    key: 'successMoveDrive',
                  })
                } else {
                  message.error(response.message)
                }
              },
            )
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        default:
          break
      }
    } else {
      switch (action) {
        case CONTEXT_MENU_ACTION.share:
          handleOpenSharingModal(selectionData[0], action)
          break
        case CONTEXT_MENU_ACTION.copyLink:
          handleOpenSharingModal(selectionData[0], action)
          break
        case CONTEXT_MENU_ACTION.trash:
          myDriveStore.trashDriveItem([selectionData[0].id])
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0520')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackTrash()}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successTrash',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => {
              error?.response?.status != 401 && message.error(error.message)
            })
          break
        case CONTEXT_MENU_ACTION.download:
          if (selectionData[0].type === 2) {
            offlineStore.saveDataForOfflineView(selectionData)
            downloadStore.getDownloadLink(selectionData[0].id)
              .then(response => {
                if (response.error_code === 0) {
                  selectionStore.clearSelectionIndex()
                  if (response.data?.url) {
                    axios.get(response.data.url, {
                      responseType: 'blob',
                    }).then(res => {
                      FileDownload(res.data, selectionData[0].name)
                    })
                  }
                } else {
                  message.error(response.message)
                }
              })
              .catch(error => error?.response?.status != 401 && message.error(error.message))
          }
          break
        case CONTEXT_MENU_ACTION.favorite:
          favoriteStore.favoriteItemDrive(selectionData[0].id)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0521')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackFavorite(selectionData[0].id)}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successFavorite',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.removeFavorite:
          favoriteStore.removeFavoriteItemDrive(selectionData[0].id)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0522')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackRemoveFavorite(selectionData[0].id)}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successRemoveFavorite',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.move:
          let payload = {
            parent_id: itemTarget?.id,
            list_node_id: [selectionData[0].id],
          }
          moveItemStore.moveDriveItem(payload)
            .then(response => {
                if (response.error_code === 0) {
                  selectionStore.clearSelectionIndex()
                  reloadDataAtCurrentNode()
                  notification.info({
                    className: 'custom-notification-share',
                    closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                    width={15}
                                    height={15}/>,
                    icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                               height={20}/>,
                    message: <CustomNotificationContent>
                      <CustomCustomNotificationMessage>
                        {`${t('i0523')} ${itemTarget?.name ? ` ${t('i0524')} ` + itemTarget?.name : t('i0525')}`}
                      </CustomCustomNotificationMessage>
                      {/*<CustomCustomNotificationLink*/}
                      {/*  onClick={() => handleRollbackRemoveFavorite(selectionData[0].id)}*/}
                      {/*>*/}
                      {/*  Hủy*/}
                      {/*</CustomCustomNotificationLink>*/}
                    </CustomNotificationContent>,
                    description: null,
                    placement: 'bottomLeft',
                    duration: 6,
                    key: 'successMoveDrive',
                  })
                } else {
                  message.error(response.message)
                }
              },
            )
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.rename:
          console.log('renaming')
          let fileTypeName = ''
          switch (selectionData[0].type) {
            case 1:
              fileTypeName = t('i0526')
              break
            case 2:
              fileTypeName = t('i0527')
              break
            default:
              fileTypeName = ''
              break
          }
          myDriveStore.renameDriveItem({ node_id: selectionData[0].id, name: selectionData[0].name })
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {`${t('i0528')} ${fileTypeName} ${t('i0529')}`}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackRename(fileTypeName)}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successRename',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => {
              error?.response?.status != 401 && message.error(error.message)
            })
          break
        case CONTEXT_MENU_ACTION.viewInfo:
          handleOpenDataInfoModal()
          break
        case CONTEXT_MENU_ACTION.lock:
          blockStore.blockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/lock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0530')}
                    </CustomCustomNotificationMessage>
                    {/*<CustomCustomNotificationLink onClick={() => handleRollbackLock(selectionData[0].id)}>*/}
                    {/*  Hủy*/}
                    {/*</CustomCustomNotificationLink>*/}
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.unlock:
          blockStore.unBlockItemDrive(selectionIds)
            .then(response => {
              if (response.error_code === 0) {
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/context-menu/unlock-white.svg`} alt={''}
                             width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0531')}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successUnBlock',
                })
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
          break
        case CONTEXT_MENU_ACTION.restore:
          trashStore.restoreFile({ list_id: selectionIds })
            .then(response => {
              if (response.error_code !== 0) {
                message.error(response.message)
              } else {
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {response.data.message}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successRollbackTrash',
                })
                selectionStore.clearSelectionIndex()
                reloadDataAtCurrentNode()
              }
            }).catch(error => {
            error?.response?.status != 401 && message.error(error.message)
          })
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          let contentModal = itemTarget === 'folder'
            ? (
              <>
                {t('i0619')}<br/>
                {t('i0620')}
              </>
            ) : (
              <>
                {t('i0621')}<br/>
                {t('i0622')}
              </>
            )
          setContentModal(contentModal)
          setActionKeyModal('delete')
          setModalVisible(true)
          break
        default:
          break
      }
    }
  }
  const handleCallbackMobileAction = (item, action) => {
    switch (action) {
      case MOBILE_ACTION.click:
        if (item.type === 1) {
          handleClickFolderOrBreadcrumb(item)
        } else {
          setItemDriveMobile(item)
          setVisibleMobileMask(true)
        }
        break
      case MOBILE_ACTION.viewInfo:
        setItemDriveMobile(item)
        setVisibleMobileDetail(true)
        break
      default:
        break
    }
  }
  const handleClickFolderOrBreadcrumb = folder => {
    if (folder?.status === 1) {
      setActionKeyModal('restore')
      setNodeId(folder.id)
      setContentModal(<>{t('i0614')}</>)
      setModalVisible(true)
      return
    }
    if (folder.is_mine) {
      history.push(`/my-drive/${folder?.id}`)
    } else {
      history.push(`/shared-with-me/${folder?.id}`)
    }
    selectionStore.clearSelectionIndex()

  }
  const getFilesInTrash = () => {
    trashStore.getFilesInTrash(1, 100, 'asc', 'name')
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
        return response
      })
      .catch(error => {
        error.response.status != 401 && message.error(error.message)
        return error
      })
  }
  const handleLoadMore = () => {
    if (isLoadingScroll) return
    if (hasNextPage) {
      setIsLoadingScroll(true)
      let nextPage = page + 1
      searchStore.setPage(nextPage)
      searchStore.getSearchItemListOnScroll(
        keyword,
        mimetype_group_id,
        storage_type,
        from_date,
        to_date,
        from_update_date,
        to_update_date,
        share_with,
        owner,
        nextPage,
        perPage,
      ).then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      }).catch(error => {
        error?.response?.status != 401 && message.error(error.message)
      }).finally(() => setIsLoadingScroll(false))
    }
  }
  const handleClearSelection = event => {
    if (event.target.closest('.selectable-item')) return
    if (event.target.closest('.context-menu')) return
    selectionStore.clearSelectionIndex()
  }
  const handleChangeSortBy = sortType => {
    searchStore.setSearchPageSortBy(sortType)
  }
  const handleChangeSortDirection = sortDirection => {
    searchStore.setSearchPageSortAsc(sortDirection)
  }
  const sortMenu = (
    <Menu selectedKeys={searchStore.searchPageSortBy} className={'sort-menu'}>
      <Menu.Item onClick={() => handleChangeSortBy('name')} key={'name'}>
        {t('i0536')}
      </Menu.Item>
      <Menu.Item onClick={() => handleChangeSortBy('created')} key={'created'}>
        {t('i0264')}
      </Menu.Item>
      <Menu.Item onClick={() => handleChangeSortBy('updated')} key={'updated'}>
        {t('i0535')}
      </Menu.Item>
    </Menu>
  )
  const sortableTools = () => {
    let sortByDisplay = t('i0536')
    switch (searchPageSortBy) {
      case 'name':
        sortByDisplay = t('i0536')
        break
      case 'created':
        sortByDisplay = t('i0264')
        break
      case 'updated':
        sortByDisplay = t('i0535')
        break
    }
    return (
      <Col>
        <Row type={'flex'} gutter={15} justify={'space-between'} align={'middle'}>
          <Col>
            <Dropdown overlay={sortMenu} trigger={['click']}>
              <SortButton>
                {sortByDisplay}
              </SortButton>
            </Dropdown>
          </Col>
          <Col>
            <SortButton>
              {
                searchStore.searchPageSortAsc
                  ? <Tooltip title={t('i0701')} placement={'bottom'}>
                    <ArrowUpOutlined onClick={() => handleChangeSortDirection(false)}/>
                  </Tooltip>
                  : <Tooltip title={t('i0700')} placement={'bottom'}>
                    <ArrowDownOutlined onClick={() => handleChangeSortDirection(true)}/>
                  </Tooltip>
              }
            </SortButton>
          </Col>
        </Row>
      </Col>
    )
  }
  const renderScrollList = () => {
    return (
      <div onClick={event => handleClearSelection(event)}>
        <PageContentWrapper>
          {
            searchList.length === 0
              ? <PageResult type={'empty-search-list'}/>
              : viewByGridMode || isMobile
                ? (
                  <>
                    {
                      folders.length > 0 && (
                        <>
                          <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                               justify={'space-between'} align={'middle'}>
                            <Col>
                              <SectionHeading style={{ marginBottom: 0 }}>{t('i0533')}</SectionHeading>
                            </Col>
                            {sortableTools()}
                          </Row>
                          <FoldersGridView
                            folderList={folders}
                            onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                            callbackAction={handleCallbackAction}
                            callbackMobileAction={handleCallbackMobileAction}
                          />
                        </>
                      )
                    }
                    {
                      files?.length > 0 &&
                      <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                           justify={'space-between'} align={'middle'}>
                        <Col>
                          <SectionHeading style={{ marginBottom: 0 }}>{t('i0534')}</SectionHeading>
                        </Col>
                        {folders.length === 0 && sortableTools()}
                      </Row>
                    }
                    <FilesGridView
                      fileList={files}
                      callbackAction={handleCallbackAction}
                      callbackMobileAction={handleCallbackMobileAction}
                    />
                  </>
                ) : !isMobile && (
                <FilesListView
                  fileList={searchList}
                  callbackAction={handleCallbackAction}
                />
              )
          }
          {
            isLoadingScroll &&
            <div style={{ display: 'flex', justifyContent: 'center', margin: '2% auto 0' }}>
              <Spin/>
            </div>
          }
        </PageContentWrapper>
      </div>
    )
  }
  const onConfirmAction = () => {
    switch (actionKeyModal) {
      case 'restore':
        trashStore.restoreFile({ list_id: [nodeId] })
          .then(response => {
            if (response.error_code !== 0) {
              message.error(response.message)
            } else {
              notification.info({
                className: 'custom-notification-share',
                closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                width={15}
                                height={15}/>,
                icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                           height={20}/>,
                message: <CustomNotificationContent>
                  <CustomCustomNotificationMessage>
                    {response.data.message}
                  </CustomCustomNotificationMessage>
                </CustomNotificationContent>,
                description: null,
                placement: 'bottomLeft',
                duration: 6,
                key: 'successRollbackTrash',
              })
              resetState()
              history.push(`/my-drive/${nodeId}`)
            }
          }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        break
      case 'delete_all':
        trashStore.deleteAllFilesInTrash({})
          .then(response => {
            if (response.error_code !== 0) {
              message.error(response.message)
            } else {
              message.success(response.data.message)
              getFilesInTrash()
              profileStore.getMyProfileNotShowSpinner()
              resetState()
            }
          }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        break
      case 'delete':
        trashStore.deleteFileInTrash({ list_id: selectionIds })
          .then(response => {
            if (response.error_code !== 0) {
              message.error(response.message)
            } else {
              message.success(response.data.message)
              resetState()
              reloadDataAtCurrentNode()
            }
          }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        break
      default:
        break
    }
  }
  const onHandleCloseModal = () => {
    resetState()
  }
  const resetState = () => {
    setActionKeyModal('')
    setModalVisible(false)
    setContentModal('')
    setNodeId('')
  }

  useEffect(() => {
    if (searchList.length === 0) return
    const tempFolder = [], tempFile = []
    searchList.forEach(record => {
      if (record.type === 1) tempFolder.push(record)
      if (record.type === 2) tempFile.push(record)
    })
    tempFolder.forEach((item, index) => {
      item.realIndex = index
    })
    tempFile.forEach((item, index) => {
      item.realIndex = index + tempFolder.length
    })
    setFolders(tempFolder)
    setFiles(tempFile)
  }, [searchList])

  useEffect(() => {
    if (firstSelectionIndex === null || lastSelectionIndex === null) return
    let fileShiftSelect = files.filter(item => item.realIndex >= firstSelectionIndex && item.realIndex <= lastSelectionIndex)
    let folderShiftSelect = folders.filter(item => item.realIndex >= firstSelectionIndex && item.realIndex <= lastSelectionIndex)
    let newSelectionItems = [...folderShiftSelect, ...fileShiftSelect]
    selectionStore.setSelectionData(newSelectionItems)
  }, [firstSelectionIndex, lastSelectionIndex, files, folders, clickSessionId])

  useEffect(() => {
    return () => {
      selectionStore.setLastSelectionIndex(null)
      selectionStore.setFirstSelectionIndex(null)
      selectionStore.clearSelectionIndex()
    }
  }, [])

  useEffect(() => {
    return () => searchStore.setSearchKeyword(null)
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('i0584')}</title>
      </Helmet>
      <ViewToggleComponent
        heading={[{ id: null, name: t('i0673') }]}
        onClickBreadcrumb={() => null}
      />
      <InfiniteScroll
        initialLoad={false}
        pageStart={page} threshold={1}
        loadMore={handleLoadMore}
        hasMore={hasNextPage}
        useWindow={true}
      >
        {renderScrollList()}
      </InfiniteScroll>
      <SharingModal
        actionType={sharingModalType}
        modalVisible={showSharingModal}
        onCancel={handleCloseSharingModal}
        itemDrive={sharingItemDrive}
        callbackSharingModal={handleCallbackSharingModal}
      />
      <DataInfoModal
        visible={showDataInfoModal}
        onClose={handleCloseDataInfoModal}
      />
      <Modal
        onCancel={() => setModalVisible(false)}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal'}
        visible={modalVisible}
        maskClosable={false}
      >
        <p>
          {contentModal}
        </p>
        <ButtonGroup>
          <Button type={'default'} onClick={onHandleCloseModal}>{t('i0629')}</Button>
          <Button type={'primary'}
                  onClick={onConfirmAction}>{actionKeyModal === 'restore' ? t('i0630') : t('i0631')} </Button>
        </ButtonGroup>
      </Modal>
      <ModalConfirmDelete onOk={handleConfirmDelete}/>
      <MobileMask itemDrive={itemDriveMobile} visible={visibleMobileMask} onClose={handleCloseMobileMask}/>
      <MobileViewDetail visible={visibleMobileDetail} onClose={handleCloseMobileDetail}
                        itemDriveId={itemDriveMobile?.id}/>
    </>
  )
}

SearchPage.propTypes = {}

export default inject(
  'searchStore',
  'commonStore',
  'selectionStore',
  'blockStore',
  'myDriveStore',
  'moveItemStore',
  'offlineStore',
  'downloadStore',
  'favoriteStore',
  'trashStore',
  'profileStore',
  'previewOverlayStore',
)(observer(SearchPage))