import { action, autorun, observable } from 'mobx'
import arrayUtils from '../utils/arrayUtils'

class OfflineStore {
  constructor() {
    autorun(() => {
      if (this.offlineData) {
        this.offlineDataList = JSON.parse(this.offlineData)
        this.setSortBy(this.sortBy)
      }
      if (this.offlineDataDetail) {
        this.offlineDataDetailList = JSON.parse(this.offlineDataDetail)
      }
    })
  }

  @observable offlineData = localStorage.getItem('offlineData')
  @observable offlineDataList = []
  @observable offlineDataDetail = localStorage.getItem('offlineDataDetail')
  @observable offlineDataDetailList = []
  @observable sortBy = 1
  @observable sortAsc = true

  @action setOfflineDataList = (newList) => {
    this.offlineDataList = newList
  }

  @action setSortBy = sortType => {
    this.sortBy = sortType
    if (sortType === 1) {
      const newList = this.offlineDataList.slice().sort((a, b) => {
        let nameA = a.name.toUpperCase()
        let nameB = b.name.toUpperCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      this.offlineDataList = this.sortAsc ? newList : newList.reverse()
    }
    if (sortType === 2) {
      const newList = this.offlineDataList.slice().sort((a, b) => {
        let keyA = new Date(a.updated_at)
        let keyB = new Date(b.updated_at)
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })
      this.offlineDataList = this.sortAsc ? newList : newList.reverse()
    }
  }
  @action setSortAsc = direction => {
    this.sortAsc = direction
    this.setSortBy(this.sortBy)
  }
  @action saveDataForOfflineView = (item, itemDetail) => {
    if (!Array.isArray(item)) return
    if (!itemDetail || (itemDetail && !itemDetail.hasOwnProperty('id'))) return
    this.offlineDataList = arrayUtils.getUnique([...this.offlineDataList, ...item], 'id')
    this.offlineData = JSON.stringify(this.offlineDataList)
    localStorage.setItem('offlineData', this.offlineData)
    this.offlineDataDetailList = arrayUtils.getUnique([...this.offlineDataDetailList, itemDetail], 'id')
    this.offlineDataDetail = JSON.stringify(this.offlineDataDetailList)
    localStorage.setItem('offlineDataDetail', this.offlineDataDetail)
  }
  @action clearOfflineData = () => {
    this.offlineData = null
    this.offlineDataList.length = 0
    this.offlineDataDetail = null
    this.offlineDataDetailList.length = 0
    localStorage.removeItem('offlineData')
    localStorage.removeItem('offlineDataDetail')
  }
}

export default new OfflineStore()