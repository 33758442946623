import React from 'react'
import { DropdownBreadcrumbIconDot, DropdownBreadcrumbIconWrapper } from './DropdownBreadcrumbIconStyled'

const DropdownBreadcrumbIcon = props => {
  return (
    <DropdownBreadcrumbIconWrapper>
      <DropdownBreadcrumbIconDot />
      <DropdownBreadcrumbIconDot />
      <DropdownBreadcrumbIconDot />
    </DropdownBreadcrumbIconWrapper>
  )
}

DropdownBreadcrumbIcon.propTypes = {}

export default DropdownBreadcrumbIcon