import PropTypes from 'prop-types'
import { CONTEXT_MENU_ACTION, MOBILE_ACTION } from '../../utils/constant'
import React, { useState } from 'react'
import { FolderContainer, Grid, GridItem, GridItemMobileHandler } from './FoldersGridViewStyled'
import SVGIcon from '../SVGIcon/SVGIcon'
import ContextMenuDropdown from '../ContextMenuDropdown'
import { ButtonGroup } from '../../pages/TrashPage/TrashPageStyled'
import { Button, Form, Input, Modal, message,Tooltip } from 'antd'
import validator from '../../validator'
import MoveItemMenuDropdown from '../MoveItemMenuDropdown'
import myDriveStore from '../../stores/myDriveStore'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { InfoCircleFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
// import FileDownload from 'js-file-download'
import uuid from 'uuid'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive/src'
import { useLocation } from 'react-router-dom'

const FoldersGridView = props => {
  const { t } = useTranslation()
  const [formRename] = Form.useForm();
  const location = useLocation()
  const {
    folderList, onDoubleClickFolder, callbackAction,
    selectionStore, commonStore, callbackMobileAction,
    downloadStore,
    filePreviewStore
  } = props
  const { selectionData, selectionIds } = selectionStore
  const { viewByGridMode, pageName } = commonStore

  const isXL = useMediaQuery({ minWidth: 1441 })
  const isMD = useMediaQuery({ maxWidth: 1440, minWidth: 1025 })
  const isSM = useMediaQuery({ maxWidth: 1024 })

  const [currentAction, setCurrentAction] = useState(null)
  const [itemToShowMoveDropDown, setItemToShowMoveDropDown] = useState(null)
  const [targetItemDrive, setTargetItemDrive] = useState(null)
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false)
  const [modalRenameVisible, setModalRenameVisible] = useState(false)
  const [modalMessage, setModalMessage] = useState(t('i0157'))
  const [modalOkText, setModalOkText] = useState(t('i0158'))
  const [modalCancelText, setModalCancelText] = useState(t('i0159'))

  const handleDoubleClickFolder = (folder) => {
    onDoubleClickFolder(folder)
  }
  const handleActionContextMenu = (action, record) => {
    setCurrentAction(action)
    if (selectionIds.length > 1) {
      switch (action) {
        case CONTEXT_MENU_ACTION.trash:
          setModalMessage(t('i0113'))
          setModalOkText(t('i0114'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.move:
          let showMoveList = selectionData.filter(x => x.type === 1)
          setItemToShowMoveDropDown(showMoveList[0])
          break
        case CONTEXT_MENU_ACTION.restore:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          callbackAction(action, 'folder')
          break
        case CONTEXT_MENU_ACTION.deleteShareWithMe:
        case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
          setModalMessage(t('i0120'))
          setModalOkText(t('i0121'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.lock:
          setModalMessage(t('i0165'))
          setModalOkText(t('i0166'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.unlock:
          callbackAction(action, undefined)
          break
        default:
          break
      }
    } else {
      switch (action) {
        case CONTEXT_MENU_ACTION.copyLink:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.share:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.trash:
          setModalMessage(t('i0162'))
          setModalOkText(t('i0163'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.download:
          if (record && record.mimetype === 'folder') {
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            const uid = uuid()
            record.uid = uid;
            record.cancelSource = source
            downloadStore.setFileList([record])
            if(location.pathname.includes('/driver/folders')) {
              filePreviewStore.downloadPublicFolder(record).then(responseLink => {
                if (responseLink.error_code === 0) {
                  if (!responseLink.data.url) {
                    message.error( t('i0692'))
                  } else {    
                      setTimeout(() => {
                        axios.get(responseLink.data.url, {
                          responseType: 'blob',
                          disableSpinner: true,
                        }).then(res => {
                          FileDownload(res.data, `${folder['name']}.zip`)
                       
                        })
                      }, 1500)
                  }
                }
              }).catch(error => {
                console.log(error)
              })
            }else {
              downloadStore.downloadFolder(record).then(responseLink => {
                if (responseLink.error_code === 0) {
                  if (!responseLink.data.url) {
                    downloadStore.updateStatusFile(record.uid, 'error')
                    downloadStore.handleMessageDownload(record.uid, t('i0692'))
                  } else {    
                    if(downloadStore.statusDownLoad[record.uid] !=='error' && downloadStore.statusDownLoad[record.uid] !=='cancelled') {
                      downloadStore.updateStatusFile(record.uid, 'success')
                      downloadStore.handleMessageDownload(record.uid, t('i0685'))
                      record.download_url = responseLink.data.url
                      downloadStore.updateFolderDownload(record)
                    }else {
                      downloadStore.updateStatusFile(record.uid, 'error')
                      downloadStore.handleMessageDownload(record.uid, t('i0692'))
                    }
                  }
                }  else {
                  message.error(responseLink.message);
                }
              }).catch(error => {
                console.log(error)
              })
            }
          } else {
            callbackAction(action, record)
          }
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          callbackAction(action, 'folder')
          break
        case CONTEXT_MENU_ACTION.restore:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.favorite:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.removeFavorite:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.move:
          setItemToShowMoveDropDown(selectionData[0])
          break
        case CONTEXT_MENU_ACTION.rename:
          formRename.setFieldsValue({
            folderName: selectionData[0].name,
          })
          setModalOkText(t('i0164'))
          setModalRenameVisible(true)
          break
        case CONTEXT_MENU_ACTION.viewInfo:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.lock:
          setModalMessage(t('i0165'))
          setModalOkText(t('i0166'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.unlock:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.deleteShareWithMe:
        case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
          setModalMessage(t('i0167'))
          setModalOkText(t('i0168'))
          setModalConfirmVisible(true)
          break
        default:
          break
      }
    }
  }
  const handleConfirmModal = (isConfirm) => {
    setModalConfirmVisible(false)
    if (!isConfirm) {
      setCurrentAction(null)
      setTargetItemDrive(null)
      setItemToShowMoveDropDown(null)
      return
    }
    switch (currentAction) {
      case CONTEXT_MENU_ACTION.copyLink:
        break
      case CONTEXT_MENU_ACTION.trash:
        myDriveStore.setItemRollbackTrash(selectionData[0])
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
      case CONTEXT_MENU_ACTION.deleteShareWithMe:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.download:
        break
      case CONTEXT_MENU_ACTION.favorite:
        break
      case CONTEXT_MENU_ACTION.move:
        callbackAction(currentAction, targetItemDrive)
        break
      case CONTEXT_MENU_ACTION.rename:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.share:
        break
      case CONTEXT_MENU_ACTION.viewInfo:
        break
      case CONTEXT_MENU_ACTION.lock:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.unlock:
        break
      default:
        break
    }
  }
  const handleCancelRename = () => {
    setCurrentAction(null)
    setModalRenameVisible(false)
  }
  const handleConfirmRename = (formData) => {
    myDriveStore.setItemRollbackRename({ ...selectionData[0] })
    selectionData[0].name = formData.folderName
    callbackAction(currentAction)
    setModalRenameVisible(false)
  }
  const handleCloseMoveMenu = () => {
    setItemToShowMoveDropDown(null)
  }
  const handleCallbackMoveItem = (itemDriveToMove, targetItemDrive) => {
    setTargetItemDrive(targetItemDrive)
    setModalMessage(`${t('i0169')} ${targetItemDrive?.name || ''} ${t('i0170')}`)
    setModalOkText(t('i0171'))
    setModalConfirmVisible(true)
  }
  const handleSelection = (event, itemData) => {
    if (pageName === 'synchronization') {
      selectionStore.setSelectionData([itemData])
      return
    }
    // Nếu click ctrl hoặc cmd thì thêm từng phần tử được click vào selectionStore
    if (event.ctrlKey || event.metaKey) {
      if (selectionIds.includes(itemData.id)) {
        const selection = toJS(selectionData)
        const filteredData = selection.filter(item => item.id !== itemData.id)
        selectionStore.setSelectionData([
          ...filteredData,
        ])
        return
      }
      selectionStore.setSelectionData([
        ...selectionData, itemData,
      ])
    } else if (event.shiftKey) {
      // Nếu shift click mà chưa có phần tử nào trong selectionStore thì thêm phần tử vừa lick vào
      if (selectionIds.length === 0) {
        selectionStore.setSelectionData([itemData])
      } else {
        // set store random
        selectionStore.setClickSessionId()
        // Nếu đã có phần tử trong selection thì lấy các item trong khoảng
        let lastItemSelectedInStore = selectionData[0]
        let itemShiftClick = itemData
        if (lastItemSelectedInStore.realIndex > itemShiftClick.realIndex) {
          selectionStore.setFirstSelectionIndex(itemShiftClick.realIndex)
          selectionStore.setLastSelectionIndex(lastItemSelectedInStore.realIndex)
        } else {
          selectionStore.setFirstSelectionIndex(lastItemSelectedInStore.realIndex)
          selectionStore.setLastSelectionIndex(itemShiftClick.realIndex)
        }
      }
    } else {
      selectionStore.setSelectionData([itemData])
    }
  }
  const handleRightClickSelection = (event, itemData) => {
    if (selectionIds.includes(itemData.id)) return
    selectionStore.setSelectionData([itemData])
  }
  const handleViewInfoMobile = (e, item) => {
    e.stopPropagation()
    callbackMobileAction(item, MOBILE_ACTION.viewInfo)
  }
  const handleClickMobile = (item) => {
    callbackMobileAction(item, MOBILE_ACTION.click)
  }
  const isLastItem = index => {
    if (isXL && index % 6 === 0) return true
    if (isMD && index % 4 === 0) return true
    return !!(isSM && index % 2 === 0)
  }

  return (
    <>
      <Grid>
        {
          folderList.map((record, index) => (
            <GridItem
              isGridMode={viewByGridMode}
              className={'selectable-item'}
              key={record.id}>
              <ContextMenuDropdown
                callbackAction={(action) => handleActionContextMenu(action, record)}>
                <Tooltip title={record.name} mouseEnterDelay={0.3} placement={'bottom'}>
                <FolderContainer
                  onContextMenu={event => handleRightClickSelection(event, record)}
                  onClick={event => handleSelection(event, record)}
                  onDoubleClick={() => handleDoubleClickFolder(record)}
                  className={selectionIds.includes(record.id) ? 'selected' : null}>
                  <SVGIcon name={'folder'} width={20} height={18} />
                  <span>{record.name}</span>
                </FolderContainer>
                </Tooltip>
              </ContextMenuDropdown>
              <MoveItemMenuDropdown
                moveMenuVisible={itemToShowMoveDropDown?.id === record.id}
                itemToMove={selectionIds}
                isLastItem={isLastItem(index + 1)}
                callbackMoveItem={handleCallbackMoveItem}
                onClose={handleCloseMoveMenu} />
              <GridItemMobileHandler onClick={() => handleClickMobile(record)}>
                <InfoCircleFilled onClick={e => handleViewInfoMobile(e, record)} />
              </GridItemMobileHandler>
            </GridItem>
          ))
        }
      </Grid>
      <Modal
        onCancel={() => handleConfirmModal(false)}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal context-menu'}
        visible={modalConfirmVisible}>
        <p>
          {modalMessage}
        </p>
        <ButtonGroup>
          <Button type={'default'} onClick={() => handleConfirmModal(false)}>{modalCancelText}</Button>
          <Button type={'primary'} onClick={() => handleConfirmModal(true)}>{modalOkText}</Button>
        </ButtonGroup>
      </Modal>
      <Modal
        forceRender={true}
        onCancel={handleCancelRename}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal context-menu'}
        visible={modalRenameVisible}>
        <Form form={formRename} onFinish={handleConfirmRename}>
          <p>
            {t('i0172')}
          </p>
          <Form.Item
            label={''}
            name={'folderName'}
            rules={[
              // { validator: validator.validateInputStringFolderName },
              { required: true, message: t('i0173') },
              { min: 1, message: t('i0174') },
            ]}>
            <Input placeholder={t('i0175')} />
          </Form.Item>
          <ButtonGroup>
            <Button type={'default'} onClick={handleCancelRename}>{modalCancelText}</Button>
            <Button type={'primary'} htmlType={'submit'}>{modalOkText}</Button>
          </ButtonGroup>
        </Form>
      </Modal>
    </>
  )
}

FoldersGridView.propTypes = {
  folderList: PropTypes.array.isRequired,
  onDoubleClickFolder: PropTypes.func,
  callbackAction: PropTypes.func.isRequired,
  callbackMobileAction: PropTypes.func,
}

export default inject(
  'selectionStore',
  'commonStore',
  'downloadStore',
  'filePreviewStore'
)(observer(FoldersGridView))
