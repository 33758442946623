import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import {
  BreadCrumbItem,
  Button,
  ButtonGroup,
  ComponentWrapper,
  Heading,
  HeadingLink,
} from './ViewToggleComponentStyled'
import SVGIcon from '../SVGIcon'
import { Dropdown, Menu, Tooltip } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import DropdownBreadcrumbIcon from '../DropdownBreadcrumbIcon'
import { useTranslation } from 'react-i18next'

const ViewToggleComponent = props => {
  const { heading, commonStore, onClickBreadcrumb, noBreadcrumb } = props
  const { viewByGridMode } = commonStore

  const [firstBreadcrumb, setFirstBreadcrumb] = useState([])
  const [dropdownBreadcrumb, setDropdownBreadcrumb] = useState([])
  const [lastTwoBreadcrumb, setLastTwoBreadcrumb] = useState([])

  const { t } = useTranslation()

  const toggleViewMode = state => {
    commonStore.toggleViewByGridMode(state)
  }
  const handleClickBreadcrumb = (folder) => {
    onClickBreadcrumb(folder)
  }

  useEffect(() => {
    if (!heading || heading.length === 0) return
    let newFirstBreadcrumb = heading[0]
    let newDropdownBreadcrumb = heading.filter((item, index) => {
      if (index > 0 && index < heading.length - 2) {
        return item
      }
    })
    let newLastTwoBreadcrumb = heading.filter((item, index) => {
      if (index >= heading.length - 2) {
        return item
      }
    })
    setFirstBreadcrumb(newFirstBreadcrumb)
    setDropdownBreadcrumb(newDropdownBreadcrumb)
    setLastTwoBreadcrumb(newLastTwoBreadcrumb)
  }, [heading])

  return (
    <ComponentWrapper noBreadcrumb={noBreadcrumb}>
      {
        !noBreadcrumb && (
          <Heading>
            {
              heading?.length < 5
                ? heading.map((item, index) =>
                  <BreadCrumbItem key={item?.id}>
                    <Tooltip title={item?.name}>
                      <HeadingLink onClick={() => handleClickBreadcrumb(item)}>{item?.name}</HeadingLink>
                    </Tooltip>
                    {(index < heading.length - 1) && <RightOutlined />}
                  </BreadCrumbItem>,
                ) : (
                  <>
                    {
                      firstBreadcrumb &&
                      <BreadCrumbItem key={firstBreadcrumb.id}>
                        <Tooltip title={firstBreadcrumb.name}>
                          <HeadingLink
                            onClick={() => handleClickBreadcrumb(firstBreadcrumb)}>{firstBreadcrumb.name}</HeadingLink>
                        </Tooltip>
                        <RightOutlined />
                      </BreadCrumbItem>
                    }
                    {
                      dropdownBreadcrumb && dropdownBreadcrumb.length > 0 &&

                      <>
                        <Dropdown overlay={
                          <Menu className={'breadcrumb-dropdown-custom'}>
                            {
                              dropdownBreadcrumb.map((item, index) => {
                                  return (
                                    <Menu.Item key={item?.id} onClick={() => handleClickBreadcrumb(item)}>
                                      <div style={{ display: 'flex', alignItems: 'end' }}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/folder.svg`}
                                             style={{ marginRight: '4px' }} />
                                        <Tooltip title={item?.name}>
                                          <HeadingLink>{item?.name}</HeadingLink>
                                        </Tooltip>
                                      </div>
                                    </Menu.Item>
                                  )
                                },
                              )
                            }
                          </Menu>
                        } trigger={['click']}>
                          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                            <DropdownBreadcrumbIcon />
                          </a>
                        </Dropdown>
                        <RightOutlined />
                      </>
                    }
                    {
                      lastTwoBreadcrumb && lastTwoBreadcrumb.length > 0 && lastTwoBreadcrumb.map((item, index) => {
                          return (
                            <BreadCrumbItem key={item?.id}>
                              <Tooltip title={item?.name}>
                                <HeadingLink onClick={() => handleClickBreadcrumb(item)}>{item?.name}</HeadingLink>
                              </Tooltip>
                              {(index < lastTwoBreadcrumb.length - 1) && <RightOutlined />}
                            </BreadCrumbItem>
                          )
                        },
                      )
                    }
                  </>
                )
            }
          </Heading>
        )
      }
      <ButtonGroup>
        {t('i0659')}:
        <Tooltip title={t('i0660')} placement={'bottomRight'}>
          <Button isActive={viewByGridMode} onClick={() => toggleViewMode(true)}>
            <SVGIcon name={'grid-view'} width={16} height={16} />
          </Button>
        </Tooltip>
        <Tooltip title={t('i0661')} placement={'bottomRight'}>
          <Button isActive={!viewByGridMode} onClick={() => toggleViewMode(false)}>
            <SVGIcon name={'list-view'} width={12.75} height={10.63} />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </ComponentWrapper>
  )
}

ViewToggleComponent.propTypes = {
  heading: PropTypes.array,
  onClickBreadcrumb: PropTypes.func,
  noBreadcrumb: PropTypes.bool,
}

export default inject(
  'commonStore',
)(observer(ViewToggleComponent))
