import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()
export const GroupRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  addGroup: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/create`,
      data: {
        name: payload.name,
      },
    })
  },
  updateGroup: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/update`,
      data: {
        name: payload.name,
        group_id: payload.group_id,
      },
    })
  },
  deleteGroup: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/delete`,
      data: {
        group_id: payload.group_id,
      },
    })
  },
  getGroupList: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/list`,
      params: {
        page: payload.page,
        per_page: payload.per_page,
      },
    })
  },
  searchContactToAddIntoGroup: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `api/group/search/user-to-add`,
      params: {
        page: payload.page,
        per_page: payload.per_page,
        q: payload.q,
        group_id: payload.group_id,
      },
      disableSpinner: true,
    })
  },
  deleteContactInGroup: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/remove-member`,
      data: {
        group_id: payload.group_id,
        members: payload.members, // array userid
      },
    })
  },
  addContactIntoGroup: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/add-member`,
      data: {
        group_id: payload.group_id,
        members: payload.members, // array userid
      },
    })
  },
  getGroupDetail: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/detail`,
      params: {
        group_id: payload.group_id,
      },
    })
  },
  getContactInGroup: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/group/list-member`,
      params: {
        group_id: payload.group_id,
        page: payload.page,
        per_page: payload.per_page,
      },
    })
  },

}