import styled from 'styled-components'

export const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.noBreadcrumb ? 0 : 24}px;
`
export const Heading = styled.h1`
  font-size: 20px;
  color: #313131;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  .anticon.anticon-right {
    font-size: 1.5rem;
    margin: 0 8px;
  }
`
export const BreadCrumbItem = styled.div`
  display: flex;
  align-items: center;
`
export const HeadingLink = styled.span`
  cursor: pointer;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`
export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
export const Button = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  border: none;
  background-color: ${props => props.isActive
          ? 'rgba(35,123,211,.1)'
          : 'transparent'};
  cursor: pointer;

  .svgicon {
    filter: ${props => props.isActive
            ? 'invert(60%) sepia(57%) saturate(6224%) hue-rotate(191deg) brightness(86%) contrast(91%)'
            : 'unset'};
  }
`
