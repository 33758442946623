import { action, observable } from 'mobx'
import { SharedWithMeRequest } from '../requests/SharedWithMeRequest'
import { PAGE_SIZE } from '../utils/constant'

class SharedWithMeStore {
  @observable sharedWithMeList = []
  @observable sharedWithMeBreadcrumbData = []
  @observable sharedWithMeCurrentNode = null
  @observable itemRollbackRename = null
  @observable itemRollbackTrash = null
  @observable sharedWithMeSort = 'desc' // desc | asc
  @observable sharedWithMeSortBy = 'created_at' // name | updated_at
  @observable folderFromNotify = null

  @action setFolderFromNotify = (folder) => {
    this.folderFromNotify = folder
  }

  @observable page = 1
  @observable perPage = PAGE_SIZE
  @observable hasNextPage = true

  @action setPage = (page) => {
    this.page = page
  }

  @action setSharedWithMeSort = (sort) => {
    this.sharedWithMeSort = sort
  }
  @action setSharedWithMeSortBy = (sortBy) => {
    this.sharedWithMeSortBy = sortBy
  }

  @action setItemRollbackRename = (itemDrive) => {
    this.itemRollbackRename = itemDrive
  }
  @action setItemRollbackTrash = (itemDrive) => {
    this.itemRollbackTrash = itemDrive
  }
  @action getBreadcrumb = (folder_id) => {
    return new Promise((resolve, reject) => {
      SharedWithMeRequest.getBreadcrumb(folder_id).then(response => {
        this.sharedWithMeCurrentNode = response.data[response?.data?.length - 1] || null
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action setBreadcrumb = (root, data) => {
    this.sharedWithMeBreadcrumbData = [root, ...data]
  }
  @action clearSharedWithMeList = () => {
    this.sharedWithMeList.length = 0
    this.sharedWithMeCurrentNode = null
    this.sharedWithMeBreadcrumbData = []
  }
  @action getSharedWithMeListOnScroll = (payload) => {
    return new Promise((resolve, reject) => {
      SharedWithMeRequest.getSharedWithMeListScroll(payload).then(response => {
        this.sharedWithMeList = [...this.sharedWithMeList, ...response.data?.data]
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getSharedWithMeList = (payload) => {
    return new Promise((resolve, reject) => {
      SharedWithMeRequest.getSharedWithMeList(payload).then(response => {
        this.sharedWithMeList = response.data.data
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action createFolder = (payload) => {
    return new Promise((resolve, reject) => {
      SharedWithMeRequest.createFolder(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action trashDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      SharedWithMeRequest.trashDriveItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action renameDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      SharedWithMeRequest.renameDriveItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action cancelRequest = () => {
    SharedWithMeRequest.cancelRequest()
  }

  @action deleteItemSharedWithMe = (payload) => {
    return new Promise((resolve, reject) => {
      SharedWithMeRequest.deleteItemSharedWithMe(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

}

export default new SharedWithMeStore()
