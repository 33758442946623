import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()

export const ShareRequest = {

  cancelRequest: () => {
    source.cancel()
  },
  getUserShared: (node_id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/nodes/${node_id}/user-shared`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        q: null,
        page: 1,
        per_page: 1000,
      },
    })
  },
  searchUserForShared: keyword => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/search/user-for-share`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        q: keyword,
        page: 1,
        per_page: 100,
      },
      disableSpinner: true,
    })
  },
  getUserForShare: (node_id, keyword) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/search/search-user-and-group`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        q: keyword,
        node_id: node_id,
        // page: 1,
        // per_page: 10,
      },
      disableSpinner: true,
    })
  },
  processShare: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/${payload.node_id}/share`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        node_id: payload.node_id,
        share_permission: payload.share_permission,
        is_secret: payload.is_secret,
        password: payload.password,
        users: payload.users,
        ...(payload.note !== null && { note: payload.note }),
        expired_at: payload.expired_at,
      },
    })
  },
  getLinkSharedInfo: (nodeId) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/${nodeId}/get-link-share`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },
  updatePasswordShare: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/${payload.node_id}/update-password`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        password: payload.password,
      },
    })
  },
  updateExpiredShare: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/${payload.node_id}/update-expired-time`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        expired_at: payload.expired_at,
      },
    })
  },
}