import styled from 'styled-components'

export const GroupManageUsersListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const GroupUserAvatarWrapper = styled.div`
  margin-right: 8px;
  img {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`
export const GroupUserAvatarMore = styled.div`
  border-radius: 50%;
  border: solid 1px #E2E2E2;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #237BD3;
  background: #fff;
`