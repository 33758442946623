import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { DocViewerPageStyled } from './DocViewerPageStyled'
import { useParams } from 'react-router-dom'
import { DocumentViewer } from 'react-documents';

const DocViewerPage = props => {
  const { downloadStore, authenticationStore, filePreviewStore } = props
  const params = useParams()

  const [url, setUrl] = useState("")

  useEffect(() => {
    if (authenticationStore.accessToken) {
      downloadStore.getDownloadLink(params.node_id)
        .then(res => {
          setUrl(res.data.url)
        })
    } else {
      filePreviewStore.getPublicLinkDownloadFile({ node_id: params.node_id })
        .then(res => {
          setUrl(res.data.url)
        })
    }
  }, [params, authenticationStore.accessToken])
  return (
    <DocViewerPageStyled>
      <DocumentViewer
        url={url}
        viewer={'office'}>
      </DocumentViewer>
    </DocViewerPageStyled>
  )
}

DocViewerPage.propTypes = {}

export default inject('downloadStore', 'authenticationStore', 'filePreviewStore')(observer(DocViewerPage))