import React, { useCallback, useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet/es/Helmet'
import { PageHeading, PageHeadingWrapper } from '../../components/CommonStyled/CommonStyled'
import PageContentWrapper from '../../components/PageContentWrapper'
import { Button, Col, Form, Input, message, Row, Space } from 'antd'
import PhoneBookTableView from '../../components/PhoneBookTableView'
import { UpdateGroupWrapper } from '../../components/GroupManageTabPane/GroupManageTabPaneStyled'
import ConfirmModal from '../../components/ConfirmModal'
import InsertMemberIntoGroupModal from '../../components/InserMemberIntoGroupModal'
import { RightOutlined } from '@ant-design/icons'
import PageResult from '../../components/PageResult'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'react-i18next'

const UpdateGroupPage = props => {
  const { match, groupStore } = props
  const { groupId } = match.params
  const [form] = Form.useForm()
  const history = useHistory()
  const { detailGroup, memberList, pageMember, perPageMember, hasNextPageMember } = groupStore
  const [selectedPhoneBookIds, setSelectedPhoneBookIds] = useState([])
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const [okText, setOkText] = useState('')
  const [cancelText, setCancelText] = useState('')
  const [confirmModalTitle, setConfirmModalTitle] = useState('')
  const [action, setAction] = useState(null) //'deleteMember', 'deleteGroup', 'deleteMembers'
  const [deletingMemberId, setDeletingMemberId] = useState(null)
  const [visibleInsertMemberIntoGroupModal, setVisibleInsertMemberIntoGroupModal] = useState(false)
  const [isLoadingScroll, setIsLoadingScroll] = useState(false)
  const [groupNameTitle, setGroupNameTitle] = useState('')

  const { t } = useTranslation()

  const handleClickDeleteGroupMember = (deletingMemberId) => {
    setDeletingMemberId(deletingMemberId)
    setVisibleConfirmModal(true)
    setAction('deleteMember')
    setConfirmModalTitle(t('i0632'))
    setOkText(t('i0633'))
    setCancelText(t('i0634'))
  }
  const handleSelectGroupMember = (rowKeys, rows) => {
    setSelectedPhoneBookIds(rowKeys)
  }
  const handleCancelConfirm = () => {
    setSelectedPhoneBookIds([])
    setDeletingMemberId(null)
    setVisibleConfirmModal(false)
    setAction(null)
  }
  const handleCallbackConfirm = () => {
    switch (action) {
      case 'deleteMember':
        groupStore.deleteContactInGroup({
          group_id: groupId,
          members: [deletingMemberId],
        })
          .then(response => {
            if (response.error_code === 0) {
              initialLoadMember()
              getGroupDetail()
              message.success(t('i0636'))
            } else {
              message.error(response.message)
            }
          })
          .catch(error => error?.response?.status != 401 && message.error(error.message))
        break
      case 'deleteMembers':
        groupStore.deleteContactInGroup({
          group_id: groupId,
          members: selectedPhoneBookIds,
        })
          .then(response => {
            if (response.error_code === 0) {
              initialLoadMember()
              getGroupDetail()
              message.success(t('i0636'))
            } else {
              message.error(response.message)
            }
          })
          .catch(error => error?.response?.status != 401 && message.error(error.message))
        break
      default:
        break
    }
    setSelectedPhoneBookIds([])
    setVisibleConfirmModal(false)
  }
  const handleClickDeleteSelectedMembers = () => {
    if (selectedPhoneBookIds.length === 0) return
    setVisibleConfirmModal(true)
    setAction('deleteMembers')
    setConfirmModalTitle(t('i0637'))
    setOkText(t('i0638'))
    setCancelText(t('i0639'))
  }
  const handleClickInsertMemberIntoGroup = () => {
    setVisibleInsertMemberIntoGroupModal(true)
  }
  const handleSubmitInsertMemberIntoGroup = (arrayMemberInsertIntoGroup) => {
    if (arrayMemberInsertIntoGroup?.length > 0) {
      groupStore.addContactIntoGroup({
        group_id: groupId,
        members: arrayMemberInsertIntoGroup,
      })
        .then(response => {
          if (response.error_code === 0) {
            initialLoadMember()
            getGroupDetail()
            message.success(t('i0640'))
          } else {
            message.error(response.message)
          }
        })
        .catch(error => error?.response?.status != 401 && message.error(error.message))
        .finally(() => setVisibleInsertMemberIntoGroupModal(false))
    }

  }
  const onSaveGroup = (collectData) => {
    if (!collectData.groupName || collectData.groupName?.length === 0) {
      message.error(t('i0720'))
      return
    }
    groupStore.updateGroup({
      name: collectData.groupName,
      group_id: groupId,
    })
      .then(response => {
        if (response.error_code === 0) {
          setGroupNameTitle(response.data?.name)
          message.success(t('i0641'))
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }

  const getGroupDetail = () => {
    if (!groupId) return
    groupStore.getGroupDetail({ group_id: groupId })
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        } else {
          setGroupNameTitle(response.data?.name)
          form.setFieldsValue({
            groupName: response.data?.name,
          })
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }

  const handleLoadMore = useCallback(() => {
    if (isLoadingScroll) return
    if (hasNextPageMember) {
      setIsLoadingScroll(true)
      let nextPage = pageMember + 1
      groupStore.setPageMember(nextPage)
      let payload = {
        page: nextPage,
        per_page: perPageMember,
        group_id: groupId,
      }
      groupStore.getContactInGroupOnScroll(payload)
        .then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        .finally(() => setIsLoadingScroll(false))
    }
  }, [hasNextPageMember, isLoadingScroll])

  const initialLoadMember = () => {
    groupStore.setPageMember(1)
    let payload = {
      page: 1,
      per_page: groupStore.perPageMember,
      group_id: groupId,
    }
    groupStore.getContactInGroup(payload)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }

  useEffect(() => {
    if (!groupId) return
    getGroupDetail()
    initialLoadMember()
  }, [groupId])

  useEffect(() => {
    return () => {
      groupStore.clearGroupListMember()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('i0642')}</title>
      </Helmet>
      <PageHeadingWrapper>
        <PageHeading>
          <span onClick={() => history.push('/config-page/3')}>{t('i0643')}</span>
          <RightOutlined style={{ margin: '0 4px' }} />
          <span>{t('i0644')}</span>
        </PageHeading>
      </PageHeadingWrapper>
      <PageContentWrapper>
        <UpdateGroupWrapper>
          <h5>{groupNameTitle}</h5>
          <Form colon={false} size={'large'} layout={'vertical'}
            form={form} onFinish={onSaveGroup}>
            <Row gutter={[16, 0]} type={'flex'} align={'bottom'}>
              <Col span={20}>
                <Form.Item label={t('i0645')} name={'groupName'}>
                  <Input placeholder={t('i0646')} className={'group-name-input'} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button type={'primary'} style={{ marginBottom: 5 }}
                  htmlType={'submit'}
                  className={'submit-config-btn'}>
                  {t('i0647')}
                </Button>
              </Col>
            </Row>
          </Form>
          <hr />
          {
            <>
              <h5>
                {t('i0648')} ({detailGroup?.total_member || '0'})
              </h5>
              <Space size={'middle'} style={{ marginBottom: 15 }}>
                <Button type={'primary'} className={'submit-config-btn'} onClick={handleClickInsertMemberIntoGroup}>
                  {t('i0649')}
                </Button>
                {
                  selectedPhoneBookIds.length !== 0 && (
                    <Button
                      onClick={handleClickDeleteSelectedMembers}
                      type={'default'}
                      className={'cancel-config-btn'}
                      size={'large'}>
                      {t('i0650')}
                    </Button>
                  )
                }
              </Space>
              <InfiniteScroll
                initialLoad={false}
                pageStart={pageMember} threshold={1}
                loadMore={handleLoadMore}
                hasMore={hasNextPageMember}
                useWindow={true}
              >
                {
                  memberList && memberList?.length > 0
                    ?
                    <div style={{ maxWidth: 820 }}>
                      <PhoneBookTableView
                        dataSource={memberList}
                        callbackDelete={handleClickDeleteGroupMember}
                        selectedPhoneBookIds={selectedPhoneBookIds}
                        callbackSelected={handleSelectGroupMember} />
                    </div>
                    :
                    <PageResult type={'group_members_empty'} />
                }
              </InfiniteScroll>
            </>

          }

        </UpdateGroupWrapper>
      </PageContentWrapper>
      <ConfirmModal
        title={confirmModalTitle}
        visible={visibleConfirmModal}
        okText={okText}
        cancelText={cancelText}
        onClose={handleCancelConfirm}
        callback={handleCallbackConfirm} />
      <InsertMemberIntoGroupModal
        visible={visibleInsertMemberIntoGroupModal}
        groupId={groupId}
        onClose={() => setVisibleInsertMemberIntoGroupModal(false)}
        onSubmitInsert={handleSubmitInsertMemberIntoGroup} />
    </>
  )
}

UpdateGroupPage.propTypes = {}

export default inject('groupStore')(observer(UpdateGroupPage))