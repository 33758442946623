import styled from 'styled-components'

export const PageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.65;
  }
`
export const FileTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;

  .ant-btn-primary {
    background-color: #237BD3;
    color: white;
    padding: 13px 44px;
    font-size: 14px;
    line-height: 22px;
    height: unset;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    border: none;
  }

  .svgicon {
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: 34px;
    cursor: pointer;
  }
`
export const FileName = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  p {
    color: white;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
  }

  .svgicon {
    margin-right: 14px;
  }
`
export const FilePreviewWrapper = styled.div`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  padding: 17px;
  background-color: #595959;
  border-radius: 8px;
  min-width: 522px;

  p {
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }

  .ant-btn-primary {
    background-color: #237BD3;
    color: white;
    padding: 8px 45px;
    font-size: 14px;
    line-height: 22px;
    height: unset;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    border: none;
  }
`

export const FilePreviewName = styled.p`
  width: 480px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`