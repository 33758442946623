import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import { CONTENT_TYPE } from '../utils/constant'

const source = axios.CancelToken.source()

export const RecentlyOpenRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getRecentlyOpenList: payload => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/folders/${payload.parent_id ? payload.parent_id : ''}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        ...(payload.parent_id && { parent_id: payload.parent_id }),
        content_type: CONTENT_TYPE.recentlyOpen,
        page: payload.page,
        per_page: payload.per_page,
        ...(payload.sort && { sort: payload.sort }),
        ...(payload.sort_by && { sort_by: payload.sort_by }),
      },
    })
  },
  getRecentlyOpenListScroll: payload => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/folders/${payload.parent_id ? payload.parent_id : ''}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        ...(payload.parent_id && { parent_id: payload.parent_id }),
        content_type: CONTENT_TYPE.recentlyOpen,
        page: payload.page,
        per_page: payload.per_page,
        ...(payload.sort && { sort: payload.sort }),
        ...(payload.sort_by && { sort_by: payload.sort_by }),
      },
      disableSpinner: true,
    })
  },
  createFolder: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/folders/create`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
    })
  },
  trashDriveItem: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/trash`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        list_id: payload,
      },
    })
  },
  renameDriveItem: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/${payload.node_id}/rename`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,

    })
  },

  deleteItemRecentlyOpen: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      url: '/api/user/delete-my-seen',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
    })
  },

}