import styled from 'styled-components'

export const MenuNotificationWrapper = styled.div`
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  min-width: 400px;
  max-height: 375px;
  overflow:auto;
  /* total width */
  ::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
  }
  
  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
  background-color: #fff;
  }
  
  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
  }
  
  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
  display: none;
  }
`
export const MenuNotificationItem = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid #237bd330;

  &:last-child {
    border-bottom: none;
  }
`
export const MenuNotificationAvatar = styled.div`
  padding: 12px 12px 0 12px;
`
export const MenuNotificationInfo = styled.div`
  padding: 12px 12px 12px 0;
`
export const OwnerInfo = styled.div`
  font-weight: ${props => props.fontWeight};
  display: flex;
  align-items: center;
  color: #313131;
  font-size: 1.4rem;

  strong {
    margin-right: 4px;
    cursor: pointer;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`
export const FileActionInfo = styled.div`
  display: flex;
  align-items: center;
  color: #313131;
  font-size: 1.4rem;
  margin-top: 4px;

  img {
    margin-right: 8px;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
  }
`
export const NotificationTime = styled.div`
  color: #8C8C8C;
  font-size: 1.2rem;
  margin-top: 4px;
`
export const NotReadIcon = styled.div`
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 8px;
`