import { action, observable } from 'mobx'
import { ContactRequest } from '../requests/ContactRequest'

class ContactStore {

  @observable contactList = []
  @observable detailContact = null

  @observable page = 1
  @observable perPage = 10
  @observable hasNextPage = true

  @action CancelRequestToken = () => {
    ContactRequest.cancelRequest()
  }

  @action setPage = (page) => {
    this.page = page
  }

  @action clearContactList = () => {
    this.page = 1
    this.perPage = 10
    this.hasNextPage = true
    this.contactList.length = 0
  }

  @action getContactListOnScroll = (payload) => {
    return new Promise((resolve, reject) => {
      ContactRequest.getContactList(payload).then(response => {
        this.contactList = [...this.contactList, ...response.data.data]
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action getContactList = (payload) => {
    return new Promise((resolve, reject) => {
      ContactRequest.getContactList(payload).then(response => {
        this.contactList = response.data.data
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action deleteContact = contactIdsArr => {
    return new Promise((resolve, reject) => {
      ContactRequest.deleteContact(contactIdsArr)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action addContact = (payload) => {
    return new Promise((resolve, reject) => {
      ContactRequest.addContact(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getDetailContact = (payload) => {
    return new Promise((resolve, reject) => {
      ContactRequest.getDetailContact(payload).then(response => {
        this.detailContact = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

}

export default new ContactStore()
