import styled from 'styled-components'
import { Modal, Button } from 'antd'


export const ModalWrapper = styled.div`
  //position: relative;
  background-color: #237BD3;
  border-radius: 14px;
  // bottom: 50px;
    //right: ${props => props.isMenubarHover ? '35px' : '18px'};
  min-width: 450px;
  padding: 15px;
  display: ${props => props.visible ? 'block' : 'none'}
`
export const ModalHeader = styled.div`
  display: flex;

`
export const ModalTotalText = styled.div`
  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 4px
`
export const ModalTimeText = styled.div`
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: rgba(255, 255, 255, 0.65);
`

export const ModalContent = styled.div`
  padding: 20px 0 5px 0;
  max-height: 350px;
  overflow: auto;
`

export const CloseButton = styled(Button)`
  background: rgba(255, 255, 255, 0.25);
  //position: absolute;
  //top: 10px;
  //right: 10px;
  margin-left: auto;

  &:hover svg {
    color: #000000 !important;
  }
`

export const ProgressItem = styled.div`
  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0 0 15px 0;

  span {
    vertical-align: bottom;
  }

`
export const ProgressItemText = styled.span`
  margin-left: 8px;
  display: inline-block;
  width: 220px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

export const ProgressIconRight = styled.span`
  float: right;
  margin-right: 8px;
  cursor: pointer;

`
export const BaseIconGroup = styled.span`
  float: right;
  margin-right: 8px;
  cursor: pointer;
`

export const IconSuccessGroup = styled(BaseIconGroup)`
  .btn-success-status {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }
`

export const IconCancelGroup = styled(BaseIconGroup)`
  display: flex;

  .btn-reload-upload {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-status-canceled {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-reload-upload {
    display: none
  }

  &:hover {
    .btn-reload-upload {
      display: block
    }

    .btn-status-canceled {
      display: none
    }
  }
`

export const IconUploadProgressGroup = styled(BaseIconGroup)`
  display: flex;

  .btn-cancel {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-cancel.ant-btn-icon-only {
    padding: 4px 0;
  !important;
  }

  .btn-progress {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-cancel {
    display: none
  }

  &:hover {
    .btn-progress {
      display: none
    }

    .btn-cancel {
      display: block
    }
  }
`