import { action, observable } from 'mobx'
import { DownloadRequest } from '../requests/DownloadRequest'
import profileStore from './profileStore'

class DownloadStore {
  @observable statusDownLoad = {}
  @observable downloadList = []
  @observable messageDownload = []
  @observable progressDownload = {}
  @observable visibleModalProgress = false

  @action getDownloadLink = (payload) => {
    return new Promise((resolve, reject) => {
      DownloadRequest.getDownloadLink(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getDownloadLinkNoSpin = (payload) => {
    return new Promise((resolve, reject) => {
      DownloadRequest.getDownloadLinkNoSpin(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action downloadFiles = (url) => {
    return new Promise((resolve, reject) => {
      DownloadRequest.downloadFiles(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action downloadFolder = (folder) => {
    this.setProgressUpload(folder.uid, 0)
    this.updateStatusFile(folder.uid, 'downloading')
    this.handleMessageDownload(folder.uid, profileStore?.appLanguage === 'en' ? 'Compressing 1 file' : 'Đang nén 1 tệp')
    this.setVisibleModalProgress(true)
    return new Promise((resolve, reject) => {
      DownloadRequest.downloadFolder(folder.id, folder.cancelSource, (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setProgressUpload(folder.uid, percentCompleted)
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }


  @action updateFolderDownload = (folder) => {
    let cloneDownloadList = [...this.downloadList]
    let findIndex = cloneDownloadList.findIndex(item => item.uid === folder.uid)
    if (findIndex > -1) {
      cloneDownloadList[findIndex] = { ...cloneDownloadList[findIndex], ...folder }
    }
    this.downloadList = cloneDownloadList
  }

  @action setFileList = (fileList) => {
    this.downloadList = [...this.downloadList, ...fileList]
  }

  /**
   *
   * @param id
   * @param progress
   */
  @action setProgressUpload = (uid, progress) => {
    let cloneProgressDownload = { ...this.progressDownload }
    cloneProgressDownload[uid] = progress
    this.progressDownload = cloneProgressDownload
  }
  /**
   *
   * @param id
   * @param status
   */
  @action updateStatusFile = (uid, status) => {
    let cloneStatusDownLoad = { ...this.statusDownLoad }
    cloneStatusDownLoad[uid] = status
    this.statusDownLoad = cloneStatusDownLoad
  }

  @action handleMessageDownload = (uid, message) => {
    let cloneMessageDownload = { ...this.messageDownload }
    cloneMessageDownload[uid] = message
    this.messageDownload = cloneMessageDownload
  }

  @action reSetFileList = () => {
    this.downloadList = []
  }

  @action setVisibleModalProgress = (value) => {
    this.visibleModalProgress = value
  }

  @action resetAllStore = () => {
    this.statusDownLoad = {}
    this.downloadList = []
    this.messageDownload = []
    this.progressDownload = {}
    this.visibleModalProgress = false
  }
}

export default new DownloadStore()