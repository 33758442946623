import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet/es/Helmet'
import { PageHeading, PageHeadingWrapper } from '../../components/CommonStyled/CommonStyled'
import PageContentWrapper from '../../components/PageContentWrapper'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'

const HistoryLogin = props => {

  const { t } = useTranslation()

  const dataSource = [
    {
      id: '1',
      time: '10:10 24/06/2021',
      device: 'Đăng nhập trên IPhone 11',
      location: 'Cầu Giấy, Hà Nội',
    },
    {
      id: '2',
      time: '10:10 24/06/2021',
      device: 'Đăng nhập trên IPhone 11',
      location: 'Cầu Giấy, Hà Nội',
    },
    {
      id: '3',
      time: '10:10 24/06/2021',
      device: 'Đăng nhập trên IPhone 11',
      location: 'Cầu Giấy, Hà Nội',
    },
    {
      id: '4',
      time: '10:10 24/06/2021',
      device: 'Đăng nhập trên IPhone 11',
      location: 'Cầu Giấy, Hà Nội',
    },
    {
      id: '5',
      time: '10:10 24/06/2021',
      device: 'Đăng nhập trên IPhone 11',
      location: 'Cầu Giấy, Hà Nội',
    },
    {
      id: '6',
      time: '10:10 24/06/2021',
      device: 'Đăng nhập trên IPhone 11',
      location: 'Cầu Giấy, Hà Nội',
    },
  ]
  const tableColumns = [
    {
      title: t('i0511'),
      render: record => <span>{record.time}</span>,
    },
    {
      title: t('i0512'),
      render: record => <span>{record.device}</span>,
    },
    {
      title: t('i0513'),
      render: record => <span>{record.location}</span>,
    },
  ]
  return (
    <>
      <Helmet>
        <title>{t('i0514')}</title>
      </Helmet>
      <PageHeadingWrapper>
        <PageHeading>{t('i0514')}</PageHeading>
      </PageHeadingWrapper>

      <PageContentWrapper>
        <Table dataSource={dataSource} columns={tableColumns} rowKey={record => record.id} pagination={false} />
      </PageContentWrapper>
    </>
  )
}

HistoryLogin.propTypes = {}

export default HistoryLogin