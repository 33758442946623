import styled from 'styled-components'

export const GroupManageTabPaneWrapper = styled.div`

`
export const GroupManageTableWrapper = styled.div`
  max-width: 1040px;

  .ant-table {
    .ant-table-thead > tr > th {
      background-color: #F0F2F5;
      padding: 10px 12px;
      font-weight: bold;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        &:nth-child(even) {
          .ant-table-cell {
            background-color: #FAFAFB;
          }
        }

        .ant-table-cell {
          border-bottom: none;
        }
      }
    }
  }
`
export const UpdateGroupWrapper = styled.div`

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 17px;

    &:first-of-type {
      margin-bottom: 24px;
    }
  }

  .ant-form {
    max-width: 600px;
  }

  hr {
    position: relative;
    left: -18px;
    width: calc(100% + 36px);
    height: 16px;
    border: none;
    background-color: #f0f2f5;
    margin: 32px 0;
  }

  .group-name-input {
    border-radius: 8px;
  }

  .ant-row {
    margin-bottom: 0;
  }

  .ant-input {
    padding: 10.5px 11px;
  }

  .ant-form-item-label {
    padding-bottom: 8px;

    label {
      height: auto;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .ant-table {
    .ant-table-thead > tr > th {
      background-color: #F0F2F5;
      padding: 10px 12px;
      font-weight: bold;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        &:nth-child(even) {
          .ant-table-cell {
            background-color: #FAFAFB;
          }
        }

        .ant-table-cell {
          border-bottom: none;
        }
      }
    }
  }

  .ant-btn-primary {
    height: 40px !important;
  }
`