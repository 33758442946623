import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const ModalWrapper = styled(Modal)`

  .ant-modal-content{
    border-radius: 8px;
  }
  .ant-modal-header {
    border-bottom: none !important;
    border-radius: 8px;
    .ant-modal-title {
      // text-align:center;
      font-size: 1.6rem;
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-family: Exo2;
    }
  }
`

export const ButtonChooseUpload = styled(Button)`
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 1.6rem;
  color: #000000;
  text-align:left;
  width:100%;
  margin-bottom:10px;
  text-align:center;
  height: 52px;
  font-style: normal;
  font-weight: normal;
  font-family: Exo2;
`
export const UploadWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 1.6rem;
  color: #000000;
  width:100%;
  margin-bottom:10px;
  text-align:center;
  height: 52px;
  font-style: normal;
  font-weight: normal;
  font-family: Exo2;
  cursor: pointer;
  &:hover{
    border-color: #40a9ff;
  }
  .ant-upload {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 16px;

`
export const ActionMenuIcon = styled.div`
  margin-right: 8px;
`
export const ActionMenuText = styled.span`
font-size: 1.6rem;
  color: #313131;
`