import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import httpUtils from '../utils/httpUtils'

const { paramsSerializer } = httpUtils
const source = axios.CancelToken.source()

export const SearchRequest = {

  cancelRequest: () => {
    source.cancel()
  },
  quickSearch: keyword => {
    const params = {
      ...(keyword && { q: keyword }),
      page: 1,
      per_page: 20,
    }
    return axios({
      method: 'get',
      url: `${apiUrl}/api/search/search-node?${paramsSerializer(params)}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      disableSpinner: true,
    })
  },
  getSearchItemList: (
    keyword,
    mimetype_group_id,
    storage_type,
    from_date,
    to_date,
    from_update_date,
    to_update_date,
    share_with,
    owner,
    page,
    per_page,
    sort,
  ) => {
    const params = {
      ...(keyword && { q: keyword }),
      ...(mimetype_group_id && { mimetype_group_id }),
      ...(storage_type && { storage_type }),
      ...(from_date && { from_date }),
      ...(to_date && { to_date }),
      ...(from_update_date && { from_update_date }),
      ...(to_update_date && { to_update_date }),
      ...(share_with && { share_with }),
      ...(owner && { owner }),
      ...(page && { page }),
      ...(per_page && { per_page }),
      ...(sort && { sort }),
    }
    return axios({
      method: 'get',
      url: `${apiUrl}/api/search/search-node?${paramsSerializer(params)}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },
  getSearchItemListOnScroll: (
    keyword,
    mimetype_group_id,
    storage_type,
    from_date,
    to_date,
    from_update_date,
    to_update_date,
    share_with,
    owner,
    page,
    per_page,
    sort,
  ) => {
    const params = {
      ...(keyword && { q: keyword }),
      ...(mimetype_group_id && { mimetype_group_id }),
      ...(storage_type && { storage_type }),
      ...(from_date && { from_date }),
      ...(to_date && { to_date }),
      ...(from_update_date && { from_update_date }),
      ...(to_update_date && { to_update_date }),
      ...(share_with && { share_with }),
      ...(owner && { owner }),
      ...(page && { page: page }),
      ...(per_page && { per_page }),
      ...(sort && { sort }),
    }
    return axios({
      method: 'get',
      url: `${apiUrl}/api/search/search-node?${paramsSerializer(params)}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      disableSpinner: true,
    })
  },
}