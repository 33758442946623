import { action, observable } from 'mobx'
import { GroupRequest } from '../requests/GroupRequest'

class GroupStore {

  @observable groupList = []
  @observable page = 1
  @observable perPage = 20
  @observable hasNextPage = true

  @action setPage = (page) => {
    this.page = page
  }

  @action clearGroupList = () => {
    this.page = 1
    this.perPage = 20
    this.hasNextPage = true
    this.groupList.length = 0
  }

  @observable detailGroup = null
  @observable memberList = []
  @observable pageMember = 1
  @observable perPageMember = 20
  @observable hasNextPageMember = true

  @action setPageMember = (page) => {
    this.pageMember = page
  }

  @action clearGroupListMember = () => {
    this.pageMember = 1
    this.perPageMember = 20
    this.hasNextPageMember = true
    this.memberList.length = 0
    this.detailGroup = null
  }

  @observable memberListInput = []
  @observable pageMemberInput = 1
  @observable perPageMemberInput = 5
  @observable hasNextPageMemberInput = true

  @action setPageMemberInput = (page) => {
    this.pageMemberInput = page
  }

  @action clearGroupListMemberInput = () => {
    this.pageMemberInput = 1
    this.perPageMemberInput = 10
    this.hasNextPageMemberInput = true
    this.memberListInput.length = 0
  }

  @action CancelRequestToken = () => {
    GroupRequest.cancelRequest()
  }

  @action addGroup = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.addGroup(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action updateGroup = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.updateGroup(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action deleteGroup = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.deleteGroup(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getGroupListOnScroll = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.getContactList(payload).then(response => {
        this.groupList = [...this.contactList, ...response.data.data]
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getGroupList = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.getGroupList(payload).then(response => {
        this.groupList = response.data.data
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action searchContactToAddIntoGroup = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.searchContactToAddIntoGroup(payload).then(response => {
        this.memberListInput = response.data.data
        this.hasNextPageMemberInput = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action deleteContactInGroup = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.deleteContactInGroup(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action addContactIntoGroup = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.addContactIntoGroup(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getGroupDetail = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.getGroupDetail(payload).then(response => {
        this.detailGroup = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getContactInGroupOnScroll = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.getContactInGroup(payload).then(response => {
        this.memberList = [...this.memberList, ...response.data.data]
        this.hasNextPageMember = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getContactInGroup = (payload) => {
    return new Promise((resolve, reject) => {
      GroupRequest.getContactInGroup(payload).then(response => {
        this.memberList = response.data.data
        this.hasNextPageMember = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

}

export default new GroupStore()
