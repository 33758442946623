import React from 'react'
import { inject, observer } from 'mobx-react'
import PublicLayout from '../../layouts/PublicLayout'
import { Switch, Route } from 'react-router-dom'
import CommonQuestionListPage from '../../pages/CommonQuestionListPage/CommonQuestionListPage'
import CommonQuestionDetailPage from '../../pages/CommonQuestionDetailPage/CommonQuestionDetailPage'
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage'
import TermOfUsePage from '../../pages/TermOfUsePage'
import IntroductionPage from '../../pages/IntroductionPage'
import FilePreviewPage from '../../pages/FilePreviewPage'
import FolderPreviewPage from '../../pages/FolderPreviewPage'
import NotPermissionViewFileShare from '../../pages/NoPermissionViewFileShare'
import PreviewOverlay from '../../components/PreviewOverlay/PreviewOverlay'

const PublicModule = (props) => {
  const { previewOverlayStore } = props
  return (
    <PublicLayout>
      <Switch>
        <Route exact path={'/introduction'} component={IntroductionPage} />
        <Route exact path={'/common-question'} component={CommonQuestionListPage} />
        <Route exact path={'/common-question/:questionId'} component={CommonQuestionDetailPage} />
        <Route exact path={'/privacy-policy'} component={PrivacyPolicyPage} />
        <Route exact path={'/term-of-use'} component={TermOfUsePage} />
        <Route exact path={'/driver/file/:id'} component={FilePreviewPage} />
        <Route exact path={'/driver/folders/:id'} component={FolderPreviewPage} />
        <Route exact path={'/not-permission'} component={NotPermissionViewFileShare} />
      </Switch>
      <div>
        {previewOverlayStore.visible && <PreviewOverlay />}
      </div>
    </PublicLayout>
  )
}

PublicModule.propTypes = {}

export default inject('previewOverlayStore')(observer(PublicModule))