import styled from 'styled-components'
import { Table } from 'antd'
export const PhoneBookTabPaneWrapper = styled.div`

`

export const PhoneBookItem = styled.div`
  display: flex;
  align-items: center;
`
export const PhoneBookAvatar = styled.div`
  img {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`
export const PhoneBookInfo = styled.div`
  margin-left: 8px;
`
export const PhoneBookName = styled.div`
  color: #313131;
  font-size: 1.4rem;
`
export const PhoneBookPhone = styled.div`
  color: #8C8C8C;
  font-size: 1.2rem;
`