import styled from 'styled-components'
import { Image, Upload } from 'antd'

const { Dragger } = Upload

export const DraggerUpload = styled(Dragger)`
  background: #fff !important;
  border: none !important
`
export const ImageDefaultUpload = styled('img')`
  width: 196.36px;
  min-height: 187.39px;

`

export const UploadText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;

`

export const UploadWrapper = styled.div`
  .upload-body {
    outline: none !important;
    text-align: center;
    min-height: 550px;

    &:focus-visible {
      outline: none;
    }

    .upload-text {
      font-size: 14px;
      color: #313131;
      line-height: 12px;
      font-family: Exo2, sans-serif;
      font-style: normal;
      font-weight: normal;
    }

    .ant-upload-drag-icon {
      min-height: 550px;
    }
  }

`
