import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ResultWrapper } from './PageResultStyled'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import profileStore from '../../stores/profileStore'
import baseReduce from 'lodash-es/_baseReduce'

const PageResult = props => {

  const { type, appConfigStore, profileStore } = props
  const [imgName, setImgName] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    switch (type) {
      case 'empty-file-list':
        setImgName('empty-file-list.png')
        break
      case 'empty-shared-file-list':
        setImgName('empty-shared-file-list.png')
        break
      case 'empty-recently-open':
        setImgName('empty-recently-open.png')
        break
      case 'empty-favorite':
        setImgName('empty-favorite.png')
        break
      case 'empty-trash':
        setImgName('empty-trash.png')
        break
      case 'synchronization-google-drive':
        setImgName('google-drive-group.png')
        break
      case 'synchronization-onedrive':
        setImgName('onedrive-group.png')
        break
      case 'empty-offline-files':
        setImgName('empty-offline-files.png')
        break
      case 'empty-search-list':
        setImgName('empty-search-list.png')
        break
      case 'search_empty_help-page':
        setImgName('empty-search-list.png')
        break
      case 'contact_list_empty':
        setImgName('empty_contact.png')
        break
      case 'group_list_empty':
        setImgName('empty-group.png')
        break
      case 'group_members_empty':
        setImgName('empty_contact.png')
        break
      default:
        break
    }
  }, [type])

  const renderMessage = (type) => {
    // Tách khỏi useEffect vì không render được theo i18n
    switch (type) {
      case 'empty-file-list':
        return (t('i0311'))
      case 'empty-shared-file-list':
        return (t('i0312'))
      case 'empty-recently-open':
        return (t('i0313'))
      case 'empty-favorite':
        return (t('i0314'))
      case 'empty-trash':
        return (`${t('i0315')} ${appConfigStore?.systemConfig?.time_in_trash} ${t('i0316')}`)
      case 'synchronization-google-drive':
        return (t('i0317'))
      case 'synchronization-onedrive':
        return (t('i0318'))
      case 'empty-offline-files':
        return (t('i0319'))
      case 'empty-search-list':
        return (t('i0320'))
      case 'search_empty_help-page':
        return (t('i0321'))
      case 'contact_list_empty':
        return (t('i0322'))
      case 'group_list_empty':
        return (t('i0323'))
      case 'group_members_empty':
        return (t('i0324'))
      default:
        break
    }
  }

  return (
    <ResultWrapper>
      <img
        src={`${process.env.PUBLIC_URL}/assets/imgs/${imgName}`}
        alt='' width={'50%'}
      />
      <p>{renderMessage(type)}</p>
    </ResultWrapper>
  )
}

PageResult.propTypes = {
  type: PropTypes.oneOf([
    'empty-file-list',
    'empty-shared-file-list',
    'empty-recently-open',
    'empty-favorite',
    'empty-trash',
    'synchronization-google-drive',
    'synchronization-onedrive',
    'empty-offline-files',
    'empty-search-list',
    'contact_list_empty',
    'group_list_empty',
    'group_members_empty',
  ]).isRequired,
}

export default inject('appConfigStore', 'profileStore')(observer(PageResult))
