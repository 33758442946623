import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()

export const DownloadRequest = {

  cancelRequest: () => {
    source.cancel()
  },

  getDownloadLink: (node_id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/nodes/${node_id}/get-download-link`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },
  getDownloadLinkNoSpin: (node_id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/nodes/${node_id}/get-download-link`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      disableSpinner: true,
    })

  },

  downloadFiles: (url) => {
    return axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  downloadFolder: (node_id, cancelSource, onDownloadProgress) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/nodes/${node_id}/get-download-link`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      onDownloadProgress: onDownloadProgress,
      cancelToken: cancelSource.token,
      disableSpinner: true,
    })
  },

}