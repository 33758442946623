import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()
export const TrashRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getFilesInTrash: (page, perPage, sort, sort_by) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/nodes/trash?page=${page}&per_page=${perPage}&sort=${sort}&sort_by=${sort_by}`,
    })
  },

  deleteAllFilesInTrash: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/nodes/empty-trash`,
      data: payload,
    })
  },

  deleteFileInTrash: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      baseURL: apiUrl,
      url: `/api/nodes/delete-in-trash`,
      data: { list_id: payload.list_id },
    })
  },

  restoreFile: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/nodes/recovery`,
      data: { list_id: payload.list_id },
    })
  },

}