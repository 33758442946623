import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { FormWrapper, LogoWrapper, RegisterLabel } from '../../../layouts/AuthLayout/AuthLayoutStyled'
import { useTranslation } from 'react-i18next'
import validator from '../../../validator'

const LoginForm = props => {

  const { authenticationStore, profileStore } = props

  const [errorText, setErrorText] = useState(null)
  const [loginForm] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const onLogin = info => {
    const { username, password } = info
    authenticationStore.userLogin(username, password)
      .then(response => {
        switch (response.error_code) {
          case 0:
            profileStore.getMyProfile()
              .then(r => {
                if (r.error_code !== 0) {
                  message.error(r.message)
                } else {
                  // message.success(`${t('i0427')}, ${r?.data?.name}`)
                  history.push(location.state?.from?.pathname || '/')
                }
              })
              .catch(error => error?.response?.status != 401 && message.error(error.message))
            break
          case 5:
            authenticationStore.setUserIdVerifying(response.data.user_id)
            history.push('/auth/otp')
            break
          default:
            setErrorText(response.message)
            ///message.error(response.message)
            break
        }
      })
      .catch(error => {
        error?.response?.status != 401 && message.error(error.message)
      })
  }
  const handleClickRegister = () => {
    history.push('/auth/register')
  }
  const handleClickForgotPassword = () => {
    history.push('/auth/forgot-password')
  }

  useEffect(() => {
    authenticationStore.setRegisterInfoCache(null)
  }, [])

  return (
    <FormWrapper>
      <LogoWrapper>
        <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_register.png`} alt={'logo'} />
      </LogoWrapper>

      <Form
        form={loginForm}
        className={'login-logo'}
        layout={'vertical'}
        onFinish={onLogin}
        scrollToFirstError
        requiredMark={false}
        colon={false}
        onValuesChange={() => setErrorText(null)}
      >
        <Form.Item>
          <span className='ant-form-text' style={{ color: '#F5222D' }}>{errorText}</span>
        </Form.Item>
        <Form.Item
          name={'username'}
          label={t('i0428')}
          rules={[
            { required: true, message: t('i0429') },
            { validator: validator.validatePhoneNumber },
          ]}
        >
          <Input placeholder={t('i0430')} className={'login-input'} />
        </Form.Item>
        <Form.Item
          name={'password'}
          className={'login-password-wrapper'}
          label={<div className={'login-password-label'}><span>{t('i0431')}</span> <a
            onClick={handleClickForgotPassword}>{t('i0432')}</a></div>}
          rules={[
            { required: true, message: t('i0433') },
          ]}
        >
          <Input.Password placeholder={t('i0434')} className={'login-input'} />
        </Form.Item>
        <Button type={'primary'} htmlType={'submit'} block className={'login-btn'}>
          {t('i0435')}
        </Button>
        <RegisterLabel>{t('i0436')}? <a onClick={handleClickRegister}> {t('i0437')} </a></RegisterLabel>

      </Form>
    </FormWrapper>

  )
}

LoginForm.propTypes = {
  children: PropTypes.node,
  authenticationStore: PropTypes.object,
}

export default inject(
  'authenticationStore',
  'profileStore',
)(observer(LoginForm))
