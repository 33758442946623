// import PropTypes from 'prop-types'
// import { inject, observer } from 'mobx-react'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Divider, Form, message, Progress, Select, Tooltip } from 'antd'
import {
  ButtonBuyCapacity,
  BuyCapacityArea,
  CommonConfigTabPaneWrapper,
  DataInfoFormItem,
  ProgressUsedArea,
} from './CommonConfigTabPaneStyled'
import SVGIcon from '../SVGIcon'
import stringUtils from '../../utils/stringUtils'
import { APP_NAME, BUY_CAPACITY_LINK } from '../../utils/constant'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const CommonConfigTabPane = props => {
  const { profileStore } = props
  const { myProfile, appLanguage } = profileStore
  const [form] = Form.useForm()
  const [currentValue, setCurrentValue] = useState('vi')
  const { t } = useTranslation()

  const handleSubmitCommonConfig = (formData) => {
    console.log(formData)
    let payload = {
      lang: formData.language,
      s_email: formData.receiveNotificationEmail ? '1' : '0',
      s_noti: formData.receiveNotificationWeb ? '1' : '0',
      s_noti_share: formData.receiveNotificationWeb ? '1' : '0',
      s_not_access: formData.receiveNotificationWeb ? '1' : '0',
    }
    profileStore.updateMyProfile(payload)
      .then(response => {
        if (response.error_code === 0) {
          profileStore.getMyProfile()
            .then(() => {
              message.success(t('i0052'))
            })
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }
  const onChangeLanguage = e => {
    setCurrentValue(e)
  }

  const renderTooltipBuyCapacity = () => {
    if (profileStore?.myProfile?.package?.type === 0) {
      return t('i0655')
    } else {
      return t('i0656')
    }
  }

  const handleClickBuyCapacity = () => {
    window.open(BUY_CAPACITY_LINK)
  }

  useEffect(() => {
    form.setFieldsValue({
      language: currentValue,
      receiveNotificationWeb: myProfile?.s_not_access == '1' && myProfile?.s_noti == '1' && myProfile?.s_noti_share == '1' ? true : false,
      receiveNotificationEmail: myProfile?.s_email == '1' ? true : false,
    })
  }, [currentValue, myProfile])

  useEffect(() => {
    let lang = !appLanguage || appLanguage === 'vi' ? 'vi' : 'en'
    setCurrentValue(lang)
  }, [appLanguage])

  return (
    <CommonConfigTabPaneWrapper>
      <Form
        form={form}
        onFinish={handleSubmitCommonConfig}
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 22 }}
        labelAlign={'left'}
        colon={false}>
        <Form.Item label={<strong>{t('i0053')}</strong>}>
          <DataInfoFormItem>
            <ProgressUsedArea>
              <Progress percent={(myProfile?.used_capacity / myProfile?.package?.capacity) * 100}
                        status="active"
                        showInfo={false}/>
              <span>{t('i0054')} {stringUtils.renderCapacity(myProfile?.used_capacity)}/{stringUtils.renderCapacity(myProfile?.package?.capacity)}</span>
            </ProgressUsedArea>
            <BuyCapacityArea>
              {/*<Tooltip title={renderTooltipBuyCapacity()}>*/}
              <ButtonBuyCapacity onClick={handleClickBuyCapacity}>{t('i0055')}</ButtonBuyCapacity>
              {/*</Tooltip>*/}
            </BuyCapacityArea>
          </DataInfoFormItem>
        </Form.Item>
        <Divider/>
        <Form.Item
          name={'language'}
          label={<strong style={{ marginTop: 15 }}>{t('i0056')}</strong>}>
          <Select
            onChange={onChangeLanguage}
            size={'large'}
            className={'common-config-select'}
            dropdownClassName={'common-config-select-option'}>
            <Option value={'vi'}>
              {t('i0057')} <SVGIcon name={'blue-checkmark'} width={18} height={13.5}/>
            </Option>
            <Option value={'en'}>
              {t('i0058')} <SVGIcon name={'blue-checkmark'} width={18} height={13.5}/>
            </Option>
          </Select>
        </Form.Item>
        <Divider/>
        <Form.Item
          valuePropName='checked'
          name={'receiveNotificationWeb'}
          label={<strong>{t('i0669')}</strong>}>
          <Checkbox>
            {t('i0059')} {APP_NAME} {t('i0060')}
          </Checkbox>
        </Form.Item>
        <Form.Item
          valuePropName='checked'
          name={'receiveNotificationEmail'}
          label={' '}>
          <Checkbox>
            {t('i0061')} {APP_NAME} {t('i0062')}
          </Checkbox>
        </Form.Item>
        <Form.Item
          label={' '}>
          <Button
            className={'submit-config-btn'}
            type={'primary'}
            htmlType={'submit'}>
            {t('i0063')}
          </Button>
        </Form.Item>
      </Form>
    </CommonConfigTabPaneWrapper>
  )
}

CommonConfigTabPane.propTypes = {}

export default inject('profileStore')(observer(CommonConfigTabPane))
