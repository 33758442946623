// import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Dropdown, message, Tooltip, Avatar, Spin, Empty, Modal } from 'antd'
import SVGIcon from '../SVGIcon/SVGIcon'
import {
  FileActionInfo,
  MenuNotificationAvatar,
  MenuNotificationInfo,
  MenuNotificationItem,
  MenuNotificationWrapper, NotificationTime, NotReadIcon, OwnerInfo,
} from './NotificationBellDropdownStyled'
import { inject, observer } from 'mobx-react'
import InfiniteScroll from 'react-infinite-scroller'
import { ScrollLoaderWrapper } from '../CommonStyled/CommonStyled'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NotificationBellDropdown = props => {

  const { notificationsStore, appConfigStore, sharedWithMeStore, authenticationStore } = props
  const history = useHistory()
  const { t } = useTranslation()

  const {
    notificationList,
    fcmToken,
    totalNotSeen,
    page,
    hasNewRealTimeNotify,
    hasNextPage,
    visibleDropdown,
  } = notificationsStore
  const { iconsByMimeTypeObj } = appConfigStore

  const [showModalNotify, setShowModalNotify] = useState(false)
  const [notifyToShow, setNotifyToShow] = useState(null)

  const handleSeenNotify = notify => {
    if (!notify.seen) {
      notificationsStore.seenNotify(notify?.id)
        .then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        })
        .catch(error => error?.response?.status != 401 && message.error(error.message))
      let newNotificationList = [...notificationList]
      newNotificationList = newNotificationList.map(item => {
        if (item.id === notify?.id) {
          item.seen = true
          return item
        } else {
          return item
        }
      })
      if (notificationsStore.totalNotSeen > 0) {
        let countNotSeen = notificationsStore.totalNotSeen - 1
        notificationsStore.setTotalNotSeen(countNotSeen)
        notificationsStore.setNotificationList(newNotificationList)
      }
    }
    switch (notify?.user_noti_type) {
      case 5:
        // Show modal
        setNotifyToShow(notify)
        setShowModalNotify(true)
        break
      case 2:
      case 4:
        // Go to shared with me page
        history.push('/shared-with-me')
        break
      case 1:
      case 3:
        // Go to folder/root (if file) in shared with me page
        if (notify?.file_node?.type === 1) {
          // Case folder
          sharedWithMeStore.setFolderFromNotify(notify?.file_node)
          history.push('/shared-with-me')
        } else {
          history.push('/shared-with-me')
        }
        break
      default:
        // Show modal
        setNotifyToShow(notify)
        setShowModalNotify(true)
        break
    }
  }
  const handleCloseModalNotify = () => {
    setShowModalNotify(false)
    setNotifyToShow(null)
  }

  const handleLoadMore = useCallback(() => {
    if (hasNextPage) {
      let nextPage = notificationsStore.page + 1
      notificationsStore.setPage(nextPage)
      notificationsStore.getNotificationListOnScroll()
        .then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        }).catch(error => {
        error?.response?.status != 401 && message.error(error.message)
      })
    }
  }, [hasNextPage])
  const renderScrollList = useCallback(() => {
    if (!notificationList) return <div key={'no-data'} />
    if (notificationList.length !== 0) {
      return notificationList.map(item =>
        <MenuNotificationItem key={item.id} id={`menu-notification-${item.id}`}>
          <MenuNotificationAvatar>
            {
              (item.user_noti_type === 5 || item.user_noti_type === null)
                ?
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_cms_notifi.png`} alt={''} width={40} height={40}
                     style={{ borderRadius: '50%', border: '1px solid #ccc' }} />
                :
                item.from_avatar
                  ?
                  <img src={item.from_avatar} alt={'avatar'} width={40} height={40} style={{ borderRadius: '50%' }} />
                  :
                  <Avatar size={40}>
                    {item?.from_fullname?.charAt(0)?.toUpperCase()}
                  </Avatar>
            }
          </MenuNotificationAvatar>
          <MenuNotificationInfo>
            <OwnerInfo fontWeight={item.seen ? '400' : '500'}>
              {
                item.seen ? null : <NotReadIcon />
              }
              <strong>{item?.from_fullname}</strong>
              <Tooltip title={<span style={{ cursor: 'text' }}>{item.title}</span>}
                       getPopupContainer={() => document.getElementById(`menu-notification-${item.id}`)}>
                <span onClick={() => handleSeenNotify(item)}>{item.title}</span>
              </Tooltip>
            </OwnerInfo>
            <FileActionInfo>
              {
                (item?.file_node?.mimetype === 'folder' ||
                  item?.file_node?.mimeType === 'application/vnd.google-apps.folder')
                  ? <SVGIcon name={'folder'} />
                  : (
                    <SVGIcon
                      url={
                        iconsByMimeTypeObj[item?.file_node?.mimetype] ||
                        iconsByMimeTypeObj['default']
                      }
                      width={18} height={18} />
                  )
              }
              <Tooltip title={<span style={{ cursor: 'text' }}>{item.content}</span>}
                       getPopupContainer={() => document.getElementById(`menu-notification-${item.id}`)}>
                <span onClick={() => handleSeenNotify(item)}>{item.content}</span>
              </Tooltip>
            </FileActionInfo>
            <NotificationTime>
              <div onClick={() => handleSeenNotify(item)}>
                {item.created_at}
              </div>
            </NotificationTime>
          </MenuNotificationInfo>
        </MenuNotificationItem>,
      )

    } else {
      return <Empty description={t('i0294')} />
    }

  }, [notificationList])

  useEffect(() => {
    if (!fcmToken) return
    if (!authenticationStore.accessToken) return
    notificationsStore.updateFcmToken(fcmToken)
  }, [fcmToken])
  useEffect(() => {
    if (!hasNewRealTimeNotify) return
    if (!visibleDropdown) return
    notificationsStore.getNotificationList().then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
      notificationsStore.setHasNewRealTimeNotify(false)
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }, [hasNewRealTimeNotify, visibleDropdown])
  useEffect(() => {
    if (!authenticationStore.accessToken) return
    const getNotification = async () => {
      await appConfigStore.getAppConfig()
      notificationsStore.getNotificationList().then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
        notificationsStore.setHasNewRealTimeNotify(false)
      }).catch(error => {
        error?.response?.status != 401 && message.error(error.message)
      })
    }
    void getNotification()
  }, [authenticationStore.accessToken])
  useEffect(() => {
    document.addEventListener('click', e => {
      if (e.target.closest('#notification-menu')) {
        notificationsStore.setVisibleDropdown(true)
      } else {
        notificationsStore.setVisibleDropdown(false)
      }
    })
  }, [])

  const menuNotification = (
    <MenuNotificationWrapper>
      <InfiniteScroll
        initialLoad={false}
        pageStart={page} threshold={1}
        loadMore={handleLoadMore}
        hasMore={hasNextPage}
        useWindow={false}
        loader={
          <ScrollLoaderWrapper key={'scroll-loader'}>
            <Spin />
          </ScrollLoaderWrapper>
        }
      >
        {renderScrollList()}
      </InfiniteScroll>
    </MenuNotificationWrapper>
  )

  return (
    <>
      <Dropdown
        visible={notificationsStore.visibleDropdown}
        overlayClassName={'notification-dropdown'}
        overlay={menuNotification}
        trigger={['click']}
        getPopupContainer={() => document.getElementById('notification-menu')}
        placement='bottomRight'>
        <Tooltip title={t('i0672')} placement={'bottom'}>
          <Badge count={totalNotSeen}>
            <SVGIcon name={'bell'} width={15.75} height={20.62} />
          </Badge>
        </Tooltip>
      </Dropdown>
      <Modal footer={null} title={notifyToShow?.title}
             visible={showModalNotify} onCancel={handleCloseModalNotify}>
        <p>{notifyToShow?.content}</p>
      </Modal>
    </>
  )
}

NotificationBellDropdown.propTypes = {}

export default inject(
  'notificationsStore',
  'appConfigStore',
  'sharedWithMeStore',
  'authenticationStore',
)(observer(NotificationBellDropdown))