import React from 'react'
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import { PageHeading, PageHeadingWrapper } from '../../components/CommonStyled/CommonStyled'
import { RightOutlined } from '@ant-design/icons'
import PageContentWrapper from '../../components/PageContentWrapper'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import validator from '../../validator'


const ChangePasswordPage = props => {
  const { authenticationStore } = props
  const history = useHistory()
  const [loginForm] = Form.useForm()

  const { t } = useTranslation()

  const handleClickBack = () => {
    history.push('/my-profile')
  }
  const onFinish = (formCollect) => {
    authenticationStore.checkOldPassword(formCollect.old_password)
      .then(response => {
        if (response.error_code === 0) {
          let payload = {
            old_password: formCollect.old_password,
            password: formCollect.password,
            password_confirmation: formCollect.password_confirmation,
          }
          console.log(payload)
          authenticationStore.changePassword(payload)
            .then(response => {
              if (response.error_code === 0) {
                message.success(t('i0459'))
              } else {
                message.error(response.message)
              }
            })
            .catch(error => error?.response?.status != 401 && message.error(error.message))
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }
  return (
    <>
      <Helmet>
        <title>{t('i0460')}</title>
      </Helmet>
      <PageHeadingWrapper>
        <PageHeading>
          <span onClick={handleClickBack}>
            {t('i0461')}
          </span>
          <RightOutlined style={{ fontSize: '16px', margin: '0 4px' }} />
          {t('i0462')}
        </PageHeading>
      </PageHeadingWrapper>
      <PageContentWrapper>
        <Row>
          <Col xxl={12} xl={12} lg={20} md={20} sm={24} xs={24}>
            <Form
              form={loginForm}
              layout={'vertical'}
              onFinish={onFinish}
              scrollToFirstError
              requiredMark={false}
              colon={false}
            >
              <Form.Item
                name={'old_password'}
                label={t('i0463')}
                rules={[
                  { required: true, message: t('i0464') },
                ]}
              >
                <Input.Password placeholder={t('i0465')} className={'register-input'} />
              </Form.Item>
              <Form.Item
                name={'password'}
                label={t('i0466')}
                rules={[
                  { required: true, message: t('i0449') },
                  { validator: validator.validatePasswordNew },
                ]}
              >
                <Input.Password placeholder={t('i0468')} className={'register-input'} />
              </Form.Item>
              <Form.Item
                name={'password_confirmation'}
                label={t('i0469')}
                rules={[
                  { required: true, message: t('i0470') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error(t('i0471')))
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('i0472')} className={'register-input'} />
              </Form.Item>
              <Button type={'primary'} htmlType={'submit'} style={{ padding: '0 32px' }} className={'register-btn'}>
                {t('i0473')}
              </Button>
            </Form>
          </Col>
        </Row>

      </PageContentWrapper>
    </>
  )
}

ChangePasswordPage.propTypes = {}

export default inject('authenticationStore')(observer(ChangePasswordPage))