import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()
export const ContactRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getContactList: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/user/list-contact`,
      params: {
        page: payload.page,
        per_page: payload.per_page,
      },
    })
  },
  deleteContact: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/user/delete-contact`,
      data: {
        list_id: payload,
      },
    })
  },
  addContact: (payload) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/user/add-contact`,
      data: {
        phone_contact: payload.phone_contact,
      },
    })
  },
  getDetailContact: (payload) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      url: `/api/user/contact/${payload?.contact_id}/detail`,
      params: {
        contact_id: payload?.contact_id,
      },
    })
  },

}