import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Button, Col, Row, Tooltip } from 'antd'
import { ProfileHeading, ProfileInfoData, ProfileInfoLabel, ProfileInfoWrapper } from '../CommonStyled/CommonStyled'
import stringUtils from '../../utils/stringUtils'
import numberUtils from '../../utils/numberUtils'
import { useTranslation } from 'react-i18next'

const PackOfDataRow = props => {
  const { profileStore } = props
  const { t } = useTranslation()
  return (
    profileStore.myProfile &&
    <Row>
      <Col xxl={3} xl={3} lg={24} md={24} sm={24} xs={24}>
        <ProfileHeading>{t('i0714')}</ProfileHeading>
      </Col>
      <Col xxl={15} xl={13} lg={12} md={24} sm={24} xs={24}>
        <ProfileInfoWrapper>
          <ProfileInfoLabel>
            <span>{t('i0302')}</span>
            <span>{t('i0303')}</span>
            <span>{t('i0304')}</span>
            <span>{t('i0305')}</span>
          </ProfileInfoLabel>
          <ProfileInfoData>
            <span>{profileStore.myProfile?.package?.name || t('i0306')}</span>
            <span>{profileStore.myProfile?.package?.capacity ? stringUtils.renderCapacity(profileStore.myProfile?.package?.capacity) : t('i0308')}</span>
            <span>{profileStore.myProfile?.package?.price ? `${numberUtils.thousandSeparator(profileStore.myProfile?.package?.price)} đ` : t('i0309')}</span>
            <span>{profileStore.myProfile?.package?.expired || t('i0307')}</span>
          </ProfileInfoData>
        </ProfileInfoWrapper>
      </Col>
      <Col xxl={6} xl={8} lg={12} md={24} sm={24} xs={24}>
        <Row gutter={[16, 16]}>
          <Col span={12}>

          </Col>
          <Col span={12}>
            {/*{*/}
            {/*  profileStore?.myProfile?.package?.type === 1 &&*/}
            {/*    <Tooltip title={`${t('ii0653')} ${profileStore.myProfile?.package?.cancel_syntax} ${t('i0654')} ${profileStore.myProfile?.package?.send_to}`}>*/}
            {/*      <Button type={'primary'}*/}
            {/*              block*/}
            {/*              style={{ borderRadius: '8px' }}>*/}
            {/*        {t('i0310')}*/}
            {/*      </Button>*/}
            {/*    </Tooltip>*/}
            {/*}*/}

          </Col>
        </Row>

      </Col>
    </Row>
  )
}

PackOfDataRow.propTypes = {}

export default inject('profileStore')(observer(PackOfDataRow))