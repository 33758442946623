// import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { BreadcrumbsHeading, MainQuestionContent, QuestionContainer } from './CommonQuestionDetailPageStyled'
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next'



const CommonQuestionDetailPage = props => {
  const { match,commonQuestionStore } = props
  const {questionItem} = commonQuestionStore
  const { questionId } = match.params

  const { t } = useTranslation()



  useEffect(() => {
    //get detail by id
  }, [questionId])
  return (
    <QuestionContainer>
      <BreadcrumbsHeading>
        <li>
          <Link to={'/common-question'}>
            {t('i0475')}
          </Link>
        </li>
        <li>
          <RightOutlined />
        </li>
        <li>
          <span>{t('i0474')}</span>
        </li>
      </BreadcrumbsHeading>
      <MainQuestionContent>
        <h1>
          {questionItem?.question}

        </h1>
        <p>
          {ReactHtmlParser(questionItem.answer)}
        </p>
      </MainQuestionContent>
    </QuestionContainer>
  )
}

CommonQuestionDetailPage.propTypes = {}

export default inject(
  'commonQuestionStore'
)(observer(CommonQuestionDetailPage))