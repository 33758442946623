import { action, observable } from 'mobx'
import { RecentlyOpenRequest } from '../requests/RecentlyOpenRequest'
import { PAGE_SIZE } from '../utils/constant'

class RecentlyOpenStore {
  @observable recentlyOpenList = []
  @observable recentlyOpenBreadcrumbData = []
  @observable recentlyOpenCurrentNode = null
  @observable itemRollbackRename = null
  @observable itemRollbackTrash = null
  @observable recentlyOpenSort = 'desc' // desc | asc
  @observable recentlyOpenSortBy = 'created_at' // name | updated_at

  @observable page = 1
  @observable perPage = PAGE_SIZE
  @observable hasNextPage = true

  @action setPage = (page) => {
    this.page = page
  }

  @action setRecentlyOpenSort = (sort) => {
    this.recentlyOpenSort = sort
  }
  @action setRecentlyOpenSortBy = (sortBy) => {
    this.recentlyOpenSortBy = sortBy
  }

  @action setItemRollbackRename = (itemDrive) => {
    this.itemRollbackRename = itemDrive
  }
  @action setItemRollbackTrash = (itemDrive) => {
    this.itemRollbackTrash = itemDrive
  }
  @action resetRecentlyOpenBreadcrumbData = () => {
    this.recentlyOpenCurrentNode = null
  }
  @action setRecentlyOpenBreadcrumbData = (item) => {
    let index = (this.recentlyOpenBreadcrumbData).indexOf(item)
    let newRecentlyOpenBreadcrumbData = []
    if (index >= 0) {
      newRecentlyOpenBreadcrumbData = this.recentlyOpenBreadcrumbData.slice(0, index + 1)
    } else {
      newRecentlyOpenBreadcrumbData = [...this.recentlyOpenBreadcrumbData, item]
    }
    this.recentlyOpenBreadcrumbData = newRecentlyOpenBreadcrumbData
    this.recentlyOpenCurrentNode = item
  }
  @action getRecentlyOpenListOnScroll = (payload) => {
    return new Promise((resolve, reject) => {
      RecentlyOpenRequest.getRecentlyOpenListScroll(payload).then(response => {
        this.recentlyOpenList = [...this.recentlyOpenList, ...response.data?.data]
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action clearRecentlyOpenList = () => {
    this.recentlyOpenList.length = 0
    this.recentlyOpenBreadcrumbData = [{ id: null, name: 'Đã mở gần đây' }]
    this.recentlyOpenCurrentNode = null
  }
  @action getRecentlyOpenList = (payload) => {
    return new Promise((resolve, reject) => {
      RecentlyOpenRequest.getRecentlyOpenList(payload).then(response => {
        this.recentlyOpenList = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action createFolder = (payload) => {
    return new Promise((resolve, reject) => {
      RecentlyOpenRequest.createFolder(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action trashDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      RecentlyOpenRequest.trashDriveItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action renameDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      RecentlyOpenRequest.renameDriveItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action deleteItemRecentlyOpen =(payload)=> {
    return new Promise((resolve, reject) => {
      RecentlyOpenRequest.deleteItemRecentlyOpen(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action cancelRequest = () => {
    RecentlyOpenRequest.cancelRequest()
  }
}

export default new RecentlyOpenStore()
