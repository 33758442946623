import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Modal } from 'antd'
import validator from '../../validator'
import { ButtonGroup, ModalTitle } from '../CommonStyled/CommonStyled'
import { useTranslation } from 'react-i18next'


const InsertPhoneBookModal = props => {
  const { visible, callback, onClose } = props
  const [form] = Form.useForm()
  const [phoneNumber, setPhoneNumber] = useState('')

  const { t } = useTranslation()

  const handleChangePhoneNumber = (name) => {
    setPhoneNumber(name.currentTarget.value)
  }
  const handleSubmit = (formData) => {
    callback(formData.phoneNumber)
    form.resetFields()
  }
  const handleCancel = () => {
    onClose()
    form.resetFields()
  }

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      wrapClassName={'custom-ant-modal'}
      forceRender={true}
    >
      <ModalTitle>{t('i0228')}</ModalTitle>
      <Form
        layout={'vertical'}
        form={form}
        colon={false}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={''}
          name={'phoneNumber'}
          rules={[
            { required: true, message: t('i0229') },
            { validator: validator.validatePhoneNumber },
          ]}>
          <Input placeholder={t('i0230')} onChange={handleChangePhoneNumber} />
        </Form.Item>
        <ButtonGroup>
          <Button type={'default'} onClick={() => handleCancel()}>{t('i0231')}</Button>
          <Button type={'primary'} htmlType={'submit'} disabled={phoneNumber.length === 0}>{t('i0232')}</Button>
        </ButtonGroup>
      </Form>
    </Modal>
  )
}

InsertPhoneBookModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  callback: PropTypes.func,
}

export default InsertPhoneBookModal