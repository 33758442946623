import React, { useEffect, useState } from 'react'
import {
  ModalWrapper,
  ButtonChooseUpload,
  UploadWrapper,
  ActionMenuIcon,
  ActionMenuItem,
  ActionMenuText,
} from './ModalChooseUploadStyled'
import PropTypes from 'prop-types'
import SVGIcon from '../SVGIcon'
import { message, Upload } from 'antd'
import { inject, observer } from 'mobx-react'
import fileUtils from '../../utils/fileUtils'
import { CONTENT_TYPE } from '../../utils/constant'
import axios from 'axios'
import uuid from 'uuid'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import { gapi } from 'gapi-script'

const { googleDriveConfig } = config

const ModalChooseUpload = props => {
  const { uploadStore, myDriveStore, onClose, keyUpload, appConfigStore, synchronizationStore, profileStore } = props
  const { accessTokenGoogleDrive } = synchronizationStore
  const { oneDriveConfig } = config
  const { myDriveCurrentNode } = myDriveStore
  const { mimeTypesByExtension, systemConfig } = appConfigStore
  const { apiKey, clientId, discoveryDocs, scope } = googleDriveConfig
  const [fileList, setFileList] = useState([])
  /// const [totalItemInFolderUploadDone, setTotalItemInFolderUploadDone] = useState(0)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false)

  const { t } = useTranslation()

  function launchOneDrivePicker() {
    return new Promise((resolve, reject) => {
      let odOptions = {
        clientId: oneDriveConfig?.clientId,
        action: 'download',
        redirectUri: 'http://localhost:3000',
        multiSelect: false,
        openInNewWindow: true,
        advanced: {
          //filter: "folder,.png" // Show only folders and png files
          //filter: "folder,photo" // Show only folders and photos
        },
        success: function(files) {
          resolve(files)
        },
        cancel: function() {
          resolve(null)
        },
        error: function(e) {
          reject(e)
        },
      }
      OneDrive.open(odOptions)
    })
  }

  const handleClickOpenPickerOneDrive = (e) => {
    e.preventDefault()
    if (!synchronizationStore.accessTokenOneDrive) {
      message.error(t('i0667'))
      return
    }
    onClose()
    launchOneDrivePicker()
      .then(async result => {
        if (result) {
          uploadStore.setVisibleModalProgress(true)
          uploadStore.setKeyUpload('sync_data')
          for (const file of result.value) {
            // const name = file.name
            // const url = file['@microsoft.graph.downloadUrl']
            let payload = {
              ...file,
              access_token: synchronizationStore.accessTokenOneDrive,
              file_id: file.id,
              driver: 'one_drive',
              cmc_parent_id: myDriveCurrentNode?.id,
              uid: file.id,
              mimetype: file.mimeType,
            }
            uploadStore.setFileList([payload])
            await handleSyncDataDriveToMobiDrive(payload)
          }
        }
      }).catch(reason => {
      message.error(reason.message)
      console.error(reason)
    })
  }

  const createPicker = () => {
    if (accessTokenGoogleDrive) {
      let view = new google.picker.View(google.picker.ViewId.DOCS)
      let picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setOAuthToken(accessTokenGoogleDrive)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(apiKey)
        .setCallback(pickerCallback).build()
      picker.setVisible(true)
      onClose()
    } else {
      message.error(t('i0666'))
    }
  }

  const handleUploadGoogleDrive = () => {
    uploadStore.setKeyUpload('sync_data')
    createPicker()
  }

  const pickerCallback = async (data) => {
    if (data.action == google.picker.Action.PICKED) {
      uploadStore.setVisibleModalProgress(true)
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      let files = []
      files = data.docs.map(item => {
        item.uid = item.id
        item.driver = 'google_drive'
        item.cmc_parent_id = myDriveStore.myDriveCurrentNode
        item.access_token = accessTokenGoogleDrive
        item.mimetype = item.mimeType
        return item
      })
      let i = 0
      uploadStore.setFileList(files)
      while (i < files.length) {
        let file = files[i]
        await handleSyncDataDriveToMobiDrive(file)
        file.cancelSource = source
        if (i === files.length - 1) {
          setTimeout(() => {
            // uploadStore.setVisibleModalProgress(false)
            // uploadStore.reSetFileList()
          }, 3000)
        }
        i++
      }
    }
  }

  const handleSyncDataDriveToMobiDrive = async (file) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    file.cancelSource = source
    if (uploadStore.statusUpload[file.uid] !== 'cancelled' && uploadStore.statusUpload[file.uid] !== 'error') {
      return await uploadStore.syncToMobifoneDrive(file).then(res => {
        if (res.error_code === 0) {
          uploadStore.updateStatusFile(file.uid, 'success')
          //reloadData()
          profileStore.getMyProfileNotShowSpinner()
        } else {
          uploadStore.handleMessageUpload(file.uid, res.message)
          uploadStore.updateStatusFile(file.uid, 'error')
        }
        return res.data
      }).catch(error => {
        if (axios.isCancel(error)) {
          uploadStore.handleMessageUpload(file.uid, t('i0716'))
          uploadStore.updateStatusFile(file.uid, 'cancelled')
        } else {
          uploadStore.handleMessageUpload(file.uid, error.message)
          uploadStore.updateStatusFile(file.uid, 'error')
        }
        return error
      })
    } else {
      return true
    }
  }


  const reloadData = () => {
    let payload = {
      parent_id: myDriveStore.myDriveCurrentNode?.id,
      content_type: CONTENT_TYPE.myDrive,
      page: 1,
      per_page: myDriveStore.perPage,
      sort: myDriveStore.myDriveSort,
      sort_by: myDriveStore.myDriveSortBy,
    }
    myDriveStore.getMyDriveListUploadDone(payload).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  const onHandleBeforeUpload = (file, fileList) => {
    setFileList(fileList)
    uploadStore.setVisibleModalProgress(true)
    onClose()
  }
  const handleUploadProgress = (progressEvent) => {

  }

  ///Upload file
  const singleUpload = async (file, parent_id) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    file.cancelSource = source
    if (uploadStore.statusUpload[file.uid] !== 'cancelled' && uploadStore.statusUpload[file.uid] !== 'error') {
      if (file.size > systemConfig?.max_upload) {
        message.error(t('i0270'))
        uploadStore.updateStatusFile(file.uid, 'error')
        return await Promise.all([])
      } else {
        let extension = fileUtils.getFileExtension(file.name)
        let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']
        const payload = {
          mimetype: mimetype,
          name: file.name,
          parent_id: parent_id,
          capacity: file.size,
        }
        let activeFile
        ///Get Link
        const getLinkUpload = await uploadStore.getUploadLink(payload)
          .then(res => {
            if (res.error_code === 0) {
              return res.data
            } else {
              uploadStore.handleMessageUpload(file.uid, res.message)
              uploadStore.updateStatusFile(file.uid, 'error')
            }
          })
          .catch(error => {
            uploadStore.handleMessageUpload(file.uid, error.message)
            uploadStore.updateStatusFile(file.uid, 'error')
          })

        ///Upload to s3
        if (getLinkUpload && getLinkUpload.url) {
          const uploadToS3 = await uploadStore.uploadFiles(getLinkUpload.url, file, handleUploadProgress)
            .then(res => {
              ////Active file
              if (uploadStore.statusUpload[file.uid] !== 'cancelled' && uploadStore.statusUpload[file.uid] !== 'error') {
                activeFile = uploadStore.activeUploadFiles({
                  node_id: getLinkUpload.node_id,
                  mimetype: mimetype,
                }).then(response => {
                  uploadStore.updateStatusFile(file.uid, 'success')
                  //reloadData()
                  profileStore.getMyProfileNotShowSpinner()
                  return response.data
                }).catch(error => {
                  uploadStore.updateStatusFile(file.uid, 'error')
                  uploadStore.handleMessageUpload(file.uid, error.message)
                  return error
                  //error?.response?.status != 401 && message.error(error.message)
                })
              }
            }).catch(error => {
              if (axios.isCancel(error)) {
                uploadStore.handleMessageUpload(file.uid, t('i0716'))
                uploadStore.updateStatusFile(file.uid, 'cancelled')
              } else {
                uploadStore.handleMessageUpload(file.uid, error.message)
                uploadStore.updateStatusFile(file.uid, 'error')
              }
              //error?.response?.status != 401 && message.error(error.message)
            })
          return await Promise.all([getLinkUpload, uploadToS3, activeFile])
        } else {
          return await Promise.all([getLinkUpload])
        }
      }
    } else {
      return await Promise.all([])
    }
  }

  ///Upload folder
  const uploadFolder = async (children, parent_id, rootId, folder) => {
    for (let index = 0; index < children.length; index++) {
      let item = children[index]
      if (item.type === 'file' &&
        uploadStore.statusUpload[rootId] !== 'error' &&
        uploadStore.statusUpload[rootId] !== 'cancelled') {
        let file = item.file
        file.cancelSource = folder.cancelSource
        let extension = fileUtils.getFileExtension(file.name)
        let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']
        let payload = { mimetype: mimetype, name: file.name, parent_id: parent_id, capacity: file.size }

        ///GetLink
        await uploadStore.getUploadLink(payload)
          .then(async res => {
            if (res.error_code === 0) {
              uploadStore.setNotEnoughCapacity(false)
              ///upload s3
              if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
                await uploadStore.uploadFiles(res.data.url, file)
                  .then(async response => {
                    ////Active file
                    if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
                      await uploadStore.activeUploadFiles({ node_id: res.data.node_id, mimetype: mimetype })
                        .then(response => {
                          file.id = response?.data.id
                          uploadStore.updateFileUpload(folder, 1, (percent) => {
                            if (percent === 100) {
                              setTimeout(() => {
                                reloadData()
                                profileStore.getMyProfileNotShowSpinner()
                              }, 3000)
                            }
                          })
                          return response.data
                        }).catch(error => {
                          uploadStore.updateStatusFile(rootId, 'error')
                          error?.response?.status != 401 && message.error(error.message)
                        })
                    }
                  }).catch(error => {
                    uploadStore.updateFileUpload(folder, 0, () => {
                    })
                    if (axios.isCancel(error)) {
                      uploadStore.updateStatusFile(rootId, 'cancelled')
                      uploadStore.handleMessageUpload(rootId, t('i0716'))
                      //uploadStore.cancelUploadFolder(folder.id)
                      reloadData()
                    } else {
                      uploadStore.handleMessageUpload(rootId, error.message)
                      uploadStore.updateStatusFile(rootId, 'error')
                      reloadData()
                    }
                  })
              }

              return res.data
            } else {
              if (res.error_code === 1) {
                uploadStore.setNotEnoughCapacity(true)
              }
              uploadStore.handleMessageUpload(file.uid, res.message)
              uploadStore.updateStatusFile(rootId, 'error')
              reloadData()
              uploadStore.reSetFileList()
            }
          })
          .catch(error => {
            uploadStore.handleMessageUpload(rootId.uid, error.message)
            uploadStore.updateStatusFile(rootId, 'error')

          })
      } else if (item.type === 'directory' &&
        uploadStore.statusUpload[rootId] !== 'error'
        && uploadStore.statusUpload[rootId] !== 'cancelled') {
        let params = {
          parent_id: parent_id,
          name: item.name,
        }
        if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
          uploadStore.createFolderUpload(params, folder.cancelSource)
            .then(response => {
              if (response.error_code !== 0) {
                message.error(response.message)
              } else {
                item.id = response?.data.id
                children[index] = item
                uploadStore.updateFileUpload(folder, 0, () => {
                })
                uploadFolder(item?.children, response?.data?.id, rootId, folder)
              }
            })
            .catch(error => {
              uploadStore.updateFileUpload(folder, 0, () => {
              })
              if (axios.isCancel(error)) {
                //uploadStore.cancelUploadFolder(folder.id)
                uploadStore.updateStatusFile(rootId, 'cancelled')
                uploadStore.handleMessageUpload(rootId, t('i0716'))
                reloadData()
              } else {
                uploadStore.handleMessageUpload(rootId, error.message)
                uploadStore.updateStatusFile(rootId, 'error')
              }
            })
        }
      }
    }

  }
  ///Format fileList to tree on action upload folder
  const formatNodeFolder = (fileArray) => {
    let tree = {}

    ///Format tree view
    function addNode(file) {
      let webkitRelativePath = file?.webkitRelativePath
      let splitPath = webkitRelativePath.replace(/^\/|\/$/g, '').split('/')
      let ptr = tree
      for (let i = 0; i < splitPath.length; i++) {
        let node = {
          name: splitPath[i],
          type: 'directory',
        }
        if (i === splitPath.length - 1) {
          node.size = file.size
          node.type = 'file'
          node.file = file
          ptr[splitPath[i]] = ptr[splitPath[i]] || node
        } else {
          ptr[splitPath[i]] = ptr[splitPath[i]] || node
          ptr[splitPath[i]].children = ptr[splitPath[i]].children || {}
          ptr = ptr[splitPath[i]].children
        }
      }
    }

    ////Format object to array/
    function objectToArr(node) {
      Object.keys(node).map((key) => {
        if (node[key].children) {
          objectToArr(node[key])
        }
      })
      if (node.children) {
        node.children = Object.values(node.children)
        node.children.forEach(objectToArr)
      }
    }

    fileArray.map(addNode)
    objectToArr(tree)
    return Object.values(tree)
  }


  const uploadProps = {
    multiple: true,
    beforeUpload: onHandleBeforeUpload,
    showUploadList: false,
    directory: keyUpload === 'uploadFolder',
    customRequest: () => {
    },
  }

  useEffect(() => {

    // const CancelToken = axios.CancelToken
    // const source = CancelToken.source()

    const upload = async () => {
      let i = 0
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      // uploadStore.setFileList(cloneFileList)
      switch (keyUpload) {
        case 'uploadFiles':
          let cloneFileList = fileList.map(item => {
            let extension = fileUtils.getFileExtension(item.name)
            let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']
            item.cancelSource = source
            item.status = 'uploading'
            item.mimetype = mimetype
            item.parent_id = myDriveCurrentNode?.id
            uploadStore.updateStatusFile(item.uid, 'uploading')
            return item
          })
          uploadStore.setFileList(cloneFileList)
          while (i < cloneFileList.length) {
            let file = cloneFileList[i]
            await singleUpload(file, myDriveCurrentNode?.id)
            file.cancelSource = source
            if (i === cloneFileList.length - 1) {
              setTimeout(() => {
                reloadData()
              }, 1500)
            }
            i++
          }
          break
        case 'uploadFolder':
          const fileListMap = formatNodeFolder(fileList)
          let folder = fileListMap[0]
          let idUpload = uuid()
          uploadStore.updateStatusFile(idUpload, 'uploading')
          let results = []
          fileUtils.findObjects(folder, 'type', 'file', results)
          folder.totalFile = results.length
          folder.totalDone = 0
          folder.cancelSource = source
          folder.status = 'uploading'
          folder.parent_id = myDriveCurrentNode?.id
          folder.uid = idUpload
          uploadStore.setFileList([folder])
          ///create root node folder
          let params = {
            parent_id: folder.parent_id,
            name: folder.name,
          }
          uploadStore.createFolderUpload(params, source)
            .then(response => {
                if (response.error_code !== 0) {
                  message.error(response.message)
                } else {
                  folder.id = response?.data.id
                  uploadStore.updateFileUpload(folder, 0, () => {
                  })
                  uploadFolder(folder.children, response?.data.id, folder.uid, folder)
                }
              },
            ).catch(error => {
            uploadStore.updateStatusFile(idUpload, 'error')
            console.log('error', error)
            // error?.response?.status != 401 && message.error(error.message)
          })
          break
        default:
          break
      }
    }
    upload()
  }, [fileList])


  useEffect(() => {
    gapi.load('picker')
  }, [accessTokenGoogleDrive])

  return (
    <>
      <ModalWrapper {...props}>
        <UploadWrapper>
          <Upload {...uploadProps} >
            <ActionMenuItem>
              <ActionMenuIcon>
                <SVGIcon name={'computer'} width={15} height={15} />
              </ActionMenuIcon>
              <ActionMenuText>{t('i0272')}</ActionMenuText>
            </ActionMenuItem>
          </Upload>
        </UploadWrapper>
        {
          keyUpload !== 'uploadFolder' && <>
            <ButtonChooseUpload key={'google-drive'} onClick={handleUploadGoogleDrive}>
              <SVGIcon name={'google-drive'} width={15} height={15} />
              <span>Google Drive</span>
            </ButtonChooseUpload>
            <ButtonChooseUpload key={'onedrive'} onClick={handleClickOpenPickerOneDrive}>
              <SVGIcon name={'onedrive'} width={15} height={15} />
              <span>OneDrive</span>
            </ButtonChooseUpload>
          </>
        }

      </ModalWrapper>
    </>
  )
}

ModalChooseUpload.prototype = {
  onClick: PropTypes.func,
}

export default inject(
  'uploadStore',
  'myDriveStore',
  'appConfigStore',
  'synchronizationStore',
  'profileStore',
)(observer(ModalChooseUpload))
