import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { FileContainer, Grid, GridItem, GridItemMobileHandler } from './FilesGridViewStyled'
import MoveItemMenuDropdown from '../MoveItemMenuDropdown/MoveItemMenuDropdown'
import { Button, Form, Input, message, Modal, Tooltip } from 'antd'
import { ButtonGroup } from '../../pages/TrashPage/TrashPageStyled'
import validator from '../../validator'
import { CONTEXT_MENU_ACTION, MIMETYPE_OFFICE, MIMETYPE_PDF, MOBILE_ACTION } from '../../utils/constant'
import SVGIcon from '../SVGIcon'
import ContextMenuDropdown from '../ContextMenuDropdown'
import myDriveStore from '../../stores/myDriveStore'
import { toJS } from 'mobx'
import { InfoCircleFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive/src'

const FilesGridView = props => {
  const [formRename] = Form.useForm()
  const {
    fileList,
    callbackAction,
    appConfigStore,
    selectionStore,
    commonStore,
    callbackMobileAction,
    previewOverlayStore,
    authenticationStore,
  } = props
  const { thumbnailByMimeTypeObj, iconsByMimeTypeObj, groupIdsByMimetype, mimetypeGroups } = appConfigStore
  const { selectionData, selectionIds } = selectionStore
  const { viewByGridMode, pageName } = commonStore

  const { t } = useTranslation()

  const isXL = useMediaQuery({ minWidth: 1441 })
  const isMD = useMediaQuery({ maxWidth: 1440, minWidth: 1025 })
  const isSM = useMediaQuery({ maxWidth: 1024 })

  const [currentAction, setCurrentAction] = useState(null)
  const [currentItemDrive, setCurrentItemDrive] = useState(null)
  const [itemToShowMoveDropDown, setItemToShowMoveDropDown] = useState(null)
  const [targetItemDrive, setTargetItemDrive] = useState(null)
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false)
  const [modalRenameVisible, setModalRenameVisible] = useState(false)
  const [modalMessage, setModalMessage] = useState(t('i0131'))
  const [modalOkText, setModalOkText] = useState(t('i0132'))
  const [modalCancelText, setModalCancelText] = useState(t('i0159'))
  const [positionModalMoveItem, setPositionModalMoveItem] = useState({ x: 0, y: 0 })
  const fileContainerRef = useRef()

  const handleActionContextMenu = (action) => {
    setCurrentAction(action)
    if (selectionIds.length > 1) {
      switch (action) {
        case CONTEXT_MENU_ACTION.trash:
          setModalMessage(t('i0113'))
          setModalOkText(t('i0114'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.move:
          let showMoveList = selectionData.filter(x => x.type !== 1)
          setItemToShowMoveDropDown(showMoveList[0])
          break
        case CONTEXT_MENU_ACTION.restore:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          callbackAction(action, 'folder')
          break
        case CONTEXT_MENU_ACTION.deleteShareWithMe:
        case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
          setModalMessage(t('i0120'))
          setModalOkText(t('i0121'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.lock:
          setModalMessage(t('i0165'))
          setModalOkText(t('i0166'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.unlock:
          callbackAction(action, undefined)
          break
        default:
          break
      }
    } else {
      switch (action) {
        case CONTEXT_MENU_ACTION.syncFromOneDrive:
        case CONTEXT_MENU_ACTION.syncFromGoogleDrive:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.copyLink:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.share:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.trash:
          setModalMessage(t('i0115'))
          setModalOkText(t('i0116'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.download:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          callbackAction(action, 'folder')
          break
        case CONTEXT_MENU_ACTION.restore:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.favorite:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.removeFavorite:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.move:
          setItemToShowMoveDropDown(selectionData[0])
          break
        case CONTEXT_MENU_ACTION.rename:
          formRename.setFieldsValue({
            fileName: selectionData[0].name,
          })
          setModalOkText(t('i0117'))
          setModalRenameVisible(true)
          break
        case CONTEXT_MENU_ACTION.viewInfo:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.lock:
          setModalMessage(t('i0118'))
          setModalOkText(t('i0119'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.unlock:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.deleteShareWithMe:
        case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
          setModalMessage(t('i0120'))
          setModalOkText(t('i0121'))
          setModalConfirmVisible(true)
        default:
          break
      }
    }
  }
  const handleConfirmModal = (isConfirm) => {
    setModalConfirmVisible(false)
    if (!isConfirm) {
      setCurrentAction(null)
      setTargetItemDrive(null)
      setItemToShowMoveDropDown(null)
      return
    }
    switch (currentAction) {
      case CONTEXT_MENU_ACTION.copyLink:
        break
      case CONTEXT_MENU_ACTION.trash:
        myDriveStore.setItemRollbackTrash(selectionData[0])
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
      case CONTEXT_MENU_ACTION.deleteShareWithMe:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.download:
        break
      case CONTEXT_MENU_ACTION.favorite:
        break
      case CONTEXT_MENU_ACTION.move:
        callbackAction(currentAction, targetItemDrive)
        break
      case CONTEXT_MENU_ACTION.rename:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.share:
        break
      case CONTEXT_MENU_ACTION.viewInfo:
        break
      case CONTEXT_MENU_ACTION.lock:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.unlock:
        break
      default:
        break
    }
  }
  const handleCancelRename = () => {
    setCurrentAction(null)
    setModalRenameVisible(false)
  }
  const handleConfirmRename = (formData) => {
    console.log(formData)
    myDriveStore.setItemRollbackRename({ ...selectionData[0] })
    selectionData[0].name = formData.fileName
    callbackAction(currentAction)
    setModalRenameVisible(false)
  }
  const handleCloseMoveMenu = () => {
    setItemToShowMoveDropDown(null)
  }
  const handleCallbackMoveItem = (itemDriveToMove, targetItemDrive) => {
    setTargetItemDrive(targetItemDrive)
    setCurrentItemDrive(itemDriveToMove)
    setModalMessage(`${t('i0122')} ${targetItemDrive?.name || ''} ${t('i0123')}`)
    setModalOkText(t('i0124'))
    setModalConfirmVisible(true)
  }
  const handleSelection = (event, itemData) => {
    if (pageName === 'synchronization') {
      selectionStore.setSelectionData([itemData])
      return
    }
    // Nếu click ctrl hoặc cmd thì thêm từng phần tử được click vào selectionStore
    if (event.ctrlKey || event.metaKey) {
      if (selectionIds.includes(itemData.id)) {
        const selection = toJS(selectionData)
        const filteredData = selection.filter(item => item.id !== itemData.id)
        selectionStore.setSelectionData([
          ...filteredData,
        ])
        return
      }
      selectionStore.setSelectionData([
        ...selectionData, itemData,
      ])
    } else if (event.shiftKey) {
      // Nếu shift click mà chưa có phần tử nào trong selectionStore thì thêm phần tử vừa click vào
      if (selectionIds.length === 0) {
        selectionStore.setSelectionData([itemData])
      } else {
        selectionStore.setClickSessionId()
        // Nếu đã có phần tử trong selection thì lấy các item trong khoảng
        let lastItemSelectedInStore = selectionData[0]
        let itemShiftClick = itemData
        if (lastItemSelectedInStore.realIndex > itemShiftClick.realIndex) {
          selectionStore.setFirstSelectionIndex(itemShiftClick.realIndex)
          selectionStore.setLastSelectionIndex(lastItemSelectedInStore.realIndex)
        } else {
          selectionStore.setFirstSelectionIndex(lastItemSelectedInStore.realIndex)
          selectionStore.setLastSelectionIndex(itemShiftClick.realIndex)
        }
      }
    } else {
      selectionStore.setSelectionData([itemData])
    }
  }
  const handleRightClickSelection = (event, itemData) => {
    if (selectionIds.includes(itemData.id)) return
    selectionStore.setSelectionData([itemData])
  }
  const handleViewInfoMobile = (e, item) => {
    e.stopPropagation()
    callbackMobileAction(item, MOBILE_ACTION.viewInfo)
  }
  const handleClickMobile = (item) => {
    callbackMobileAction(item, MOBILE_ACTION.click)
  }
  const handleDoubleClick = (record) => {
    if (pageName === 'offline-files' || pageName === 'synchronization') {
      return
    }
    if (pageName !== 'trash') {
      let group_id = groupIdsByMimetype[record.mimetype]
      let typeName
      let findInd = mimetypeGroups.findIndex(item => item.id === group_id)
      if (findInd > -1) typeName = mimetypeGroups[findInd].name
      if (typeName === 'Image' || typeName === 'Video' || typeName === 'Audio') {
        record.type_name = typeName
        previewOverlayStore.setVisiblePreviewOverlay()
        previewOverlayStore.setRecordOverlay(record)
      }
      if (MIMETYPE_OFFICE.includes(record.mimetype)) {
        const win = window.open(`/doc-viewer/${authenticationStore.accessToken ? record.id : record.encrypt_id}`, '_blank')
        win.focus()
      }
      if (MIMETYPE_PDF.includes(record.mimetype)) {
        const win = window.open(`/pdf-viewer/${authenticationStore.accessToken ? record.id : record.encrypt_id}`, '_blank')
        win.focus()
      }
    }
  }
  const isLastItem = index => {
    if (isXL && index % 6 === 0) return true
    if (isMD && index % 4 === 0) return true
    return !!(isSM && index % 2 === 0)
  }

  return (
    <>
      <Grid>
        {
          fileList.map((record, index) => (
            <GridItem
              isGridMode={viewByGridMode}
              key={record.id}
              className={'selectable-item'}>
              <ContextMenuDropdown
                callbackAction={handleActionContextMenu}>
                <Tooltip title={record.name} mouseEnterDelay={0.3} placement={'bottom'}>
                  <FileContainer
                    ref={fileContainerRef}
                    onContextMenu={event => handleRightClickSelection(event, record)}
                    onClick={event => handleSelection(event, record)}
                    onDoubleClick={() => handleDoubleClick(record)}
                    className={selectionIds.includes(record.id) ? 'selected' : null}>
                    <div className='file-thumbnail'>
                      <img
                        src={`${record.thumbnail && record.thumbnail['2x'] ||
                        thumbnailByMimeTypeObj[record.mimetype] ||
                        thumbnailByMimeTypeObj['default']}`}
                        alt='' style={{
                        height: record.thumbnail ? '100%' : 'auto',
                        width: record.thumbnail ? '100%' : 64,
                      }}
                      />
                    </div>
                    <div className='file-info'>
                      <SVGIcon
                        url={
                          record.iconLink ||
                          iconsByMimeTypeObj[record.mimetype] ||
                          iconsByMimeTypeObj['default']
                        }
                        width={18}
                        height={18} name={'image'}
                      />
                      <span>{record.name}</span>
                    </div>
                  </FileContainer>
                </Tooltip>
              </ContextMenuDropdown>
              <MoveItemMenuDropdown
                moveMenuVisible={itemToShowMoveDropDown?.id === record.id}
                isLastItem={isLastItem(index + 1)}
                itemToMove={selectionIds}
                callbackMoveItem={handleCallbackMoveItem}
                onClose={handleCloseMoveMenu} />
              <GridItemMobileHandler onClick={() => handleClickMobile(record)}>
                <InfoCircleFilled onClick={e => handleViewInfoMobile(e, record)} />
              </GridItemMobileHandler>
            </GridItem>
          ))
        }
      </Grid>
      <Modal
        onCancel={() => handleConfirmModal(false)}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal context-menu'}
        visible={modalConfirmVisible}>
        <p>
          {modalMessage}
        </p>
        <ButtonGroup>
          <Button type={'default'} onClick={() => handleConfirmModal(false)}>{modalCancelText}</Button>
          <Button type={'primary'} onClick={() => handleConfirmModal(true)}>{modalOkText}</Button>
        </ButtonGroup>
      </Modal>
      <Modal
        forceRender={true}
        onCancel={handleCancelRename}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal context-menu'}
        visible={modalRenameVisible}>
        <Form form={formRename} onFinish={handleConfirmRename}>
          <p>
            {t('i0125')}
          </p>
          <Form.Item
            label={''}
            name={'fileName'}
            rules={[
              { validator: validator.validateInputStringFileName },
              { required: true, message: t('i0126') },
              { max: 255, message: t('i0127') },
            ]}>
            <Input placeholder={t('i0128')} />
          </Form.Item>
          <ButtonGroup>
            <Button type={'default'} onClick={handleCancelRename}>{modalCancelText}</Button>
            <Button type={'primary'} htmlType={'submit'}>{modalOkText}</Button>
          </ButtonGroup>
        </Form>
      </Modal>
    </>
  )
}

FilesGridView.propTypes = {
  fileList: PropTypes.array.isRequired,
  callbackAction: PropTypes.func.isRequired,
  callbackMobileAction: PropTypes.func,
}

export default inject(
  'appConfigStore',
  'selectionStore',
  'commonStore',
  'previewOverlayStore',
  'authenticationStore',
)(observer(FilesGridView))
