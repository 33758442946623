import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()

export const MoveItemRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getFolderList: parentId => {
    let url = parentId ? `${apiUrl}/api/folders/${parentId}?page=1&per_page=1000` : `${apiUrl}/api/folders?page=1&per_page=1000`
    return axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

  getItemToMoveList: parentId => {
    let url = parentId ? `${apiUrl}/api/folders/${parentId}` : `${apiUrl}/api/folders`
    return axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

  moveDriveItem: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/move`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
    })
  },

  getDetailParentFolder: (node_id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/nodes/${node_id}/detail`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

}