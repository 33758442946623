import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  AdvanceFilterDropdownBody,
  AdvanceFilterDropdownFooter,
  AdvanceFilterDropdownWrapper,
  AdvanceFilterFormWrapper,
  DateRangeWrapper,
  SharingUserInfo,
  StyledTagsSelect,
  UserAvatar,
  UserInfo,
} from './AdvanceFilterDropdownStyled'
import { Button, Col, DatePicker, Empty, Form, message, Row, Select } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import httpUtils from '../../utils/httpUtils'
import SVGIcon from '../SVGIcon'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive/src'
import { useTranslation } from 'react-i18next'
import DefaultAvatar from '../DefaultAvatar/DefaultAvatar'
import { DEBOUNCE_DELAY, EMAIL_REGEX } from '../../utils/constant'
import { toJS } from 'mobx'
import { debounce } from 'lodash-es/function'

const { Option } = Select
const { paramsSerializer } = httpUtils
const ISO_FORMAT = 'YYYY-MM-DD'

const AdvanceFilterDropdown = props => {
  const {
    searchStore, appConfigStore, shareStore, commonStore,
    visible, onClose, children, defaultKeyword,
  } = props
  const { searchPageSortAsc, searchPageSortBy } = searchStore
  const { userForShare } = shareStore
  const { pageName } = commonStore
  const { t } = useTranslation()
  const wrapperRef = useRef(null)
  const [form] = Form.useForm()
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const inputSearchRef = useRef()

  const [animationClass, setAnimationClass] = useState()
  const [showMenu, setShowMenu] = useState(false)
  const [showPickerForCreatedDate, setShowPickerForCreatedDate] = useState(false)
  const [showPickerForUpdatedDate, setShowPickerForUpdatedDate] = useState(false)
  const [keywordSearchUser, setKeywordSearchUser] = useState('')
  const [newSharingUsername, setNewSharingUsername] = useState([])
  const [newSharingUserObjs, setNewSharingUserObjs] = useState([])
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  const query = new URLSearchParams(useLocation().search)
  const keyword = query.get('q')
  const mimetype_group_id = query.get('mimetype_group_id')
  const storage_type = query.get('storage_type')
  const from_date = query.get('from_date')
  const to_date = query.get('to_date')
  const from_update_date = query.get('from_update_date')
  const to_update_date = query.get('to_update_date')
  const share_with = query.get('share_with')
  const owner = query.get('owner')

  const EmptySearchResult = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        keywordSearchUser && !EMAIL_REGEX.test(keywordSearchUser.toString())
          ? (
            <>
              {t('i0351')}
            </>
          ) : (
            <>
              {t('i0352')} <br/>
              {t('i0353')}
            </>
          )
      }
    />
  )

  const debounceDropDown = useCallback(debounce(nextValue => {
    if (nextValue?.length === 0) {
      shareStore.setUserForShare([])
    }
    setKeywordSearchUser(nextValue)
  }, DEBOUNCE_DELAY), [])
  const handleSearch = formData => {
    const {
      createdDate, updatedDate,
      createdDate_from, createdDate_to,
      updatedDate_form, updatedDate_to,
    } = formData
    const createdDateData = handleDateRangeData(createdDate)
    const updatedDateData = handleDateRangeData(updatedDate)
    const filteredFormData = {
      ...formData,
      ...(defaultKeyword && { q: defaultKeyword }),
      ...(newSharingUsername[0] && { share_with: newSharingUsername[0] }),
    }
    if (createdDateData) {
      filteredFormData.from_date = createdDateData.from
      filteredFormData.to_date = createdDateData.to
    }
    if (updatedDateData) {
      filteredFormData.from_update_date = updatedDateData.from
      filteredFormData.to_update_date = updatedDateData.to
    }
    if (createdDate === 4) {
      delete filteredFormData['createdDate_from']
      delete filteredFormData['createdDate_to']
      filteredFormData.from_date = createdDate_from &&
        createdDate_from.format(ISO_FORMAT) || null
      filteredFormData.to_date = createdDate_to &&
        createdDate_to.format(ISO_FORMAT) || null
    }
    if (updatedDate === 4) {
      delete filteredFormData['updatedDate_form']
      delete filteredFormData['updatedDate_to']
      filteredFormData.from_update_date = updatedDate_form &&
        updatedDate_form.format(ISO_FORMAT) || null
      filteredFormData.to_update_date = updatedDate_to &&
        updatedDate_to.format(ISO_FORMAT) || null
    }
    delete filteredFormData['createdDate']
    delete filteredFormData['updatedDate']
    Object.keys(filteredFormData).forEach(key => {
      filteredFormData[key] == null && delete filteredFormData[key]
    })
    const {
      keyword,
      mimetype_group_id,
      storage_type,
      from_date,
      to_date,
      from_update_date,
      to_update_date,
      owner,
    } = filteredFormData
    searchStore.getSearchItemList(
      keyword,
      mimetype_group_id,
      storage_type,
      from_date,
      to_date,
      from_update_date,
      to_update_date,
      newSharingUsername[0],
      owner,
    ).then(() => {
      history.push(`/search?${paramsSerializer(filteredFormData)}`)
      onClose()
    }).catch(() => {
      message.error(t('i0016'))
    })
  }
  const handleDateRangeData = dateType => {
    switch (dateType) {
      case 1:
        return {
          from: moment().format(ISO_FORMAT),
          to: moment().format(ISO_FORMAT),
        }
      case 2:
        return {
          from: moment().subtract(7, 'days').format(ISO_FORMAT),
          to: moment().format(ISO_FORMAT),
        }
      case 3:
        return {
          from: moment().subtract(1, 'months').format(ISO_FORMAT),
          to: moment().format(ISO_FORMAT),
        }
      default:
        return null
    }
  }
  const handleResetFilter = () => {
    form.setFieldsValue({
      mimetype_group_id: null,
      owner: null,
      storage_type: null,
      createdDate: null,
      updatedDate: null,
      share_with: null,
    })
    setShowPickerForCreatedDate(false)
    setShowPickerForUpdatedDate(false)
    setNewSharingUsername([])
    searchStore.clearSearchList()
    searchStore.setSearchKeyword(null)
    pageName === 'search' && history.push(`/search`)
  }
  const onTimeTypeChange = (value, field) => {
    switch (field) {
      case 'createdDate':
        setShowPickerForCreatedDate(value === 4)
        break
      case 'updatedDate':
        setShowPickerForUpdatedDate(value === 4)
        break
    }
  }
  const filteredDatePicker = date => {
    return date.isAfter(moment(), 'day')
  }
  const handleOnEnter = e => {
    if (!keywordSearchUser) return
    if (e.keyCode !== 13) return
    const EMAIL_REGEX = /^[A-Za-z][A-Za-z0-9-_.]{1,32}(\+?[0-9]){0,5}@[A-Za-z0-9_-]{2,}(\.[A-Za-z0-9]{2,4}){1,2}$/gm
    if (keywordSearchUser && !EMAIL_REGEX.test(keywordSearchUser.toString())) {
      // message.error(t('i0348'))
      inputSearchRef.current.blur()
      return
    }
    if (newSharingUsername.includes(keywordSearchUser)) {
      inputSearchRef.current.blur()
      return
    }
    let dataSharingUserIds = [...newSharingUsername, keywordSearchUser]
    let dataSharingUserObj = [...newSharingUserObjs, { id: keywordSearchUser, type: 'email' }]
    setNewSharingUsername(dataSharingUserIds)
    setNewSharingUserObjs(dataSharingUserObj)
    setKeywordSearchUser(null)
    inputSearchRef.current.blur()
  }
  const handleSelectUserForSharing = ids => {
    let selectedUser = userForShare.filter(x => ids.includes(x.id))
    let newSharingUsername = ids[ids.length - 1] ? [ids[ids.length - 1]] : []
    setNewSharingUsername(newSharingUsername)
    setNewSharingUserObjs(selectedUser)
    setKeywordSearchUser(null)
    inputSearchRef.current.blur()
  }
  const handleSearchNewSharingUsername = val => {
    debounceDropDown(val)
    setIsLoadingSearch(true)
  }
  const handleCloseUserSharedDropdown = () => {
    setKeywordSearchUser(null)
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (event.target.closest('.advance-filter-dropdown')?.classList) return
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) onClose()
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])
  useEffect(() => {
    if (isMobile) return
    if (!visible) {
      setAnimationClass('slide-up')
      setTimeout(() => {
        setShowMenu(false)
        setAnimationClass('')
      }, 190)
      return
    }
    setShowMenu(true)
    setAnimationClass('slide-down')
    setTimeout(() => setAnimationClass(''), 190)
  }, [visible, isMobile])
  useEffect(() => {
    if (
      keyword || mimetype_group_id || storage_type ||
      from_date || to_date ||
      from_update_date || to_update_date ||
      share_with || owner
    ) {
      searchStore.getSearchItemList(
        keyword,
        mimetype_group_id,
        storage_type,
        from_date,
        to_date,
        from_update_date,
        to_update_date,
        share_with,
        owner,
      )
    }
  }, [searchPageSortAsc, searchPageSortBy])
  useEffect(() => {
    keywordSearchUser && keywordSearchUser.trim() !== '' && shareStore.searchUserForShared(keywordSearchUser)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
      .finally(() => setIsLoadingSearch(false))
  }, [keywordSearchUser])

  return (
    <AdvanceFilterDropdownWrapper ref={wrapperRef}>
      {children}
      {
        showMenu && (
          <AdvanceFilterFormWrapper className={animationClass}>
            <Form
              onFinish={handleSearch}
              form={form}
              colon={false}
              labelAlign={'left'}
              size={'large'}
              initialValues={{
                mimetype_group_id: null,
                owner: null,
                storage_type: null,
                createdDate: null,
                updatedDate: null,
                share_with: null,
              }}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}>
              <AdvanceFilterDropdownBody id={'advanced-search-popup'}>
                <Form.Item label={t('i0017')} name={'mimetype_group_id'}>
                  <Select
                    suffixIcon={<CaretDownOutlined/>}
                    getPopupContainer={() => document.getElementById('advanced-search-popup')}
                    dropdownClassName={'advance-filter-dropdown'}>
                    <Option value={null}>{t('i0018')}</Option>
                    {
                      appConfigStore.mimetypeGroups && appConfigStore.mimetypeGroups.map(item =>
                        <Option key={item.id} value={item.id}>
                          <img src={item.icon} alt={'icon'} width={20} height={20}/>
                          <span>{item.name}</span>
                        </Option>,
                      )
                    }
                  </Select>
                </Form.Item>
                <Form.Item label={t('i0019')} name={'owner'}>
                  <Select
                    suffixIcon={<CaretDownOutlined/>}
                    getPopupContainer={() => document.getElementById('advanced-search-popup')}
                    dropdownClassName={'advance-filter-dropdown'}>
                    <Option value={null}>{t('i0020')}</Option>
                    <Option value={1}>{t('i0021')}</Option>
                    <Option value={2}>{t('i0022')}</Option>
                  </Select>
                </Form.Item>
                <Form.Item label={t('i0023')} name={'storage_type'}>
                  <Select
                    suffixIcon={<CaretDownOutlined/>}
                    getPopupContainer={() => document.getElementById('advanced-search-popup')}
                    dropdownClassName={'advance-filter-dropdown'}>
                    <Option value={null}>{t('i0260')}</Option>
                    <Option value={1}>{t('i0024')}</Option>
                    <Option value={2}>{t('i0025')}</Option>
                    {/*<Option value={3}>{t('i0026')}</Option>*/}
                    {/*<Option value={4}>{t('i0027')}</Option>*/}
                  </Select>
                </Form.Item>
                <Form.Item label={t('i0028')} name={'createdDate'}>
                  <Select
                    onChange={val => onTimeTypeChange(val, 'createdDate')}
                    suffixIcon={<CaretDownOutlined/>}
                    getPopupContainer={() => document.getElementById('advanced-search-popup')}
                    dropdownClassName={'advance-filter-dropdown'}>
                    <Option value={null}>{t('i0029')}</Option>
                    <Option value={1}>{t('i0030')}</Option>
                    <Option value={2}>{t('i0031')}</Option>
                    <Option value={3}>{t('i0032')}</Option>
                    <Option value={4}>{t('i0033')}</Option>
                  </Select>
                </Form.Item>
                {
                  showPickerForCreatedDate && (
                    <Row type={'flex'} gutter={15} justify={'end'}>
                      <Col span={7}/>
                      <Col span={17}>
                        <DateRangeWrapper>
                          <Form.Item label={t('i0034')} name={'createdDate_from'}>
                            <DatePicker
                              getPopupContainer={() => document.getElementById('advanced-search-popup')}
                              suffixIcon={<SVGIcon name={'calendar'}/>}
                              placeholder={t('i0035')}
                              format={'DD/MM/YYYY'}
                              disabledDate={date => filteredDatePicker(date)}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t('i0036')}
                            name={'createdDate_to'}
                            dependencies={['createdDate_from']}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || !getFieldValue('createdDate_from')) {
                                    return Promise.resolve()
                                  }
                                  if (getFieldValue('createdDate_from').isAfter(value, 'day')) {
                                    return Promise.reject(new Error('Ngày bắt đầu phải trước ngày kết thúc!'))
                                  }
                                  return Promise.resolve()
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              getPopupContainer={() => document.getElementById('advanced-search-popup')}
                              suffixIcon={<SVGIcon name={'calendar'}/>}
                              placeholder={t('i0037')}
                              format={'DD/MM/YYYY'}
                              disabledDate={date => filteredDatePicker(date)}
                            />
                          </Form.Item>
                        </DateRangeWrapper>
                      </Col>
                    </Row>
                  )
                }
                <Form.Item label={t('i0038')} name={'updatedDate'}>
                  <Select
                    onChange={val => onTimeTypeChange(val, 'updatedDate')}
                    suffixIcon={<CaretDownOutlined/>}
                    getPopupContainer={() => document.getElementById('advanced-search-popup')}
                    dropdownClassName={'advance-filter-dropdown'}>
                    <Option value={null}>{t('i0039')}</Option>
                    <Option value={1}>{t('i0040')}</Option>
                    <Option value={2}>{t('i0041')}</Option>
                    <Option value={3}>{t('i0042')}</Option>
                    <Option value={4}>{t('i0043')}</Option>
                  </Select>
                </Form.Item>
                {
                  showPickerForUpdatedDate && (
                    <Row type={'flex'} gutter={15} justify={'end'}>
                      <Col span={7}/>
                      <Col span={17}>
                        <DateRangeWrapper>
                          <Form.Item label={t('i0044')} name={'updatedDate_form'}>
                            <DatePicker
                              getPopupContainer={() => document.getElementById('advanced-search-popup')}
                              suffixIcon={<SVGIcon name={'calendar'}/>}
                              placeholder={t('i0045')}
                              format={'DD/MM/YYYY'}
                              disabledDate={date => filteredDatePicker(date)}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t('i0046')}
                            name={'updatedDate_to'}
                            dependencies={['updatedDate_form']}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || !getFieldValue('updatedDate_form')) {
                                    return Promise.resolve()
                                  }
                                  if (getFieldValue('updatedDate_form').isAfter(value, 'day')) {
                                    return Promise.reject(new Error('Ngày bắt đầu phải trước ngày kết thúc!'))
                                  }
                                  return Promise.resolve()
                                },
                              }),
                            ]}>
                            <DatePicker
                              getPopupContainer={() => document.getElementById('advanced-search-popup')}
                              suffixIcon={<SVGIcon name={'calendar'}/>}
                              placeholder={t('i0047')}
                              format={'DD/MM/YYYY'}
                              disabledDate={date => filteredDatePicker(date)}
                            />
                          </Form.Item>
                        </DateRangeWrapper>
                      </Col>
                    </Row>
                  )
                }
                <Form.Item label={t('i0048')} name={'share_with'}>
                  <StyledTagsSelect>
                    <Select
                      ref={inputSearchRef}
                      className={'custom-tags-select'}
                      value={newSharingUsername}
                      filterOption={false}
                      optionFilterProp={'name'}
                      notFoundContent={
                        (keywordSearchUser && keywordSearchUser?.length > 0)
                          ? EmptySearchResult
                          : null
                      }
                      onChange={handleSelectUserForSharing}
                      onSearch={handleSearchNewSharingUsername}
                      onBlur={handleCloseUserSharedDropdown}
                      onInputKeyDown={handleOnEnter}
                      getPopupContainer={() => document.getElementById('advanced-search-popup')}
                      mode={'multiple'} placeholder={t('i0355')}>
                      {
                        userForShare && userForShare.length > 0 && userForShare.map(user => (
                          <Option key={user.id} name={user.username} value={user.username}>
                            <SharingUserInfo>
                              <UserAvatar>
                                {
                                  user.avatar && user.avatar.length > 0 && user.type !== 'group'
                                    ? <img src={user.avatar} alt="avatar"/>
                                    : <DefaultAvatar username={user.name}/>
                                }
                              </UserAvatar>
                              <UserInfo>
                                <p>{user.name || t('i0356')}</p>
                                <p>{user.type !== 'group' ? (user.username || t('i0356')) : (`${user.username} ${t('i0675')}`)}</p>
                              </UserInfo>
                            </SharingUserInfo>
                          </Option>
                        ))
                      }
                    </Select>
                  </StyledTagsSelect>
                </Form.Item>
              </AdvanceFilterDropdownBody>
              <AdvanceFilterDropdownFooter>
                <Button className={'gray-btn'} onClick={handleResetFilter}>{t('i0050')}</Button>
                <Button type={'primary'} htmlType={'submit'}>{t('i0051')}</Button>
              </AdvanceFilterDropdownFooter>
            </Form>
          </AdvanceFilterFormWrapper>
        )
      }
    </AdvanceFilterDropdownWrapper>
  )
}

AdvanceFilterDropdown.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultKeyword: PropTypes.string,
  children: PropTypes.node,
}

export default inject(
  'searchStore',
  'appConfigStore',
  'shareStore',
  'commonStore',
)(observer(AdvanceFilterDropdown))
