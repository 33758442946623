import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AuthLayout from '../../layouts/AuthLayout'
import LoginForm from './LoginForm'
import Helmet from 'react-helmet/es/Helmet'
import RegisterForm from './RegisterForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import { useTranslation } from 'react-i18next'
import OtpPage from './OtpInputForm'

const AuthModule = props => {

  const { match } = props
  const { authType } = match.params
  const { t } = useTranslation()

  const [pageTitle, setPageTitle] = useState('')
  const [content, setContent] = useState(null)

  const renderFormContent = useCallback(() => {
    switch (authType) {
      case 'forgot-password':
        setPageTitle(t('i0456'))
        setContent(<ForgotPasswordForm />)
        return
      case 'login':
        setPageTitle(t('i0457'))
        setContent(<LoginForm />)
        return
      case 'register':
        setPageTitle(t('i0458'))
        setContent(<RegisterForm />)
        return
      case 'otp':
        setPageTitle('OTP')
        setContent(<OtpPage />)
        return
      default:
        return
    }
  }, [authType])

  useEffect(() => {
    renderFormContent()
  }, [authType])

  return (
    <AuthLayout>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {content}
    </AuthLayout>
  )
}

AuthModule.propTypes = {
  children: PropTypes.node,
}

export default AuthModule
