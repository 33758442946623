import styled from 'styled-components'

export const HelpPageWrapper = styled.div``
export const HelpNavBar = styled.div`
  background: url(${props => props.background}) no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 213px;
  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }

  .header-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`
export const HelpNavBarTitle = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin-bottom: 32px;
`
export const HelpNavBarSearch = styled.div`
  position: relative;
  height: 50px;

  .svgicon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .ant-input {
    padding-left: 48px;
    font-size: 14px;
    height: 50px;
    border-radius: 8px;

    &::placeholder {
      color: #A7A7A7;
    }
  }
`
export const QuestionListWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
  padding: 30px 15px 155px;

  .title {
    color: #313131;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }
`