import styled from 'styled-components'

export const SectionHeading = styled.h2`
  color: #313131;
  font-size: 16px;
  line-height: 18.75px;
  margin-bottom: 16px;
`
export const DeleteNotice = styled.p`
  color: #313131;
  font-size: 16px;
  line-height: 1.6;
  padding-bottom: 18px;
  border-bottom: 1px dashed #E2E2E2;
  margin-bottom: 18px;

  a {
    color: #2F80ED;
  }
`
export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  .ant-btn-primary{
    color: #ffffff !important;
    background-color: #1890ff !important;
  }
`
