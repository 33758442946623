import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import { GroupManageUsersListWrapper, GroupUserAvatarMore, GroupUserAvatarWrapper } from './GroupManageUsersListStyled'

const GroupManageUsersList = props => {
  const { usersList, andMoreNumber } = props
  return (
    usersList && usersList?.length > 0
      ?
      <GroupManageUsersListWrapper>
        {
          usersList.map((item, index) => {
              if (index < 5) {
                return (
                  <GroupUserAvatarWrapper key={index}>
                    {
                      item?.avatar
                        ?
                        <img src={item?.avatar} alt={item?.display_name} width={32} height={32} />
                        :
                        <Avatar size={32}>{item?.display_name?.charAt(0)?.toUpperCase()}</Avatar>
                    }

                  </GroupUserAvatarWrapper>
                )
              }
            },
          )
        }
        {
          andMoreNumber && <GroupUserAvatarMore>{`+${andMoreNumber}`}</GroupUserAvatarMore>
        }

      </GroupManageUsersListWrapper>
      :
      <>
        {/*Nội dung khi ko có user nào trong nhóm*/}
      </>
  )
}

GroupManageUsersList.propTypes = {
  usersList: PropTypes.array,
  andMoreNumber: PropTypes.number,
}

export default GroupManageUsersList