import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import { CONTENT_TYPE } from '../utils/constant'

const source = axios.CancelToken.source()

export const MyDriveRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getMyDriveList: payload => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/folders/${(payload?.parent_id && payload?.parent_id !== 'root') ? payload?.parent_id : ''}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        parent_id: payload?.parent_id !== 'root' ? payload?.parent_id : null,
        content_type: CONTENT_TYPE.myDrive,
        page: payload.page,
        per_page: payload.per_page,
        ...(payload.sort && { sort: payload.sort }),
        ...(payload.sort_by && { sort_by: payload.sort_by }),
      },
    })
  },
  getMyDriveListScroll: payload => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/folders/${(payload?.parent_id && payload?.parent_id !== 'root') ? payload?.parent_id : ''}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        parent_id: payload?.parent_id !== 'root' ? payload?.parent_id : null,
        content_type: CONTENT_TYPE.myDrive,
        page: payload.page,
        per_page: payload.per_page,
        ...(payload.sort && { sort: payload.sort }),
        ...(payload.sort_by && { sort_by: payload.sort_by }),
      },
      disableSpinner: true,
    })
  },

  getMyDriveListUploadDone: payload => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/folders/${(payload?.parent_id && payload?.parent_id !== 'root') ? payload?.parent_id : ''}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      disableSpinner: true,
      params: {
        parent_id: payload?.parent_id !== 'root' ? payload?.parent_id : null,
        content_type: CONTENT_TYPE.myDrive,
        page: payload.page,
        per_page: payload.per_page,
        ...(payload.sort && { sort: payload.sort }),
        ...(payload.sort_by && { sort_by: payload.sort_by }),
      },
    })
  },

  createFolder: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/folders/create`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
    })
  },
  trashDriveItem: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/trash`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        list_id: payload,
      },
    })
  },
  renameDriveItem: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/${payload.node_id}/rename`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
    })
  },
  getBreadcrumb: (folder_id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/folders/${folder_id}/full-path`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },
}