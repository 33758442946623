import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, message } from 'antd'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { FormWrapper, LogoWrapper, OTPInputWrapper } from '../../../layouts/AuthLayout/AuthLayoutStyled'
import validator from '../../../validator'
import { useTranslation } from 'react-i18next'

const ForgotPasswordForm = props => {
  const { authenticationStore } = props
  const [processStep, setProcessStep] = useState(0)
  const [forgotPassUserName, setForgotPassUserName] = useState(null)
  const [forgotPassUserId, setForgotPassUserId] = useState(null)
  const [isTimerOver, setIsTimerOver] = useState(true)
  const [secondsCountdown, setSecondsCountdown] = useState(0)

  const { t } = useTranslation()
  const [loginForm] = Form.useForm()
  const history = useHistory()

  const onForgotPassword = formData => {
    authenticationStore.forgotPassword(formData.username)
      .then(response => {
        if (response.error_code === 0) {
          setForgotPassUserName(formData.username.replace(formData.username.substr(3, 3), 'xxx'))
          setForgotPassUserId(response.data.user_id)
          setProcessStep(1)
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }
  const handleSubmitOtp = (formData) => {
    authenticationStore.verifyOtpForgotPassword(forgotPassUserId, formData.otp, 1)
      .then(response => {
        switch (response.error_code) {
          case 0:
            let payload = {
              user_id: forgotPassUserId,
              session_key: response.data?.session_key,
              password: formData.password,
              password_confirmation: formData.password_confirmation,
            }
            authenticationStore.saveNewPassword(payload)
              .then(response => {
                if (response.error_code === 0) {
                  history.push('/auth/login')
                  message.success(t('i0410'))
                } else {
                  message.error(response.message)
                }
              })
              .catch(error => error?.response?.status != 401 && message.error(error.message))
            break
          case 6:
            loginForm.resetFields()
            setProcessStep(0)
            message.error(response.message)
            break
          default:
            message.error(response.message)
            break
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }
  const handleClickBackLogin = () => {
    history.push('/auth/login')
  }
  const getNewOTP = () => {
    setIsTimerOver(false)
    setSecondsCountdown(60)
    authenticationStore.resendOtp(forgotPassUserId)
      .then((response) => {
        if (response.error_code !== 0) {
          message.error(response.message)
        } else {
          message.success('Đã gửi lại mã OTP')
        }
      })
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      if (isTimerOver) return
      if (secondsCountdown === 1) {
        setIsTimerOver(true)
        return
      }
      setSecondsCountdown(secondsCountdown - 1)
    }, 1000)
    return () => clearInterval(timerID)
  })

  return (
    <FormWrapper>
      <img src={`${process.env.PUBLIC_URL}/assets/icons/backleft.svg`}
        width={30} height={30}
        className={'back-login-icon'}
        onClick={handleClickBackLogin} alt={''} />
      <LogoWrapper>
        <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_register.png`} alt={''} />
      </LogoWrapper>

      {
        processStep === 0 &&
        <Form
          form={loginForm}
          style={{ marginTop: '60px' }}
          layout={'vertical'}
          onFinish={onForgotPassword}
          scrollToFirstError
          requiredMark={false}
          colon={false}
        >
          <Form.Item
            name={'username'}
            label={t('i0411')}
            autoComplete='off'
            rules={[
              { required: true, message: t('i0412') },
              { validator: validator.validatePhoneNumber },
            ]}
          >
            <Input placeholder={t('i0413')} className={'register-input'} />
          </Form.Item>
          <Button type={'primary'} htmlType={'submit'} block className={'register-btn'}>
            {t('i0414')}
          </Button>
        </Form>
      }
      {
        processStep === 1 && <>
          <Form
            form={loginForm}
            style={{ marginTop: '60px' }}
            layout={'vertical'}
            onFinish={handleSubmitOtp}
            scrollToFirstError
            requiredMark={false}
            colon={false}
          >
            <OTPInputWrapper>
              <Form.Item
                name={'otp'}
                label={`${t('i0415')} ${forgotPassUserName}`}
                rules={[
                  { required: true, message: t('i0416') },
                ]}
              >
                <Input placeholder={t('i0417')} className={'register-input'} />
              </Form.Item>
              <a className={`otp-req-btn ${!isTimerOver && 'disabled'}`} onClick={getNewOTP}>
                {
                  isTimerOver
                    ? t('i0418')
                    : `${t('i0418')} (${secondsCountdown}s)`
                }
              </a>
            </OTPInputWrapper>
            <Form.Item
              name={'password'}
              label={t('i0419')}
              rules={[
                { required: true, message: t('i0420') },
                { validator: validator.validatePasswordNew },
              ]}
            >
              <Input.Password placeholder={t('i0421')} maxLength={19} className={'register-input'} />
            </Form.Item>
            <Form.Item
              name={'password_confirmation'}
              label={t('i0422')}
              rules={[
                { required: true, message: t('i0423') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('i0424')))
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t('i0425')} className={'register-input'} />
            </Form.Item>
            <Button type={'primary'} htmlType={'submit'} block className={'register-btn'}>
              {t('i0426')}
            </Button>
          </Form>
        </>
      }
    </FormWrapper>
  )
}

ForgotPasswordForm.propTypes = {
  children: PropTypes.node,
  authenticationStore: PropTypes.object,
}

export default inject(
  'authenticationStore',
)(observer(ForgotPasswordForm))
