import styled from 'styled-components'

export const ModalHeader = styled.p`
  text-align: center;
  color: #313131;
  font-size: 1.6rem;
  font-weight: 500;
  position: relative;
  top: -8px;
`
export const InfoHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  span {
    margin-left: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 400px;
  }
`
export const InfoDetail = styled.dl`
  display: flex;
  flex-wrap: wrap;

  dt {
    width: 120px;
    color: #8C8C8C;
  }

  dd {
    color: #313131;
    width: calc(100% - 120px);
  }

  dt, dd {
    margin-bottom: 5px;
    font-size: 14px;
  }
`
export const ExtraSharedList = styled.ul`
  margin-bottom: 0;
  padding-right: 12px;
  width: auto;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    figure {
      margin: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      border: ${props => props.border};
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    span {
      color: #FAFAFB;
      font-size: 14px;
      line-height: 24px;
    }
  }
`
export const SharingDetail = styled.section`
  .heading {
    font-size: 16px;
    color: #313131;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .sharing-list {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 57px;
      height: 57px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 16px;
      border: 1px solid #E2E2E2;

      &:nth-child(7n) {
        margin-right: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &.counter {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #237BD3;
        font-size: 16px;
        transition: all ease .3s;

        &:hover {
          border-color: #237BD3;
          background-color: #EDF6FF;
          box-shadow: 0 2px 6px rgba(117, 205, 255, 0.31);
          transition: all ease .3s;
        }
      }
    }
  }

  .sharing-action {
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      figure {
        margin: 0;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .desc {
        margin-left: 10px;
        width: calc(100% - 42px);

        p {
          color: #313131;
          font-size: 14px;
          margin-bottom: 0;
          line-height: 16.41px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          strong {
            font-weight: bold;
          }
        }

        time {
          color: #8C8C8C;
          font-size: 12px;
          line-height: 14.06px;
        }
      }
    }
  }
`