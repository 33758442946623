import React, { useState } from 'react'
import { Button, message, Progress, Tooltip } from 'antd'
import {
  CloseButton,
  IconCancelGroup,
  IconSuccessGroup,
  IconUploadProgressGroup,
  ModalConfirmWrapper,
  ModalContent,
  ModalHeader,
  ModalTimeText,
  ModalTotalText,
  ModalWrapper,
  ProgressItem,
  ProgressItemText,
} from './ModalProgressStyled'
import { CloseOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import SVGIcon from '../SVGIcon/SVGIcon'
import fileUtils from '../../utils/fileUtils'
import axios from 'axios'
import { CONTENT_TYPE } from '../../utils/constant'
import { useTranslation } from 'react-i18next'

const ModalProgress = (props) => {

  const { uploadStore, appConfigStore, myDriveStore, profileStore, commonStore } = props
  const { visibleModalProgress, fileListUpload, progressUpload, statusUpload, messageUpload, completeAll } = uploadStore
  const { iconsByMimeTypeObj, systemConfig, mimeTypesByExtension } = appConfigStore
  const { isMenubarHover } = commonStore
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const { t } = useTranslation()

  const cancelUpload = (cancelSource, currentFile) => {
    uploadStore.updateStatusFile(currentFile.uid, 'cancelled')
    uploadStore.handleMessageUpload(currentFile.uid, t('i0676'))
    if (cancelSource) {
      cancelSource.cancel('Cancelled by user')
    }
  }
  const handleUploadProgress = (progressEvent) => {
  }
  const reloadData = (parent_id) => {
    let payload = {
      parent_id: parent_id,
      content_type: CONTENT_TYPE.myDrive,
      page: 1,
      per_page: myDriveStore.perPage,
      sort: myDriveStore.myDriveSort,
      sort_by: myDriveStore.myDriveSortBy,
    }
    myDriveStore.getMyDriveListUploadDone(payload).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  ///Upload File
  const singleUpload = async (file, parent_id) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    file.cancelSource = source
    if (uploadStore.statusUpload[file.uid] !== 'cancelled' && uploadStore.statusUpload[file.uid] !== 'error') {
      if (file.size > systemConfig?.max_upload) {
        message.error(t('i0270'))
        uploadStore.updateStatusFile(file.uid, 'cancelled')
        return await Promise.all([])
      } else {
        let extension = fileUtils.getFileExtension(file.name)
        let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']
        const payload = {
          mimetype: mimetype,
          name: file.name,
          parent_id: parent_id,
          capacity: file.size,
        }
        let activeFile
        ///Get Link
        const getLinkUpload = await uploadStore.getUploadLink(payload)
          .then(res => {
            if (res.error_code === 0) {
              return res.data
            } else {
              uploadStore.handleMessageUpload(file.uid, res.message)
              uploadStore.updateStatusFile(file.uid, 'error')
            }
          })
          .catch(error => {
            uploadStore.handleMessageUpload(file.uid, error.message)
            uploadStore.updateStatusFile(file.uid, 'error')
          })

        ///Upload to s3
        if (getLinkUpload && getLinkUpload.url) {
          const uploadToS3 = await uploadStore.uploadFiles(getLinkUpload.url, file, handleUploadProgress)
            .then(res => {
              ////Active file
              activeFile = uploadStore.activeUploadFiles({
                node_id: getLinkUpload.node_id,
                mimetype: mimetype,
              }).then(response => {
                uploadStore.updateStatusFile(file.uid, 'success')
                reloadData()
                profileStore.getMyProfileNotShowSpinner()
                return response.data
              }).catch(error => {
                uploadStore.updateStatusFile(file.uid, 'error')
                uploadStore.handleMessageUpload(file.uid, error.message)
                return error
                //error?.response?.status != 401 && message.error(error.message)
              })
            }).catch(error => {
              if (axios.isCancel(error)) {
                uploadStore.handleMessageUpload(file.uid, t('i0676'))
                uploadStore.updateStatusFile(file.uid, 'cancelled')
              } else {
                uploadStore.handleMessageUpload(file.uid, error.message)
                uploadStore.updateStatusFile(file.uid, 'error')
              }
              //error?.response?.status != 401 && message.error(error.message)
            })
          return await Promise.all([getLinkUpload, uploadToS3, activeFile])
        } else {
          return await Promise.all([getLinkUpload])
        }
      }
    } else {
      return await Promise.all([])
    }
  }
  ///Upload folder
  const uploadFolder = (children, parent_id, rootId, folder) => {
    children.forEach(async (item, index) => {
      if (item.type === 'file' &&
        uploadStore.statusUpload[rootId] !== 'error' &&
        uploadStore.statusUpload[rootId] !== 'cancelled') {
        let file = item.file
        file.cancelSource = folder.cancelSource
        let extension = fileUtils.getFileExtension(file.name)
        let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']
        let payload = { mimetype: mimetype, name: file.name, parent_id: parent_id, capacity: file.size }
        if (!file.id) {
          ///GetLink
          await uploadStore.getUploadLink(payload)
            .then(res => {
              if (res.error_code === 0) {
                ///upload s3
                if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
                  uploadStore.uploadFiles(res.data.url, file).then(response => {
                    ////Active file
                    if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
                      uploadStore.activeUploadFiles({ node_id: res.data.node_id, mimetype: mimetype }).then(response => {
                        file.id = response?.data.id
                        ///uploadStore.updateFileUpload(folder)
                        uploadStore.updateFileUpload(folder, 1, (percent) => {
                          if (percent === 100) {
                            setTimeout(() => {
                              reloadData()
                              profileStore.getMyProfileNotShowSpinner()
                            }, 3000)
                          }
                        })
                        return response.data
                      }).catch(error => {
                        uploadStore.updateStatusFile(rootId, 'error')
                        error?.response?.status != 401 && message.error(error.message)
                      })
                    }
                  }).catch(error => {
                    uploadStore.updateFileUpload(folder, 0, () => {
                    })
                    if (axios.isCancel(error)) {
                      uploadStore.updateStatusFile(rootId, 'cancelled')
                      uploadStore.handleMessageUpload(rootId, t('i0676'))
                      reloadData()
                    } else {
                      uploadStore.handleMessageUpload(rootId, error.message)
                      uploadStore.updateStatusFile(rootId, 'error')
                      reloadData()
                    }
                  })
                }
                return res.data
              } else {
                uploadStore.handleMessageUpload(file.uid, res.message)
                uploadStore.updateStatusFile(rootId, 'error')
                reloadData()
                uploadStore.reSetFileList()
              }
            })
            .catch(error => {
              uploadStore.handleMessageUpload(rootId.uid, error.message)
              uploadStore.updateStatusFile(rootId, 'error')
            })
        } else {
          uploadStore.updateFileUpload(folder, 0, (percent) => {
            if (percent === 100) {
              setTimeout(() => {
                reloadData()
                profileStore.getMyProfileNotShowSpinner()
              }, 3000)
            }
          })
        }
      } else if (item.type === 'directory' &&
        uploadStore.statusUpload[rootId] !== 'error'
        && uploadStore.statusUpload[rootId] !== 'cancelled') {
        let params = {
          parent_id: parent_id,
          name: item.name,
        }
        if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
          if (!item.id) {
            uploadStore.createFolderUpload(params, folder.cancelSource)
              .then(async response => {
                if (response.error_code !== 0) {
                  message.error(response.message)
                } else {
                  item.id = response?.data.id
                  children[index] = item
                  await uploadStore.updateFileUpload(folder, 0, () => {
                  })
                  uploadFolder(item?.children, response?.data?.id, rootId, folder)
                }
              })
              .catch(error => {
                uploadStore.updateFileUpload(folder, 0, () => {
                })
                if (axios.isCancel(error)) {
                  //uploadStore.cancelUploadFolder(folder.id)
                  uploadStore.updateStatusFile(rootId, 'cancelled')
                  uploadStore.handleMessageUpload(rootId, t('i0676'))
                  reloadData()
                } else {
                  uploadStore.handleMessageUpload(rootId, error.message)
                  uploadStore.updateStatusFile(rootId, 'error')
                }

              })
          } else {
            uploadFolder(item?.children, item.id, rootId, folder)
          }
        }
      }
    })
  }
  const handleSyncDataDriveToMobiDrive = async (file) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    file.cancelSource = source
    if (uploadStore.statusUpload[file.uid] !== 'cancelled' && uploadStore.statusUpload[file.uid] !== 'error') {
      return await uploadStore.syncToMobifoneDrive(file).then(res => {
        if (res.error_code === 0) {
          uploadStore.updateStatusFile(file.uid, 'success')
          //reloadData()
          profileStore.getMyProfileNotShowSpinner()
        } else {
          uploadStore.handleMessageUpload(file.uid, res.message)
          uploadStore.updateStatusFile(file.uid, 'error')
        }
        return res.data
      }).catch(error => {
        if (axios.isCancel(error)) {
          uploadStore.handleMessageUpload(file.uid, t('i0676'))
          uploadStore.updateStatusFile(file.uid, 'cancelled')
        } else {
          uploadStore.handleMessageUpload(file.uid, error.message)
          uploadStore.updateStatusFile(file.uid, 'error')
        }
        return error
      })
    } else {
      return true
    }
  }
  const handleReload = async (file) => {
    uploadStore.updateStatusFile(file.uid, 'uploading')
    if (file.driver === 'google_drive' || file.driver === 'one_drive') {
      handleSyncDataDriveToMobiDrive(file)
    } else {
      if (file.type === 'directory') {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        let params = {
          parent_id: file?.parent_id,
          name: file.name,
        }
        file.cancelSource = source
        if (file.id) {
          uploadFolder(file.children, file.id, file.uid, file)
        } else {
          uploadStore.createFolderUpload(params, source)
            .then(response => {
                if (response.error_code !== 0) {
                  message.error(response.message)
                  uploadStore.updateFileUpload(file, 0, () => {
                  })
                } else {
                  file.id = response?.data.id
                  uploadStore.updateFileUpload(file, 0, () => {
                  })
                  /// uploadStore.resetFolder(file.uid, file)
                  uploadFolder(file.children, response?.data.id, file.uid, file)
                }
              },
            ).catch(error => {
            uploadStore.updateFileUpload(file, 0, () => {
            })
            error?.response?.status != 401 && message.error(error.message)
          })
        }

      } else {
        await singleUpload(file)
      }
    }
  }
  const handleCancelAll = () => {
    if (Object.values(statusUpload).indexOf('uploading') > -1) {
      setVisibleConfirmModal(true)
    } else {
      uploadStore.cancelRequest()
      uploadStore.reSetFileList()
      uploadStore.setVisibleModalProgress(false)
    }

  }
  const onCancelUploadAll = () => {
    if (statusUpload) {
      Object.keys(statusUpload).map(key => {
        if (statusUpload[key] !== 'success') {
          statusUpload[key] = 'cancelled'
          uploadStore.handleMessageUpload(key, t('i0676'))
        }
      })
    }
    uploadStore.cancelAllUpload(statusUpload)
    uploadStore.cancelRequest()
    uploadStore.reSetFileList()
    uploadStore.setVisibleModalProgress(false)
    setVisibleConfirmModal(false)
  }
  const onCloseModalConfirm = () => {
    setVisibleConfirmModal(false)
  }

  const renderIcon = (status, percent, cancelSource, currentFile, message) => {

    if (status === 'success') {
      return (
        <IconSuccessGroup>
          <Button className={'btn-success-status'}
                  type='primary'
                  shape='circle'
                  icon={<SVGIcon name={'success'} width={18} height={18} />}
          />
        </IconSuccessGroup>
      )
    } else if (status === 'error') {
      return (
        <IconSuccessGroup>
          <span>{message}</span>
          <Button className={'btn-success-status'}
                  type='primary'
                  shape='circle'
                  icon={<SVGIcon name={'warning'} width={18} height={18} />}
          />
        </IconSuccessGroup>
      )
    } else if (status === 'cancelled') {
      return (
        <IconCancelGroup>
          <span>{message}</span>
          <Tooltip placement='bottom' title={t('i0682')}>
            <Button className={'btn-reload-upload'}
                    onClick={() => handleReload(currentFile)}
                    type='primary'
                    shape='circle'
                    icon={<SVGIcon name={'reload'} width={18} height={18} />}
            />
          </Tooltip>
          <Button className={'btn-status-canceled'}
                  type='primary'
                  shape='circle'
                  icon={<SVGIcon name={'warning'} width={18} height={18} />}
          />

        </IconCancelGroup>
      )
    } else if (status === 'uploading') {
      return (
        <IconUploadProgressGroup>
          <Button className={'btn-progress'} type='primary' shape='circle'
                  icon={
                    <Progress type='circle'
                              trailColor={'#2C2C2C'}
                              strokeColor={'#FFFFFF'} status={status}
                              strokeWidth={10} showInfo={false} percent={percent} width={15}
                    />
                  }
          >
          </Button>
          <Tooltip placement='bottom' title={t('i0679')}>
            <Button className={'btn-cancel'}
                    type='primary'
                    shape='circle'
                    onClick={() => cancelUpload(cancelSource, currentFile)}
                    icon={<SVGIcon name={'huy'} width={18} height={18} />}
            />
          </Tooltip>
        </IconUploadProgressGroup>
      )
    } else {
      return (
        <IconUploadProgressGroup>
          <Button className={'btn-progress'} type='primary' shape='circle'
                  icon={
                    <Progress type='circle'
                              trailColor={'#2C2C2C'}
                              strokeColor={'#FFFFFF'} status={status}
                              strokeWidth={10} showInfo={false} percent={percent} width={15}
                    />
                  }
          >
          </Button>
          <Tooltip placement='bottom' title={t('i0679')}>
            <Button className={'btn-cancel'}
                    type='primary'
                    shape='circle'
                    onClick={() => cancelUpload(cancelSource, currentFile)}
                    icon={<SVGIcon name={'huy'} width={18} height={18} />}
            />
          </Tooltip>
        </IconUploadProgressGroup>
      )
    }
  }

  const renderHeaderText =()=> {
    let text = t('i0706')
    // let inCompleteList = []
    let inCancelled = []
    Object.keys(statusUpload).map(key => {
      if(statusUpload[key] !== 'uploading' && statusUpload[key] !== 'success') {
        inCancelled.push(key)
      }
    });
    let countItem = (fileListUpload?.length - inCancelled?.length)
    return text  + ': ' + (countItem < 0 ? 0 : countItem);
  }

  return (
    <>
      <ModalWrapper {...props} visible={visibleModalProgress} isMenubarHover={isMenubarHover}>
        <ModalHeader>
          <ModalTotalText>
            {
              uploadStore.notEnoughCapacity ?
                t('i0717')
                : completeAll
                ? renderHeaderText()
                : uploadStore.keyUpload === 'sync_data'
                  ? `${t('i0707')} ${fileListUpload?.length} ${t('i0708')}`
                  : `${t('i0273')} ${fileListUpload?.length} ${uploadStore.keyUpload === 'uploadFolder' ? t('i0274') : t('i0275')}`
            }
          </ModalTotalText>
          <ModalTimeText>
            {/*{keyUpload !== 'uploadFolder' ? '...' : ''}*/}
          </ModalTimeText>
          <CloseButton shape='circle' size='small' onClick={() => {
            handleCancelAll()
          }}>
            <CloseOutlined  />
          </CloseButton>
        </ModalHeader>
        <ModalContent noPadding={uploadStore.notEnoughCapacity}>
          {fileListUpload?.map((item, index) => (
            <ProgressItem key={index}>
              {item.type !== 'directory' ?
                <SVGIcon url={iconsByMimeTypeObj[item.mimetype] || iconsByMimeTypeObj['default']} width={18}
                         height={18} /> :
                <SVGIcon name={'folder-upload'} width={18} height={18} />
              }
              <ProgressItemText>
                {item.type === 'directory' ? <><span>{item.name}</span></> : item.name}
              </ProgressItemText>
                {item.type === "directory" && <span
                  style={{ marginLeft: 10 }}> ({item.totalDone || 0} / {item.totalFile}) </span>}
              {renderIcon(statusUpload[item.uid], progressUpload[item.uid], item.cancelSource, item, messageUpload[item.uid])}
            </ProgressItem>
          ))}
        </ModalContent>
      </ModalWrapper>
      <ModalConfirmWrapper
        title={t('i0679')}
        visible={visibleConfirmModal}
        footer={null}
        onCancel={onCloseModalConfirm}
      >
        <p>{t('i0680')}</p>
        <div className={'btn-group'}>
          <Button type={'primary'} onClick={onCloseModalConfirm}>{t('i0681')}</Button>
          <Button type={'default'} onClick={onCancelUploadAll}>{t('i0679')}</Button>
        </div>
      </ModalConfirmWrapper>
    </>
  )
}

export default inject(
  'commonStore',
  'uploadStore',
  'appConfigStore',
  'myDriveStore',
  'profileStore',
)(observer(ModalProgress))
