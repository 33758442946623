import React from 'react'
import PropTypes from 'prop-types'
import { ContentWrapper, LayoutWrapper, ProgressWrapper } from './DefaultLayoutStyled'
import MainHeaderBar from '../../components/MainHeaderBar'
import MainSidebar from '../../components/MainSidebar'
import ModalProgress from '../../components/ModalProgress'
import MobileMenuDrawer from '../../components/MobileMenuDrawer'
import ModalDownloadProgress from '../../components/ModalDownloadProgress'
import { notification } from 'antd'
import { useMediaQuery } from 'react-responsive/src'

const DefaultLayout = props => {
  const { children } = props
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <>
      {
        isMobile
          ? <MobileMenuDrawer />
          : (
            <>
              <MainHeaderBar />
              <MainSidebar />
            </>
          )
      }
      <LayoutWrapper isMobile={isMobile}>
        <ContentWrapper isMobile={isMobile}>
          {children}
        </ContentWrapper>
        <ProgressWrapper >
          <ModalProgress />
          <ModalDownloadProgress />
        </ProgressWrapper>
      </LayoutWrapper>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
}

export default DefaultLayout

