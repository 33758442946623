import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Input, message } from 'antd'
import {
  HelpNavBar,
  HelpNavBarSearch,
  HelpNavBarTitle,
  HelpPageWrapper,
  QuestionListWrapper,
} from './CommonQuestionListPageStyled'
import QuestionList from '../../components/QuestionList'
import SvgIcon from '../../components/SVGIcon'
import PageResult from '../../components/PageResult'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CommonQuestionListPage = props => {
  const { commonQuestionStore } = props
  const { questionList } = commonQuestionStore

  const history = useHistory()
  const { t } = useTranslation()

  const [searchKeyword, setSearchKeyword] = useState(null)
  const [listQuestion, setListQuestion] = useState([])

  const handleSearch = () => {
    if (questionList && searchKeyword) {
      let newQuestionList = questionList.filter(item => item?.question.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)
      setListQuestion(newQuestionList)
    } else {
      setListQuestion(questionList)
    }
  }
  const handleChangeSearchKeyword = (e) => {
    setSearchKeyword(e.currentTarget.value)
  }

  useEffect(() => {
    commonQuestionStore.getCommonQuestionList().then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }, [])
  useEffect(() => {
    if (!questionList) return
    let listQuestion = []
    listQuestion = questionList.map((item, key) => {
      item.key = key + 1
      item.id = key + 1
      return item
    })
    setListQuestion(listQuestion)
  }, [questionList])
  useEffect(() => {
    if (localStorage.getItem('jwt') !== 'undefined') return
    history.push('/auth/login', { from: props.location })
  }, [])

  return (
    <HelpPageWrapper>
      <Helmet>
        <title>{t('i0477')}</title>
      </Helmet>
      <HelpNavBar background={`${process.env.PUBLIC_URL}/assets/backgrounds/ambient-blue-bg1.png`}>
        <div className={'header-container'}>
          <HelpNavBarTitle>
            {t('i0476')}
          </HelpNavBarTitle>
          <HelpNavBarSearch>
            <SvgIcon name={'search'} />
            <Input
              size={'large'}
              placeholder={t('i0478')}
              onPressEnter={handleSearch}
              onChange={handleChangeSearchKeyword} />
          </HelpNavBarSearch>
        </div>
      </HelpNavBar>
      <QuestionListWrapper>
        <h3 className={'title'}>{searchKeyword ? t('i0479') : t('i0480')} </h3>
        {listQuestion.length < 1 && searchKeyword ? <PageResult type={'search_empty_help-page'} /> :
          <QuestionList listQuestion={listQuestion} />}

      </QuestionListWrapper>
    </HelpPageWrapper>
  )
}

export default inject(
  'commonQuestionStore',
)(observer(CommonQuestionListPage))