import { action, observable } from 'mobx'
import { ItemDriveRequest } from '../requests/ItemDriveRequest'

class ItemDriveStore {

  @observable itemDriveInfo = null

  @action CancelRequestToken = () => {
    ItemDriveRequest.cancelRequest()
  }

  @action clearItemDriveDetail = () => {
    this.itemDriveInfo = null
  }

  @action setItemDriveInfo = itemInfo => {
    this.itemDriveInfo = itemInfo
  }

  @action getItemDriveDetail = (node_id) => {
    return new Promise((resolve, reject) => {
      ItemDriveRequest.getItemDriveDetail(node_id).then(response => {
        this.setItemDriveInfo(response.data?.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default new ItemDriveStore()
