import React, { Fragment, memo, useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Wrapper } from './NotFoundPageStyled'
import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'

const NotFoundPage = props => {

  const { history } = props
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Helmet>
        <title>404</title>
      </Helmet>
      <Result
        status="404"
        title="404"
        subTitle={t('i0544')}
        extra={
          <Fragment>
            <Button onClick={() => history.goBack()}>
              {t('i0545')}
            </Button>
            <Button type={'primary'} onClick={() => history.push('/')}>
              {t('i0546')}
            </Button>
          </Fragment>
        }
      />
    </Wrapper>
  )
}

export default memo(NotFoundPage)
