import styled from 'styled-components'
import { Modal, Button } from 'antd'


export const ModalWrapper = styled.div`
  //position: relative;
  background-color: #237BD3;
  border-radius: 14px;
  ///bottom: 50px;
    //right: ${props => props.isMenubarHover ? '35px' : '18px'};
  min-width: 450px;
  padding: 15px;
  margin-bottom: 15px;
  display: ${props => props.visible ? 'block' : 'none'}
`
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`
export const ModalTotalText = styled.div`
  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
`
export const ModalTimeText = styled.div`
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.65);
`

export const ModalContent = styled.div`
  padding: ${props => props.noPadding ? '0' : '20px 0 5px 0'} ;
  max-height: 350px;
  overflow: auto;
`

export const CloseButton = styled.div`
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  &:hover svg {
    color: #000000 !important;
  }
`

export const ProgressItem = styled.div`

  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0 0 15px 0;

  span {
    vertical-align: bottom;
  }

`
export const ProgressItemText = styled.span`
  margin-left: 8px;
  display: inline-block;
  width: 220px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

export const ProgressIconRight = styled.span`
  float: right;
  margin-right: 8px;
  cursor: pointer;

`
export const BaseIconGroup = styled.span`
  float: right;
  margin-right: 8px;
  cursor: pointer;
`

export const IconSuccessGroup = styled(BaseIconGroup)`
  .btn-success-status {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }
`

export const IconCancelGroup = styled(BaseIconGroup)`
  display: flex;

  .btn-reload-upload {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-status-canceled {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-reload-upload {
    display: none
  }

  &:hover {
    .btn-reload-upload {
      display: block
    }

    .btn-status-canceled {
      display: none
    }
  }
`

export const IconUploadProgressGroup = styled(BaseIconGroup)`
  display: flex;

  .btn-cancel {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-cancel.ant-btn-icon-only {
    padding: 4px 0;
  !important;
  }

  .btn-progress {
    background-color: #237BD3 !important;
    border: 0px;
    box-shadow: none;
    border-radius: 100%;
    margin-top: -3px;
  }

  .btn-cancel {
    display: none
  }

  &:hover {
    .btn-progress {
      display: none
    }

    .btn-cancel {
      display: block
    }
  }
`
export const ModalConfirmWrapper = styled(Modal)`
  .ant-modal-content {
    .ant-modal-header {
      border-radius: 8px 8px 0px 0px;
      border: 0;

      .ant-modal-title {
        text-align: center;
        color: #313131;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    .ant-modal-body {
      .int-password {

        background: #F9FAFC;
        border: 1px solid #F1F1F5;
        border-radius: 8px;
        padding: 13px 11px;

        &::placeholder {
          color: #A7A7A7;
        }

        .ant-input {
          background-color: transparent;
        }
      }

      .btn-group {
        text-align: center;
        margin-top: 24px;

        .ant-btn {
          margin: 0px 10px;
          border-radius: 8px;
          padding: 0 30px;

        }

        .ant-btn-default {
          background: #E2E2E2;
        }
      }
    }
  }
`