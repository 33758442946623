import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DEBOUNCE_DELAY, EMAIL_REGEX } from '../../utils/constant'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import {
  AudienceRole,
  ButtonRow,
  Divider,
  EditSharingLinkPermissionWrapper,
  LinkSharingSection,
  MemberSharingSection,
  ModalHeading,
  NewUserSharingSection,
  PrivateSharingDropdown,
  SharingLinkInput,
  SharingModalWrapper,
  SharingNotice,
  SharingUserInfo,
  StyledDatePicker,
  StyledInputPassword,
  StyledTagsSelect,
  TimeSharingSection,
  UserAvatar,
  UserInfo,
  UserList,
  UserListItem,
  UserRole,
} from './SharingModalStyled'
import './sharing-modal.less'
import { CaretDownOutlined, CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import SVGIcon from '../SVGIcon'
import moment from 'moment'
import { CustomCustomNotificationMessage, CustomNotificationContent } from '../CommonStyled/CommonStyled'
import { debounce } from 'lodash-es/function'
import PerfectScroll from 'react-perfect-scrollbar'
import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'
import miscUtils from '../../utils/miscUtils'
import DefaultAvatar from '../DefaultAvatar'
import validator from '../../validator'

const { Option } = Select
const { TextArea } = Input

const SharingModal = props => {

  const {
    modalVisible, onCancel, actionType, callbackSharingModal,
    appConfigStore, itemDrive, shareStore, profileStore, selectionStore,
  } = props

  const { t } = useTranslation()

  const { dataSharingPermission } = appConfigStore
  const { userForShare } = shareStore
  const { selectionData } = selectionStore

  const [updateUserForm] = Form.useForm()
  const [insertUserForm] = Form.useForm()
  const [updateLinkForm] = Form.useForm()
  const [updateTimeForm] = Form.useForm()

  const [useSharePassword, setUseSharePassword] = useState(false)
  const [isEditSharingLink, setIsEditSharingLink] = useState(false)
  const [isSecret, setIsSecret] = useState('1')
  const [audienceRole, setAudienceRole] = useState(1)
  const [linkRole, setLinkRole] = useState(1)
  const [isEditSharingTime, setIsEditSharingTime] = useState(false)
  const [timeSharing, setTimeSharing] = useState(null)
  const [newSharingUserIds, setNewSharingUserIds] = useState([])
  const [newSharingUserObjs, setNewSharingUserObjs] = useState([])
  const [enableSendInform, setEnableSendInform] = useState(false)
  const [enableEditSharedUser, setEnableEditSharedUser] = useState(false)
  const [usersEditingPermission, setUsersEditingPermission] = useState([])
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [isInfiniteTimeSharing, setIsInfiniteTimeSharing] = useState(true)
  const [keywordSearchUser, setKeywordSearchUser] = useState('')
  const [isMine, setIsMine] = useState(null)
  const [currentUserPermission, setCurrentUserPermission] = useState(null)
  const [inputPassword, setInputPassword] = useState('')

  const inputSearchRef = useRef()

  const userRoleDropdownMenu = userShared => {
    return (
      <Menu>
        {
          dataSharingPermission.map(permission => (
            <Menu.Item
              onClick={() => handleClickEditPermissionShared(userShared, permission.bit_val)}
              key={permission.bit_val}
            >
              {permission.label}
            </Menu.Item>
          ))
        }
        <Menu.Item
          onClick={() => handleClickEditPermissionShared(userShared, 0)}
          key={0}
        >
          {t('i0334')}
        </Menu.Item>
      </Menu>
    )
  }
  const handleClickEditPermissionShared = (userShared, permissionVal) => {
    let newDataUserShared = shareStore.userShared.map(item => {
      if (item.user_id === userShared.user_id) {
        item.permission = permissionVal
        return item
      } else return item
    })
    shareStore.setUserShared(newDataUserShared)
  }
  const handleCloseModal = () => {
    onCancel()
    setKeywordSearchUser('')
    setUseSharePassword(false)
    setIsEditSharingLink(false)
    setIsSecret(1)
    setAudienceRole(1)
    setIsEditSharingTime(false)
    setTimeSharing(null)
    setNewSharingUserIds([])
    setNewSharingUserObjs([])
    setEnableSendInform(false)
    setEnableEditSharedUser(false)
    setIsInfiniteTimeSharing(true)
    updateUserForm.resetFields()
    shareStore.setUserShared([])
    shareStore.setUserForShare([])
    selectionStore.clearSelectionIndex()
  }
  const renderPermission = roleId => {
    if (roleId === 0) return t('i0651')
    return dataSharingPermission.filter(item => item.bit_val === roleId)[0]?.label
  }
  const renderPermissionLink = roleId => {
    if (roleId === null || roleId === 0) {
      setLinkRole(1)
      return dataSharingPermission.filter(item => item.bit_val === 1)[0]?.label
    }
    return dataSharingPermission.filter(item => item.bit_val === roleId)[0]?.label
  }
  const renderSharedUsers = userList => {
    if (!userList?.length) return t('i0336')
    const str = userList.slice(0, 2).map(item => item?.user?.name).toString().replaceAll(',', ', ')
    if (userList.length === 1 || userList.length === 2) {
      return `${t('i0337')} ${str}`
    }
    return `${t('i0337')} ${str} ${t('i0338')} ${userList.length - 2} ${t('i0339')}`
  }
  const loadShareInfo = () => {
    shareStore.getLinkSharedInfo(itemDrive?.id)
      .then(response => {
        if (response.error_code === 0) {
          setLinkRole(response?.data?.share_permission)
          if (response?.data?.share_expired_at) {
            setIsInfiniteTimeSharing(false)
            setTimeSharing(moment(response?.data?.share_expired_at))
          }
          if (response?.data?.share_password_encrypt) {
            setUseSharePassword(true)
          }
          setIsSecret((response?.data?.is_secret === null || response?.data?.is_secret?.length === 0) ? '1' : response?.data?.is_secret)
          setIsMine(response?.data?.is_mine)
          setCurrentUserPermission(response?.data?.permission)
          updateLinkForm.setFieldsValue({
            sharedUrl: response?.data?.url,
            shareExpiredAt: response?.data?.share_expired_at ? moment(response?.data?.share_expired_at) : null,
            password: response?.data?.share_password_encrypt,
          })
          setInputPassword(response?.data?.share_password_encrypt)
          updateTimeForm.setFieldsValue({
            shareExpiredAt: response?.data?.share_expired_at ? moment(response?.data?.share_expired_at) : null,
          })
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }
  const copyToClipboard = () => {
    const elem = document.createElement('textarea')
    elem.value = updateLinkForm.getFieldValue('sharedUrl')
    document.body.appendChild(elem)
    elem.select()
    document.execCommand('copy')
    document.body.removeChild(elem)
    message.success(t('i0340'))
  }
  const renderSharingInformationText = () => {
    if (isSecret === '1') return t('i0341')
    const bodyText = `${t('i0342')} `
    let actionText = dataSharingPermission.filter(item => item.bit_val === linkRole)[0]?.label?.toLowerCase() || ''
    return `${bodyText}${actionText}`
  }
  const renderSharingTime = () => {
    if (timeSharing === null || !timeSharing) return t('i0343')
    return `${t('i0344')} ${timeSharing.format('DD/MM/YYYY')}`
  }
  const filterPastDate = dateMomentObj => {
    return dateMomentObj.isBefore(moment().utc(), 'days')
  }
  const handleSelectUserForSharing = ids => {
    let selectedUser = toJS(userForShare).filter(x => ids.includes(x.id))
    setNewSharingUserIds(ids)
    setNewSharingUserObjs(toJS(selectedUser))
    setKeywordSearchUser(null)
    inputSearchRef.current.blur()
  }
  const onEnableInformInput = e => {
    setEnableSendInform(e.target.checked)
  }
  // const handleRollback = action => {
  //   notification.close('successShare')
  //   handleCloseModal()
  //   let dataRollback
  //   switch (action) {
  //     case 'insert':
  //       dataRollback = { ...shareStore.insertUserShareRollback }
  //       break
  //     case 'update':
  //       dataRollback = { ...shareStore.updateUserShareRollback }
  //       break
  //     default:
  //       break
  //   }
  //   shareStore.processShare(dataRollback)
  //     .then(response => {
  //       if (response.error_code === 0) {
  //         notification.info({
  //           className: 'custom-notification-share',
  //           closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
  //                           height={15} />,
  //           icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
  //                      height={20} />,
  //           message: <CustomNotificationContent>
  //             <CustomCustomNotificationMessage>
  //               {t('i0347')}
  //             </CustomCustomNotificationMessage>
  //           </CustomNotificationContent>,
  //           description: null,
  //           placement: 'bottomLeft',
  //           duration: 6,
  //         })
  //       } else {
  //         message.error(response.message)
  //       }
  //     })
  //     .catch(error => error?.response?.status != 401 && message.error(error.message))
  // }
  const handleSearchNewSharingUserIds = val => {
    debounceDropDown(val)
    setIsLoadingSearch(true)
  }
  const handleOnEnter = e => {
    if (!keywordSearchUser) return
    if (e.keyCode !== 13) return
    const EMAIL_REGEX = /^[A-Za-z][A-Za-z0-9-_.]{1,32}(\+?[0-9]){0,5}@[A-Za-z0-9_-]{2,}(\.[A-Za-z0-9]{2,4}){1,2}$/gm
    if (keywordSearchUser && !EMAIL_REGEX.test(keywordSearchUser.toString())) {
      // message.error(t('i0348'))
      inputSearchRef.current.blur()
      return
    }
    if (newSharingUserIds.includes(keywordSearchUser)) {
      inputSearchRef.current.blur()
      return
    }
    let dataSharingUserIds = [...newSharingUserIds, keywordSearchUser]
    let dataSharingUserObj = [...newSharingUserObjs, { id: keywordSearchUser, type: 'email' }]
    setNewSharingUserIds(dataSharingUserIds)
    setNewSharingUserObjs(dataSharingUserObj)
    setKeywordSearchUser(null)
    inputSearchRef.current.blur()
  }
  const handleChangeSharingTime = e => {
    setIsInfiniteTimeSharing(e.target.value)
    if (e.target.value) {
      setTimeSharing(null)
      updateTimeForm.setFieldsValue({
        shareExpiredAt: null,
      })
    }
  }
  const shouldDisableShareUser = () => {
    // Hàm này return true sẽ disable việc share
    // Thứ tự ưu tiên như dưới
    if (selectionData?.length && isMine) return false
    if (selectionData?.length && currentUserPermission === 4) return false
    return true
  }
  const handleSubmitUpdateTime = formData => {
    // case cập nhật phân quyền hoặc xóa phân quyền, cài đặt mật khẩu, thời gian
    // Nếu chia sẻ link công khai thì bắt buộc có linkRole
    let dataShare = {
      node_id: itemDrive?.id,
      expired_at: timeSharing ? timeSharing.format('YYYY-MM-DD') : '',
    }
    shareStore.updateExpiredShare(dataShare)
      .then(response => {
        if (response.error_code === 0) {
          notification.info({
            className: 'custom-notification-share',
            closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
                            height={15} />,
            icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                       height={20} />,
            message: <CustomNotificationContent>
              <CustomCustomNotificationMessage>
                {
                  t('i0652')
                }
              </CustomCustomNotificationMessage>
              {/*<CustomCustomNotificationLink onClick={() => handleRollback(action)}>Hủy</CustomCustomNotificationLink>*/}
            </CustomNotificationContent>,
            description: null,
            placement: 'bottomLeft',
            duration: 6,
            key: 'successShare',
          })
          loadShareInfo()
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
      .finally(() => {
      })
  }
  const handleSubmitUpdateLink = formData => {
    if (useSharePassword && (inputPassword?.length === 0 || !inputPassword)) {
      message.error(t('i0664'))
      return
    }
    shareStore.updatePasswordShare({
      node_id: itemDrive?.id,
      password: useSharePassword ? (inputPassword && inputPassword) : '',
    })
      .then(response => {
        if (response.error_code === 0) {
          notification.info({
            className: 'custom-notification-share',
            closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
                            height={15} />,
            icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                       height={20} />,
            message: <CustomNotificationContent>
              <CustomCustomNotificationMessage>
                {
                  t('i0652')
                }
              </CustomCustomNotificationMessage>
              {/*<CustomCustomNotificationLink onClick={() => handleRollback(action)}>Hủy</CustomCustomNotificationLink>*/}
            </CustomNotificationContent>,
            description: null,
            placement: 'bottomLeft',
            duration: 6,
            key: 'successShare',
          })
          loadShareInfo()
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))

  }
  const handleSubmitUpdateUser = formData => {
    let users = shareStore.userShared.map(item => {
      return {
        id: item.user_id,
        permission: item.permission,
        type: 'user',
      }
    })
    let dataShare = {
      node_id: itemDrive?.id,
      is_secret: isSecret,
      password: inputPassword,
      users: users,
      expired_at: timeSharing ? timeSharing.format('YYYY-MM-DD') : '',
      share_permission: linkRole,

    }
    processShare(dataShare, 'updateUsers')
  }
  const handleSubmitInsertUser = formData => {
    if (enableSendInform && (!formData?.inform || formData?.inform?.trim()?.length === 0)) {
      message.error(t('i0674'))
      return
    }
    if (newSharingUserIds.length > 0) {
      // Share cho người dùng khác qua input, tất cả chung 1 quyền, sẽ cho phép chỉnh sửa quyền mỗi người sau
      let users = newSharingUserObjs.map(item => {
        return {
          id: item.id,
          permission: audienceRole,
          type: item?.type || 'email',
        }
      })
      // chuẩn bị data để hủy chia sẻ ngay sau khi chia sẻ xong
      let usersForRollback = newSharingUserIds.map(item => {
        return {
          id: item,
          permission: 0,
        }
      })
      shareStore.setInsertUserShareRollback({
        node_id: itemDrive?.id,
        is_secret: null,
        password: null,
        users: usersForRollback,
        note: null,
        expired_at: null,
        share_permission: null,
      })
      let dataShare = {
        node_id: itemDrive?.id,
        users: users,
        note: enableSendInform ? formData.inform : null,
        is_secret: isSecret,
        password: inputPassword,
        expired_at: timeSharing ? timeSharing.format('YYYY-MM-DD') : '',
        share_permission: linkRole,

      }
      processShare(dataShare, 'insert')
      insertUserForm.resetFields()
    }
  }
  const processShare = (dataShare, action) => {
    shareStore.processShare(dataShare)
      .then(response => {
        if (response.error_code === 0) {
          notification.info({
            className: 'custom-notification-share',
            closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15}
                            height={15} />,
            icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                       height={20} />,
            message: <CustomNotificationContent>
              <CustomCustomNotificationMessage>
                {
                  action === 'insert'
                    ? t('i0346')
                    : t('i0652')
                }
              </CustomCustomNotificationMessage>
              {/*<CustomCustomNotificationLink onClick={() => handleRollback(action)}>Hủy</CustomCustomNotificationLink>*/}
            </CustomNotificationContent>,
            description: null,
            placement: 'bottomLeft',
            duration: 6,
            key: 'successShare',
          })
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
      .finally(() => {
        switch (action) {
          case 'updateUsers':
            let myselft = dataShare?.users?.filter(item => item.id === profileStore?.myProfile?.id)
            if (myselft[0]?.permission === 0) {
              callbackSharingModal()
              handleCloseModal()
            } else {
              shareStore.getUserShared(itemDrive?.id)
                .then(response => {
                  if (response.error_code !== 0) {
                    message.error(response.message)
                  }
                })
                .catch(error => error?.response?.status != 401 && message.error(error.message))
              loadShareInfo()
            }
            break
          case 'insert':
            handleCloseModal()
            break
          default:
            loadShareInfo()
            break
        }
      })
  }

  const debounceDropDown = useCallback(debounce(nextValue => {
    if (nextValue?.length === 0) {
      shareStore.setUserForShare([])
    }
    setKeywordSearchUser(nextValue)
  }, DEBOUNCE_DELAY), [])

  const handleChangeIsSecret = (secret) => {
    setIsSecret(secret)
    let dataShare = {
      node_id: itemDrive?.id,
      is_secret: secret,
      share_permission: secret === '0' ? (linkRole === 0 ? 1 : linkRole) : 0,
      password: inputPassword || '',
      users: usersEditingPermission || [],
      expired_at: timeSharing ? timeSharing.format('YYYY-MM-DD') : '',
    }
    processShare(dataShare)
  }

  const audienceSharingDropdownMenu = (
    <Menu>
      <Menu.Item onClick={() => handleChangeIsSecret('1')}>
        {t('i0349')}
      </Menu.Item>
      <Menu.Item onClick={() => handleChangeIsSecret('0')}>
        {t('i0350')}
      </Menu.Item>
    </Menu>
  )
  const sharingPermissionDropdownMenu = (
    <Menu>
      {
        dataSharingPermission
          .filter(permission => permission.bit_val !== 8)
          .map(permission => (
            <Menu.Item
              onClick={() => setAudienceRole(permission.bit_val)}
              key={permission.bit_val}>
              {permission.label}
            </Menu.Item>
          ))
      }
    </Menu>
  )
  const handleChangeLinkRole = (newLinkRole) => {
    setLinkRole(newLinkRole)
    let dataShare = {
      node_id: itemDrive?.id,
      is_secret: 0,
      share_permission: newLinkRole,
    }
    processShare(dataShare)
  }
  const sharingPermissionOfLinkDropdownMenu = (
    <Menu>
      {
        dataSharingPermission
          .filter(permission => permission.bit_val !== 8)
          .map(permission => (
            <Menu.Item
              onClick={() => handleChangeLinkRole(permission.bit_val)}
              key={permission.bit_val}>
              {permission.label}
            </Menu.Item>
          ))
      }
    </Menu>
  )
  const EmptySearchResult = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        keywordSearchUser && !EMAIL_REGEX.test(keywordSearchUser.toString())
          ? (
            <>
              {t('i0351')}
            </>
          ) : (
            <>
              {t('i0352')} <br />
              {t('i0353')}
            </>
          )
      }
    />
  )

  const handleChangePasswordInput = (e) => {
    setInputPassword(e.currentTarget.value || '')
  }

  useEffect(() => {
    if (!itemDrive) return
    shareStore.getUserShared(itemDrive?.id)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }, [itemDrive?.id])
  useEffect(() => {
    if (!itemDrive || !keywordSearchUser || keywordSearchUser?.length === 0) {
      setIsLoadingSearch(false)
      return
    }
    shareStore.getUserForShare(itemDrive?.id, keywordSearchUser)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
      .finally(() => setIsLoadingSearch(false))
  }, [keywordSearchUser, itemDrive?.id])
  useEffect(() => {
    if (!itemDrive?.id) return
    loadShareInfo()
  }, [itemDrive?.id])
  useEffect(() => {
    setAudienceRole(1)
  }, [])
  // useEffect(() => {
  //   // Mỗi khi thay đổi dropdown chuẩn bị sẵn 1 list user được update permission để thực hiện update
  //   if (!shareStore.userShared) return
  //   let newUserEditList = shareStore.userShared.map(item => {
  //     return {
  //       id: item.user_id,
  //       permission: item.permission,
  //     }
  //   })
  //   setUsersEditingPermission(newUserEditList)
  // }, [shareStore.userShared])
  useEffect(() => {
    // chuẩn bị sẵn list users rollback sau khi cập nhật phân quyền
    if (!shareStore.userSharedRollback) return
    let newUpdateUserRollback = shareStore.userShared.map(item => {
      return {
        id: item.user_id,
        permission: item.permission,
      }
    })
    shareStore.setUpdateUserShareRollback({
      node_id: itemDrive?.id,
      is_secret: null,
      password: null,
      users: newUpdateUserRollback,
      note: null,
      expired_at: null,
      share_permission: linkRole,
    })
  }, [shareStore.userSharedRollback])
  useEffect(() => {
    if (modalVisible) {
      notification.close('successShare')
    }
  }, [modalVisible])

  return (
    <SharingModalWrapper>
      <Modal
        title={null} footer={null}
        closable={false}
        visible={modalVisible}
        onCancel={handleCloseModal}
        wrapClassName={'sharing-modal'}>

        {
          <>
            <MemberSharingSection>
              <ModalHeading>
                {
                  newSharingUserIds.length > 0 &&
                  <LeftOutlined style={{ marginRight: '16px' }}
                                onClick={() => {
                                  setNewSharingUserObjs([])
                                  setNewSharingUserIds([])
                                }} />
                }

                <h2 style={{ marginRight: 'auto' }}>{t('i0354')}</h2>
                <CloseOutlined onClick={handleCloseModal} />
              </ModalHeading>
              <Form
                form={updateUserForm}
                onFinish={handleSubmitUpdateUser}
                layout={'vertical'}>
                {
                  actionType === 7 || enableEditSharedUser
                    ? (
                      <>
                        {
                          shouldDisableShareUser()
                            ?
                            <div>{t('i0510')} {renderPermission(currentUserPermission)?.toLowerCase()}</div>
                            : (
                              // TODO
                              <Form.Item>
                                <StyledTagsSelect>
                                  <Select
                                    ref={inputSearchRef}
                                    className={'custom-tags-select'}
                                    value={newSharingUserIds}
                                    filterOption={false}
                                    optionFilterProp={'name'}
                                    notFoundContent={(keywordSearchUser && keywordSearchUser?.length > 0) ? EmptySearchResult : null}
                                    onChange={handleSelectUserForSharing}
                                    onSearch={handleSearchNewSharingUserIds}
                                    onBlur={() => setKeywordSearchUser(null)}
                                    onInputKeyDown={handleOnEnter}
                                    mode={'multiple'} placeholder={t('i0355')}>
                                    {
                                      userForShare.map(user => (
                                        <Option key={user.id} name={user.username} value={user.id}>
                                          <SharingUserInfo>
                                            <UserAvatar>
                                              {
                                                user.avatar && user.avatar.length > 0 && user.type !== 'group'
                                                  ? <img src={user.avatar} alt='avatar' />
                                                  : <DefaultAvatar username={user.name} />
                                              }
                                            </UserAvatar>
                                            <UserInfo>
                                              <p>{user.name || t('i0356')}</p>
                                              <p>{user.type !== 'group' ? (user.username || t('i0356')) : (`${user.username} ${t('i0675')}`)}</p>
                                            </UserInfo>
                                          </SharingUserInfo>
                                        </Option>
                                      ))
                                    }
                                  </Select>
                                  {isLoadingSearch && <Spin />}
                                </StyledTagsSelect>
                              </Form.Item>
                            )
                        }

                        {
                          newSharingUserIds?.length > 0
                            ? null
                            : shareStore.userShared.length > 0
                              ? !shouldDisableShareUser() &&
                              (
                                <UserList id={'shared-user-role-dropdown'} showScroll={shareStore.userShared.length >= 3}>
                                  <PerfectScroll>
                                    {/*<UserListItem key={profileStore.myProfile.id}>*/}
                                    {/*  <UserAvatar>*/}
                                    {/*    {*/}
                                    {/*      profileStore.myProfile.avatar ?*/}
                                    {/*        <img src={profileStore.myProfile.avatar} alt='' /> :*/}
                                    {/*        <Avatar icon={<UserOutlined />} />*/}
                                    {/*    }*/}
                                    {/*  </UserAvatar>*/}
                                    {/*  <UserInfo>*/}
                                    {/*    <p>{profileStore.myProfile.name || 'Người dùng'} (tôi)</p>*/}
                                    {/*    <p>{profileStore.myProfile.username || 'Chưa có số điện thoại'}</p>*/}
                                    {/*  </UserInfo>*/}
                                    {/*  <UserOwned>{'Người sở hữu'}</UserOwned>*/}
                                    {/*</UserListItem>*/}
                                    {
                                      shareStore.userShared.map(item => (
                                        <UserListItem key={item.user_id}>
                                          <UserAvatar>
                                            {
                                              item.user?.avatar
                                                ? <img src={item.user?.avatar} alt='' />
                                                : <DefaultAvatar username={item.user?.name} />
                                            }
                                          </UserAvatar>
                                          <UserInfo>
                                            <p>{item.user?.name || item.user_id || t('i0357')}</p>
                                            <p>{miscUtils.phoneNumberReplace(item.user?.username) || t('i0358')}</p>
                                          </UserInfo>
                                          <Dropdown
                                            getPopupContainer={() => document.getElementById('shared-user-role-dropdown')}
                                            overlay={() => userRoleDropdownMenu(item)}
                                            placement={'bottomRight'}
                                            trigger={['click']}>
                                            <UserRole>
                                              {renderPermission(item.permission)}
                                              <CaretDownOutlined />
                                            </UserRole>
                                          </Dropdown>
                                        </UserListItem>
                                      ))
                                    }
                                  </PerfectScroll>
                                </UserList>
                              ) : <div style={{ marginBottom: 15 }}>{t('i0359')}</div>
                        }
                        {/*Cập nhật*/}
                        {
                          (newSharingUserIds?.length > 0 || shareStore.userShared?.length === 0)
                            ? null
                            : !shouldDisableShareUser() && <ButtonRow>
                            <Button type={'primary'} htmlType={'submit'}>
                              {t('i0360')}
                            </Button>
                          </ButtonRow>
                        }
                      </>
                    ) : (
                      shouldDisableShareUser()
                        ?
                        <div>{t('i0510')} {renderPermission(currentUserPermission)?.toLowerCase()}</div>
                        :
                        <p style={{ cursor: 'pointer', marginBottom: 0 }}
                           onClick={() => setEnableEditSharedUser(true)}>
                          {renderSharedUsers(shareStore.userShared)}
                        </p>
                    )
                }
              </Form>
              {/*Thêm mới*/}
              {
                newSharingUserIds?.length > 0 &&
                <Form
                  form={insertUserForm}
                  onFinish={handleSubmitInsertUser}
                  layout={'vertical'}>
                  <NewUserSharingSection>
                    <div className='permission-setting-row'>
                      <p>{t('i0361')}</p>
                      <Dropdown overlay={sharingPermissionDropdownMenu} trigger={['click']}>
                        <AudienceRole>
                          <span>{renderPermission(audienceRole)}</span>
                          <CaretDownOutlined />
                        </AudienceRole>
                      </Dropdown>
                    </div>
                    <div className='send-inform-row'>
                      <p>{t('i0362')}</p>
                      <Checkbox checked={enableSendInform} onChange={onEnableInformInput} />
                    </div>
                    {
                      enableSendInform && (
                        <Form.Item
                          name={'inform'}
                          rules={[{ validator: validator.validateNoteShare }]}
                          label={t('i0363')}>
                          <TextArea rows={5} />
                        </Form.Item>
                      )
                    }
                    <div className='button-row'>
                      <Button type={'default'} onClick={handleCloseModal}>
                        {t('i0364')}
                      </Button>
                      <Button type={'primary'} htmlType={'submit'}>
                        {t('i0365')}
                      </Button>
                    </div>
                  </NewUserSharingSection>
                </Form>
              }
            </MemberSharingSection>
            {
              newSharingUserIds?.length === 0 && (
                (
                  <>
                    {/*Sửa link*/}
                    <Form
                      form={updateLinkForm}
                      onFinish={handleSubmitUpdateLink}
                      layout={'vertical'}>
                      <LinkSharingSection>
                        <ModalHeading>
                          <h2>{t('i0366')}</h2>
                          {
                            !isEditSharingLink && !shouldDisableShareUser() && (
                              <a onClick={() => setIsEditSharingLink(true)}>
                                {t('i0367')}
                              </a>
                            )
                          }
                        </ModalHeading>
                        {
                          !isEditSharingLink && <p>{renderSharingInformationText()}</p>
                        }
                        <Row type={'flex'} gutter={15} align={'middle'} justify={'space-between'}>
                          <Col flex={1}>
                            <Form.Item noStyle={true} name={'sharedUrl'}>
                              <SharingLinkInput
                                disabled={selectionData?.length && !isMine}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <a onClick={copyToClipboard}>{t('i0368')}</a>
                          </Col>
                        </Row>
                        {
                          isEditSharingLink && !shouldDisableShareUser() && (
                            <EditSharingLinkPermissionWrapper id={'permission-select-wrapper'}>
                              <div className={!(isSecret === '1') ? 'col-left' : ''}>
                                <Dropdown
                                  getPopupContainer={() => document.getElementById('permission-select-wrapper')}
                                  overlay={audienceSharingDropdownMenu}
                                  trigger={['click']}>
                                  <PrivateSharingDropdown>
                                    <SVGIcon name={isSecret === '1' ? 'users' : 'globe'} width={16.67} height={16.67} />
                                    <span>{isSecret === '1' ? t('i0369') : t('i0370')}</span>
                                    <CaretDownOutlined />
                                  </PrivateSharingDropdown>
                                </Dropdown>
                                <p style={{ marginLeft: 30 }}>
                                  {renderSharingInformationText()}
                                </p>
                              </div>
                              {
                                !(isSecret === '1') && (
                                  <div className='col-right'>
                                    <Dropdown
                                      getPopupContainer={() => document.getElementById('permission-select-wrapper')}
                                      overlay={sharingPermissionOfLinkDropdownMenu}
                                      trigger={['click']}>
                                      <AudienceRole>
                                        <span>{renderPermissionLink(linkRole)}</span>
                                        <CaretDownOutlined />
                                      </AudienceRole>
                                    </Dropdown>
                                  </div>
                                )
                              }
                            </EditSharingLinkPermissionWrapper>
                          )
                        }
                        {
                          selectionData?.length && isMine && (
                            <>
                              <Divider />
                              <Checkbox checked={useSharePassword}
                                        onChange={() => setUseSharePassword(!useSharePassword)}>
                                {t('i0371')}
                              </Checkbox>
                              <SharingNotice>
                                {t('i0372')}
                              </SharingNotice>
                            </>
                          )
                        }
                        {
                          useSharePassword && isMine && (
                            <Form.Item
                              name={'password'}
                              rules={[
                                { required: true, message: t('i0449') },
                                { validator: validator.validatePasswordShare },
                              ]}
                            >
                              <StyledInputPassword
                                style={{ marginTop: 15 }}
                                placeholder={t('i0373')}
                                onChange={handleChangePasswordInput}
                              />
                            </Form.Item>
                          )
                        }
                        {
                          isMine &&
                          <ButtonRow style={{ marginTop: (isMine && useSharePassword) ? 0 : 20 }}>
                            <Button type={'primary'} htmlType={'submit'}>
                              {t('i0374')}
                            </Button>
                          </ButtonRow>

                        }
                      </LinkSharingSection>
                    </Form>
                    {
                      selectionData?.length &&
                      <Form
                        form={updateTimeForm}
                        onFinish={handleSubmitUpdateTime}
                        layout={'vertical'}>
                        <TimeSharingSection>
                          <ModalHeading>
                            <h2>{t('i0375')}</h2>
                            {
                              !isEditSharingTime && isMine && (
                                <a onClick={() => setIsEditSharingTime(true)}>{t('i0376')}</a>
                              )
                            }
                          </ModalHeading>
                          <p>
                            {t('i0377')} {renderSharingTime()}.
                          </p>
                          {
                            isEditSharingTime && (
                              <>
                                <Radio.Group onChange={handleChangeSharingTime} value={isInfiniteTimeSharing}>
                                  <Space direction={'vertical'}>
                                    <Radio value={true}>{t('i0378')}</Radio>
                                    <Radio value={false}>{t('i0379')}</Radio>
                                  </Space>
                                </Radio.Group>
                                {
                                  (!isInfiniteTimeSharing) && (
                                    <Form.Item name={'shareExpiredAt'} rules={[
                                      { required: true, message: t('i0380') },
                                    ]}>
                                      <StyledDatePicker
                                        style={{ marginTop: 20 }}
                                        suffixIcon={<SVGIcon name={'calendar'} width={20} height={20} />}
                                        format={'DD/MM/YYYY'} disabledDate={filterPastDate}
                                        onChange={setTimeSharing}
                                        placeholder={t('i0381')}
                                      />
                                    </Form.Item>
                                  )
                                }
                                <ButtonRow>
                                  <Button type={'primary'} htmlType={'submit'}>
                                    {t('i0382')}
                                  </Button>
                                </ButtonRow>
                              </>
                            )
                          }
                        </TimeSharingSection>
                      </Form>
                    }
                  </>
                )
              )
            }
          </>
        }

      </Modal>
    </SharingModalWrapper>
  )
}

SharingModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  actionType: PropTypes.number.isRequired,
  itemDrive: PropTypes.object,
  callbackSharingModal: PropTypes.func,
}

export default inject(
  'appConfigStore',
  'shareStore',
  'profileStore',
  'selectionStore',
)(observer(SharingModal))
