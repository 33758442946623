import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'

export const SidebarWrapper = styled.aside`
  position: fixed;
  height: 100vh;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 256px;
  display: flex;
  flex-direction: column;
  z-index: 10;
`

export const LogoWrapper = styled(Link)`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  img {
    width: 100%;
  }
`

export const BottomMenu = styled.div`
  margin-top: auto;

  ul {
    margin: 0 20px 0;
    padding: 15px 0 5px;

    li {
      margin-bottom: 15px;

      a {
        font-size: 14px;
        color: #313131;
        line-height: 20px;
        transition: ease .3s;

        &:hover {
          color: #237BD3;
          transition: ease .3s;
        }
      }
    }
  }
`

export const Copyright = styled.p`
  padding: 0 20px 30px;
  color: #858A8F;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
`

export const ActionButtonWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 24px;

  .ant-btn {
    font-size: 14px;
    height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #237BD3;

    .anticon {
      font-size: 20px;
    }
  }
`

export const MainMenu = styled.div`
  padding: 0 8px 0 11px;
  margin-bottom: 15px;

  .ant-menu {
    border-right: none;

    .ant-menu-item {
      border-radius: 10px;
      overflow: visible;
      margin: 0;
      height: 50px;
      line-height: 50px;
      padding-left: 50px !important;

      &:after {
        width: 3px;
        height: 32px;
        right: auto;
        left: -11px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 0 100px 100px 0;
      }

      .svgicon, .anticon {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.ant-menu-item-selected, &.ant-menu-item-active {
        .svgicon, .anticon {
          filter: invert(60%) sepia(57%) saturate(6224%) hue-rotate(191deg) brightness(86%) contrast(91%);
        }
      }
    }

    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: rgba(35, 123, 211, .1);
        color: #237BD3;
      }
    }
  }
`

export const HorizontalLine = styled.hr`
  display: block;
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  border: none;
  background-color: #E5E9F2;
`

export const Capacity = styled.div`
  padding: 18px 16px;

  .ant-progress {
    margin-top: 5px;
    margin-bottom: 10px;

    .ant-progress-inner {
      background-color: #E2E2E2;
    }
  }

  strong {
    color: #000;
    font-size: 14px;
    padding-left: 12px;
    position: relative;
  }

  p {
    color: #313131;
    font-size: 12px;
    line-height: 20px;
  }

  .ant-btn {
    background-color: rgba(35, 123, 211, 0.1);
    color: #237BD3;
    border: none;
    width: 122px;
    height: 36px;
    line-height: 36px;
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Scroller = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 150px;
`

export const ActionMenuInsert = styled(Menu)`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  padding: 0;

  .ant-dropdown-menu-item {
    padding: 0;
  }

  .ant-dropdown-menu-item:first-child:hover {
    border-radius: 14px 14px 0 0;
  }

  .ant-dropdown-menu-item:last-child:hover {
    border-radius: 0 0 14px 14px;
  }
`

export const ActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
`
export const ActionMenuIcon = styled.div`
  margin-right: 8px;
`
export const ActionMenuText = styled.span`
  font-size: 1.4rem;
  color: #313131;
`
export const CustomScroll = styled.div`
  overflow-y: auto;
`