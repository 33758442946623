import React, { memo } from 'react'
import { message, Progress, Tooltip, Button } from 'antd'
import {
  ModalWrapper,
  CloseButton,
  ModalHeader,
  ModalContent,
  ModalTimeText,
  ModalTotalText, IconSuccessGroup, IconCancelGroup, IconUploadProgressGroup, ProgressItemText, ProgressItem,
} from './ModalDownloadProgressStyle'
import { inject, observer } from 'mobx-react'
import { CloseOutlined } from '@ant-design/icons'
import SVGIcon from '../SVGIcon/SVGIcon'
import { useTranslation } from 'react-i18next'
import FileDownload from 'js-file-download'
import axios from 'axios'

const ModalDownloadProgress =(props) => {
  const { downloadStore, commonStore } = props
  const { visibleModalProgress, downloadList, messageDownload, progressDownload, statusDownLoad } = downloadStore
  const { isMenubarHover } = commonStore

  const { t } = useTranslation()

  const renderIcon = (status, percent, cancelSource, currentFile, message) => {

    if (status === 'success') {
      return (
        <IconSuccessGroup>
          <Tooltip title={t('i0702')} placement={"bottom"}>
          <Button className={'btn-success-status'}
                  type='primary'
                  shape='circle'
                  onClick={()=>handleDownloadFile(currentFile)}
                  icon={<SVGIcon name={'success'} width={18} height={18} />}
          />
          </Tooltip>
        </IconSuccessGroup>
      )
    }  else if (status === 'cancelled' || status ==='error') {
      return (
        <IconCancelGroup>
            <Button className={'btn-cancel'}
                    type='link'
                    shape='circle'
                    onClick={() => cancelDownload(currentFile)}
                    icon={<SVGIcon name={'huy'} width={18} height={18} />}
            />
        </IconCancelGroup>
      )
    } else if (status === 'downloading') {
      return (
        <IconUploadProgressGroup>
          <Button className={'btn-progress'} type='primary' shape='circle'
                  icon={
                    <Progress type='circle'
                              trailColor={'#2C2C2C'}
                              strokeColor={'#FFFFFF'} status={status}
                              strokeWidth={10} showInfo={false} percent={percent} width={15}
                    />
                  }
          >
          </Button>
          <Tooltip placement='bottom' title={'Hủy tải xuống'}>
            <Button className={'btn-cancel'}
                    type='primary'
                    shape='circle'
                    onClick={() => cancelDownload(currentFile)}
                    icon={<SVGIcon name={'huy'} width={18} height={18} />}
            />
          </Tooltip>
        </IconUploadProgressGroup>
      )
    } else {
      return (
        <IconUploadProgressGroup>
          <Button className={'btn-progress'} type='primary' shape='circle'
                  icon={
                    <Progress type='circle'
                              trailColor={'#2C2C2C'}
                              strokeColor={'#FFFFFF'} status={status}
                              strokeWidth={10} showInfo={false} percent={percent} width={15}
                    />
                  }
          >
          </Button>
          <Tooltip placement='bottom' title={'Hủy tải xuống'}>
            <Button className={'btn-cancel'}
                    type='primary'
                    shape='circle'
                  onClick={() => cancelDownload(currentFile)}
                    icon={<SVGIcon name={'huy'} width={18} height={18} />}
            />
          </Tooltip>
        </IconUploadProgressGroup>
      )
    }
  }

  const handleDownloadFile = (folder) => {
    if(folder.download_url) {
      setTimeout(() => {
        axios.get(folder.download_url, {
          responseType: 'blob',
          disableSpinner: true,
        }).then(res => {
          FileDownload(res.data, `${folder['name']}.zip`)
          // downloadStore.reSetFileList()
          // downloadStore.setVisibleModalProgress(false)
        })
      }, 1500)
    }

  }

 const cancelDownload =(folder)=>{
    if(statusDownLoad[folder.uid] === 'downloading') {
      downloadStore.updateStatusFile(folder.uid,'cancelled')
      downloadStore.handleMessageDownload(folder.uid, t('i0705'))
      if (folder.cancelSource) {
        folder.cancelSource.cancel('Cancelled by user')
      }
    }
  }

  const handleCancelAll = ()=>{
    Object.keys(statusDownLoad).map(key =>{
      if(statusDownLoad[key] !== 'success') {
        downloadStore.updateStatusFile(key,'cancelled')
        downloadStore.handleMessageDownload(key, t('i0691'))
      }
    })
    downloadStore.reSetFileList()
    downloadStore.setVisibleModalProgress(false)
  }

  return (
    <ModalWrapper {...props} visible={visibleModalProgress} isMenubarHover={isMenubarHover}>
      <ModalHeader>
        <ModalTotalText>
          {t('i0690')}
        </ModalTotalText>
        <CloseButton shape='circle' size='small' onClick={() => {handleCancelAll()}}>
          <CloseOutlined style={{ color: '#FFFFFF' }} />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        {downloadList?.map((item, index) => (
            <ProgressItem key={index}>
                <SVGIcon name={'folder-upload'} width={18} height={18} />
              <ProgressItemText>
                {statusDownLoad[item.uid] === 'success' ? item.name : messageDownload[item.uid]}
              </ProgressItemText>
              {renderIcon(statusDownLoad[item.uid], progressDownload[item.uid], item.cancelSource, item, messageDownload[item.uid])}
            </ProgressItem>

        ))}
      </ModalContent>
    </ModalWrapper>
  )
}

export default inject('commonStore','downloadStore')(observer(ModalDownloadProgress))
