import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import 'firebase/messaging'
import firebase from '../../firebase'
import { ConfigProvider, message } from 'antd'
import { useTranslation } from 'react-i18next'
import enUs from 'antd/es/locale/en_US'
import viVN from 'antd/es/locale/vi_VN'

const DataProvider = (props) => {
  const {
    notificationsStore,
    authenticationStore,
    children,
    profileStore,
  } = props
  const { visibleDropdown, page, perPage, fcmToken } = notificationsStore
  const { i18n, t } = useTranslation()
  useEffect(() => {
    if (!authenticationStore.accessToken) return
    console.log('supported firebase', firebase.messaging.isSupported())
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging()
      Notification.requestPermission()
        .then(() => {
          return messaging.getToken()
        })
        .then(token => {
          console.log('fcm token (projectId: "mobifone-drive")', token)
          notificationsStore.setFcmToken(token)
          localStorage.setItem('fcmToken', token)
        })
        .catch(err => {
          console.log('error', err)
        })
      messaging.onMessage((payload) => {
        console.log('Message received. ', payload)
        if (visibleDropdown) {
          console.log('visibleDropdown', visibleDropdown)
          notificationsStore.setPage(1)
          notificationsStore.getNotificationListNoSpinner().then(response => {
            if (response.error_code !== 0) {
              message.error(response.message)
            }
          }).catch(error => {
            error?.response?.status != 401 && message.error(error.message)
          })
        } else {
          console.log('visibleDropdown', visibleDropdown)
          notificationsStore.setPage(1)
          notificationsStore.setHasNewRealTimeNotify(true)
          let newTotalNotSeen = notificationsStore.totalNotSeen + 1
          notificationsStore.setTotalNotSeen(newTotalNotSeen)
        }
      })
    }

  }, [authenticationStore.accessToken])

  useEffect(() => {
    if (!authenticationStore.accessToken) return
    profileStore.getMyProfile()
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }, [authenticationStore.accessToken])

  useEffect(() => {
    i18n.changeLanguage(profileStore.appLanguage)
  }, [profileStore.appLanguage])

  useEffect(() => {
    console.log('Last Update: 08-10-2021 17:00')
  }, [])

  return (
    <>
      <ConfigProvider locale={profileStore?.appLanguage === 'en' ? enUs : viVN}>
        {children}
      </ConfigProvider>
    </>
  )
}

export default inject(
  'notificationsStore',
  'authenticationStore',
  'profileStore',
  'synchronizationStore',
)(observer(DataProvider))
