import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Button, message, Modal, Select, Avatar, Spin } from 'antd'
import {
  ButtonRow,
  InsertMemberIntoGroupModalWrapper,
  SharingUserInfo,
  StyledTagsSelect,
  UserAvatar,
  UserInfo,
} from './InsertMemberIntoGroupModalStyled'
import { userMockData } from '../SharingModal/mock/mock'
import { debounce } from 'lodash-es/function'
import { DEBOUNCE_DELAY } from '../../utils/constant'
import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const InsertMemberIntoGroupModal = props => {
  const { visible, onSubmitInsert, onClose, groupId, groupStore } = props
  const { memberListInput, pageMemberInput, perPageMemberInput, hasNextPageMemberInput } = groupStore
  const inputSearchRef = useRef()

  const [keywordSearchUser, setKeywordSearchUser] = useState(null)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [insertingMembers, setInsertingMembers] = useState([])

  const { t } = useTranslation()

  const handleSelectUserForSharing = user => {
    setInsertingMembers(user)
    inputSearchRef.current.blur()
  }
  const debounceDropDown = useCallback(debounce(nextValue => {
    if (nextValue?.length === 0) {
      setKeywordSearchUser(null)
    } else {
      setKeywordSearchUser(nextValue)
    }
  }, DEBOUNCE_DELAY), [])
  const handleSearchNewSharingUser = val => {
    debounceDropDown(val)
    setIsLoadingSearch(true)
  }
  const handleCancelInsert = () => {
    onClose()
    setInsertingMembers([])
    setKeywordSearchUser(null)
  }
  const handleSubmitInsert = () => {
    onClose()
    onSubmitInsert(insertingMembers)
    setInsertingMembers([])
    setKeywordSearchUser(null)
  }

  useEffect(() => {
    if (!groupId) return
    let payload = {
      page: 1,
      per_page: groupStore.perPageMemberInput,
      q: keywordSearchUser,
      group_id: groupId,
    }
    groupStore.searchContactToAddIntoGroup(payload)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
      .finally(() => setIsLoadingSearch(false))
  }, [groupId, keywordSearchUser])

  return (
    <InsertMemberIntoGroupModalWrapper>
      <Modal
        title={t('i0211')}
        closable={false}
        onCancel={handleCancelInsert}
        visible={visible}
        footer={null}
      >
        <StyledTagsSelect>
          <Select
            ref={inputSearchRef}
            className={'custom-tags-select'}
            value={insertingMembers}
            optionFilterProp={'name'}
            onChange={handleSelectUserForSharing}
            onSearch={handleSearchNewSharingUser}
            mode={'multiple'} filterOption={false}
            placeholder={t('i0212')}>
            {
              memberListInput.map(user => (
                <Option key={user.id} name={user.name} value={user.id}>
                  <SharingUserInfo>
                    <UserAvatar>
                      {
                        user.avatar
                          ?
                          <img src={user.avatar} alt='avatar' />
                          :
                          <Avatar size={32}>{user?.name?.charAt(0)?.toUpperCase()}</Avatar>
                      }
                    </UserAvatar>
                    <UserInfo>
                      <p>{user.name || t('i0213')}</p>
                      <p>{user.username}</p>
                    </UserInfo>
                  </SharingUserInfo>
                </Option>
              ))
            }
          </Select>
          {isLoadingSearch && <Spin />}
        </StyledTagsSelect>
        <ButtonRow>
          <Button type={'default'} onClick={handleCancelInsert}>{t('i0214')}</Button>
          <Button type={'primary'} onClick={handleSubmitInsert}>{t('i0215')}</Button>
        </ButtonRow>
      </Modal>
    </InsertMemberIntoGroupModalWrapper>
  )
}

InsertMemberIntoGroupModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  groupId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmitInsert: PropTypes.func.isRequired,
}

export default inject('groupStore')(observer(InsertMemberIntoGroupModal))
