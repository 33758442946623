import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import profileStore from '../stores/profileStore'
import notificationsStore from '../stores/notificationsStore'

const source = axios.CancelToken.source()

export const AuthenticationRequest = {
  cancelRequest: () => {
    source.cancel()
  },
  userLogin: (username, password) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/auth/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { username: username, password: password, lang: profileStore.appLanguage },
    })
  },
  userLogout: () => {
    console.log(notificationsStore.fcmToken)
    return axios({
      method: 'post',
      url: `${apiUrl}/api/user/logout`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        fcm_token: notificationsStore.fcmToken
      }
    })
  },
  userRegister: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/auth/register`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        lang: profileStore.appLanguage,
        ...payload,
      },
    })
  },
  verifyOtp: (user_id, otp, in_forgot) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/auth/${user_id}/verify-otp`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        user_id,
        otp,
        lang: profileStore.appLanguage,
        ...(in_forgot && { in_forgot: in_forgot }),
      },
    })
  },
  forgotPassword: (username) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/auth/forgot-password`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        username: username,
        lang: profileStore.appLanguage,
      },
    })
  },
  saveNewPassword: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/auth/${payload.user_id}/save-password`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        password: payload.password,
        password_confirmation: payload.password_confirmation,
        session_key: payload.session_key,
      },
    })
  },
  checkOldPassword: (old_password) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/user/check-password`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        old_password: old_password,
      },
    })
  },
  changePassword: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/user/change-password`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        old_password: payload.old_password,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      },
    })
  },
  resendOtp: (user_id) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/auth/${user_id}/resend-otp`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        lang: profileStore.appLanguage
      }
    })
  },
}
