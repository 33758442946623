import { observable, action, autorun } from 'mobx'

class SelectionStore {

  constructor() {
    autorun(() => {
      this.selectionIds = this.selectionData.map(item => item.id)
    })
  }

  /** Xử lý select file & folder
   * Push luôn list items vào để autorun detect được data changes
   * @selectionData: Array of object
   * @selectionIds: Array of number
   * */
  @observable selectionData = []
  @observable selectionIds = []
  @observable clickSessionId = 0
  @observable firstSelectionIndex = null
  @observable lastSelectionIndex = null

  @action clearSelectionIndex = () => {
    this.firstSelectionIndex = null
    this.lastSelectionIndex = null
    this.selectionData = []
  }

  @action setClickSessionId = () => {
    this.clickSessionId = ++this.clickSessionId
  }
  @action setFirstSelectionIndex = items => {
    this.firstSelectionIndex = items
  }
  @action setLastSelectionIndex = items => {
    this.lastSelectionIndex = items
  }
  @action setSelectionData = items => {
    this.selectionData = items
  }
}

export default new SelectionStore()