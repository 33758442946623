import { action, observable } from 'mobx'
import { MoveItemRequest } from '../requests/MoveItemRequest'
import { MyDriveRequest } from '../requests/MyDriveRequest'


class MoveItemStore {
  @observable currentFolderNestedFolderList = []
  @observable currentFolder = null
  @observable currentFolderAllItems = []
  @observable selectedTargetFolder = null

  @action setCurrentFolder = (folder) => {
    this.currentFolder = folder
  }

  @action setSelectedTargetFolder = (folder) => {
    this.selectedTargetFolder = folder
  }

  @action clearMoveItem = () => {
    this.currentFolderNestedFolderList.length = 0
    this.currentFolder = null
    this.selectedTargetFolder = null
  }

  @action getFolderList = (parentId, itemToMoveId) => {
    // Chỉ lấy folder để hiển thị menu di chuyển
    return new Promise((resolve, reject) => {
      MoveItemRequest.getFolderList(parentId).then(response => {
        this.currentFolderNestedFolderList = response.data.data.filter(x => (x.type === 1 && !itemToMoveId.includes(x?.id)))
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action getItemToMoveList = (parentId) => {
    // Lấy tất cả item trong folder được di chuyển
    return new Promise((resolve, reject) => {
      MoveItemRequest.getItemToMoveList(parentId).then(response => {
        this.currentFolderAllItems = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action moveDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      MoveItemRequest.moveDriveItem(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getDetailParentFolder = (node_id) => {
    return new Promise((resolve, reject) => {
      MoveItemRequest.getDetailParentFolder(node_id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action cancelRequest = () => {
    MoveItemRequest.cancelRequest()
  }
}

export default new MoveItemStore()
