import React, { useEffect, useState } from 'react'
import LoadingSpinner from './components/LoadingSpinner'
// Styling
import './App.less'
import 'react-perfect-scrollbar/dist/css/styles.css'
// Ant Design
import { ConfigProvider, message } from 'antd'
// Axios
import axios from 'axios'
// MomentJS
import moment from 'moment'
import viVN from 'antd/es/locale/vi_VN'
import enUs from 'antd/es/locale/en_US'
import 'moment/locale/vi'
// React Router
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'
// MobX
import { Provider } from 'mobx-react'
import commonStore from './stores/commonStore'
import loadingAnimationStore from './stores/loadingAnimationStore'
import authenticationStore from './stores/authenticationStore'
import myDriveStore from './stores/myDriveStore'
import favoriteStore from './stores/favoriteStore'
import recentlyOpenStore from './stores/recentlyOpenStore'
import uploadStore from './stores/uploadStore'
import moveItemStore from './stores/moveItemStore'
import searchStore from './stores/searchStore'
import profileStore from './stores/profileStore'
import appConfigStore from './stores/appConfigStore'
import downloadStore from './stores/downloadStore'
import trashStore from './stores/trashStore'
import shareStore from './stores/shareStore'
import synchronizationStore from './stores/synchronizationStore'
import sharedWithMeStore from './stores/sharedWithMeStore'
import selectionStore from './stores/selectionStore'
import commonQuestionStore from './stores/commonQuestionStore'
import notificationsStore from './stores/notificationsStore'
import blockStore from './stores/blockStore'
import termOfUseStore from './stores/termOfUseStore'
import privacyPolicyStore from './stores/privacyPolicyStore'
import introductionStore from './stores/introductionStore'
import itemDriveStore from './stores/itemDriveStore'
import groupStore from './stores/groupStore'
import contactStore from './stores/contactStore'
import offlineStore from './stores/offlineStore'
import filePreviewStore from './stores/filePreviewStore'
import previewOverlayStore from './stores/previewOverlayStore'
// Modules
import AuthModule from './modules/AuthModule'
import ProtectedModule from './modules/ProtectedModule'
import PublicModule from './modules/PublicModule'
// Pages
import NotFoundPage from './pages/NotFoundPage'
// One Drive
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './components/SyncDataTabPane/OneDriveConfig/OneDriveAuthen'
// Provider & config
import DataProvider from './providers/DataProvider'
import { Detector } from 'react-detect-offline'
import { deviceDetect } from 'react-device-detect'
import baseConfig from './config'
import HomePage from './pages/HomePage/HomePage'
import DocViewerPage from './pages/DocViewerPage'
import PdfViewer from './pages/PdfViewer'

const { apiUrl } = baseConfig

const msalInstance = new PublicClientApplication(msalConfig)

const history = createBrowserHistory()

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('jwt') !== 'undefined' ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

// axios.defaults.timeout = 20000
axios.interceptors.request.use(
  config => {
    !config.disableSpinner && loadingAnimationStore.showSpinner(true)
    config.headers = {
      ...config.headers,
    }
    if (apiUrl === config.baseURL || config?.url?.includes(apiUrl)) {
      config.params = {
        ...config.params,
        device: `${deviceDetect().osName}-${deviceDetect().browserName}`,
        location: 'Ha Noi',
      }
    } else {
      config.params = {
        ...config.params,
      }
    }
    return config
  },
  error => {
    loadingAnimationStore.showSpinner(false)
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => {
    loadingAnimationStore.showSpinner(false)
    // if (response?.data?.error_code === 403) {
    //   history.push('/not-permission')
    //   return response
    // } else {
    //   return response
    // }
    return response
  },
  error => {
    loadingAnimationStore.showSpinner(false)
    message.config({ maxCount: 1 })
    if (
      error?.response?.status === 408 ||
      error?.code === 'ECONNABORTED'
    ) {
      message.error('Request timeout!')
    }
    if (error?.response?.status === 401) {
      if (
        (!error?.response?.config?.url.includes('googleapis.com') &&
          !error?.response?.config?.url.includes('graph.microsoft.com')) &&
        !error.response?.config.notRedirectLogin
      ) {
        message.error(localStorage.getItem('lang') === 'en' ? 'Login session expired!' : 'Phiên đăng nhập hết hạn!')
        authenticationStore.clearUserInfoWhenLogout()
        history.push('/auth/login')
      } else {
        // Process when 3rd token expired
      }
    }
    return Promise.reject(error)
  },
)

moment.locale('vi', {
  week: {
    dow: 1,
  },
})

const rootStores = {
  commonStore,
  loadingAnimationStore,
  authenticationStore,
  myDriveStore,
  favoriteStore,
  recentlyOpenStore,
  uploadStore,
  moveItemStore,
  searchStore,
  profileStore,
  appConfigStore,
  downloadStore,
  trashStore,
  shareStore,
  synchronizationStore,
  sharedWithMeStore,
  selectionStore,
  commonQuestionStore,
  notificationsStore,
  blockStore,
  termOfUseStore,
  privacyPolicyStore,
  introductionStore,
  itemDriveStore,
  contactStore,
  groupStore,
  offlineStore,
  filePreviewStore,
  previewOverlayStore,
}

const App = () => {

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme')
    commonStore.setTheme(currentTheme || 'blue')
    localStorage.getItem('jwt') && commonStore.deleteSizeCache()
  }, [])

  return (
    <MsalProvider instance={msalInstance}>
      <Provider {...rootStores}>
        <DataProvider>
          <Detector
            polling={{
              interval: 10000,
              timeout: 20000,
            }}
            render={({ online }) => {
              commonStore.checkIsOffline(!online)
              return null
            }}
          />
          <div className={'app-wrapper'}>

            <Router history={history}>
              <Switch>
                <Route exact path={'/'} component={HomePage} />
                <Route exact path={'/auth/:authType'} component={AuthModule} />
                <Route exact path={'/doc-viewer/:node_id'} component={DocViewerPage} />
                <Route exact path={'/pdf-viewer/:node_id'} component={PdfViewer} />
                <Route exact path={[
                  '/introduction',
                  '/common-question',
                  '/common-question/:questionId',
                  '/term-of-use',
                  '/privacy-policy',
                  '/driver/file/:id',
                  '/driver/folders/:id',
                  '/not-permission',
                ]} component={PublicModule} />
                <ProtectedRoute
                  path={[
                    '/my-drive',
                    '/my-drive/:parentId',
                    '/synchronization',
                    '/shared-with-me',
                    '/shared-with-me/:parentId',
                    '/recently-opened',
                    '/recently-opened/:parentId',
                    '/favorite',
                    '/favorite/:parentId',
                    '/offline-files',
                    '/trash',
                    '/helper',
                    '/notifications',
                    '/my-profile',
                    '/my-profile-edit',
                    '/history-login',
                    '/upload-file',
                    '/search',
                    '/search/:parentId',
                    '/config-page',
                    '/config-page/:activeTab',
                    '/change-password',
                    '/update-group/:groupId',
                    '/one-drive',
                  ]}
                  exact
                  component={ProtectedModule}
                />
                <Route component={NotFoundPage} />
              </Switch>
            </Router>
            <LoadingSpinner />
          </div>
        </DataProvider>
      </Provider>
    </MsalProvider>
  )
}

export default App
