import React, { useEffect, useState } from 'react'
import { ImageDefaultUpload, UploadWrapper } from './DragAndDropUploadStyled'
import fileUtils from '../../utils/fileUtils'
import { inject, observer } from 'mobx-react'
import { message } from 'antd'
import { CONTENT_TYPE } from '../../utils/constant'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import uuid from 'uuid'
import { useTranslation } from 'react-i18next'


const DragAndDropUpload = props => {

  const { uploadStore, myDriveStore, appConfigStore, children, noClick, profileStore } = props
  const { myDriveCurrentNode, perPage } = myDriveStore
  const { mimeTypesByExtension, systemConfig } = appConfigStore
  const { keyUpload } = uploadStore
  const [fileList, setFileList] = useState([])
  const [isDragActive, setIsDragActive] = useState(false)
  const { t } = useTranslation()

  const reloadData = () => {
    let payload = {
      parent_id: myDriveCurrentNode?.id,
      content_type: CONTENT_TYPE.myDrive,
      page: 1,
      per_page: perPage,
      sort: myDriveStore.myDriveSort,
      sort_by: myDriveStore.myDriveSortBy,
    }
    myDriveStore.getMyDriveListUploadDone(payload).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }

  const onDropFile = (acceptedFiles) => {
    setIsDragActive(false)
    if (acceptedFiles) {
      uploadStore.setKeyUpload('uploadFiles')
      uploadStore.setVisibleModalProgress(true)
      setFileList(formatNodeFolder(acceptedFiles))
    }
  }

  ///Format fileList to tree on action upload folder
  const formatNodeFolder = (fileArray) => {
    let tree = {}

    ///Format tree view
    function addNode(file) {
      let path = file?.path
      let splitPath = path.replace(/^\/|\/$/g, '').split('/')
      let ptr = tree
      for (let i = 0; i < splitPath.length; i++) {
        let node = {
          name: splitPath[i],
          type: 'directory',
        }
        if (i === splitPath.length - 1) {
          node.size = file.size
          node.type = 'file'
          node.file = file
          ptr[splitPath[i]] = ptr[splitPath[i]] || node
        } else {
          ptr[splitPath[i]] = ptr[splitPath[i]] || node
          ptr[splitPath[i]].children = ptr[splitPath[i]].children || {}
          ptr = ptr[splitPath[i]].children
        }
      }
    }

    ////Format object to array/
    function objectToArr(node) {
      Object.keys(node).map((key) => {
        if (node[key].children) {
          objectToArr(node[key])
        }
      })
      if (node.children) {
        node.children = Object.values(node.children)
        node.children.forEach(objectToArr)
      }
    }

    fileArray.map(addNode)
    objectToArr(tree)
    return Object.values(tree)
  }



  ///Upload folder
  const uploadFolder = async (children, parent_id, rootId, folder) => {
    for (let index = 0; index < children.length; index++) {
      let item = children[index]
      if (item.type === 'file' &&
        uploadStore.statusUpload[rootId] !== 'error' &&
        uploadStore.statusUpload[rootId] !== 'cancelled') {
        let file = item.file
        file.cancelSource = folder.cancelSource
        let extension = fileUtils.getFileExtension(file.name)
        let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']
        let payload = { mimetype: mimetype, name: file.name, parent_id: parent_id, capacity: file.size }

        ///GetLink
        await uploadStore.getUploadLink(payload)
          .then(async res => {
            if (res.error_code === 0) {
              uploadStore.setNotEnoughCapacity(false)
              ///upload s3
              if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
                await uploadStore.uploadFiles(res.data.url, file)
                  .then(async response => {
                    ////Active file
                    if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
                      await uploadStore.activeUploadFiles({ node_id: res.data.node_id, mimetype: mimetype })
                        .then(response => {
                          file.id = response?.data.id
                          uploadStore.updateFileUpload(folder, 1, (percent) => {
                            if (percent === 100) {
                              setTimeout(() => {
                                reloadData()
                                profileStore.getMyProfileNotShowSpinner()
                              }, 3000)
                            }
                          })
                          return response.data
                        }).catch(error => {
                          uploadStore.updateStatusFile(rootId, 'error')
                          error?.response?.status != 401 && message.error(error.message)
                        })
                    }
                  }).catch(error => {
                    uploadStore.updateFileUpload(folder, 0, () => {
                    })
                    if (axios.isCancel(error)) {
                      uploadStore.updateStatusFile(rootId, 'cancelled')
                      uploadStore.handleMessageUpload(rootId, t('i0716'))
                      //uploadStore.cancelUploadFolder(folder.id)
                      reloadData()
                    } else {
                      uploadStore.handleMessageUpload(rootId, error.message)
                      uploadStore.updateStatusFile(rootId, 'error')
                      reloadData()
                    }
                  })
              }

              return res.data
            } else {
              if (res.error_code === 1) {
                uploadStore.setNotEnoughCapacity(true)
              }
              uploadStore.handleMessageUpload(file.uid, res.message)
              uploadStore.updateStatusFile(rootId, 'error')
              reloadData()
              uploadStore.reSetFileList()
            }
          })
          .catch(error => {
            uploadStore.handleMessageUpload(rootId.uid, error.message)
            uploadStore.updateStatusFile(rootId, 'error')

          })
      } else if (item.type === 'directory' &&
        uploadStore.statusUpload[rootId] !== 'error'
        && uploadStore.statusUpload[rootId] !== 'cancelled') {
        let params = {
          parent_id: parent_id,
          name: item.name,
        }
        if (uploadStore.statusUpload[rootId] !== 'cancelled' && uploadStore.statusUpload[rootId] !== 'error') {
          uploadStore.createFolderUpload(params, folder.cancelSource)
            .then(response => {
              if (response.error_code !== 0) {
                message.error(response.message)
              } else {
                item.id = response?.data.id
                children[index] = item
                uploadStore.updateFileUpload(folder, 0, () => {
                })
                uploadFolder(item?.children, response?.data?.id, rootId, folder)
              }
            })
            .catch(error => {
              uploadStore.updateFileUpload(folder, 0, () => {
              })
              if (axios.isCancel(error)) {
                //uploadStore.cancelUploadFolder(folder.id)
                uploadStore.updateStatusFile(rootId, 'cancelled')
                uploadStore.handleMessageUpload(rootId, t('i0716'))
                reloadData()
              } else {
                uploadStore.handleMessageUpload(rootId, error.message)
                uploadStore.updateStatusFile(rootId, 'error')
              }
            })
        }
      }
    }

  }

  const handleUploadProgress = (progressEvent) => {

  }

  ///Upload file
  const singleUpload = async (file, parent_id) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    file.cancelSource = source
    if (uploadStore.statusUpload[file.uid] !== 'cancelled' && uploadStore.statusUpload[file.uid] !== 'error') {
      if (file.size > systemConfig?.max_upload) {
        message.error(t('i0270'))
        uploadStore.updateStatusFile(file.uid, 'error')
        return await Promise.all([])
      } else {
        let extension = fileUtils.getFileExtension(file.name)
        let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']
        const payload = {
          mimetype: mimetype,
          name: file.name,
          parent_id: parent_id,
          capacity: file.size,
        }
        let activeFile
        ///Get Link
        const getLinkUpload = await uploadStore.getUploadLink(payload)
          .then(res => {
            if (res.error_code === 0) {
              return res.data
            } else {
              uploadStore.handleMessageUpload(file.uid, res.message)
              uploadStore.updateStatusFile(file.uid, 'error')
            }
          })
          .catch(error => {
            uploadStore.handleMessageUpload(file.uid, error.message)
            uploadStore.updateStatusFile(file.uid, 'error')
          })

        ///Upload to s3
        if (getLinkUpload && getLinkUpload.url) {
          const uploadToS3 = await uploadStore.uploadFiles(getLinkUpload.url, file, handleUploadProgress)
            .then(res => {
              ////Active file
              if (uploadStore.statusUpload[file.uid] !== 'cancelled' && uploadStore.statusUpload[file.uid] !== 'error') {
                activeFile = uploadStore.activeUploadFiles({
                  node_id: getLinkUpload.node_id,
                  mimetype: mimetype,
                }).then(response => {
                  uploadStore.updateStatusFile(file.uid, 'success')
                  //reloadData()
                  profileStore.getMyProfileNotShowSpinner()
                  return response.data
                }).catch(error => {
                  uploadStore.updateStatusFile(file.uid, 'error')
                  uploadStore.handleMessageUpload(file.uid, error.message)
                  return error
                  //error?.response?.status != 401 && message.error(error.message)
                })
              }
            }).catch(error => {
              if (axios.isCancel(error)) {
                uploadStore.handleMessageUpload(file.uid, 'Đã huỷ tải lên')
                uploadStore.updateStatusFile(file.uid, 'cancelled')
              } else {
                uploadStore.handleMessageUpload(file.uid, error.message)
                uploadStore.updateStatusFile(file.uid, 'error')
              }
              //error?.response?.status != 401 && message.error(error.message)
            })
          return await Promise.all([getLinkUpload, uploadToS3, activeFile])
        } else {
          return await Promise.all([getLinkUpload])
        }
      }
    } else {
      return await Promise.all([])
    }
  }

  const dragActiveBorderStyle = {
    borderOpacity: 0.5,
    boxShadow: '0px 0px 0px 2px rgba(35,123,211,0.3)',
  }


  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    let cloneFileList = fileList.map(item => {
      let idUpload = uuid()
      let extension = fileUtils.getFileExtension(item.name)
      let mimetype = mimeTypesByExtension[extension] || mimeTypesByExtension['default']

      uploadStore.updateStatusFile(idUpload, 'uploading')
      if (item.type === 'directory') {
        let results = []
        fileUtils.findObjects(item, 'type', 'file', results)
        item.totalFile = results.length
        item.totalDone = 0;
        item.cancelSource = source
        item.status = 'uploading'
        item.parent_id = myDriveCurrentNode?.id
        item.mimetype = mimetype
        item.uid = idUpload
        return item
      }else {
        item.file.cancelSource = source
        item.file.status = 'uploading'
        item.file.parent_id = myDriveCurrentNode?.id
        item.file.mimetype = mimetype
        item.file.uid = idUpload
        return item.file
      }

    })
    const upload = async () => {
      let i = 0
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      uploadStore.setFileList(cloneFileList)
      while (i < cloneFileList.length) {
        let file = cloneFileList[i]
        if (file.type !== 'directory') {
          await singleUpload(file, myDriveCurrentNode?.id)
          file.cancelSource = source
          if (i === cloneFileList.length - 1) {
            setTimeout(() => {
              reloadData()
            }, 1500)
          }
        } else {
          let folder = file
          let params = {
            parent_id: myDriveCurrentNode?.id,
            name: folder.name,
          }

          uploadStore.createFolderUpload(params, source)
            .then(response => {
              if (response.error_code !== 0) {
                message.error(response.message)
              } else {
                folder.id = response?.data.id
                folder.totalDone = 0
                folder.cancelSource = source
                folder.parent_id = myDriveCurrentNode?.id
                uploadStore.updateFileUpload(folder, 0, () => { })
                uploadFolder(folder.children, response?.data.id, folder.uid, folder)
              }
            },
            ).catch(error => {
              error?.response?.status != 401 && message.error(error.message)
            })
        }
        i++
      }
    }
    upload()
  }, [fileList])


  return (
    <UploadWrapper>
      <Dropzone
        onDrop={onDropFile}
        noClick={noClick}
        onDragOver={(e) => {
          setIsDragActive(true)
        }}
        onDragLeave={(e) => {
          setIsDragActive(false)
        }}
      >
        {
          ({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className={'upload-body'}
                style={isDragActive ? { ...dragActiveBorderStyle } : {}}>
                <input {...getInputProps()} directory={'true'} />
                {
                  children
                    ? children
                    : (
                      <div className='ant-upload-drag-icon'>
                        <ImageDefaultUpload src={`${process.env.PUBLIC_URL}/assets/imgs/empty-file-list.png`} alt='' />
                        <p className={'upload-text'}>{t('i0099')}</p>
                        <p className={'upload-text'}>{t('i0100')}</p>
                      </div>
                    )
                }
              </div>
            </section>
          )
        }
      </Dropzone>
    </UploadWrapper>
  )
}


export default inject(
  'uploadStore',
  'myDriveStore',
  'appConfigStore',
  'profileStore',
)(observer(DragAndDropUpload))
