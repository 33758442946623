/* eslint-disable react-hooks/exhaustive-deps */
// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FileTitleWrapper, PageWrapper, FileName, FilePreviewWrapper, FilePreviewName } from './FilePreviewPageStyled'
import { Button, message, Tooltip } from 'antd'
import SVGIcon from '../../components/SVGIcon'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ModalInputPassword from '../../components/ModalInputPassword'
import filePreviewStore from '../../stores/filePreviewStore'
import { useTranslation } from 'react-i18next'
import FileDownload from 'js-file-download'
import axios from 'axios'
import { MIMETYPE_OFFICE, MIMETYPE_PDF } from '../../utils/constant'

const FilePreviewPage = props => {

  const { filePreviewStore, appConfigStore, authenticationStore, previewOverlayStore } = props
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const [nodeId, setNodeId] = useState('')
  const [fileError, setFileError] = useState(false)
  const [file, setFile] = useState(null)
  const [typeName, setTypeName] = useState('')
  const [visibleModalPass, setVisibleModalPass] = useState(false)
  const { fileTypes, mimetypeGroups, iconsByMimeTypeObj, groupIdsByMimetype } = appConfigStore

  useEffect(() => {
    if (location.pathname.includes('/driver/file/') || location.pathname.includes('/driver/folders/')) {
      let split = location.pathname.replace(/^\/|\/$/g, '').split('/')
      let node_id = split[split.length - 1]
      setNodeId(node_id)
      if (authenticationStore.accessToken) {
        filePreviewStore.checkShareLinkFile({ node_id: node_id })
          .then(response => {
            if (response?.error_code === 0) {
              if (!response?.data?.is_me) {
                setVisibleModalPass(true)
              } else {
                history.push('/shared-with-me')
              }
            } else if (response?.error_code === 403) {
              history.push('/not-permission')
            } else {
              setFileError(true)
              message.error(response?.message)
            }
          })
      } else {
        checkPermissionFile(node_id)
        if (location.pathname.includes('folders')) {
          history.push(`/driver/folders/${node_id}`)
        }
      }
    }
  }, [location.pathname, authenticationStore.accessToken])


  const checkPermissionFile = (node_id) => {
    filePreviewStore.checkFilePermission({ node_id: node_id }).then(res => {
      if (res.error_code === 0) {
        let data = res.data
        if (!data.is_secret && data.password_required) {
          setVisibleModalPass(true)
        } else if (!data.is_secret && !data.password_required) {
          getDetailFileUnauthenticated(node_id)
        } else if (data.is_secret) {
          history.push('/not-permission')
        }
      } else {
        setFileError(true)
        message.error(res.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }

  const getDetailFileUnauthenticated = (node_id, password) => {
    filePreviewStore.getDetailFileUnauthenticated({
      node_id: node_id,
      password: password,
      node_share_id: node_id,
    }).then(res => {
      if (res.error_code === 0) {
        if (authenticationStore.accessToken) {
          return history.push('/shared-with-me')
        }

        let resFile = res.data
        let group_id = fileTypes.find(item => item.mimetype === resFile.mimetype)?.group_id
        let type_name = mimetypeGroups.find(item => item.id === group_id)?.name
        setTypeName(type_name)
        if (type_name === 'Image' || type_name === 'Video' || type_name === 'Audio') {
          resFile.type_name = type_name
        }
        setFile(resFile)
        setVisibleModalPass(false)
      } else {
        message.error(res.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }


  const onHandleDownloadFile = () => {
    filePreviewStore.getPublicLinkDownloadFile({ node_id: nodeId }).then(response => {
      if (response?.error_code === 0) {
        if (response.data?.url) {
          axios.get(response.data.url, {
            responseType: 'blob',
          }).then(res => {
            FileDownload(res.data, file?.type === 1 ? `${file.name}.zip` : file.name)
          })
        }
      } else {
        message.error(response?.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }

  const onCloseModal = () => {
    setFileError(true)
    setVisibleModalPass(false)
  }

  const onConFirmModal = (password) => {
    getDetailFileUnauthenticated(nodeId, password)
  }
  const handleClickPreview = () => {
    if (file.type_name === 'Image' || file.type_name === 'Video' || file.type_name === 'Audio') {
      previewOverlayStore.setVisiblePreviewOverlay()
      previewOverlayStore.setRecordOverlay(file)
    }
    if (MIMETYPE_OFFICE.includes(file.mimetype)) {
      const win = window.open(`/doc-viewer/${authenticationStore.accessToken ? file.id : file.encrypt_id}`, '_blank')
      win.focus()
    }
    if (MIMETYPE_PDF.includes(file.mimetype)) {
      const win = window.open(`/pdf-viewer/${authenticationStore.accessToken ? file.id : file.encrypt_id}`, '_blank')
      win.focus()
    }
  }
  useEffect(() => {
    console.log('file?.type_name', file?.type_name)
    if (!file) return
    if (fileTypes?.length === 0) return
    if (mimetypeGroups?.length === 0) return
    let group_id = fileTypes.find(item => item.mimetype === file.mimetype)?.group_id
    let type_name = mimetypeGroups.find(item => item.id === group_id)?.name
    setTypeName(type_name)
    console.log('type_name', type_name)
    if (type_name === 'Image' || type_name === 'Video' || type_name === 'Audio') {
      file.type_name = type_name
    }
  }, [file, file?.type_name, fileTypes, mimetypeGroups])

  return (
    <>
      <Helmet>
        <title>mobiCloud</title>
      </Helmet>
      {
        file && <PageWrapper>
          <FileTitleWrapper>
            <FileName>
              <SVGIcon url={iconsByMimeTypeObj[file.mimetype] || iconsByMimeTypeObj['default']} width={18}
                       height={18} />
              <p>{file.name}</p>
            </FileName>
            <div>
              {!fileError &&
              <Tooltip title={t('i0702')} placement={'bottom'}>
                <Button type={'text'}>
                  <SVGIcon
                    onClick={onHandleDownloadFile}
                    name={'download-white'}
                    width={28.04} height={26}
                  />
                </Button>
              </Tooltip>
              }
              <Button type={'primary'} onClick={() => history.push('/auth/login')}>
                {t('i0683')}
              </Button>
            </div>
          </FileTitleWrapper>
          <FilePreviewWrapper>
            {
              (typeName === 'Image' || typeName === 'Video' || typeName === 'Audio' || MIMETYPE_OFFICE.includes(file.mimetype) || MIMETYPE_PDF.includes(file.mimetype))
                ?
                <>
                  <Tooltip title={file.name}>
                    <FilePreviewName>{file.name}</FilePreviewName>
                  </Tooltip>
                  <Button type={'primary'} onClick={handleClickPreview}>Xem trước</Button>
                </>
                :
                <>
                  <p>{t('i0684')}</p>
                  {!fileError ?
                    <Button type={'primary'} onClick={onHandleDownloadFile}>
                      {t('i0685')}
                    </Button> : <p>{t('i0686')}</p>
                  }
                </>
            }
          </FilePreviewWrapper>
        </PageWrapper>
      }

      <ModalInputPassword
        title={t('i0687')}
        visible={visibleModalPass}
        closable={false}
        footer={null}
        onCloseModal={onCloseModal}
        onConFirmModal={onConFirmModal}
      />
    </>
  )
}

FilePreviewPage.propTypes = {}

export default inject('filePreviewStore', 'appConfigStore', 'authenticationStore', 'previewOverlayStore')(observer(FilePreviewPage))
