import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
  ActionButtonWrapper,
  ActionMenuIcon,
  ActionMenuInsert,
  ActionMenuItem,
  ActionMenuText,
  BottomMenu,
  Capacity,
  Copyright, CustomScroll,
  HorizontalLine,
  LogoWrapper,
  MainMenu,
  Scroller,
  SidebarWrapper,
} from './MainSidebarStyled'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Dropdown, Menu, message, Progress, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import SvgIcon from '../SVGIcon'
import InsertFolderSimpleModal from '../InsertFolderSimpleModal'
import ModalChooseUpload from '../ModalChooseUpload'
import stringUtils from '../../utils/stringUtils'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { BUY_CAPACITY_LINK, CONTENT_TYPE } from '../../utils/constant'
import { useTranslation } from 'react-i18next'

const MainSidebar = props => {

  const { myDriveStore, favoriteStore, commonStore, profileStore, uploadStore, searchStore } = props
  const { t } = useTranslation()

  const [insertFolderSimpleModalVisible, setInsertFolderSimpleModalVisible] = useState(false)
  const [visibleModalChooseUpload, setVisibleModalChooseUpload] = useState(false)
  const [keyUpload, setKeyUpload] = useState()

  const history = useHistory()
  const location = useLocation()

  const handleClickInsertFolderSimple = () => {
    setInsertFolderSimpleModalVisible(true)
  }
  const onHandleActionAddNew = (e) => {
    setKeyUpload(e.key)
    uploadStore.setKeyUpload(e.key)
    switch (e.key) {
      case 'uploadFiles':
      case 'uploadFolder':
        setVisibleModalChooseUpload(true)
        break
      case 'createFolder':
        handleClickInsertFolderSimple()
        break
      default:
        break
    }
  }
  const getRootMyFile = () => {
    if (!myDriveStore.myDriveCurrentNode) return
    let payload = {
      parent_id: null,
      content_type: CONTENT_TYPE.myDrive,
      page: 1,
      per_page: myDriveStore.perPage,
      sort: myDriveStore.myDriveSort,
      sort_by: myDriveStore.myDriveSortBy,
    }
    myDriveStore.clearMyDriveList()
    myDriveStore.getMyDriveList(payload).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  const getRootFavorite = () => {
    if (!favoriteStore.favoriteCurrentParentId) return
    favoriteStore.clearFavoriteList()
    favoriteStore.getFavoriteList().then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  const renderTooltipBuyCapacity = () => {
    if (profileStore?.myProfile?.package?.type === 0) {
      return t('i0655')
    } else {
      return t('i0656')
    }
  }
  const handleNavigate = menu => {
    switch (menu.key) {
      case 'my-drive':
        getRootMyFile()
        break
      case 'favorite':
        getRootFavorite()
        break
      default:
        break
    }
    history.push(`/${menu.key}`)
  }
  const handleClickBuyCapacity = () => {
    window.open(BUY_CAPACITY_LINK)
  }
  const handlePreventBodyScroll = state => {
    const clientWidth = document.body.clientWidth
    const windowsWidth = window.innerWidth
    const body = document.querySelector('body')
    if (clientWidth !== windowsWidth) {
      commonStore.checkIsMenubarHover(state)
      state
        ? body.classList.add('no-scroll')
        : body.classList.remove('no-scroll')
    }
  }

  const actionMenuDropdown = (
    <ActionMenuInsert onClick={onHandleActionAddNew}>
      <Menu.Item key='uploadFiles'>
        <ActionMenuItem>
          <ActionMenuIcon><SvgIcon name={'file-arrow-up'} width={20} height={20}/></ActionMenuIcon>
          <ActionMenuText>{t('i0240')}</ActionMenuText>
        </ActionMenuItem>
      </Menu.Item>
      <Menu.Item key='uploadFolder'>
        <ActionMenuItem>
          <ActionMenuIcon><SvgIcon name={'folder-arrow-up'} width={20} height={20}/></ActionMenuIcon>
          <ActionMenuText>{t('i0241')}</ActionMenuText>
        </ActionMenuItem>
      </Menu.Item>
      <Menu.Item key='createFolder'>
        <ActionMenuItem>
          <ActionMenuIcon><SvgIcon name={'folder-plus'} width={20} height={20}/></ActionMenuIcon>
          <ActionMenuText>{t('i0242')}</ActionMenuText>
        </ActionMenuItem>
      </Menu.Item>
    </ActionMenuInsert>
  )

  useEffect(() => {
    searchStore.clearTemporarySearchList()
    const segment = location.pathname.split('/').filter(item => item !== '')
    switch (segment[0]) {
      case 'my-drive':
        commonStore.setPageName('my-drive')
        return
      case 'synchronization':
        commonStore.setPageName('synchronization')
        return
      case 'shared-with-me':
        commonStore.setPageName('shared-with-me')
        return
      case 'recently-opened':
        commonStore.setPageName('recently-opened')
        return
      case 'favorite':
        commonStore.setPageName('favorite')
        return
      case 'offline-files':
        commonStore.setPageName('offline-files')
        return
      case 'trash':
        commonStore.setPageName('trash')
        return
      case 'my-profile':
        commonStore.setPageName('my-profile')
        return
      case 'my-profile-edit':
        commonStore.setPageName('my-profile-edit')
        return
      case 'history-login':
        commonStore.setPageName('history-login')
        return
      case 'search':
        commonStore.setPageName('search')
        return
      case 'config-page':
        commonStore.setPageName('config-page')
        return
      case 'change-password':
        commonStore.setPageName('change-password')
        return
      default:
        return
    }
  }, [location.pathname])

  return (
    <SidebarWrapper>
      <LogoWrapper to={'/'}>
        <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_home.png`} alt=''/>
      </LogoWrapper>
      <ActionButtonWrapper>
        <Dropdown overlay={actionMenuDropdown} trigger={['click']}>
          <Button type={'primary'} block>
            <PlusOutlined/>
            {t('i0001')}
          </Button>
        </Dropdown>
      </ActionButtonWrapper>
      <Scroller>
        <CustomScroll
          onMouseEnter={() => handlePreventBodyScroll(true)}
          onMouseLeave={() => handlePreventBodyScroll(false)}>
          <MainMenu>
            <Menu
              onClick={handleNavigate}
              selectedKeys={commonStore.pageName}
              mode='inline'>
              <Menu.Item key={'my-drive'}>
                <SvgIcon name={'drive'} width={23} height={20}/>
                <span>{t('i0002')}</span>
              </Menu.Item>
              <Menu.Item key={'synchronization'}>
                <SvgIcon name={'sync'} width={16.69} height={18}/>
                <span>{t('i0003')}</span>
              </Menu.Item>
              <Menu.Item key={'shared-with-me'}>
                <SvgIcon name={'user-group'} width={16.69} height={18}/>
                <span>{t('i0004')}</span>
              </Menu.Item>
              <Menu.Item key={'recently-opened'}>
                <SvgIcon name={'clock'} width={16.69} height={18}/>
                <span>{t('i0005')}</span>
              </Menu.Item>
              <Menu.Item key={'favorite'}>
                <SvgIcon name={'star'} width={20} height={19.14}/>
                <span>{t('i0006')}</span>
              </Menu.Item>
              {/*<Menu.Item key={'offline-files'}>*/}
              {/*  <SvgIcon name={'offline'} width={20} height={18}/>*/}
              {/*  <span>{t('i0007')}</span>*/}
              {/*</Menu.Item>*/}
              <Menu.Item key={'trash'}>
                <SvgIcon name={'trash'} width={15} height={18}/>
                <span>{t('i0008')}</span>
              </Menu.Item>
            </Menu>
          </MainMenu>
        </CustomScroll>
      </Scroller>
      <HorizontalLine/>
      <Capacity>
        <SvgIcon name={'cloud'} width={24} height={16}/>
        <strong>{t('i0009')}</strong>
        <Progress
          percent={(
            profileStore.myProfile?.used_capacity / profileStore.myProfile?.package?.capacity) * 100
          }
          status='active'
          showInfo={false}/>
        <p style={{ marginBottom: 24 }}>
          {t('i0010')} {stringUtils.renderCapacity(profileStore.myProfile?.used_capacity)}/{stringUtils.renderCapacity(profileStore.myProfile?.package?.capacity)}
        </p>
        {/*<Tooltip title={renderTooltipBuyCapacity()}>*/}
        <Button onClick={handleClickBuyCapacity}>{t('i0011')}</Button>
        {/*</Tooltip>*/}
      </Capacity>
      <BottomMenu>
        <HorizontalLine/>
        <ul>
          <li>
            <Link to={'/introduction'}>{t('i0012')}</Link>
          </li>
          <li>
            <Link to={'/term-of-use'}>{t('i0013')}</Link>
          </li>
          <li>
            <Link to={'/privacy-policy'}>{t('i0014')}</Link>
          </li>
        </ul>
      </BottomMenu>
      <Copyright>
        &copy; 2021 - Mobifone
      </Copyright>
      <InsertFolderSimpleModal visible={insertFolderSimpleModalVisible}
                               onClose={() => setInsertFolderSimpleModalVisible(false)}/>

      <ModalChooseUpload
        title={t('i0015')}
        visible={visibleModalChooseUpload}
        onCancel={() => setVisibleModalChooseUpload(false)}
        footer={null}
        keyUpload={keyUpload}
        onClose={() => setVisibleModalChooseUpload(false)}
      />
    </SidebarWrapper>
  )
}

export default inject(
  'myDriveStore',
  'favoriteStore',
  'profileStore',
  'uploadStore',
  'commonStore',
  'searchStore',
)(observer(MainSidebar))
