import React, { useEffect, useState } from 'react'
import { SyncDataTitle, SyncDataWrapper } from './SyncDataTabPaneStyled'
import { inject, observer } from 'mobx-react'
import { Button, message } from 'antd'
import { gapi } from 'gapi-script'
import config from '../../config'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './OneDriveConfig/OneDriveAuthen'
import { useTranslation } from 'react-i18next'
import profileStore from '../../stores/profileStore'
import { APP_NAME } from '../../utils/constant'

const SyncDataTabPane = props => {
  const { synchronizationStore } = props
  const { t } = useTranslation()
  const { accessTokenGoogleDrive } = synchronizationStore
  const { instance, accounts } = useMsal()
  const { googleDriveConfig } = config
  const { apiKey, clientId, discoveryDocs, scope } = googleDriveConfig
  const [accessOneDriveToken, setAccessOneDriveToken] = useState(undefined)
  const [isSignedIn, setIsSignedIn] = useState(false)

  const handleGoogleDriveClientLoad = () => {
    gapi.load('client:auth2', googleDriveInitClient)
  }

  const googleDriveInitClient = () => {
    gapi.client.init({
      'apiKey': apiKey,
      'clientId': clientId,
      'scope': scope,
      'discoveryDocs': discoveryDocs,
    }).then(function() {
      gapi.auth2.getAuthInstance().isSignedIn.listen(updateSignInStatus)
      updateSignInStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
    })
  }

  const updateSignInStatus = (isSignedIn) => {
    setIsSignedIn(isSignedIn)
  }

  const handleSignInGoogleDrive = () => {
    gapi.auth2.getAuthInstance().signIn()
      .then(res => {
        synchronizationStore.handleAuthenGoogleDrive(res.xc)
          .then(() => {
            synchronizationStore.getFileListOnGoogleDrive(undefined, 1000).then(res => {
              if (res.files) {
                let totalFolder = 0
                let filterFolder = []
                filterFolder = res.files.filter(item => item.mimeType === 'application/vnd.google-apps.folder')
                if (filterFolder) totalFolder = filterFolder.length
                message.success(`${t('i0696')} ${totalFolder} ${t('i0697')} / ${res.files.length - totalFolder} ${t('i0698')}`)
              }
            })
          })
        // message.success(t('i0383'))
      })
      .catch(e => {
        // message.error(`${t('i0384')} ${e.message}`)
      })
  }

  const handleCloseConnectGoogleDrive = () => {
    gapi.auth2.getAuthInstance().signOut().then(() => {
      updateSignInStatus()
      synchronizationStore.handleAuthenGoogleDrive(undefined)
    })
  }

  const handleLoginOneDrive = (loginType) => {
    if (loginType === 'popup') {
      instance.loginPopup(loginRequest).then(res => {
        // message.success(t('i0385'))
      }).catch(e => {
        // message.error(`${t('i0386')} ${e.message}`)
      })
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e)
      })
    }
  }

  const handleCloseConnectOneDrive = (logoutType) => {
    synchronizationStore.handleAuthenOneDrive(undefined)
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/config-page/4',
        mainWindowRedirectUri: '/config-page/4',
      })
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      })
    }
  }
  // Lấy OneDrive's access_token sau khi login
  useEffect(() => {
    if (synchronizationStore.accessTokenOneDrive) return
    if (accounts && accounts.length > 0) {
      console.log(accounts)
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      }).then((response) => {
        synchronizationStore.handleAuthenOneDrive(response.accessToken)
          .then(() => {
            synchronizationStore.onedriveGetChildrenByFolderId(synchronizationStore.accessTokenOneDrive, 'root').then(res => {
              if (res.value) {
                let totalFolder = 0
                let filterFolder = []
                filterFolder = res.value.filter(item => item.folder)
                if (filterFolder) totalFolder = filterFolder.length
                message.success(`${t('i0696')} ${totalFolder} ${t('i0697')} / ${res.value.length - totalFolder} ${t('i0698')}`)
              }
            })
          })

      }).catch(error => {
        console.log(error)
        synchronizationStore.handleAuthenOneDrive(undefined)
        // setIsVisibleModal(true)
        // message.error(t('i0387'))
      })
    } else {
      return
      // setIsVisibleModal(true)
    }
  }, [accounts, synchronizationStore.accessTokenOneDrive])

  useEffect(() => {
    handleGoogleDriveClientLoad()
  })

  return (
    <div>
      <SyncDataTitle>
        {t('i0662')} {APP_NAME} {t('i0663')}
      </SyncDataTitle>
      <SyncDataWrapper>
        <strong>Google Drive</strong>
        <Button className={'submit-config-btn'} onClick={() => {
          if (accessTokenGoogleDrive) {
            handleCloseConnectGoogleDrive()
          } else {
            handleSignInGoogleDrive()
          }
        }} type={'primary'}>{(accessTokenGoogleDrive) ? t('i0389') : t('i0390')} </Button>
      </SyncDataWrapper>
      <SyncDataWrapper>
        <strong>One Drive</strong>
        <Button className={'submit-config-btn'} onClick={() => {
          if (synchronizationStore.accessTokenOneDrive) {
            handleCloseConnectOneDrive('popup')
          } else {
            handleLoginOneDrive('popup')
          }
        }} type={'primary'}>{synchronizationStore.accessTokenOneDrive ? t('i0389') : t('i0390')} </Button>
      </SyncDataWrapper>
    </div>
  )
}


SyncDataTabPane.propTypes = {}

export default inject(
  'synchronizationStore',
  'commonStore',
)(observer(SyncDataTabPane))