import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Form, Input, message } from 'antd'
import { inject, observer } from 'mobx-react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FormWrapper, LogoWrapper } from '../../../layouts/AuthLayout/AuthLayoutStyled'
import { ArrowLeftOutlined } from '@ant-design/icons'
import validator from '../../../validator'
import { useTranslation } from 'react-i18next'

const RegisterForm = props => {

  const { authenticationStore } = props
  const [errorMessage, setErrorMessage] = useState({})
  const [registerForm] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const onRegister = info => {
    if (info.accept_policy) {
      authenticationStore.setRegisterInfoCache(info)
      authenticationStore.userRegister(info)
        .then(response => {
          console.log(response)
          if (response.error_code === 0) {
            history.push('/auth/otp')
            authenticationStore.setUserIdVerifying(response.data.user_id)
          } else {
            setErrorMessage(response.data)
            ///message.error(response.message)
          }

        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
    } else {
      message.error(t('i0711'))
    }
  }

  const handleClickBackLogin = () => {
    history.push('/auth/login')
  }

  const onHandleFieldsChange = (changedFields, allValues) => {
    if (changedFields && changedFields.length > 0) {
      let name = changedFields[0].name[0]
      if (errorMessage && errorMessage[name]) {
        setErrorMessage(null)
      }
    }
  }

  useEffect(() => {
    if (!authenticationStore.registerInfoCache) return
    registerForm.setFieldsValue(authenticationStore.registerInfoCache)
  }, [authenticationStore.registerInfoCache])

  return (
    <FormWrapper>
      <img src={`${process.env.PUBLIC_URL}/assets/icons/backleft.svg`}
           width={30} height={30}
           className={'back-login-icon'}
           onClick={handleClickBackLogin} alt={''} />
      <LogoWrapper>
        <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_register.png`} alt={''} />
      </LogoWrapper>
      <p style={{ marginTop: 15 }}>
        * {t('i0722')}
      </p>
      <Form
        form={registerForm}
        style={{ marginTop: '48px' }}
        layout={'vertical'}
        onFinish={onRegister}
        scrollToFirstError
        requiredMark={false}
        colon={false}
        onFieldsChange={onHandleFieldsChange}
      >
        <Form.Item
          name={'username'}
          label={t('i0438')}
          rules={[
            { required: true, message: t('i0439') },
            { validator: validator.validatePhoneNumber },
          ]}
          validateStatus={errorMessage && errorMessage['username'] ? 'error' : undefined}
          help={errorMessage && errorMessage['username'] ? errorMessage['username'][0] : undefined}
        >
          <Input placeholder={t('i0440')} className={'register-input'} />
        </Form.Item>
        <Form.Item
          name={'name'}
          label={t('i0441')}
          rules={[
            { required: true, message: t('i0442') },
            { max: 40, message: t('i0443') },
            { min: 3, message: t('i0665') },
          ]}
          validateStatus={errorMessage && errorMessage['name'] ? 'error' : undefined}
          help={errorMessage && errorMessage['name'] ? errorMessage['name'][0] : undefined}
        >
          <Input maxLength={41} minLength={3} placeholder={t('i0444')} className={'register-input'} />
        </Form.Item>
        <Form.Item
          name={'email'}
          label={'Email'}
          rules={[
            { required: true, message: t('i0445') },
            { type: 'email', message: t('i0446') },
          ]}
          validateStatus={errorMessage && errorMessage['email'] ? 'error' : undefined}
          help={errorMessage && errorMessage['email'] ? errorMessage['email'][0] : undefined}
        >
          <Input placeholder={t('i0447')} className={'register-input'} />
        </Form.Item>
        <Form.Item
          name={'password'}
          label={t('i0448')}
          rules={[
            { required: true, message: t('i0449') },
            { validator: validator.validatePasswordNew },
          ]}
          validateStatus={errorMessage && errorMessage['password'] ? 'error' : undefined}
          help={errorMessage && errorMessage['password'] ? errorMessage['password'][0] : undefined}
        >
          <Input.Password maxLength={19} placeholder={t('i0450')} className={'register-input'} />
        </Form.Item>
        <Form.Item
          name={'password_confirmation'}
          label={t('i0451')}
          rules={[
            { required: true, message: t('i0452') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('i0453')))
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('i0454')} className={'register-input'} />
        </Form.Item>
        <Form.Item
          name={'accept_policy'}
          valuePropName='checked'
          noStyle
        >
          <Checkbox style={{ marginBottom: 16 }}>{t('i0709')} <Link target={'_blank'}
                                                                    to={'/term-of-use'}>{t('i0710')}</Link></Checkbox>
        </Form.Item>
        <Button type={'primary'} htmlType={'submit'} block className={'register-btn'}>
          {t('i0455')}
        </Button>
      </Form>
    </FormWrapper>
  )
}

RegisterForm.propTypes = {
  children: PropTypes.node,
  authenticationStore: PropTypes.object,
}

export default inject(
  'authenticationStore',
)(observer(RegisterForm))
