import styled from 'styled-components'
import { Modal } from 'antd'

export const ChangeAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px;
  margin-left: 64px;
  margin-top: 48px;
  cursor: pointer;
  img {
    border-radius: 50%;
    margin-bottom: 16px;
  }
  .edit-account-btn {
    border: 1px solid #237BD3;
    color: #237BD3;
    border-radius: 8px;
    padding: 0 32px;
    .ant-upload {
      color: #237BD3;
    }
  }
`
export const ImgCropModal = styled(Modal)`
    .ant-modal-content {
      border-radius: 8px;
      .ant-modal-header {
        border-radius: 8px 8px 0px 0px;
        text-align:center;
        border:0px
      }
      .ant-modal-body{
        
      }
    }
`
export const CropContainer = styled.div`
    position: relative;
    height: 300px;
    .reactEasyCrop_Container{
      border-radius: 2px;
    }
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  .btn-cancel{
    border-radius: 8px;
    width:100px;
    margin-right:15px
  }
  .btn-save {
    border-radius: 8px;
    width:100px;
  }
`

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:15px;
  .ant-slider{ 
    width:380px;
    margin: 0 10px 0 10px;
  }
  
`
