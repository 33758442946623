import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()
export const ItemDriveRequest = {
  cancelRequest: () => {
    source.cancel()
  },

  getItemDriveDetail: (node_id) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/nodes/${node_id}/detail`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

}