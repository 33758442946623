import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Button, Col, Form, Input, Row, Upload, Slider, Avatar, message } from 'antd'
import { CloudUploadOutlined, UserOutlined } from '@ant-design/icons'
import { ChangeAvatarWrapper, ImgCropModal, CropContainer, ButtonGroup, SliderWrapper } from './EditAccountInfoRowStyled'
import ImgCrop from 'antd-img-crop'
import 'antd/es/modal/style'
import 'antd/es/slider/style'
import { useTranslation } from 'react-i18next'

const EditAccountInfoRow = props => {
  const { profileStore, onCancel } = props
  const history = useHistory()
  const [form] = Form.useForm()
  const [fileCropped, setFileCropped] = useState(null)
  const [fileSrcCropped, setFileSrcCropped] = useState(null)

  const { t } = useTranslation()

  const handleSubmitEdit = (formData) => {
    profileStore.updateMyProfile(formData)
      .then(response => {
        if (response.error_code === 0) {
          profileStore.getMyProfile()
          history.push('/my-profile')
          message.success(t('i0101'))
        } else {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))

  }

  useEffect(() => {
    if (!profileStore.myProfile) return
    form.setFieldsValue({
      name: profileStore.myProfile.name,
      phone: profileStore.myProfile.username,
      email: profileStore.myProfile.email,
    })
  }, [profileStore.myProfile])


  const handleBeforeCrop = (file) => {
    console.log(file.size)
    const acceptType = [
      'image/jpeg',
      'image/jpg',
      'image/png',
    ]
    let isAcceptType = acceptType.includes(file.type)
    if (!isAcceptType) {
      message.error(t('i0102'))

    }
    if (file.size > 6291456) {
      message.error(t('i0719'))
      return false
    }
    return true
  }
  const handleBeforeUpload = (file) => {
    setFileCropped(file)
    let uploadData = new FormData()
    uploadData.append('file', file)
    profileStore.updateAvatar(uploadData)
      .then(response => {
        if (response.error_code === 0) {
          profileStore.getMyProfile()
        }
      })
      .catch(error => {
        error?.response?.status != 401 && message.error(error.message)
        setFileSrcCropped(null)
      })
    return false
  }

  useEffect(() => {
    if (!fileCropped) return
    const objectUrl = URL.createObjectURL(fileCropped)
    setFileSrcCropped(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [fileCropped])

  return (
    profileStore.myProfile &&
    <>
      <Row>
        <Col xxl={10} xl={10} lg={10} md={24} sm={24} xs={24}>
          <Form size={'large'} form={form} colon={false} layout={'vertical'}
                onFinish={handleSubmitEdit}
                requiredMark={false}
          >
            <Form.Item label={t('i0103')} name={'name'}
                       rules={[
                         { required: true, message: t('i0442') },
                         { max: 40, message: t('i0443') },
                         { min: 3, message: t('i0665') },
                       ]}
            >
              <Input placeholder={t('i0104')} style={{ borderRadius: '8px' }} />
            </Form.Item>
            <Form.Item label={t('i0105')} name={'phone'}>
              <Input placeholder={t('i0106')} disabled={true} style={{ borderRadius: '8px' }} />
            </Form.Item>
            <Form.Item label={'Email'} name={'email'}
                       rules={[
                         { required: true, message: t('i0445') },
                         { type: 'email', message: t('i0446') },
                       ]}
            >
              <Input placeholder={t('i0107')} style={{ borderRadius: '8px' }} />
            </Form.Item>
            <Form.Item>
              <Button type={'primary'} htmlType={'submit'} style={{ padding: '0 32px', borderRadius: '8px' }}>
                {t('i0108')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>

          <ChangeAvatarWrapper>
            {
              fileSrcCropped
                ?
                <img src={fileSrcCropped} width={150} height={150} />
                :
                profileStore.myProfile.avatar
                  ?
                  <img src={profileStore.myProfile.avatar} width={150} height={150} />
                  :
                  <Avatar size={150} icon={<UserOutlined />} style={{ marginBottom: '16px' }} />
            }
            <ImgCrop
              modalTitle={t('i0109')}
              beforeCrop={handleBeforeCrop}
              shape={'round'}
              modalOk={t('i0110')}
              modalCancel={t('i0111')}>
              <Upload
                listType={null}
                beforeUpload={handleBeforeUpload}
              >
                <Button type={'link'} className={'edit-account-btn'}><CloudUploadOutlined /> {t('i0112')}</Button>
              </Upload>
            </ImgCrop>
          </ChangeAvatarWrapper>
        </Col>
      </Row>

    </>
  )
}

EditAccountInfoRow.propTypes = {
  onCancel: PropTypes.func,
}

export default inject('profileStore')(observer(EditAccountInfoRow))