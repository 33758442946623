import styled from 'styled-components'

export const PageContainer = styled.main`
  background-color: #fff;
`
export const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  max-width: 1200px;
`
export const Jumbotron = styled.section`
  background: url(${props => props.bg}) #e0e0e0 no-repeat center center/cover;
  padding: 100px 0;
  margin-bottom: 30px;

  .jumbotron-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .text {
      width: 50%;

      h1 {
        font-size: 44px;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
      }

      h1, p {
        color: white;
        text-shadow: 0 0 5px black;
      }

      p {
        font-size: 18px;
      }
    }

    .img {
      width: 50%;
      padding-left: 30px;
      @media screen and (max-width: 768px) {
        padding: 0 60px;
      }

      img {
        width: 100%;
      }
    }

    .text, .img {
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
    }
  }
`
export const MainSection = styled.h1`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
`
export const FeatureList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`
export const FeatureListItem = styled.li`
  @media screen and (min-width: 769px) {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) and (min-width: 481px) {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 15px;
  }

  .title {
    font-size: 24px;
  }

  p {
    margin-bottom: 0;
    font-size: 18px;
  }
`
export const AppDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  a {
    margin: 0 15px 15px;
    overflow: hidden;
    border-radius: 5px;
  }
`
export const PageFooter = styled.footer`
  background: #fff;
  height: 70px;
  box-shadow: inset 0 1px 0 #E2E2EA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  flex-wrap: wrap;
  margin-top: 30px;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 15px;
  }

  .copyright {
    margin-bottom: 0;
    color: #858A8F;
    font-size: 14px;
    @media screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
      order: -1;
      margin-bottom: 15px;
    }

    li {
      &:not(:last-child) {
        display: flex;
        align-items: center;

        &:after {
          display: block;
          content: '-';
          margin: 0 5px;
        }
      }

      a {
        font-size: 14px;
        color: #313131;
        transition: all ease .3s;

        &:hover {
          color: #237BD3;
          transition: all ease .3s;
        }
      }
    }
  }
`
export const PageHeader = styled.header`
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 0 15px;
  }

  .button-group {
    display: flex;
    align-items: center;

    .ant-btn {
      margin-left: 10px;
    }
  }

  .logo {
    @media screen and (min-width: 481px) {
      img:last-child {
        display: none;
      }
    }
    @media screen and (max-width: 480px) {
      img {
        &:last-child {
          display: block;
        }

        &:first-child {
          display: none;
        }
      }
    }

    img:last-child {
      padding: 17px 0;
    }
  }
`