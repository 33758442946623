import { action, observable } from 'mobx'
import { FavoriteRequest } from '../requests/FavoriteRequest'
import { PAGE_SIZE } from '../utils/constant'

class FavoriteStore {
  @observable favoriteList = []
  @observable favoriteBreadcrumbData = []
  @observable favoriteCurrentNode = null
  @observable itemRollbackRename = null
  @observable itemRollbackTrash = null
  @observable favoriteSort = 'desc' // desc | asc
  @observable favoriteSortBy = 'updated_at' // name | updated_at

  @observable page = 1
  @observable perPage = PAGE_SIZE
  @observable hasNextPage = true

  @action setPage = (page) => {
    this.page = page
  }

  @action setFavoriteSort = (sort) => {
    this.favoriteSort = sort
  }
  @action setFavoriteSortBy = (sortBy) => {
    this.favoriteSortBy = sortBy
  }

  @action setItemRollbackRename = (itemDrive) => {
    this.itemRollbackRename = itemDrive
  }
  @action setItemRollbackTrash = (itemDrive) => {
    this.itemRollbackTrash = itemDrive
  }

  @action getBreadcrumb = (folder_id) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.getBreadcrumb(folder_id).then(response => {
        this.favoriteCurrentNode = response.data[response?.data?.length - 1] || null
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action setBreadcrumb = (root, data) => {
    this.favoriteBreadcrumbData = [root, ...data]
  }
  @action clearFavoriteList = () => {
    this.favoriteList.length = 0
    this.favoriteBreadcrumbData = []
    this.favoriteCurrentNode = null
  }
  @action getFavoriteListOnScroll = (payload) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.getFavoriteListScroll(payload).then(response => {
        this.favoriteList = [...this.favoriteList, ...response.data?.data]
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getFavoriteList = (payload) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.getFavoriteList(payload).then(response => {
        this.favoriteList = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action createFolder = (payload) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.createFolder(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action trashFavoriteItem = (payload) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.trashFavoriteItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action renameDriveItem = (payload) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.renameDriveItem(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action favoriteItemDrive = (node_id) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.favoriteItemDrive(node_id).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action removeFavoriteItemDrive = (node_id) => {
    return new Promise((resolve, reject) => {
      FavoriteRequest.removeFavoriteItemDrive(node_id).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action cancelRequest = () => {
    FavoriteRequest.cancelRequest()
  }
}

export default new FavoriteStore()
