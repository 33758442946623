import React, { memo } from 'react'
import PropTypes from 'prop-types'

const iconName = {
  'drive': 'drive.svg',
  'clock': 'clock.svg',
  'sync': 'sync.svg',
  'user-group': 'user-group.svg',
  'offline': 'offline.svg',
  'trash': 'trash.svg',
  'star': 'star.svg',
  'cloud': 'cloud.svg',
  'search-filter': 'search-filter.svg',
  'search': 'search.svg',
  'bell': 'bell.svg',
  'question-circle': 'question-circle.svg',
  'list-view': 'list-view.svg',
  'grid-view': 'grid-view.svg',
  'share': 'share.svg',
  'lock': 'lock.svg',
  'move-folder': 'movefolder.svg',
  'edit': 'edit.svg',
  'download': 'download.svg',
  'download-white': 'download-white.svg',
  'info-circle': 'info-circle.svg',
  'link': 'link.svg',
  'google-drive': 'google-drive.svg',
  'computer': 'computer.svg',
  'onedrive': 'onedrive.svg',
  'file-arrow-up': 'file-arrow-up2.svg',
  'folder-arrow-up': 'folder-arrow-up.svg',
  'folder-plus': 'folder-simple-plus2.svg',
  'down': 'down.svg',
  'up': 'up.svg',
  'pdf-color': 'pdf-color.svg',
  'zip-color': 'zip-color.svg',
  'huy': 'huy.svg',
  'globe': 'globe.svg',
  'users': 'users.svg',
  'calendar': 'calendar.svg',
  'minus-icon': 'minus-icon.svg',
  'plus-icon': 'plus-icon.svg',
  'blue-checkmark': 'blue-checkmark.svg',
  'success': 'success.svg',
  'warning': 'warning.svg',
  'reload':'reload.svg',
  // File list icon. Dùng tạm sau này lấy hàng từ server về
  'archive': 'files/archive.svg',
  'excel': 'files/excel.svg',
  'folder': 'files/folder.svg',
  'image': 'files/image.svg',
  'movie': 'files/movie.svg',
  'music': 'files/music.svg',
  'pdf': 'files/pdf.svg',
  'word': 'files/word.svg',
  'folder-upload': 'files/folder-plus.svg',
}

const SvgIcon = props => {

  const { name, width, height, url, onClick, customStyle } = props

  return (
    <img
      style={customStyle}
      className={'svgicon anticon'}
      src={url || `${process.env.PUBLIC_URL}/assets/icons/${iconName[name]}`}
      alt=''
      width={width} height={height}
      onClick={onClick}
    />
  )
}

SvgIcon.propTypes = {
  name: PropTypes.oneOf(Object.keys(iconName)),
  width: PropTypes.number,
  height: PropTypes.number,
  url: PropTypes.string,
  customStyle: PropTypes.object,
}

export default memo(SvgIcon)
