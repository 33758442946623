import { action, observable } from 'mobx'
import { IntroductionRequest } from '../requests/IntroductionRequest'

class IntroductionStore {

  @observable introductionContent = {}

  @action cancelRequest = () => {
    IntroductionRequest.cancelRequest()
  }

  @action getContentIntroductionPage = () => {
    return new Promise((resolve,reject) => {
      IntroductionRequest.getContentIntroductionPage().then(response => {
        this.introductionContent = response.data?.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default new IntroductionStore()