import { action, autorun, observable } from 'mobx'
import { UploadRequest } from '../requests/UploadRequest'
import { MyDriveRequest } from '../requests/MyDriveRequest'

class UploadStore {
  @observable uploadLink
  @observable uploadFileResult
  @observable visibleModalProgress = false
  @observable fileListUpload = []
  @observable progressUpload = {}
  @observable statusUpload = {}
  @observable messageUpload = {}
  @observable keyUpload = 'uploadFiles'
  @observable creatFolderUploadResult = {}
  @observable completeAll = false
  @observable notEnoughCapacity = false

  constructor() {
    autorun(() => {
      let inCompleteList = []
      Object.keys(this.statusUpload).map(key => {
        if (this.statusUpload[key] === 'uploading') {
          inCompleteList.push(key)
        }
      })
      this.completeAll = inCompleteList?.length === 0
    })
  }

  @action setNotEnoughCapacity = isNotEnoughCapacity => {
    this.notEnoughCapacity = isNotEnoughCapacity
  }

  @action cancelRequest = () => {
    UploadRequest.cancelRequest()
  }

  @action getUploadLink = (payload) => {
    return new Promise((resolve, reject) => {
      UploadRequest.getUploadLink(payload).then(response => {
        this.uploadLink = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action uploadFiles = (url, payload, onUploadProgress) => {
    return new Promise((resolve, reject) => {
      UploadRequest.uploadFiles(url, payload, (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setProgressUpload(payload.uid, percentCompleted)
        if (onUploadProgress) onUploadProgress(progressEvent)
      }).then(response => {
        this.uploadFileResult = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action syncToMobifoneDrive = (payload) => {
    return new Promise((resolve, reject) => {
      UploadRequest.syncToMobifoneDrive(payload, (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setProgressUpload(payload.uid, percentCompleted)
      }).then(response => {
        this.uploadFileResult = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action activeUploadFiles = (payload) => {
    return new Promise((resolve, reject) => {
      UploadRequest.activeUploadFiles(payload).then(response => {
        this.uploadFileResult = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action createFolderUpload = (payload, cancelSource) => {
    return new Promise((resolve, reject) => {
      UploadRequest.createFolderUpload(payload, cancelSource).then(response => {
        this.creatFolderUploadResult = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action cancelUploadFolder = (payload) => {
    return new Promise((resolve, reject) => {
      UploadRequest.cancelUploadFolder(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action setFileList = (fileList) => {
    this.fileListUpload = [...this.fileListUpload, ...fileList]
  }

  @action updateStatusFile = (uid, status) => {
    let cloneStatusFiles = { ...this.statusUpload }
    cloneStatusFiles[uid] = status
    this.statusUpload = cloneStatusFiles
  }
  @action handleMessageUpload = (uid, message) => {
    let cloneMessageUpload = { ...this.messageUpload }
    cloneMessageUpload[uid] = message
    this.messageUpload = cloneMessageUpload
  }

  @action updateFileUpload = (file, totalDone, callBack) => {
    let cloneFileListUpload = [...this.fileListUpload]
    let findIndex = cloneFileListUpload.findIndex(item => item.uid === file.uid)
    if (findIndex > -1) {
      let percent = ((cloneFileListUpload[findIndex].totalDone + totalDone) / cloneFileListUpload[findIndex].totalFile) * 100
      this.setProgressUpload(file.uid, percent)
      if (percent === 100) {
        this.updateStatusFile(file.uid, 'success')
        if (callBack) {
          callBack(percent)
        }
      }
      file.totalDone = cloneFileListUpload[findIndex].totalDone + totalDone
      cloneFileListUpload[findIndex] = { ...cloneFileListUpload[findIndex], ...file }
    }
    this.fileListUpload = cloneFileListUpload
  }

  @action updateFolderUpload = (uid, totalDone, callBack) => {
    let cloneFileListUpload = [...this.fileListUpload]
    let findIndex = cloneFileListUpload.findIndex(item => item.uid === uid)
    if (findIndex > -1) {
      let percent = ((cloneFileListUpload[findIndex].totalDone + totalDone) / cloneFileListUpload[findIndex].totalFile) * 100
      this.setProgressUpload(uid, percent)
      if (percent === 100) {
        this.updateStatusFile(uid, 'success')
        if (callBack) {
          callBack(percent)
        }
      }
      cloneFileListUpload[findIndex].totalDone = cloneFileListUpload[findIndex].totalDone + totalDone
    }
    this.fileListUpload = cloneFileListUpload
  }

  @action resetFolder = (uid, folder) => {
    let cloneFileListUpload = [...this.fileListUpload]
    let findIndex = cloneFileListUpload.findIndex(item => item.uid === uid)
    if (findIndex > -1) {
      cloneFileListUpload[findIndex].totalDone = 0
      cloneFileListUpload[findIndex].cancelSource = folder.cancelSource
    }
    this.fileListUpload = cloneFileListUpload
  }

  ///uid file and progress
  @action setProgressUpload = (uid, progress) => {
    let cloneProgressUpload = { ...this.progressUpload }
    cloneProgressUpload[uid] = progress
    this.progressUpload = cloneProgressUpload
  }

  @action cancelAllUpload = (statusUpload) => {
    this.statusUpload = statusUpload
  }

  @action reSetFileList = () => {
    this.fileListUpload = []
  }

  @action setKeyUpload = (key) => {
    this.keyUpload = key
  }

  @action setVisibleModalProgress = (value) => {
    this.visibleModalProgress = value
  }
  @action resetAllStore = () => {
    this.uploadLink = undefined
    this.uploadFileResult = undefined
    this.visibleModalProgress = false
    this.fileListUpload = []
    this.progressUpload = {}
    this.statusUpload = {}
    this.messageUpload = {}
    this.keyUpload = 'uploadFiles'
    this.creatFolderUploadResult = {}
  }
}

export default new UploadStore()