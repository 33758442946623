import { action, observable } from 'mobx'
import { ProfileRequest } from '../requests/ProfileRequest'
import offlineStore from './offlineStore'

class ProfileStore {

  @observable myProfile = null
  @observable appLanguage = localStorage.getItem('lang')
  @observable historyLoginList = []

  @action getMyProfile = () => {
    return new Promise((resolve, reject) => {
      ProfileRequest.getMyProfile().then(response => {
        this.myProfile = response.data.data
        this.appLanguage = response.data.data?.lang
        localStorage.setItem('lang', response.data.data?.lang)
        if (response?.data?.data?.id !== localStorage.getItem('lastSession')) {
          offlineStore.clearOfflineData()
        }
        localStorage.setItem('lastSession', response?.data?.data?.id)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action getMyProfileNotShowSpinner = () => {
    return new Promise((resolve, reject) => {
      ProfileRequest.getMyProfileNotShowSpinner().then(response => {
        this.myProfile = response.data.data
        this.appLanguage = response.data.data?.lang
        localStorage.setItem('lang', response.data.data?.lang)
        localStorage.setItem('lastSession', response?.data?.data?.id)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action updateMyProfile = (payload) => {
    return new Promise((resolve, reject) => {
      ProfileRequest.updateMyProfile(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action updateAvatar = (payload) => {
    return new Promise((resolve, reject) => {
      ProfileRequest.updateAvatar(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action getHistoryLogin = (page, per_page) => {
    return new Promise((resolve, reject) => {
      ProfileRequest.getHistoryLogin(page, per_page).then(response => {
        this.historyLoginList = response.data?.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default new ProfileStore()
