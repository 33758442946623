import axios from 'axios'
import { apiUrl } from '../config'
import synchronizationStore from '../stores/synchronizationStore'
import { graphConfig } from '../components/SyncDataTabPane/OneDriveConfig/OneDriveAuthen'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()

export const SynchronizationRequest = {

  getFileListOnGoogleDrive: (parentId, pageSize, sortBy, sort) => {
    let q = '"root" in parents and trashed = false and "me" in owners'
    if (parentId) q = `'${parentId}' in parents and trashed = false and "me" in owners`
    let url = `https://www.googleapis.com/drive/v3/files`
    return axios({
      method: 'get',
      url: url,
      headers: {
        'Authorization': `Bearer ${synchronizationStore.accessTokenGoogleDrive}`,
      },
      params: {
        q: q,
        fields: '*',
        spaces: 'drive',
        orderBy: `${sortBy} ${sort}`,
        pageSize: pageSize,
      },
    })
  },

  onedriveGetChildrenByFolderId: (token, folderId, sortBy, sort) => {
    return axios({
      method: 'get',
      url: `${graphConfig.graphMeEndpoint}/drive/items/${folderId || 'root'}/children`,
      headers: {
        'Authorization': `Bearer ${token || synchronizationStore.accessTokenOneDrive}`,
      },
      params: {
        orderby: `${sortBy} ${sort}`,
      },
    })
  },

  syncToMobifoneDrive: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/social-storage/sync`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        access_token: payload.access_token,
        file_id: payload.file_id,
        driver: payload.driver,
        cmc_parent_id: payload.cmc_parent_id || null,
      },
    })
  },
}