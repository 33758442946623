import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory } from 'react-router-dom'
import { Divider, message, Tabs } from 'antd'
import { BackToViewMode, PageHeading, PageHeadingWrapper } from '../../components/CommonStyled/CommonStyled'
import PageContentWrapper from '../../components/PageContentWrapper'
import EditAccountInfoRow from '../../components/EditAccountInfoRow/EditAccountInfoRow'
import ViewAccountInfoRow from '../../components/ViewAccountInfoRow/ViewAccountInfoRow'
import PackOfDataRow from '../../components/PackOfDataRow'
import { RightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs
const ProfileEditPage = props => {
  const { profileStore } = props
  const history = useHistory()

  const { t } = useTranslation()

  const handleCancelEditProfile = () => {
    history.push('/my-profile')
  }

  return (
    <>
      <Helmet>
        <title>{t('i0557')}</title>
      </Helmet>
      <PageHeadingWrapper>
        <PageHeading>
          <BackToViewMode onClick={handleCancelEditProfile}>{t('i0558')}</BackToViewMode>
          <RightOutlined style={{ fontSize: '1.5rem', margin: '1px 8px' }} />
          <span>{t('i0559')}</span>
        </PageHeading>
      </PageHeadingWrapper>

      <PageContentWrapper>
        <EditAccountInfoRow />
      </PageContentWrapper>
    </>
  )
}

ProfileEditPage.propTypes = {}

export default inject('profileStore')(observer(ProfileEditPage))