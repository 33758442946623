import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { PdfViewerWrapper } from './PdfViewerStyled'
import { useParams } from 'react-router-dom'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'

const PdfViewer = props => {
  const { downloadStore, authenticationStore, filePreviewStore } = props
  const params = useParams()

  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (authenticationStore.accessToken) {
      downloadStore.getDownloadLink(params.node_id)
        .then(res => {
          setUrl(res.data.url)
        })
    } else {
      filePreviewStore.getPublicLinkDownloadFile({ node_id: params.node_id })
        .then(res => {
          setUrl(res.data.url)
        })
    }
  }, [params, authenticationStore.accessToken])

  return (
    <PdfViewerWrapper>
      {
        url &&
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js'>
          <div style={{ height: '100vh' }}>
            <Viewer fileUrl={url} />
          </div>
        </Worker>
      }

    </PdfViewerWrapper>
  )
}

PdfViewer.propTypes = {}

export default inject('downloadStore', 'authenticationStore', 'filePreviewStore')(observer(PdfViewer))