import styled from 'styled-components'

export const ResultWrapper = styled.div`
  display: block;
  max-width: 330px;
  margin: 85px auto;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    color: #313131;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
`
