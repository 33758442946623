import React, { useState } from 'react'
import PropTypes  from 'prop-types'
import {ModalWrapper} from './ModalInputPasswordStyled'
import { Button, Input } from 'antd'
import { ButtonGroup } from '../../pages/TrashPage/TrashPageStyled'

const ModalInputPassword =props => {
  const {title,visible,onCloseModal, onConFirmModal} = props

  const [password,setPassword] = useState('')

  const onCancelModal =() => {
    setPassword('')
   if(onCloseModal) onCloseModal()
  }
  const onHandleSave = ()=> {
   if(onConFirmModal) onConFirmModal(password)
  }

  const onChangePassword = (e)=>{
    setPassword(e.target.value)
  }

  return(
    <ModalWrapper
      title={title}
      visible={visible}
      {...props}
    >
      <Input.Password className={"int-password"} value={password} placeholder={"Nhập mật khẩu"} onChange={onChangePassword}/>
      <div className={'btn-group'}>
        <Button type={'default'} onClick={() => onCancelModal()}>Hủy</Button>
        <Button type={'primary'} onClick={() => onHandleSave()}>Xác nhận</Button>
      </div>
    </ModalWrapper>
  )
}
ModalInputPassword.prototype={
  title:PropTypes.string,
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onConFirmModal: PropTypes.func

}

export default ModalInputPassword