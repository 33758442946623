import styled from 'styled-components'

export const QuestionsCommonWrapper = styled.div`
 
`
export const QuestionsCommonItem = styled.div`
  background: #FFFFFF;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 18px 24px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`