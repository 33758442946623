import { action, observable } from 'mobx'
import { CommonQuestionRequest } from '../requests/CommonQuestionRequest'
import { ShareWithMeRequest } from '../requests/SharedWithMeRequest'

class CommonQuestionStore {
  @observable questionList = []
  @observable questionItem = {}

  ///Payload is Object
  @action getCommonQuestionList = () => {
    return new Promise((resolve, reject) => {
      CommonQuestionRequest.getCommonQuestionList().then(response => {
        this.questionList = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action setQuestionItem = (item) =>{
    this.questionItem = item
  }
}
export default new CommonQuestionStore();