import styled, { css } from 'styled-components'

export const MoveItemMenuWrapper = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  background: #fff;
  position: absolute;
  z-index: 1;
  width: 350px;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  ${props => props.isLastItem && css`
    left: unset;
    right: 0;
  `}
`
export const MoveItemMenuHeader = styled.div`
  padding: 13px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E2E2E2;

  span {
    font-size: 1.6rem;
    font-weight: 500;
  }
`
export const MoveItemMenuBody = styled.div`
  padding: 8px 16px;
  height: 250px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
  }

  ::-webkit-scrollbar-thumb {
    background: #E2E2E2;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
  }
`
export const MoveBodyItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  
  .folderName {
    width: 235px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 12px;
    font-size: 1.4rem;
    color: #313131;
    text-align: left;
  }

  &:hover, &.active {
    background: #E9F2FB;
    border-radius: 10px;
  }
`
export const CreatingContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  word-break: break-all;
`
export const MoveItemMenuFooter = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E2E2E2;

  a {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }

  img {
    margin-right: 8px;
  }
`
export const MoveRootItem = styled.div`
  text-align: left;
  padding: 16px;
  cursor: ${props => props.isDisable ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.isDisable ? 0.7 : 1};

  &:hover, &.active {
    background: #E9F2FB;
    border-radius: 10px;
  }

  img {
    margin-right: 16px;
    width: 24px;
    height: 24px;
  }
`
export const FolderName = styled.span`
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 12px;
  font-size: 1.4rem;
  color: #313131;
  text-align: left;
`