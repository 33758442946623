import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'
// Pages
import HomePage from '../../pages/HomePage'
import MyDrivePage from '../../pages/MyDrivePage'
import SharedWithMePage from '../../pages/SharedWithMePage'
import RecentlyOpenPage from '../../pages/RecentlyOpenPage'
import FavoritePage from '../../pages/FavoritePage'
import TrashPage from '../../pages/TrashPage'
import ProfilePage from '../../pages/ProfilePage'
import ProfileEditPage from '../../pages/ProfileEditPage'
import HistoryLogin from '../../pages/HistoryLogin'
import SearchPage from '../../pages/SearchPage'
import ConfigPage from '../../pages/ConfigPage'
import SynchronizationPage from '../../pages/SynchronizationPage'
import OfflineFilesPage from '../../pages/OfflineFilesPage'
import ChangePasswordPage from '../../pages/ChangePasswordPage'
import UpdateGroupPage from '../../pages/UpdateGroupPage'
import PreviewOverlay from '../../components/PreviewOverlay'
import OneDriveLogout from '../../pages/OneDriveLogout'
import { inject, observer } from 'mobx-react'

const ProtectedModule = (props) => {
  const { previewOverlayStore } = props
  return (
    <DefaultLayout>
      <Switch>
        <Route exact path={[
          '/my-drive',
          '/my-drive/:parentId',
        ]} component={MyDrivePage} />
        <Route exact path={'/synchronization'} component={SynchronizationPage} />
        <Route exact path={['/shared-with-me', '/shared-with-me/:parentId']} component={SharedWithMePage} />
        <Route exact path={['/recently-opened', '/recently-opened/:parentId']} component={RecentlyOpenPage} />
        <Route exact path={['/favorite', '/favorite/:parentId']} component={FavoritePage} />
        <Route exact path={'/offline-files'} component={OfflineFilesPage} />
        <Route exact path={'/trash'} component={TrashPage} />
        <Route exact path={'/my-profile'} component={ProfilePage} />
        <Route exact path={'/my-profile-edit'} component={ProfileEditPage} />
        <Route exact path={'/history-login'} component={HistoryLogin} />
        <Route exact path={['/config-page', '/config-page/:activeTab']} component={ConfigPage} />
        <Route exact path={['/search', '/search/:parentId']} component={SearchPage} />
        <Route exact path={'/change-password'} component={ChangePasswordPage} />
        <Route exact path={'/update-group/:groupId'} component={UpdateGroupPage} />
        <Route exact path={'/one-drive'} component={OneDriveLogout} />
      </Switch>
      <div>
        {previewOverlayStore.visible && <PreviewOverlay />}
      </div>
    </DefaultLayout>
  )

}

ProtectedModule.propTypes = {}

export default inject(
  'previewOverlayStore',
  'commonStore',
)(observer(ProtectedModule))
