import React, { useEffect, useState, useCallback } from 'react'
import { PageWrapper, FileTitleWrapper, FileName, FilePreviewWrapper } from './PreviewOverlayStyled'
import SVGIcon from '../SVGIcon/SVGIcon'
import ReactPlayer from 'react-player'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import FileDownload from 'js-file-download'
import { Button, message, Spin, Tooltip } from 'antd'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.less'
import { useTranslation } from 'react-i18next'

const PreviewOverlay = (props) => {
  const {
    previewOverlayStore,
    appConfigStore,
    downloadStore,
    itemDriveStore,
    offlineStore,
    selectionStore,
    authenticationStore,
    filePreviewStore,
  } = props
  const { recordOverlay } = previewOverlayStore
  const { iconsByMimeTypeObj } = appConfigStore
  const { selectionData, selectionIds } = selectionStore
  const [urlPreview, setUrlPreview] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const { t } = useTranslation()

  const onHandleDownloadFile = () => {
    // if (recordOverlay.type_name === 'Image') {
    //   axios.get(urlPreview, {
    //     responseType: 'blob',
    //   }).then(res => {
    //     FileDownload(res.data, recordOverlay.name)
    //   })
    // } else {
    //   window.open(urlPreview, '_blank')
    // }
    if (recordOverlay.type === 2) {
      if (authenticationStore.accessToken) {
        Promise.all([
          downloadStore.getDownloadLink(recordOverlay.id),
          itemDriveStore.getItemDriveDetail(recordOverlay.id),
        ]).then(([downloadRes, itemDetailRes]) => {
          // Handle download
          if (downloadRes.error_code === 0) {
            selectionStore.clearSelectionIndex()
            if (downloadRes.data?.url) {
              axios.get(downloadRes.data.url, {
                responseType: 'blob',
              }).then(res => {
                FileDownload(res.data, recordOverlay.name)
              })
            }
          } else {
            message.error(downloadRes.message)
          }
          // Handle save offline data
          if (downloadRes.error_code === 0 && itemDetailRes.error_code === 0) {
            offlineStore.saveDataForOfflineView(selectionData, itemDetailRes.data)
          }
        }).catch(([downloadErr, itemDetailRes]) => {
          message.error(downloadErr.message)
          message.error(itemDetailRes.message)
        })
      } else {
        Promise.all([
          filePreviewStore.getPublicLinkDownloadFile({ node_id: recordOverlay.encrypt_id})
        ]).then(([downloadRes]) => {
          // Handle download
          if (downloadRes.error_code === 0) {
            selectionStore.clearSelectionIndex()
            if (downloadRes.data?.url) {
              axios.get(downloadRes.data.url, {
                responseType: 'blob',
              }).then(res => {
                FileDownload(res.data, recordOverlay.name)
              })
            }
          } else {
            message.error(downloadRes.message)
          }

        }).catch(([downloadErr, itemDetailRes]) => {
          message.error(downloadErr.message)
          message.error(itemDetailRes.message)
        })
      }
    }
  }

  const onClosePreviewOverlay = () => {
    previewOverlayStore.setVisiblePreviewOverlay()
    previewOverlayStore.setRecordOverlay({})
  }
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      previewOverlayStore.closePreviewOverlay()
      previewOverlayStore.setRecordOverlay({})
    }
  }, [])
  useEffect(() => {
    if (authenticationStore.accessToken) {
      downloadStore.getDownloadLinkNoSpin(recordOverlay.id)
        .then(response => {
          if (response.error_code === 0) {
            if (response.data?.url) {
              setUrlPreview(response.data?.url)
            }
          } else {
            message.error(response.message)
          }
        })
        .catch(error => error?.response?.status != 401 && message.error(error.message))
    } else {
      filePreviewStore.getPublicLinkDownloadFile({ node_id: recordOverlay.encrypt_id })
        .then(response => {
          if (response.error_code === 0) {
            if (response.data?.url) {
              setUrlPreview(response.data?.url)
            }
          } else {
            message.error(response.message)
          }
        })
    }
  }, [recordOverlay, authenticationStore.accessToken])
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])
  return (
    <PageWrapper>
      <FileTitleWrapper>
        <ArrowLeftOutlined onClick={onClosePreviewOverlay} style={{ color: '#fff', zIndex: 99999, marginRight: 15 }}
                           size={28} />
        <FileName>
          <SVGIcon url={iconsByMimeTypeObj[recordOverlay.mimetype] || iconsByMimeTypeObj['default']} width={18}
                   height={18} />
          <p>{recordOverlay.name}</p>
        </FileName>
        <img
          onClick={onHandleDownloadFile}
          src={`${process.env.PUBLIC_URL}/assets/icons/download-white.svg`}
          width={28.04} height={26}
          style={{ zIndex: 11, cursor: 'pointer' }}
        />
      </FileTitleWrapper>
      <FilePreviewWrapper>
        {
          recordOverlay.type_name === 'Image'
            ?
            <>
              <img src={urlPreview} onLoad={() => setIsLoaded(true)} />
              {
                !isLoaded && <Spin style={{ position: 'absolute', left: '50%', top: '50%' }} />
              }
            </>
            : recordOverlay.type_name === 'Video' ?
            <ReactPlayer
              className='react-player'
              url={urlPreview}
              width='100%'
              height='100%'
              playing={true}
              controls={true}
              muted={true}
            /> : <AudioPlayer
              autoPlay
              src={urlPreview}
              onPlay={e => console.log('onPlay')}
              // other props here
            />
        }
      </FilePreviewWrapper>
    </PageWrapper>
  )
}


export default inject(
  'previewOverlayStore',
  'appConfigStore',
  'downloadStore',
  'itemDriveStore',
  'offlineStore',
  'selectionStore',
  'authenticationStore',
  'filePreviewStore',
)(observer(PreviewOverlay))