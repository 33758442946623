export const EMAIL_REGEX = /^[A-Za-z][A-Za-z0-9-_.]{1,32}(\+?[0-9]){0,5}@[A-Za-z0-9_-]{2,}(\.[A-Za-z0-9]{2,4}){1,2}$/gm
export const BUY_CAPACITY_LINK = 'https://www.mobifone.vn/dich-vu-di-dong/dich-vu/mobicloud-mobiCloud'
export const APP_NAME = 'mobiCloud'
export const PAGE_SIZE = 30
export const MIMETYPE_OFFICE = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
]
export const MIMETYPE_PDF = [
  'application/pdf',
]
export const MOBILE_ACTION = {
  click: 1,
  viewInfo: 2,
}
export const CONTEXT_MENU_ACTION = {
  copyLink: 1,
  trash: 2,
  download: 3,
  favorite: 4,
  move: 5,
  rename: 6,
  share: 7,
  viewInfo: 8,
  restore: 9,
  permanentlyDelete: 10,
  removeFavorite: 11,
  syncFromOneDrive: 17,
  syncFromGoogleDrive: 12,
  lock: 13,
  unlock: 14,
  deleteShareWithMe: 15,
  deleteRecentlyOpen: 16,

}

export const DEBOUNCE_DELAY = 500
export const PAGE_NAME = {
  MY_DATA: 'i0250',
  SYNC: 'i0251',
  SHARED: 'i0252',
  RECENT: 'i0253',
  FAVORITE: 'i0254',
  OFFLINE: 'i0255',
  RECYCLE: 'i0256',
}

export const CONTEXT_MENU_MY_DRIVE = [
  {
    id: 1,
    text: 'i0085',
    icon: 'share.svg',
    action: CONTEXT_MENU_ACTION.share,
    multiSelectVisible: false,
  },
  {
    id: 2,
    text: 'i0086',
    icon: 'copy-link.svg',
    action: CONTEXT_MENU_ACTION.copyLink,
    multiSelectVisible: false,
  },
  {
    id: 3,
    text: 'i0087',
    icon: 'move.svg',
    action: CONTEXT_MENU_ACTION.move,
    multiSelectVisible: true,
  },
  {
    id: 4,
    text: 'i0088',
    icon: 'favorite.svg',
    action: CONTEXT_MENU_ACTION.favorite,
    multiSelectVisible: false,
  },
  {
    id: 11,
    text: 'i0089',
    icon: 'favorite.svg',
    action: CONTEXT_MENU_ACTION.removeFavorite,
    multiSelectVisible: false,
  },
  {
    id: 13,
    text: 'i0090',
    icon: 'lock.svg',
    action: CONTEXT_MENU_ACTION.lock,
    multiSelectVisible: true,
  },
  {
    id: 14,
    text: 'i0091',
    icon: 'unlock.svg',
    action: CONTEXT_MENU_ACTION.unlock,
    multiSelectVisible: true,
  },
  {
    id: 5,
    text: 'i0092',
    icon: 'rename.svg',
    action: CONTEXT_MENU_ACTION.rename,
    multiSelectVisible: false,
  },
  {
    id: 6,
    text: 'i0093',
    icon: 'view-info.svg',
    action: CONTEXT_MENU_ACTION.viewInfo,
    multiSelectVisible: false,
  },
  {
    id: 7,
    text: 'i0094',
    icon: 'download.svg',
    action: CONTEXT_MENU_ACTION.download,
    multiSelectVisible: false,
  },
  {
    id: 8,
    text: 'i0095',
    icon: 'delete.svg',
    action: CONTEXT_MENU_ACTION.trash,
    multiSelectVisible: true,
  },
]
export const CONTEXT_MENU_SYNC = [
  {
    id: 12,
    text: 'i0657',
    icon: 'move.svg',
    action: CONTEXT_MENU_ACTION.syncFromGoogleDrive,
    multiSelectVisible: false,
  },
  {
    id: 17,
    text: 'i0658',
    icon: 'move.svg',
    action: CONTEXT_MENU_ACTION.syncFromOneDrive,
    multiSelectVisible: false,
  },
]
export const CONTEXT_MENU_SHARE_WITH_ME = [
  {
    id: 1,
    text: 'i0085',
    icon: 'share.svg',
    action: CONTEXT_MENU_ACTION.share,
    multiSelectVisible: false,
  },
  {
    id: 2,
    text: 'i0086',
    icon: 'copy-link.svg',
    action: CONTEXT_MENU_ACTION.copyLink,
    multiSelectVisible: false,
  },
  {
    id: 4,
    text: 'i0088',
    icon: 'favorite.svg',
    action: CONTEXT_MENU_ACTION.favorite,
    multiSelectVisible: false,
  },
  {
    id: 11,
    text: 'i0089',
    icon: 'favorite.svg',
    action: CONTEXT_MENU_ACTION.removeFavorite,
    multiSelectVisible: false,
  },

  {
    id: 6,
    text: 'i0093',
    icon: 'view-info.svg',
    action: CONTEXT_MENU_ACTION.viewInfo,
    multiSelectVisible: false,
  },
  {
    id: 7,
    text: 'i0094',
    icon: 'download.svg',
    action: CONTEXT_MENU_ACTION.download,
    multiSelectVisible: false,
  },
  {
    id: 8,
    text: 'i0095',
    icon: 'delete.svg',
    action: CONTEXT_MENU_ACTION.deleteShareWithMe,
    multiSelectVisible: true,
  },
]
export const CONTEXT_MENU_RECENTLY_OPENED = [
  {
    id: 1,
    text: 'i0085',
    icon: 'share.svg',
    action: CONTEXT_MENU_ACTION.share,
    multiSelectVisible: false,
  },
  {
    id: 2,
    text: 'i0086',
    icon: 'copy-link.svg',
    action: CONTEXT_MENU_ACTION.copyLink,
    multiSelectVisible: false,
  },
  {
    id: 4,
    text: 'i0088',
    icon: 'favorite.svg',
    action: CONTEXT_MENU_ACTION.favorite,
    multiSelectVisible: false,
  },
  {
    id: 11,
    text: 'i0089',
    icon: 'favorite.svg',
    action: CONTEXT_MENU_ACTION.removeFavorite,
    multiSelectVisible: false,
  },
  {
    id: 13,
    text: 'i0090',
    icon: 'lock.svg',
    action: CONTEXT_MENU_ACTION.lock,
    multiSelectVisible: true,
  },
  {
    id: 14,
    text: 'i0091',
    icon: 'unlock.svg',
    action: CONTEXT_MENU_ACTION.unlock,
    multiSelectVisible: true,
  },
  {
    id: 5,
    text: 'i0092',
    icon: 'rename.svg',
    action: CONTEXT_MENU_ACTION.rename,
    multiSelectVisible: false,
  },
  {
    id: 6,
    text: 'i0093',
    icon: 'view-info.svg',
    action: CONTEXT_MENU_ACTION.viewInfo,
    multiSelectVisible: false,
  },
  {
    id: 7,
    text: 'i0094',
    icon: 'download.svg',
    action: CONTEXT_MENU_ACTION.download,
    multiSelectVisible: false,
  },
  {
    id: 8,
    text: 'i0095',
    icon: 'delete.svg',
    action: CONTEXT_MENU_ACTION.deleteRecentlyOpen,
    multiSelectVisible: true,
  },
]
export const CONTEXT_MENU_FAVORITE = [
  {
    id: 1,
    text: 'i0085',
    icon: 'share.svg',
    action: CONTEXT_MENU_ACTION.share,
    multiSelectVisible: false,
  },
  {
    id: 2,
    text: 'i0086',
    icon: 'copy-link.svg',
    action: CONTEXT_MENU_ACTION.copyLink,
    multiSelectVisible: false,
  },
  {
    id: 11,
    text: 'i0089',
    icon: 'favorite.svg',
    action: CONTEXT_MENU_ACTION.removeFavorite,
    multiSelectVisible: false,
  },
  {
    id: 13,
    text: 'i0090',
    icon: 'lock.svg',
    action: CONTEXT_MENU_ACTION.lock,
    multiSelectVisible: true,
  },
  {
    id: 14,
    text: 'i0091',
    icon: 'unlock.svg',
    action: CONTEXT_MENU_ACTION.unlock,
    multiSelectVisible: true,
  },
  {
    id: 5,
    text: 'i0092',
    icon: 'rename.svg',
    action: CONTEXT_MENU_ACTION.rename,
    multiSelectVisible: false,
  },
  {
    id: 6,
    text: 'i0093',
    icon: 'view-info.svg',
    action: CONTEXT_MENU_ACTION.viewInfo,
    multiSelectVisible: false,
  },
  {
    id: 7,
    text: 'i0094',
    icon: 'download.svg',
    action: CONTEXT_MENU_ACTION.download,
    multiSelectVisible: false,
  },
  {
    id: 8,
    text: 'i0095',
    icon: 'delete.svg',
    action: CONTEXT_MENU_ACTION.trash,
    multiSelectVisible: true,
  },

]
export const CONTEXT_MENU_OFFLINE_FILES = [
  {
    id: 6,
    text: 'i0093',
    icon: 'view-info.svg',
    action: CONTEXT_MENU_ACTION.viewInfo,
    multiSelectVisible: false,
  },
  {
    id: 8,
    text: 'i0095',
    icon: 'delete.svg',
    action: CONTEXT_MENU_ACTION.trash,
    multiSelectVisible: true,
  },

]
export const CONTEXT_MENU_TRASH = [
  {
    id: 9,
    text: 'i0097',
    icon: 'restore.svg',
    action: CONTEXT_MENU_ACTION.restore,
    multiSelectVisible: true,
  },
  {
    id: 10,
    text: 'i0098',
    icon: 'delete.svg',
    action: CONTEXT_MENU_ACTION.permanentlyDelete,
    multiSelectVisible: true,
  },
]

export const CONTEXT_MENU_FOLDER_PREVIEW = [
  {
    id: 6,
    text: 'i0093',
    icon: 'view-info.svg',
    action: CONTEXT_MENU_ACTION.viewInfo,
    multiSelectVisible: false,
  },
  {
    id: 7,
    text: 'i0094',
    icon: 'download.svg',
    action: CONTEXT_MENU_ACTION.download,
    multiSelectVisible: false,
  },
]

export const CONTENT_TYPE = {
  myDrive: 1,
  favorite: 2,
  share: 3,
  recentlyOpen: 4,
}

export const PERMISSION = {
  myDrive: 1,
  favorite: 2,
  share: 3,
  recentlyOpen: 4,
}

