import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import { message, Tabs } from 'antd'
import PageContentWrapper from '../../components/PageContentWrapper'
import GoogleDriveTab from '../../components/GoogleDriveTab'
import ViewToggleComponent from '../../components/ViewToggleComponent/ViewToggleComponent'
import OneDriveTab from '../../components/OneDriveTab'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { PAGE_NAME } from '../../utils/constant'
import profileStore from '../../stores/profileStore'

const { TabPane } = Tabs

const SynchronizationPage = props => {
  const { synchronizationStore, commonStore, profileStore, selectionStore } = props
  const { breadcrumbDataItems } = synchronizationStore

  const { t } = useTranslation()

  const handleChangeTab = (tabKey) => {
    commonStore.setSyncTab(tabKey)
  }

  const handleClickFolderOrBreadcrumb = folder => {
    synchronizationStore.setBreadcrumbDataItems(folder)
    if (commonStore.syncTab === 'google-drive') {
      synchronizationStore.getFileListOnGoogleDrive(folder?.id, 1000)
        .catch(error => {
          if (error?.response?.status === 401) {
            synchronizationStore.handleAuthenGoogleDrive(undefined)
          }
        })
    } else {
      synchronizationStore.onedriveGetChildrenByFolderId(synchronizationStore.accessTokenOneDrive, folder?.id || 'root')
        .catch(error => {
          if (error?.response?.status === 401) {
            synchronizationStore.handleAuthenOneDrive(undefined)
          }
        })
    }
  }



  useEffect(() => {
    if (!profileStore.appLanguage) return
    if (!commonStore.syncTab) return
    handleClickFolderOrBreadcrumb(null)
    synchronizationStore.breadcrumbDataItems = [{ id: null, name: profileStore.appLanguage === 'en' ? 'Synchronize' : 'Đồng bộ dữ liệu' }]
    synchronizationStore.filesInGoogleDrive = []
    synchronizationStore.listFileOneDrive = []
    selectionStore.clearSelectionIndex()
  }, [commonStore.syncTab, profileStore.appLanguage])

  useEffect(() => {
    return () => {
      synchronizationStore.clearFilesOnLeave()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('i0607')}</title>
      </Helmet>
      <ViewToggleComponent
        heading={breadcrumbDataItems}
        onClickBreadcrumb={handleClickFolderOrBreadcrumb}
      />
      <PageContentWrapper>
        <Tabs activeKey={commonStore.syncTab} onChange={handleChangeTab}>
          <TabPane tab='Google Drive' key={'google-drive'} forceRender={false}>
            <GoogleDriveTab />
          </TabPane>
          <TabPane tab='OneDrive' key={'one-drive'} forceRender={false}>
            <OneDriveTab />
          </TabPane>
        </Tabs>
      </PageContentWrapper>
    </>
  )
}

export default inject(
  'commonStore',
  'synchronizationStore',
  'profileStore',
  'selectionStore'
)(observer(SynchronizationPage))
