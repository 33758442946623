import styled from 'styled-components'
import {Modal} from 'antd'

export const ModalWrapper =styled(Modal)`
    .ant-modal-content {
      .ant-modal-header {
        border-radius: 8px 8px 0px 0px;
        border: 0;
        .ant-modal-title {
          text-align: center;
          color: #313131;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
      .ant-modal-body{
        .int-password{

          background: #F9FAFC;
          border: 1px solid #F1F1F5;
          border-radius: 8px;
          padding: 13px 11px;

          &::placeholder {
            color: #A7A7A7;
          }

          .ant-input {
            background-color: transparent;
          }
        }
        .btn-group {
          text-align: center;
          margin-top: 24px;
          .ant-btn{
            margin: 0px 10px;
            border-radius: 8px;
            padding: 0 30px;
            
          }
          .ant-btn-default {
            background: #E2E2E2;
          }
        }
      }
    }
`


