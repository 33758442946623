import { action, autorun, observable } from 'mobx'
import { AuthenticationRequest } from '../requests/AuthenticationRequest'
import moment from 'moment'
import { message } from 'antd'
import synchronizationStore from './synchronizationStore'
import profileStore from './profileStore'

class AuthenticationStore {
  // constructor() {
  //   autorun(() => {
  //     if (this.appToken) {
  //       const tokenData = JSON.parse(this.appToken);
  //       this.accessToken = tokenData;
  //       const appTokenExpiration = tokenData.expires_in;
  //       const refressToken = tokenData.refresh_token;
  //       if (!moment().isBefore(moment().add(appTokenExpiration, "seconds"))) {
  //         // message.info("Session expired");
  //         // Handle get new token
  //       }
  //     }
  //   });
  // }

  @observable accessToken = localStorage.getItem('jwt') || undefined
  @observable currentUser = undefined
  @observable userIdVerifying = undefined

  @observable registerInfoCache = null

  @action setRegisterInfoCache = info => {
    this.registerInfoCache = info
  }

  @action setUserIdVerifying = (id) => {
    this.userIdVerifying = id
  }
  @action clearUserIdVerifying = () => {
    this.userIdVerifying = undefined
  }

  @action userLogin = (username, password) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.userLogin(username, password)
        .then(response => {
          const tokenData = response.data.data.token
          localStorage.setItem('jwt', tokenData)
          this.accessToken = tokenData
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action userRegister = (payload) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.userRegister(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action verifyOtp = (user_id, otp, in_forgot) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.verifyOtp(user_id, otp, in_forgot)
        .then(response => {
          const tokenData = response.data.data.token
          localStorage.setItem('jwt', tokenData)
          this.accessToken = tokenData
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action forgotPassword = (username) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.forgotPassword(username)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action verifyOtpForgotPassword = (user_id, otp, in_forgot) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.verifyOtp(user_id, otp, in_forgot)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action saveNewPassword = (payload) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.saveNewPassword(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action checkOldPassword = (old_password) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.checkOldPassword(old_password)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action changePassword = (payload) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.changePassword(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action resendOtp = (payload) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.resendOtp(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action clearUserInfoWhenLogout = () => {
    this.accessToken = undefined
    this.currentUser = {}
    profileStore.myProfile = null
    localStorage.removeItem('jwt')
    localStorage.removeItem('fcmToken')
    localStorage.removeItem(`one_drive_token`)
    localStorage.removeItem(`google_drive_token`)
    synchronizationStore.accessTokenGoogleDrive = undefined
    synchronizationStore.accessTokenOneDrive = undefined
    sessionStorage.clear()
  }
  @action userLogout = () => {
    if (localStorage.getItem('jwt')) {
      return new Promise((resolve, reject) => {
        AuthenticationRequest.userLogout()
          .then(response => {
            this.clearUserInfoWhenLogout()
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    }
    this.clearUserInfoWhenLogout()
    return Promise.resolve()
  }
  @action cancelRequest = () => {
    AuthenticationRequest.cancelRequest()
  }

}

export default new AuthenticationStore()
