import { action, observable } from 'mobx'
import { ShareRequest } from '../requests/ShareRequest'

class ShareStore {
  @observable userShared = []
  @observable userSharedRollback = null
  @observable userForShare = []
  @observable insertUserShareRollback = null
  @observable updateUserShareRollback = null

  @action setInsertUserShareRollback = (payload) => {
    this.insertUserShareRollback = payload
  }
  @action setUpdateUserShareRollback = (payload) => {
    this.updateUserShareRollback = payload
  }
  @action setUserForShare = (newUserForShare) => {
    this.userForShare = newUserForShare
  }
  @action setUserShared = (newUserShared) => {
    this.userShared = newUserShared
  }
  @action getUserShared = (node_id) => {
    return new Promise((resolve, reject) => {
      ShareRequest.getUserShared(node_id).then(response => {
        this.userShared = response.data.data
        this.userSharedRollback = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action searchUserForShared = keyword => {
    return new Promise((resolve, reject) => {
      ShareRequest.searchUserForShared(keyword)
        .then(response => {
          this.userForShare = response.data.data
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
  @action getUserForShare = (node_id, keyword) => {
    return new Promise((resolve, reject) => {
      ShareRequest.getUserForShare(node_id, keyword).then(response => {
        this.userForShare = response.data.data
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action updatePasswordShare = (payload) => {
    return new Promise((resolve, reject) => {
      ShareRequest.updatePasswordShare(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action updateExpiredShare = (payload) => {
    return new Promise((resolve, reject) => {
      ShareRequest.updateExpiredShare(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action processShare = (payload) => {
    return new Promise((resolve, reject) => {
      ShareRequest.processShare(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getLinkSharedInfo = (nodeId) => {
    return new Promise((resolve, reject) => {
      ShareRequest.getLinkSharedInfo(nodeId).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action clearUserForShare = () => {
    this.userForShare.length = 0
  }
}

export default new ShareStore()
