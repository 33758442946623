import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Menu, Modal, Row, message, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import PageResult from '../PageResult'
import { ButtonGroup, SectionHeading } from '../../pages/TrashPage/TrashPageStyled'
import FoldersGridView from '../FoldersGridView'
import FilesGridView from '../FilesGridView/FilesGridView'
import FilesListView from '../FilesListView/FilesListView'
import { gapi } from 'gapi-script'
import { useHistory } from 'react-router-dom'
import config from '../../config'
import { ResultWrapper } from '../PageResult/PageResultStyled'
import { SortButton } from '../CommonStyled/CommonStyled'
import { useMediaQuery } from 'react-responsive/src'
import MobileViewDetail from '../MobileViewDetail/MobileViewDetail'
import { useTranslation } from 'react-i18next'
import { toJS } from 'mobx'
import { CONTEXT_MENU_ACTION } from '../../utils/constant'


const GoogleDriveTab = props => {
  const { commonStore, synchronizationStore, selectionStore, profileStore } = props
  const { viewByGridMode } = commonStore
  const { filesInGoogleDrive, accessTokenGoogleDrive } = synchronizationStore
  const { selectionData, selectionIds } = selectionStore
  const history = useHistory()
  const { googleDriveConfig } = config
  // const { apiKey, clientId, discoveryDocs, scope } = googleDriveConfig

  const isMobile = useMediaQuery({ maxWidth: 768 })
  const { t } = useTranslation()

  const [files, setFiles] = useState([])
  const [folders, setFolders] = useState([])
  // const [isSignedIn, setIsSignedIn] = useState(false)
  const [isVisibleModal, setIsVisibleModal] = useState(false)

  const handleClickFolderOrBreadcrumb = folder => {
    synchronizationStore.setBreadcrumbDataItems(folder)
    synchronizationStore.getFileListOnGoogleDrive(folder.id, 1000)
    selectionStore.clearSelectionIndex()
  }

  const handleCallbackAction = (action) => {
    switch (action) {
      case CONTEXT_MENU_ACTION.syncFromGoogleDrive:
        let params = {
          access_token: accessTokenGoogleDrive,
          file_id: selectionData[0].id,
          driver: 'google_drive',
          cmc_parent_id: null,
        }
        synchronizationStore.syncToMobifoneDrive(params).then(res => {
          if (res.error_code === 0) {
            message.success(res?.message)
          } else {
            message.error(res?.message)
          }
        }).catch(error => {
          message.error(error?.message)
        })
        break
      default:
        break
    }
  }

  // const handleGoogleDriveClientLoad = () => {
  //   gapi.load('client:auth2', googleDriveInitClient)
  // }
  //
  // const googleDriveInitClient = () => {
  //   gapi.client.init({
  //     'apiKey': apiKey,
  //     'clientId': clientId,
  //     'scope': scope,
  //     'discoveryDocs': discoveryDocs,
  //   }).then(function() {
  //     gapi.auth2.getAuthInstance().isSignedIn.listen(updateSignInStatus)
  //     updateSignInStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
  //   })
  // }
  //
  // const updateSignInStatus = (isSignedIn) => {
  //   setIsSignedIn(isSignedIn)
  // }

  const onHandleCloseModal = () => {
    setIsVisibleModal(false)
  }

  const onConfirmAction = () => {
    setIsVisibleModal(false)
    history.push('/config-page')
  }

  const sortMenu = (
    <Menu>
      <Menu.Item onClick={() => synchronizationStore.setGoogleDriveSortBy('modifiedTime')}>
        {t('i0176')}
      </Menu.Item>
      <Menu.Item onClick={() => synchronizationStore.setGoogleDriveSortBy('name')}>
        {t('i0177')}
      </Menu.Item>
    </Menu>
  )

  const sortableTools = () => {
    return (
      <Col>
        <Row type={'flex'} gutter={15} justify={'space-between'} align={'middle'}>
          <Col>
            <Dropdown overlay={sortMenu} trigger={['click']}>
              <SortButton>
                {
                  synchronizationStore.googleDriveSortBy === 'name'
                    ? t('i0538')
                    : t('i0537')
                }
              </SortButton>
            </Dropdown>
          </Col>
          <Col>

            <SortButton>
              {
                synchronizationStore.googleDriveSort === 'asc'
                  ? <Tooltip title={t('i0701')} placement={'bottom'}>
                    <ArrowUpOutlined onClick={() => synchronizationStore.setGoogleDriveSort('desc')}/>
                  </Tooltip>
                  : <Tooltip title={t('i0700')} placement={'bottom'}>
                    <ArrowDownOutlined onClick={() => synchronizationStore.setGoogleDriveSort('asc')}/>
                  </Tooltip>
              }
            </SortButton>

          </Col>
        </Row>
      </Col>
    )
  }

  useEffect(() => {
    if (!accessTokenGoogleDrive || !synchronizationStore.googleDriveSortBy || !synchronizationStore.googleDriveSort) return
    synchronizationStore.getFileListOnGoogleDrive(synchronizationStore.currentNode?.id || undefined, 1000)
      .catch(error => {
        switch (error?.response?.status) {
          case 401:
            localStorage.removeItem(`google_drive_token`)
            synchronizationStore.handleAuthenGoogleDrive(undefined)
            break
          case 403:
            message.error(t('i0718'))
            break
          default:
            message.error(error.message)
            break
        }
      })
  }, [accessTokenGoogleDrive, synchronizationStore.googleDriveSortBy, synchronizationStore.googleDriveSort])

  useEffect(() => {
    if (!filesInGoogleDrive) return
    const tempFolder = [], tempFile = []
    filesInGoogleDrive.forEach(record => {
      if (record.type === 1) tempFolder.push(record)
      if (record.type === 2) tempFile.push(record)
    })
    setFiles(tempFile)
    setFolders(tempFolder)
  }, [filesInGoogleDrive])

  useEffect(() => {
    return () => {
      setFiles([])
      setFolders([])
    }
  }, [])

  return (
    <>
      {folders.length === 0 && files.length === 0 ?
        <ResultWrapper>
          <img
            src={`${process.env.PUBLIC_URL}/assets/imgs/google-drive-group.png`}
            alt='' width={'50%'}
          />
          {
            synchronizationStore.accessTokenGoogleDrive
              ?
              <p>
                {t('i0693')}
              </p>
              :
              <p>
                {t('i0180')} <a onClick={() => history.push('/config-page/4')}>{t('i0181')}</a>.
              </p>
          }

        </ResultWrapper>
        : viewByGridMode || isMobile ?
          (
            <>
              {folders?.length > 0 && (
                <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                     justify={'space-between'} align={'middle'}>
                  <Col>
                    <SectionHeading style={{ marginBottom: 0 }}>{t('i0182')}</SectionHeading>
                  </Col>
                  <Col>
                    {sortableTools()}
                  </Col>
                </Row>
              )
              }
              <FoldersGridView
                folderList={folders}
                onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                callbackAction={handleCallbackAction}
                callbackMobileAction={() => {
                }}
              />
              {
                files?.length > 0 &&
                <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                     justify={'space-between'} align={'middle'}>
                  <Col>
                    <SectionHeading style={{ marginBottom: 0 }}>{t('i0534')}</SectionHeading>
                  </Col>
                  {
                    folders?.length === 0 && sortableTools()
                  }
                </Row>
              }
              <FilesGridView fileList={files}
                             callbackMobileAction={() => {
                             }}
                             callbackAction={handleCallbackAction}/>
            </>
          ) : !isMobile && <FilesListView fileList={[...folders, ...files]} callbackAction={handleCallbackAction}
                                          onDoubleClickFolder={handleClickFolderOrBreadcrumb}/>

      }
      <Modal
        onCancel={() => setIsVisibleModal(false)}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal'}
        visible={isVisibleModal}
        maskClosable={false}
      >

        <p>
          {t('i0183')}
        </p>
        <ButtonGroup>
          <Button type={'default'} onClick={onHandleCloseModal}>{t('i0185')}</Button>
          <Button type={'primary'}
                  onClick={onConfirmAction}>{t('i0184')} </Button>
        </ButtonGroup>
      </Modal>

    </>
  )
}

GoogleDriveTab.propTypes = {}

export default inject(
  'authenticationStore',
  'synchronizationStore',
  'commonStore',
  'selectionStore',
  'profileStore',
)(observer(GoogleDriveTab))
