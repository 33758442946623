import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { ButtonGroup } from '../../pages/TrashPage/TrashPageStyled'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { CustomCustomNotificationMessage } from '../CommonStyled/CommonStyled'

const ModalConfirmDelete = props => {
  const { commonStore, onOk } = props
  const { t } = useTranslation()
  return (
    <Modal
      onCancel={() => commonStore.setVisibleModalDelete(false)}
      footer={null} closable={null}
      wrapClassName={'custom-ant-modal context-menu'}
      visible={commonStore.visibleModalDelete}>
      <p>
        {t('i0115')}
      </p>
      <ButtonGroup>
        <Button type={'default'} onClick={() => commonStore.setVisibleModalDelete(false)}>{t('i0111')}</Button>
        <Button type={'primary'} onClick={() => onOk()}>{t('i0114')}</Button>
      </ButtonGroup>
    </Modal>
  )
}

ModalConfirmDelete.propTypes = {
  onOk: PropTypes.func
}
export default inject('commonStore')(observer(ModalConfirmDelete))
