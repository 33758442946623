import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  height: 64px;
  width: 100%;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 15px;
  z-index: 10;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }

  .menu-toggle {
    cursor: pointer;
    font-size: 24px;
  }

  .ant-btn-primary {
    background-color: #237BD3;
    color: white;
    padding: 13px 44px;
    font-size: 14px;
    line-height: 22px;
    height: unset;
    border-radius: 8px;
    order: 10;
    @media screen and (max-width: 768px) {
      padding: 10px 20px;
      line-height: 1.5;
    }
  }

  .ant-drawer-body {
    padding: 20px 0;
  }
`
export const UserMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  p {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .anticon {
    color: #595959;
  }
`
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  @media screen and (min-width: 769px ) {
    margin-bottom: 0;
    img {
      width: 100%;
    }
  }

  a {
    &.logo {
      width: 190px;
    }

    &:not(.logo) {
      white-space: nowrap;
      color: #313131;
      font-size: 16px;
      font-weight: 500;
      border-left: 1px solid #E2E2EA;
      padding-left: 20px;
      margin-left: 20px;
    }
  }
`
export const DrawerContent = styled.div`

`
export const Hotline = styled.a`
  margin-left: auto;
  color: #237BD3;
  font-size: 1.6rem;
  font-weight: 500;
`
export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
`