// import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import {
  DrawerWrapper,
  MobileMenuWrapper,
  LogoWrapper,
  DrawerContent,
  DownloadAppBanner,
} from './MobileMenuDrawerStyled'
import { CaretDownOutlined, EllipsisOutlined, MenuOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { Button, Dropdown, Menu, message, Progress } from 'antd'
import SvgIcon from '../SVGIcon/SVGIcon'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CONTENT_TYPE } from '../../utils/constant'
import stringUtils from '../../utils/stringUtils'
import { Capacity, HorizontalLine } from '../MainSidebar/MainSidebarStyled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AvatarWrapper, SortViewMobile, UserMenu } from '../MainHeaderBar/MainHeaderBarStyled'
import SVGIcon from '../SVGIcon/SVGIcon'
import { useTranslation } from 'react-i18next'
import DefaultAvatar from '../DefaultAvatar/DefaultAvatar'
import miscUtils from '../../utils/miscUtils'

const MobileMenuDrawer = props => {

  const { commonStore, myDriveStore, favoriteStore, profileStore, authenticationStore } = props
  const { pageName } = commonStore

  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  const [drawerVisible, setDrawerVisible] = useState(false)

  const renderPageName = useCallback(() => {
    switch (pageName) {
      case 'my-drive':
        return 'My Drive'
      default:
        return null
    }
  }, [pageName])
  const onCloseDrawer = () => {
    setDrawerVisible(false)
  }
  const getRootFavorite = () => {
    if (!favoriteStore.favoriteCurrentParentId) return
    favoriteStore.clearFavoriteList()
    favoriteStore.getFavoriteList().then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  const getRootMyFile = () => {
    if (!myDriveStore.myDriveCurrentNode) return
    let payload = {
      parent_id: null,
      content_type: CONTENT_TYPE.myDrive,
      page: 1,
      per_page: myDriveStore.perPage,
      sort: myDriveStore.myDriveSort,
      sort_by: myDriveStore.myDriveSortBy,
    }
    myDriveStore.clearMyDriveList()
    myDriveStore.getMyDriveList(payload).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  const handleNavigate = menu => {
    switch (menu.key) {
      case 'my-drive':
        getRootMyFile()
        break
      case 'favorite':
        getRootFavorite()
        break
      default:
        break
    }
    history.push(`/${menu.key}`)
    setDrawerVisible(false)
  }
  const handleLogout = () => {
    authenticationStore.userLogout()
      .then(() => history.push('/auth/login'))
  }
  const handleClickProfileHistory = (tab) => {
    if (commonStore.pageName === 'my-profile') {
      commonStore.setActiveTabProfile(tab)
    } else {
      commonStore.setActiveTabProfile(tab)
      history.push('/my-profile')
    }
  }
  const handleDownloadApp = () => {
    const device = miscUtils.detectMobileOperatingSystem()
    const androidURL = 'https://play.google.com/store/apps/details?id=com.mobifone.mobicloud'
    const iOSURL = 'https://apps.apple.com/us/app/mobifone-cloud/id1587835285'
    switch (device) {
      case 'Android':
        window.open(androidURL, '_blank')
        break
      case 'iOS':
        window.open(iOSURL, '_blank')
        break
    }
  }

  const menuUserProps = (
    <Menu>
      <Menu.Item onClick={() => handleClickProfileHistory('1')}>
        {t('i0245')}
      </Menu.Item>
      <Menu.Item onClick={() => handleClickProfileHistory('2')}>
        {t('i0246')}
      </Menu.Item>
      <Menu.Item danger onClick={handleLogout}>
        {t('i0247')}
      </Menu.Item>
    </Menu>
  )
  const sortViewOverlay = (
    <Menu selectedKeys={commonStore.viewByGridMode.toString()}>
      <Menu.Item key={'true'} onClick={() => commonStore.toggleViewByGridMode(true)}>
        <span>{t('i0248')}</span>
      </Menu.Item>
      <Menu.Item key={'false'} onClick={() => commonStore.toggleViewByGridMode(false)}>
        <span>{t('i0249')}</span>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    const segment = location.pathname.split('/').filter(item => item !== '')
    switch (segment[0]) {
      case 'my-drive':
        commonStore.setPageName('my-drive')
        return
      case 'synchronization':
        commonStore.setPageName('synchronization')
        return
      case 'shared-with-me':
        commonStore.setPageName('shared-with-me')
        return
      case 'recently-opened':
        commonStore.setPageName('recently-opened')
        return
      case 'favorite':
        commonStore.setPageName('favorite')
        return
      case 'offline-files':
        commonStore.setPageName('offline-files')
        return
      case 'trash':
        commonStore.setPageName('trash')
        return
      case 'my-profile':
        commonStore.setPageName('my-profile')
        return
      case 'my-profile-edit':
        commonStore.setPageName('my-profile-edit')
        return
      case 'history-login':
        commonStore.setPageName('history-login')
        return
      case 'search':
        commonStore.setPageName('search')
        return
      case 'config-page':
        commonStore.setPageName('config-page')
        return
      case 'change-password':
        commonStore.setPageName('change-password')
        return
      default:
        return
    }
  }, [location.pathname])

  return (
    <MobileMenuWrapper id={'mobile-drawer'}>
      <MenuOutlined
        className={'menu-toggle'}
        onClick={() => setDrawerVisible(!drawerVisible)}
      />
      <h1 className={'page-name'}>{renderPageName()}</h1>
      <DrawerWrapper
        getContainer={() => document.getElementById('mobile-drawer')}
        placement={'left'}
        closable={false}
        onClose={onCloseDrawer}
        visible={drawerVisible}>
        <DrawerContent>
          <LogoWrapper to={'/'} onClick={() => setDrawerVisible(false)}>
            <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_register.png`} alt='' width={140} />
          </LogoWrapper>
          <Menu selectedKeys={commonStore.pageName} mode={'inline'} onClick={handleNavigate}>
            <Menu.Item key={'my-drive'}>
              <SvgIcon name={'drive'} width={23} height={20} />
              <span>{t('i0250')}</span>
            </Menu.Item>
            <Menu.Item key={'synchronization'}>
              <SvgIcon name={'sync'} width={16.69} height={18} />
              <span>{t('i0251')}</span>
            </Menu.Item>
            <Menu.Item key={'shared-with-me'}>
              <SvgIcon name={'user-group'} width={16.69} height={18} />
              <span>{t('i0252')}</span>
            </Menu.Item>
            <Menu.Item key={'recently-opened'}>
              <SvgIcon name={'clock'} width={16.69} height={18} />
              <span>{t('i0253')}</span>
            </Menu.Item>
            <Menu.Item key={'favorite'}>
              <SvgIcon name={'star'} width={20} height={19.14} />
              <span>{t('i0254')}</span>
            </Menu.Item>
            {/*<Menu.Item key={'offline-files'}>*/}
            {/*  <SvgIcon name={'offline'} width={20} height={18} />*/}
            {/*  <span>{t('i0255')}</span>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key={'trash'}>
              <SvgIcon name={'trash'} width={15} height={18} />
              <span>{t('i0256')}</span>
            </Menu.Item>
          </Menu>
          <HorizontalLine />
          <Capacity>
            <SvgIcon name={'cloud'} width={24} height={16} />
            <strong>{t('i0257')}</strong>
            <Progress
              percent={(
                profileStore.myProfile?.used_capacity / profileStore.myProfile?.package?.capacity) * 100
              }
              showInfo={false} />
            <p style={{ marginBottom: 24 }}>
              {t('i0258')} {stringUtils.renderCapacity(profileStore.myProfile?.used_capacity)}/{stringUtils.renderCapacity(profileStore.myProfile?.package?.capacity)}</p>
            <Button>{t('i0259')}</Button>
          </Capacity>
          <HorizontalLine />
          <Menu>
            <Menu.Item>
              <Link to={'/introduction'}>{t('i0012')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to={'/term-of-use'}>{t('i0013')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to={'/privacy-policy'}>{t('i0014')}</Link>
            </Menu.Item>
          </Menu>
          <p className={'copyright'} style={{ paddingBottom: 24 }}>&copy; 2021 - Mobifone</p>
          <DownloadAppBanner onClick={handleDownloadApp}>
            <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_home_trimmed.png`} alt='' width={40} />
            <span style={{
              marginLeft: 5,
              marginTop: 7,
            }}>{t('i0721')}</span>
          </DownloadAppBanner>
        </DrawerContent>
      </DrawerWrapper>
      <Dropdown overlay={sortViewOverlay}>
        <SortViewMobile>
          <EllipsisOutlined />
        </SortViewMobile>
      </Dropdown>
      <Dropdown overlay={menuUserProps}>
        <UserMenu>
          <AvatarWrapper>
            {
              profileStore.myProfile?.avatar
                ? <img src={profileStore.myProfile?.avatar} alt='' width={32} height={32} />
                : <DefaultAvatar username={profileStore.myProfile?.name} defaultTheme />
            }
          </AvatarWrapper>
          <CaretDownOutlined />
        </UserMenu>
      </Dropdown>
    </MobileMenuWrapper>
  )
}

MobileMenuDrawer.propTypes = {}

export default inject(
  'commonStore',
  'myDriveStore',
  'favoriteStore',
  'profileStore',
  'authenticationStore',
)(observer(MobileMenuDrawer))