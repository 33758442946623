const miscUtils = {

  /** Get gender name */
  getGenderName: gender => {
    let genderName = ''
    switch (gender) {
      case 0:
        genderName = 'Nữ'
        break
      case 1:
        genderName = 'Nam'
        break
      case 2:
        genderName = 'Khác'
        break
      default:
        genderName = undefined
        break
    }
    return genderName
  },

  /** Check nullish */
  isNullish: value => {
    return value === undefined || value === null || value === ''
  },

  /** Phone number format */
  phoneNumberReplace: phone_number => {
    if (!phone_number) return null
    if (phone_number.charAt(0) !== '0') {
      return phone_number
        .replace('84', '0')
        .replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
    }
    return phone_number
      .replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
  },

  detectMobileOperatingSystem: () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }
}

export default miscUtils
