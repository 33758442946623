import React, { Fragment, memo, useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Wrapper } from './NotPermissionViewFileShareStyled'
import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'


const NotPermissionViewFileShare = props => {

  const { history } = props
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Helmet>
        <title>{t('i0540')}</title>
      </Helmet>
      <Result
        status="403"
        title={t('i0540')}
        subTitle={t('i0541')}
        extra={
          <Fragment>
            <Button onClick={() => history.goBack()}>
              {t('i0542')}
            </Button>
            <Button type={'primary'} onClick={() => history.push('/')}>
              {t('i0543')}
            </Button>
          </Fragment>
        }
      />
    </Wrapper>
  )
}

export default memo(NotPermissionViewFileShare)
