import styled from 'styled-components'
import { Button } from 'antd'

export const CommonConfigTabPaneWrapper = styled.div`  
  .common-config-select {
    width: 150px;
    font-size: 1.4rem;
    .ant-select-selector {
      border-radius: 8px;
      background: #F9FAFC;
    }
    .ant-select-selector {
      border: 1px solid #F1F1F5 !important;
      height: 50px !important;
      line-height: 50px !important;
      .ant-select-selection-item {
        line-height: 48px !important;
      }
      .svgicon {
        display: none;
      }
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
  }
`
export const ButtonBuyCapacity = styled(Button)`
  margin-left: 16px;
  color: #237BD3;
  background-color: rgba(35, 123, 211, 0.1);
  border-color: rgba(35, 123, 211, 0.1);
  &:hover, &:active, &:focus {
    color: #237BD3;
    background-color: rgba(35, 123, 211, 0.1);
  }
`
export const DataInfoFormItem = styled.div`
 display: flex;
 margin-top: 5px; 
`
export const ProgressUsedArea = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
`
export const BuyCapacityArea = styled.div`

`
