// import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { SimpleArticle } from '../../components/CommonStyled/CommonStyled'
import { inject, observer } from 'mobx-react'
import ReactHtmlParser from 'react-html-parser'
import { message } from 'antd'
import { Helmet } from 'react-helmet/es/Helmet'
import { useTranslation } from 'react-i18next'

const PrivacyPolicyPage = props => {
  const { privacyPolicyStore, privacyPolicyStore: { privacyPolicyContent } } = props

  const { t } = useTranslation()

  useEffect(() => {
    privacyPolicyStore.getContentPrivacyPolicyPage().then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('i0556')}</title>
      </Helmet>
      <SimpleArticle>
        <h1>{privacyPolicyContent.title}</h1>
        {ReactHtmlParser(privacyPolicyContent.content)}
      </SimpleArticle>
    </>
  )
}

PrivacyPolicyPage.propTypes = {}

export default inject(
  'privacyPolicyStore',
)(observer(PrivacyPolicyPage))