import styled from 'styled-components'
import { Table } from 'antd'

export const TableWrapper = styled.div`
  overflow: auto;
  * {
    user-select: none;
  }
`
export const CustomTable = styled(Table)`
 .ant-table-row {
   &.selected {
     td {
       background-color: #e6f7ff;
     }
     &:hover {
       td {
         background-color: #e6f7ff;
       }
     }
   }
 }
`