import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CustomTable, TableWrapper } from './FilesListViewStyled'
import ContextMenuListView from '../ContextMenuListView'
import { CONTEXT_MENU_ACTION, MIMETYPE_OFFICE, MIMETYPE_PDF } from '../../utils/constant'
import { ButtonGroup } from '../../pages/TrashPage/TrashPageStyled'
import { Button, Form, Input, message, Modal, Tooltip } from 'antd'
import MoveItemMenuDropdown from '../MoveItemMenuDropdown'
import SVGIcon from '../../components/SVGIcon'
import { inject, observer } from 'mobx-react'
import myDriveStore from '../../stores/myDriveStore'
import stringUtils from '../../utils/stringUtils'
import { FileListViewNameWrapper } from '../CommonStyled/CommonStyled'
import { toJS } from 'mobx'
import { useMediaQuery } from 'react-responsive/src'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import uuid from 'uuid'
import { useLocation } from 'react-router-dom'

const FilesListView = props => {

  const {
    fileList,
    callbackAction,
    onDoubleClickFolder,
    appConfigStore,
    selectionStore,
    commonStore,
    previewOverlayStore,
    downloadStore,
    myDriveStore,
    sharedWithMeStore,
    recentlyOpenStore,
    favoriteStore,
    trashStore,
    offlineStore,
    searchStore,
    authenticationStore,
    filePreviewStore
  } = props
  const { iconsByMimeTypeObj, mimetypeGroups, groupIdsByMimetype } = appConfigStore
  const { selectionData, selectionIds } = selectionStore
  const { pageName } = commonStore
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const { t } = useTranslation()
  const location = useLocation()
  
  const [formRename] = Form.useForm()
  const [contextMenuOptions, setContextMenuOptions] = useState({ record: {}, visible: false, x: 0, y: 0 })
  const [currentAction, setCurrentAction] = useState(null)
  const [currentItemDrive, setCurrentItemDrive] = useState(null)
  const [moveItemDrive, setMoveItemDrive] = useState(null)
  const [visibleModalMoveItem, setVisibleModalMoveItem] = useState(false)
  const [positionModalMoveItem, setPositionModalMoveItem] = useState({ x: 0, y: 0 })
  const [targetItemDrive, setTargetItemDrive] = useState(null)
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false)
  const [modalRenameVisible, setModalRenameVisible] = useState(false)
  const [modalMessage, setModalMessage] = useState(t('i0131'))
  const [modalOkText, setModalOkText] = useState(t('i0132'))

  const handleActionContextMenu = (action) => {
    setCurrentAction(action)
    if (selectionIds.length > 1) {
      switch (action) {
        case CONTEXT_MENU_ACTION.trash:
          setModalMessage(t('i0113'))
          setModalOkText(t('i0114'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.move:
          setMoveItemDrive(selectionData[0])
          setVisibleModalMoveItem(true)
          break
        case CONTEXT_MENU_ACTION.restore:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          callbackAction(action, 'folder')
          break
        case CONTEXT_MENU_ACTION.deleteShareWithMe:
        case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
          setModalMessage(t('i0120'))
          setModalOkText(t('i0121'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.lock:
          setModalMessage(t('i0165'))
          setModalOkText(t('i0166'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.unlock:
          callbackAction(action, undefined)
          break
        default:
          break
      }
    } else {
      switch (action) {
        case CONTEXT_MENU_ACTION.syncFromOneDrive:
        case CONTEXT_MENU_ACTION.syncFromGoogleDrive:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.copyLink:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.share:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.trash:
          setModalMessage(t('i0133'))
          setModalOkText(t('i0134'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.download:
          let item = contextMenuOptions.record
          if (contextMenuOptions.record && contextMenuOptions.record.mimetype === 'folder') {
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            const uid = uuid()
            item.uid = uid
            item.cancelSource = source
            downloadStore.setFileList([item])
            if(location.pathname.includes('/driver/folders')) {
              filePreviewStore.downloadPublicFolder(item).then(responseLink => {
                if (responseLink.error_code === 0) {
                  if (!responseLink.data.url) {
                    message.error( t('i0692'))
                  } else {    
                      setTimeout(() => {
                        axios.get(responseLink.data.url, {
                          responseType: 'blob',
                          disableSpinner: true,
                        }).then(res => {
                          FileDownload(res.data, `${folder['name']}.zip`)
                       
                        })
                      }, 1500)
                  }
                }
              }).catch(error => {
                console.log(error)
              })
            }else {
              downloadStore.downloadFolder(item).then(responseLink => {
                if (responseLink.error_code === 0) {
                  if (!responseLink.data.url) {
                    downloadStore.updateStatusFile(item.uid, 'error')
                    downloadStore.handleMessageDownload(item.uid, t('i0692'))
                  } else {
                    if (downloadStore.statusDownLoad[item.uid] !== 'error' && downloadStore.statusDownLoad[item.uid] !== 'cancelled') {
                      downloadStore.updateStatusFile(item.uid, 'success')
                      downloadStore.handleMessageDownload(item.uid, t('i0685'))
                      item.download_url = responseLink.data.url
                      downloadStore.updateFolderDownload(item)
                    } else {
                      downloadStore.updateStatusFile(item.uid, 'error')
                      downloadStore.handleMessageDownload(item.uid, t('i0692'))
                    }
                  }
                } else {
                  message.error(responseLink.message);
                }
              }).catch(error => {
                console.log(error)
              })
            }

          } else {
            callbackAction(action, item)
          }
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          callbackAction(action, 'folder')
          break
        case CONTEXT_MENU_ACTION.restore:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.favorite:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.removeFavorite:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.move:
          setMoveItemDrive(selectionData[0])
          setVisibleModalMoveItem(true)
          break
        case CONTEXT_MENU_ACTION.rename:
          formRename.setFieldsValue({
            fileName: selectionData[0].name,
          })
          setModalOkText(t('i0135'))
          setModalRenameVisible(true)
          break
        case CONTEXT_MENU_ACTION.viewInfo:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.lock:
          setModalMessage(t('i0136'))
          setModalOkText(t('i0137'))
          setModalConfirmVisible(true)
          break
        case CONTEXT_MENU_ACTION.unlock:
          callbackAction(action, undefined)
          break
        case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
        case CONTEXT_MENU_ACTION.deleteShareWithMe:
          setModalMessage(t('i0138'))
          setModalOkText(t('i0139'))
          setModalConfirmVisible(true)
          break
        default:
          break
      }
    }
  }
  const handleConfirmModal = (isConfirm) => {
    setModalConfirmVisible(false)
    if (!isConfirm) {
      setCurrentAction(null)
      setTargetItemDrive(null)
      setMoveItemDrive(null)
      return
    }
    switch (currentAction) {
      case CONTEXT_MENU_ACTION.copyLink:
        break
      case CONTEXT_MENU_ACTION.trash:
        myDriveStore.setItemRollbackTrash(selectionData[0])
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.deleteRecentlyOpen:
      case CONTEXT_MENU_ACTION.deleteShareWithMe:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.download:
        break
      case CONTEXT_MENU_ACTION.favorite:
        break
      case CONTEXT_MENU_ACTION.move:
        callbackAction(currentAction, targetItemDrive)
        break
      case CONTEXT_MENU_ACTION.rename:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.share:

        break
      case CONTEXT_MENU_ACTION.viewInfo:

        break
      case CONTEXT_MENU_ACTION.lock:
        callbackAction(currentAction, undefined)
        break
      case CONTEXT_MENU_ACTION.unlock:
        break
      default:
        break
    }
  }
  const handleCancelRename = () => {
    setCurrentAction(null)
    setModalRenameVisible(false)
  }
  const handleConfirmRename = (formData) => {
    myDriveStore.setItemRollbackRename({ ...selectionData[0] })
    selectionData[0].name = formData.fileName
    callbackAction(currentAction)
    setModalRenameVisible(false)
  }
  const handleCloseMoveMenu = () => {
    setMoveItemDrive(null)
    setVisibleModalMoveItem(false)
  }
  const handleCallbackMoveItem = (itemDriveToMove, targetItemDrive) => {
    setTargetItemDrive(targetItemDrive)
    setModalMessage(`${t('i0140')} ${targetItemDrive?.name || ''} ${t('i0141')}`)
    setModalOkText(t('i0142'))
    setModalConfirmVisible(true)
  }
  const handleSelection = (event, itemData) => {
    if (pageName === 'synchronization') {
      selectionStore.setSelectionData([itemData])
      return
    }
    // Nếu click ctrl hoặc cmd thì thêm từng phần tử được click vào selectionStore
    if (event.ctrlKey || event.metaKey) {
      if (selectionIds.includes(itemData.id)) {
        const selection = toJS(selectionData)
        const filteredData = selection.filter(item => item.id !== itemData.id)
        selectionStore.setSelectionData([
          ...filteredData,
        ])
        return
      }
      selectionStore.setSelectionData([
        ...selectionData, itemData,
      ])
    } else if (event.shiftKey) {
      // Nếu shift click mà chưa có phần tử nào trong selectionStore thì thêm phần tử vừa lick vào
      if (selectionIds.length === 0) {
        selectionStore.setSelectionData([itemData])
      } else {
        selectionStore.setClickSessionId()
        // Nếu đã có phần tử trong selection thì lấy các item trong khoảng
        let lastItemSelectedInStore = selectionData[0]
        let itemShiftClick = itemData
        if (lastItemSelectedInStore.realIndex > itemShiftClick.realIndex) {
          selectionStore.setFirstSelectionIndex(itemShiftClick.realIndex)
          selectionStore.setLastSelectionIndex(lastItemSelectedInStore.realIndex)
        } else {
          selectionStore.setFirstSelectionIndex(lastItemSelectedInStore.realIndex)
          selectionStore.setLastSelectionIndex(itemShiftClick.realIndex)
        }
      }
    } else {
      selectionStore.setSelectionData([itemData])
    }
  }
  const handleRightClickSelection = (event, itemData) => {
    if (selectionIds.includes(itemData.id)) return
    selectionStore.setSelectionData([itemData])
  }
  const onTableRowAction = record => ({
    onContextMenu: event => {
      event.preventDefault()
      const mouseX = event.pageX
      const mouseY = event.pageY
      if (!contextMenuOptions.visible) {
        document.addEventListener(`click`, function onClickOutside() {
          setContextMenuOptions({ ...contextMenuOptions, visible: false, x: mouseX, y: mouseY })
          document.removeEventListener(`click`, onClickOutside)
        })
      }
      setPositionModalMoveItem({ x: event.clientX, y: event.clientY })
      setContextMenuOptions({
        ...contextMenuOptions,
        record: record,
        visible: true,
        x: mouseX,
        y: mouseY,
      })
      handleRightClickSelection(event, record)
    },
    onClick: event => {
      handleSelection(event, record)
    },
    onDoubleClick: () => {
      if (pageName === 'trash') return
      if (record.type === 1) {
        onDoubleClickFolder(record)
      } else {
        if (pageName === 'offline-files' || pageName === 'synchronization') return
        let group_id = groupIdsByMimetype[record.mimetype]
        let typeName
        let findInd = mimetypeGroups.findIndex(item => item.id === group_id)
        if (findInd > -1) typeName = mimetypeGroups[findInd].name
        if (typeName === 'Image' || typeName === 'Video' || typeName === 'Audio') {
          record.type_name = typeName
          previewOverlayStore.setVisiblePreviewOverlay()
          previewOverlayStore.setRecordOverlay(record)
        }
        if (MIMETYPE_OFFICE.includes(record.mimetype)) {
          const win = window.open(`/doc-viewer/${authenticationStore.accessToken ? record.id : record.encrypt_id}`, '_blank')
          win.focus()
        }
        if (MIMETYPE_PDF.includes(record.mimetype)) {
          const win = window.open(`/pdf-viewer/${authenticationStore.accessToken ? record.id : record.encrypt_id}`, '_blank')
          win.focus()
        }
      }
    },
  })
  const renderRowClassName = record => {
    const recordData = toJS(record)
    return selectionIds.includes(recordData.id) ? 'selected' : null
  }
  const renderTitleName = () => {
    let icon = ''
    let tooltipText = ''
    let oppositeSort = ''
    switch (pageName) {
      case 'my-drive':
        icon = myDriveStore.myDriveSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = myDriveStore.myDriveSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = myDriveStore.myDriveSort === 'asc' ? 'desc' : 'asc'
        return (
          myDriveStore.myDriveSortBy === 'name'
            ?
            <>{t('i0143')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => myDriveStore.setMyDriveSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }} onClick={() => myDriveStore.setMyDriveSortBy('name')}>{t('i0144')}</a>
        )
      case 'synchronization':
        return (
          <a style={{ color: '#313131' }}>{t('i0144')}</a>
        )
      case 'shared-with-me':
        icon = sharedWithMeStore.sharedWithMeSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = sharedWithMeStore.sharedWithMeSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = sharedWithMeStore.sharedWithMeSort === 'asc' ? 'desc' : 'asc'
        return (
          sharedWithMeStore.sharedWithMeSortBy === 'name'
            ?
            <>{t('i0143')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => sharedWithMeStore.setSharedWithMeSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }}
               onClick={() => sharedWithMeStore.setSharedWithMeSortBy('name')}>{t('i0144')}</a>
        )
      case 'recently-opened':
        icon = recentlyOpenStore.recentlyOpenSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = recentlyOpenStore.recentlyOpenSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = recentlyOpenStore.recentlyOpenSort === 'asc' ? 'desc' : 'asc'
        return (
          recentlyOpenStore.recentlyOpenSortBy === 'name'
            ?
            <>{t('i0143')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => recentlyOpenStore.setRecentlyOpenSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }}
               onClick={() => recentlyOpenStore.setRecentlyOpenSortBy('name')}>{t('i0144')}</a>
        )
      case 'favorite':
        icon = favoriteStore.favoriteSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = favoriteStore.favoriteSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = favoriteStore.favoriteSort === 'asc' ? 'desc' : 'asc'
        return (
          favoriteStore.favoriteSortBy === 'name'
            ?
            <>{t('i0143')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => favoriteStore.setFavoriteSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }} onClick={() => favoriteStore.setFavoriteSortBy('name')}>{t('i0144')}</a>
        )
      case 'trash':
        icon = trashStore.trashSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = trashStore.trashSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = trashStore.trashSort === 'asc' ? 'desc' : 'asc'
        return (
          trashStore.trashSortBy === 'name'
            ?
            <>{t('i0143')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => trashStore.setTrashSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }} onClick={() => trashStore.setTrashSortBy('name')}>{t('i0144')}</a>
        )
      case 'offline-files':
        icon = offlineStore.sortAsc ? 'up.svg' : 'down.svg'
        tooltipText = offlineStore.sortAsc ? t('i0701') : t('i0700')
        return (
          offlineStore.sortBy === 1
            ? (
              <>
                {t('i0143')}
                <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}>
                  <img onClick={() => offlineStore.setSortAsc(!offlineStore.sortAsc)} style={{ cursor: 'pointer' }}
                       src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} />
                </Tooltip>
              </>
            ) : (
              <a style={{ color: '#313131' }}
                 onClick={() => offlineStore.setSortBy(1)}>
                {t('i0144')}
              </a>
            )
        )
      case 'search':
        icon = searchStore.searchPageSortAsc ? 'up.svg' : 'down.svg'
        tooltipText = searchStore.searchPageSortAsc ? t('i0701') : t('i0700')
        oppositeSort = !searchStore.searchPageSortAsc
        return (
          searchStore.searchPageSortBy === 'name'
            ?
            <>{t('i0143')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => searchStore.setSearchPageSortAsc(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }} onClick={() => searchStore.setSearchPageSortBy('name')}>{t('i0144')}</a>
        )
      default:
        break
    }

  }

  const renderTitleUpdateAt = () => {
    let icon = ''
    let tooltipText = ''
    let oppositeSort = ''

    switch (pageName) {
      case 'my-drive':
        icon = myDriveStore.myDriveSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = myDriveStore.myDriveSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = myDriveStore.myDriveSort === 'asc' ? 'desc' : 'asc'
        return (
          myDriveStore.myDriveSortBy === 'updated_at'
            ?
            <>{t('i0145')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => myDriveStore.setMyDriveSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }} onClick={() => myDriveStore.setMyDriveSortBy('updated_at')}>{t('i0146')}</a>
        )
      case 'synchronization':
        return (
          <a style={{ color: '#313131' }}>{t('i0146')}</a>
        )
      case 'shared-with-me':
        icon = sharedWithMeStore.sharedWithMeSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = sharedWithMeStore.sharedWithMeSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = sharedWithMeStore.sharedWithMeSort === 'asc' ? 'desc' : 'asc'
        return (
          sharedWithMeStore.sharedWithMeSortBy === 'updated_at'
            ?
            <>{t('i0145')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => sharedWithMeStore.setSharedWithMeSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }}
               onClick={() => sharedWithMeStore.setSharedWithMeSortBy('updated_at')}>{t('i0146')}</a>
        )
      case 'recently-opened':
        icon = recentlyOpenStore.recentlyOpenSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = recentlyOpenStore.recentlyOpenSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = recentlyOpenStore.recentlyOpenSort === 'asc' ? 'desc' : 'asc'
        return (
          recentlyOpenStore.recentlyOpenSortBy === 'updated_at'
            ?
            <>{t('i0145')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => recentlyOpenStore.setRecentlyOpenSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }}
               onClick={() => recentlyOpenStore.setRecentlyOpenSortBy('updated_at')}>{t('i0146')}</a>
        )
      case 'favorite':
        icon = favoriteStore.favoriteSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = favoriteStore.favoriteSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = favoriteStore.favoriteSort === 'asc' ? 'desc' : 'asc'
        return (
          favoriteStore.favoriteSortBy === 'updated_at'
            ?
            <>{t('i0145')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => favoriteStore.setFavoriteSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }}
               onClick={() => favoriteStore.setFavoriteSortBy('updated_at')}>{t('i0146')}</a>
        )
      case 'trash':
        icon = trashStore.trashSort === 'asc' ? 'up.svg' : 'down.svg'
        tooltipText = trashStore.trashSort === 'asc' ? t('i0701') : t('i0700')
        oppositeSort = trashStore.trashSort === 'asc' ? 'desc' : 'asc'
        return (
          trashStore.trashSortBy === 'updated_at'
            ?
            <>{t('i0145')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => trashStore.setTrashSort(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }} onClick={() => trashStore.setTrashSortBy('updated_at')}>{t('i0146')}</a>
        )
      case 'offline-files':
        icon = offlineStore.sortAsc ? 'up.svg' : 'down.svg'
        tooltipText = offlineStore.sortAsc ? t('i0701') : t('i0700')
        return (
          offlineStore.sortBy === 2
            ?
            <>{t('i0145')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => offlineStore.setSortAsc(!offlineStore.sortBy)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }} onClick={() => offlineStore.setSortBy(2)}>{t('i0146')}</a>
        )
      case 'search':
        icon = searchStore.searchPageSortAsc ? 'up.svg' : 'down.svg'
        tooltipText = searchStore.searchPageSortAsc ? t('i0701') : t('i0700')
        oppositeSort = !searchStore.searchPageSortAsc
        return (
          searchStore.searchPageSortBy === 'updated'
            ?
            <>{t('i0145')} <Tooltip title={tooltipText} mouseEnterDelay={0.3} placement={'bottom'}><img
              onClick={() => searchStore.setSearchPageSortAsc(oppositeSort)} style={{ cursor: 'pointer' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} alt={''} /></Tooltip></>
            :
            <a style={{ color: '#313131' }}
               onClick={() => searchStore.setSearchPageSortBy('updated')}>{t('i0146')}</a>
        )
      default:
        break
    }
  }

  const tableColumns = [
    {
      title: renderTitleName(),
      width: '20%',
      render: record => (
        <FileListViewNameWrapper>
          {
            (record.mimetype === 'folder' || record.mimeType === 'application/vnd.google-apps.folder')
              ? <SVGIcon name={'folder'} />
              : (
                <SVGIcon
                  url={record.iconLink || iconsByMimeTypeObj[record.mimetype] || iconsByMimeTypeObj['default']}
                  width={18} height={18}
                />
              )
          }
          <Tooltip mouseEnterDelay={1} title={record.name} placement={'bottom'}>
            <span className={'data-name'}>{record.name}</span>
          </Tooltip>
          {
            record.favorited &&
            <img src={`${process.env.PUBLIC_URL}/assets/icons/staractive.svg`} className={'favorite-star'} width={16}
                 height={16} alt={''} />
          }
        </FileListViewNameWrapper>
      ),
    },
    {
      title: t('i0147'),
      render: record => record.owner_name,
    },
    {
      title: t('i0148'),
      render: record => record.updated_name,
    },
    {
      title: renderTitleUpdateAt(),
      render: record => record.updated_at,
    },
    {
      title: t('i0149'),
      align: 'center',
      render: record => {
        if (record?.type === 1) {
          return '-'
        } else {
          return stringUtils.renderCapacity(record.usage_capacity)
        }
      },
    },
  ]

  return (
    <TableWrapper>
      <CustomTable
        rowClassName={renderRowClassName}
        className={'selectable-item'}
        pagination={false}
        rowKey={record => record.id}
        columns={tableColumns}
        dataSource={fileList}
        onRow={onTableRowAction}
      />

      <ContextMenuListView
        visible={contextMenuOptions.visible}
        record={contextMenuOptions.record}
        x={contextMenuOptions.x}
        y={contextMenuOptions.y}
        callbackAction={handleActionContextMenu}
      />

      <MoveItemMenuDropdown
        moveMenuVisible={visibleModalMoveItem}
        itemToMove={selectionIds}
        callbackMoveItem={handleCallbackMoveItem}
        onClose={handleCloseMoveMenu}
        x={positionModalMoveItem.x}
        y={positionModalMoveItem.y}
      />

      <Modal
        onCancel={() => handleConfirmModal(false)}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal context-menu'}
        visible={modalConfirmVisible}>
        <p>
          {modalMessage}
        </p>
        <ButtonGroup>
          <Button type={'default'} onClick={() => handleConfirmModal(false)}>Huỷ</Button>
          <Button type={'primary'} onClick={() => handleConfirmModal(true)}>{modalOkText}</Button>
        </ButtonGroup>
      </Modal>
      <Modal
        forceRender={true}
        onCancel={handleCancelRename}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal context-menu'}
        visible={modalRenameVisible}>
        <Form form={formRename} onFinish={handleConfirmRename}>
          <p>
            {t('i0150')}
          </p>
          <Form.Item
            label={''}
            name={'fileName'}
            rules={[
              // { validator: validator.validateInputStringFolderName },
              { required: true, message: t('i0151') },
              // { max: 40, message: t('i0152') },
            ]}>
            <Input placeholder={t('i0153')} />
          </Form.Item>
          <ButtonGroup>
            <Button type={'default'} onClick={handleCancelRename}>{t('i0154')}</Button>
            <Button type={'primary'} htmlType={'submit'}>{modalOkText}</Button>
          </ButtonGroup>
        </Form>
      </Modal>
    </TableWrapper>
  )
}

FilesListView.propTypes = {
  fileList: PropTypes.array.isRequired,
  onDoubleClickFolder: PropTypes.func,
}

export default inject(
  'appConfigStore',
  'selectionStore',
  'commonStore',
  'previewOverlayStore',
  'downloadStore',
  'myDriveStore',
  'sharedWithMeStore',
  'recentlyOpenStore',
  'favoriteStore',
  'trashStore',
  'offlineStore',
  'searchStore',
  'authenticationStore',
  'filePreviewStore'
)(observer(FilesListView))
