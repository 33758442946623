import styled, { css } from 'styled-components'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  height: 64px;
  width: calc(100% - 250px);
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 15px;
  z-index: 10;
  ${props => props.isMenubarHover && css`
    padding-right: 47px;
  `}
`
export const SearchBarWrapper = styled.div`
  background-color: #F9FAFC;
  border: 1px solid #F1F1F5;
  height: 50px;
  width: 550px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 8px;
  margin-right: auto;
  position: relative;

  @media screen and (max-width: 1150px) {
    width: auto;
    flex: 1;
    margin-right: 30px;
  }

  .ant-input-affix-wrapper {
    border: none;
    background-color: transparent;
    padding: 0 20px;

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      outline: none;
    }

    .ant-input-suffix {
      display: none;
    }

    .ant-input {
      background-color: transparent;
    }
  }

  .svgicon {

  }
`
export const UserMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  p {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .anticon {
    color: #595959;
  }
`
export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
`
export const NotificationMenu = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .ant-badge-count {
    width: 22px;
    height: 22px;
    padding: 0;
    font-size: 10px;
    border: 2px solid white;
  }
`
export const QuickSearchResult = styled.ul`
  padding: 12px 5px 12px 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 300px;
  overflow: auto;
`
export const QuickSearchResultItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    width: 20px;
  }

  .desc {
    width: calc(100% - 34px);

    p {
      margin-bottom: 0;

      &.file-name {
        color: #313131;
        font-size: 14px;
      }

      &.file-owner {
        color: #8C8C8C;
        font-size: 12px;
      }
    }
  }
`
export const SortViewMobile = styled.div`
  .anticon {
    font-size: 24px;
  }
`
export const Toolbox = styled.div`
  display: flex;
  align-items: center;

  > a {
    display: flex;
    align-items: center;
  }
`