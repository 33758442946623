import styled from 'styled-components'
import { slideDownIn, slideUpIn } from '../CommonStyled/CommonStyled'

export const AdvanceFilterDropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
`
export const AdvanceFilterFormWrapper = styled.div`
  position: absolute;
  top: 54px;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  border-radius: 14px;

  &.slide-down {
    animation: ${slideDownIn} 0.2s ease-in-out;
  }

  &.slide-up {
    animation: ${slideUpIn} 0.2s ease-in-out;
  }

  .ant-select-selector {
    border-radius: 8px !important;
    background: #F9FAFC !important;
    color: #313131;
    font-size: 1.4rem;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid #d9d9d9;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .advance-filter-input {
    border-radius: 8px !important;
    background: #F9FAFC !important;
    color: #313131;
    font-size: 1.4rem;

    &:hover, &:focus {
      border-radius: 8px !important;
      background: #F9FAFC !important;
      box-shadow: none;
      border-color: #d9d9d9;
    }
  }

  .ant-form-item-label {
    label {
      height: 50px;
    }
  }

  .ant-select {
    .ant-select-selector {
      height: 50px !important;
      border: 1px solid #F1F1F5;

      .ant-select-selection-item {
        line-height: 48px !important;
      }
    }

    .ant-select-arrow {
      color: #000;
    }
  }

  .ant-input {
    height: 50px !important;
    border: 1px solid #F1F1F5;
    line-height: 48px !important;
  }

  .ant-picker {
    border: 1px solid #F1F1F5;
    height: 50px;
    line-height: 48px;
    background-color: #F9FAFC;
    border-radius: 8px;

    &.ant-picker-focused {
      box-shadow: none;
    }

    .ant-picker-input {
      flex-direction: row-reverse;

      .ant-picker-suffix {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
`
export const AdvanceFilterDropdownBody = styled.div`
  padding: 16px;
`
export const AdvanceFilterDropdownFooter = styled.div`
  border-top: 1px solid #E2E2E2;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .gray-btn {
    background: #E2E2E2;

    &:hover, &:active, &:focus {
      color: #313131;
      background: #E2E2E2;
      border: 1px solid #E2E2E2;
    }
  }

  .ant-btn {
    min-width: 110px;
    min-height: 38px;
    border-radius: 8px;
  }

  .ant-btn-primary {
    margin-left: 24px;
  }
`
export const DateRangeWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .ant-form-item {
    width: 48%;
    justify-content: space-between;

    .ant-col-7 {
      width: 20%;
      flex: unset;
      max-width: unset;
    }

    .ant-col-17 {
      width: 80%;
      flex: unset;
      max-width: unset;
    }
  }
`
export const StyledTagsSelect = styled.div`
  position: relative;

  .custom-tags-select {
    background: #F9FAFC;
    border: 1px solid #F1F1F5;
    border-radius: 8px;

    &::placeholder {
      color: #A7A7A7;
    }

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      padding: 0 50px 0 5px;
      min-height: 50px;
      height: auto !important;

      .ant-select-selection-item {
        padding: 5px 10px;
        height: auto;
        line-height: normal !important;
        margin: 4px;
        border: none;
        border-radius: 40px;
        background-color: #E8E8E8;
        min-height: 42px;
        align-items: center;

        p:last-of-type {
          display: none;
        }

        .ant-select-selection-item-content {
          margin-right: 10px;
        }

        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          color: black;

          * {
            line-height: 0;
          }
        }
      }
    }
  }

  .ant-spin {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`
export const SharingUserInfo = styled.div`
  display: flex;
  align-items: center;
`
export const UserAvatar = styled.a`
  display: block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;

  img {
    width: 100%;
    object-position: center;
    object-fit: cover;
  }
`
export const UserInfo = styled.div`
  margin-right: auto;
  margin-left: 8px;

  p {
    margin-bottom: 0;

    &:first-child {
      color: #313131;
      font-size: 1.4rem;
    }

    &:last-child {
      color: #8C8C8C;
      font-size: 1.2rem;
    }
  }
`