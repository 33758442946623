const httpUtils = {

  paramsSerializer(queryObj) {
    let str = ''
    for (let key in queryObj) {
      if (str !== '') str += '&'
      str += key + '=' + encodeURIComponent(queryObj[key])
    }
    return str
  },

}

export default httpUtils
