import { action, observable } from 'mobx'
import { NotificationsRequest } from '../requests/NotificationsRequest'

class NotificationsStore {

  @observable fcmToken = localStorage.getItem('fcmToken') || undefined
  @observable notificationList = []
  @observable totalNotSeen = null
  @observable visibleDropdown = false
  @observable page = 1
  @observable perPage = 20
  @observable hasNextPage = true
  @observable hasNewRealTimeNotify = false

  @action cancelRequest = () => {
    NotificationsRequest.cancelRequest()
  }
  @action setHasNewRealTimeNotify = (isHas) => {
    this.hasNewRealTimeNotify = isHas
  }
  @action setHasNextPage = (isHasMore) => {
    this.hasNextPage = isHasMore
  }
  @action setTotalNotSeen = (total) => {
    this.totalNotSeen = total
  }
  @action setPage = (number) => {
    this.page = number
  }
  @action setNotificationList = (list) => {
    this.notificationList = list
  }
  @action setVisibleDropdown = (visible) => {
    this.visibleDropdown = visible
  }
  @action setFcmToken = (fcm_token) => {
    this.fcmToken = fcm_token
  }
  @action getNotificationListOnScroll = () => {
    return new Promise((resolve, reject) => {
      NotificationsRequest.getNotificationListNoSpinner(this.page, this.perPage).then(response => {
        this.totalNotSeen = response.data?.data?.total_not_seen
        this.hasNextPage = response.data?.meta?.has_next_page
        this.notificationList = [...this.notificationList, ...response.data?.data?.notifications]
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getNotificationList = () => {
    this.notificationList = null
    return new Promise((resolve, reject) => {
      NotificationsRequest.getNotificationList(this.page, this.perPage).then(response => {
        this.totalNotSeen = response.data?.data?.total_not_seen
        this.hasNextPage = response.data?.meta?.has_next_page
        this.notificationList = response.data?.data?.notifications
        resolve(response.data)
      }).catch(error => {
        this.notificationList = []
        reject(error)
      })
    })
  }
  @action getNotificationListNoSpinner = () => {
    this.notificationList = null
    return new Promise((resolve, reject) => {
      NotificationsRequest.getNotificationListNoSpinner(this.page, this.perPage).then(response => {
        this.totalNotSeen = response.data?.data?.total_not_seen
        this.hasNextPage = response.data?.meta?.has_next_page
        this.notificationList = response.data?.data?.notifications
        resolve(response.data)
      }).catch(error => {
        this.notificationList = []
        reject(error)
      })
    })
  }
  @action updateFcmToken = (fcmToken) => {
    return new Promise((resolve, reject) => {
      NotificationsRequest.updateFcmToken(fcmToken).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action seenNotify = (notifyId) => {
    return new Promise((resolve, reject) => {
      NotificationsRequest.seenNotify(notifyId).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default new NotificationsStore()
