import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Button, message, Space, Table, Row, Col, Form, Input, Divider } from 'antd'
import { GroupManageTableWrapper, GroupManageTabPaneWrapper, UpdateGroupWrapper } from './GroupManageTabPaneStyled'
import { PhoneBookItem } from '../PhoneBookTabPane/PhoneBookTabPaneStyled'
import GroupManageUsersList from '../GroupManageUsersList'
import InsertGroupModal from '../InsertGroupModal'
import ConfirmModal from '../ConfirmModal'
import PageResult from '../PageResult'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'react-i18next'

const GroupManageTabPane = (props) => {
  const { groupStore } = props
  const history = useHistory()
  const { groupList, page, perPage, hasNextPage } = groupStore
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const [okText, setOkText] = useState('')
  const [cancelText, setCancelText] = useState('')
  const [confirmModalTitle, setConfirmModalTitle] = useState('')
  const [visibleInsertGroupModal, setVisibleInsertGroupModal] = useState(false)
  const [deletingGroupId, setDeletingGroupId] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isDeleteMultiple, setIsDeleteMultiple] = useState(false)
  const [isLoadingScroll, setIsLoadingScroll] = useState(false)

  const { t } = useTranslation()

  const handleLoadMore = useCallback(() => {
    if (isLoadingScroll) return
    if (hasNextPage) {
      setIsLoadingScroll(true)
      let nextPage = page + 1
      groupStore.setPage(nextPage)
      let payload = {
        page: nextPage,
        per_page: perPage,
      }
      groupStore.getGroupListOnScroll(payload)
        .then(response => {
          if (response.error_code !== 0) {
            message.error(response.message)
          }
        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        .finally(() => setIsLoadingScroll(false))
    }
  }, [hasNextPage, isLoadingScroll])
  const handleClickInsertGroup = () => {
    setVisibleInsertGroupModal(true)
  }
  const handleSubmitInsertGroup = (groupName) => {
    setVisibleInsertGroupModal(false)
    groupStore.addGroup({ name: groupName })
      .then(response => {
        if (response.error_code === 0) {
          initialLoadData()
          message.success(t('i0186'))
        } else {
          message.error(response.message)
        }
      })
      .catch(error => {
        error?.response?.status != 401 && message.error(error.message)
      })
  }
  const handleCancelInsertGroup = () => {
    setVisibleInsertGroupModal(false)
  }
  const handleClickDeleteGroup = (groupId) => {
    setDeletingGroupId(groupId)
    setVisibleConfirmModal(true)
    setConfirmModalTitle(t('i0187'))
    setOkText(t('i0188'))
    setCancelText(t('i0189'))
  }
  const handleClickDeleteMultiplePhoneBook = () => {
    setIsDeleteMultiple(true)
    setVisibleConfirmModal(true)
    setConfirmModalTitle(t('i0190'))
    setOkText(t('i0191'))
    setCancelText(t('i0192'))
  }

  const handleCancelConfirm = () => {
    setVisibleConfirmModal(false)
    setIsDeleteMultiple(false)
    setSelectedRowKeys([]);
  }
  const handleCallbackConfirm = () => {
    setVisibleConfirmModal(false)
    if (isDeleteMultiple) {
      setIsDeleteMultiple(false)
      groupStore.deleteGroup({ group_id: selectedRowKeys })
        .then(response => {
          if (response.error_code === 0) {
            initialLoadData()
            setSelectedRowKeys([])
            message.success(t('i0193'))
          } else {
            message.error(response.message)
          }
        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })

    } else {
      groupStore.deleteGroup({ group_id: [deletingGroupId] })
        .then(response => {
          if (response.error_code === 0) {
            initialLoadData()
            message.success(t('i0194'))
          } else {
            message.error(response.message)
          }
        })
        .catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.id,
    }),
  }

  const tableColumns = [
    {
      title: t('i0195'),
      render: record => (
        <PhoneBookItem>
          {record.name}
        </PhoneBookItem>
      ),
    },
    {
      title: t('i0196'),
      render: record => (
        <PhoneBookItem>
          <GroupManageUsersList usersList={record.avatars} andMoreNumber={(record.total_member - 5) > 0 ? (record.total_member - 5) : null} />
        </PhoneBookItem>
      ),
    },
    {
      title: ' ',
      width: '10%',
      render: record => (
        <Space>
          {/*<a onClick={() => handleClickUpdateGroup(record)}>*/}
          <a onClick={() => history.push(`/update-group/${record.id}`)}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/edit.svg`}
              alt={''}
            />
          </a>
          <a onClick={() => handleClickDeleteGroup(record.id)}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/trash-alt1.svg`}
              alt={''}
            />
          </a>
        </Space>
      ),
    },
  ]

  const initialLoadData = () => {
    groupStore.setPage(1)
    let payload = {
      page: 1,
      per_page: groupStore.perPage,
    }
    groupStore.getGroupList(payload)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
      })
      .catch(error => error?.response?.status != 401 && message.error(error.message))
  }

  useEffect(() => {
    initialLoadData()
    return () => {
      groupStore.clearGroupList()
    }
  }, [])


  return (
    <GroupManageTabPaneWrapper>
      <Button
        onClick={handleClickInsertGroup}
        className={'submit-config-btn'}
        type={'primary'}
        style={{ marginBottom: '16px' }}>
        {t('i0197')}
      </Button>
      {
        selectedRowKeys && selectedRowKeys.length > 0 &&
        <Button
          onClick={handleClickDeleteMultiplePhoneBook}
          className={'default-config-btn'}
          style={{ marginLeft: '24px' }}>
          {t('i0198')}
        </Button>
      }
      <InfiniteScroll
        initialLoad={false}
        pageStart={page} threshold={1}
        loadMore={handleLoadMore}
        hasMore={hasNextPage}
        useWindow={true}
      >
        {
          groupList && groupList.length > 0
            ?
            <GroupManageTableWrapper>
              <Table
                rowSelection={{ ...rowSelection }}
                dataSource={groupList}
                columns={tableColumns}
                rowKey={record => record.id}
                pagination={false} />
            </GroupManageTableWrapper>
            :
            <PageResult type={'group_list_empty'} />
        }
      </InfiniteScroll>

      <InsertGroupModal
        visible={visibleInsertGroupModal}
        onClose={handleCancelInsertGroup}
        callback={handleSubmitInsertGroup} />

      <ConfirmModal
        title={confirmModalTitle}
        visible={visibleConfirmModal}
        okText={okText}
        cancelText={cancelText}
        onClose={handleCancelConfirm}
        callback={handleCallbackConfirm} />
    </GroupManageTabPaneWrapper>
  )
}

GroupManageTabPane.propTypes = {}

export default inject('groupStore')(observer(GroupManageTabPane))