import { action, observable } from 'mobx'
import { SearchRequest } from '../requests/SearchRequest'
import { PAGE_SIZE } from '../utils/constant'

class SearchStore {
  @observable searchList = []
  @observable temporarySearchList = []
  @observable searchBreadcrumbData = [{ id: null, name: 'Tìm kiếm' }]
  @observable searchCurrentNode = null
  @observable searchPageSortAsc = true
  @observable searchPageSortBy = 'name'
  @observable page = 1
  @observable perPage = PAGE_SIZE
  @observable hasNextPage = true
  @observable searchKeyword = null

  @action setPage = (page) => {
    this.page = page
  }
  @action setSearchKeyword = (q) => {
    this.searchKeyword = q
  }
  @action setSearchPageSortAsc = sortDirection => {
    this.searchPageSortAsc = sortDirection
  }
  @action setSearchPageSortBy = sortBy => {
    this.searchPageSortBy = sortBy
  }
  @action resetSearchBreadcrumbData = () => {
    this.searchBreadcrumbData = [{ id: null, name: 'Tìm kiếm' }]
    this.searchCurrentNode = null
  }
  @action setSearchBreadcrumbData = (item) => {
    let index = (this.searchBreadcrumbData).indexOf(item)
    let newSearchBreadcrumbData = []
    if (index >= 0) {
      newSearchBreadcrumbData = this.searchBreadcrumbData.slice(0, index + 1)
    } else {
      newSearchBreadcrumbData = [...this.searchBreadcrumbData, item]
    }
    this.searchBreadcrumbData = newSearchBreadcrumbData
    this.searchCurrentNode = item
  }
  @action clearTemporarySearchList = () => {
    this.temporarySearchList.length = 0
  }
  @action clearSearchList = () => {
    this.searchList.length = 0
  }
  @action quickSearch = keyword => {
    return new Promise((resolve, reject) => {
      SearchRequest.quickSearch(keyword)
        .then(response => {
          this.temporarySearchList = response.data.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getSearchItemList = (
    keyword,
    mimetype,
    storage_type,
    from_date,
    to_date,
    from_update_date,
    to_update_date,
    share_with,
    owner,
    page = 1,
    per_page = 20,
    sort = `${this.searchPageSortBy}_${this.searchPageSortAsc ? 'asc' : 'desc'}`,
  ) => {
    return new Promise((resolve, reject) => {
      SearchRequest.getSearchItemList(
        keyword,
        mimetype,
        storage_type,
        from_date,
        to_date,
        from_update_date,
        to_update_date,
        share_with,
        owner,
        page,
        per_page,
        sort,
      ).then(response => {
        this.searchList = response.data.data
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action getSearchItemListOnScroll = (
    keyword,
    mimetype,
    storage_type,
    from_date,
    to_date,
    from_update_date,
    to_update_date,
    share_with,
    owner,
    page,
    per_page,
    sort = `${this.searchPageSortBy}_${this.searchPageSortAsc ? 'asc' : 'desc'}`,
  ) => {
    return new Promise((resolve, reject) => {
      SearchRequest.getSearchItemListOnScroll(
        keyword,
        mimetype,
        storage_type,
        from_date,
        to_date,
        from_update_date,
        to_update_date,
        share_with,
        owner,
        page,
        per_page,
        sort,
      ).then(response => {
        this.searchList = [...this.searchList, ...response.data.data]
        this.hasNextPage = response.data?.meta?.has_next_page
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default new SearchStore()