
import React, { useEffect } from 'react'
import { SimpleArticle } from '../../components/CommonStyled/CommonStyled'
import { inject, observer } from 'mobx-react'
import ReactHtmlParser from 'react-html-parser'
import { message } from 'antd'
import { Helmet } from 'react-helmet/es/Helmet'
import { useTranslation } from 'react-i18next'

const IntroductionPage = props => {
  const { introductionStore, introductionStore: { introductionContent } } = props
  const { t } = useTranslation()

  useEffect(() => {
    introductionStore.getContentIntroductionPage().then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }, [])


  return (
    <>
      <Helmet>
        <title>
          {t('i0515')}
        </title>
      </Helmet>
      <SimpleArticle>
        <h1>{introductionContent.title}</h1>
        {ReactHtmlParser(introductionContent.content)}
      </SimpleArticle>
    </>

  )
}

IntroductionPage.propTypes = {}

export default inject(
  'introductionStore',
)(observer(IntroductionPage))