import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { QuestionsCommonItem, QuestionsCommonWrapper } from './QuestionListStyled'
import { inject, observer } from 'mobx-react'
const QuestionList = props => {
  const { listQuestion,commonQuestionStore } = props
  const history = useHistory()
  const handleClickQuestion = (item) => {
    commonQuestionStore.setQuestionItem(item)
    history.push(`/common-question/${item.id}`)

  }
  return (
    <QuestionsCommonWrapper>
      {
        listQuestion && listQuestion.map(item =>
          <QuestionsCommonItem
            onClick={() => handleClickQuestion(item)}
            key={item.id}>
            {item.question}
          </QuestionsCommonItem>,
        )
      }
    </QuestionsCommonWrapper>
  )
}

QuestionList.propTypes = {
  listQuestion: PropTypes.array.isRequired,
}
export default inject(
  'commonQuestionStore'
)(observer(QuestionList))
