import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Modal, Form, Input, Button, message, notification } from 'antd'
import { InsertFolderSimpleModalWrapper, InsertFolderSimpleTitle } from './InsertFolderSimpleModalStyled'
import { ButtonGroup } from '../../pages/TrashPage/TrashPageStyled'
import validator from '../../validator'
import { CustomCustomNotificationMessage, CustomNotificationContent } from '../CommonStyled/CommonStyled'
import { CONTENT_TYPE } from '../../utils/constant'
import { useTranslation } from 'react-i18next'

const InsertFolderSimpleModal = props => {

  const { visible, onClose, myDriveStore, commonStore } = props

  const [form] = Form.useForm()
  const history = useHistory()
  const [folderName, setFolderName] = useState('')

  const { t } = useTranslation()

  const handleCancel = () => {
    form.resetFields()
    onClose()
  }
  const handleChangeFolderName = (name) => {
    setFolderName(name.currentTarget.value)
  }
  const handleReloadMyFile = () => {
    let payload = {
      parent_id: myDriveStore.myDriveCurrentNode?.id,
      content_type: CONTENT_TYPE.myDrive,
      page: 1,
      per_page: myDriveStore.perPage,
      sort: myDriveStore.myDriveSort,
      sort_by: myDriveStore.myDriveSortBy,
    }
    myDriveStore.getMyDriveList(payload).then(response => {
      if (response.error_code !== 0) {
        message.error(response.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }
  const handleSubmitCreateFolder = (formData) => {
    let data = {
      name: formData.folderName,
    }
    if (myDriveStore.myDriveCurrentNode) {
      data = { ...data, parent_id: myDriveStore.myDriveCurrentNode?.id }
    }
    myDriveStore.createFolder(data).then(response => {
      if (response.error_code === 0) {
        onClose()
        handleReloadMyFile()
        if (commonStore.pageName !== 'my-drive') {
          history.push('my-drive')
        }
        notification.info({
          className: 'custom-notification-share',
          closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''} width={15} height={15} />,
          icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20} height={20} />,
          message: <CustomNotificationContent>
            <CustomCustomNotificationMessage>
              {t('i0216')}
            </CustomCustomNotificationMessage>
          </CustomNotificationContent>,
          description: null,
          placement: 'bottomLeft',
          duration: 6,
        })
      } else {
        onClose()
        message.error(response.message)
      }
    }).catch(error => {
      onClose()
      message.error(t('i0217'))
    }).finally(() => form.resetFields())
  }

  useEffect(() => {
    return () => {
      form.resetFields()
    }
  }, [])

  return (
    <InsertFolderSimpleModalWrapper>
      <Modal
        title={null}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        wrapClassName={'custom-ant-modal'}
        forceRender={true}
      >
        <InsertFolderSimpleTitle>{t('i0218')}</InsertFolderSimpleTitle>
        <Form
          form={form}
          onFinish={handleSubmitCreateFolder}
        >
          <Form.Item
            label={''}
            name={'folderName'}
            rules={[
              { required: true, message: t('i0219') },
              { min: 1, message: t('i0220') },
            ]}>
            <Input placeholder={t('i0221')} onChange={handleChangeFolderName} />
          </Form.Item>
          <ButtonGroup>
            <Button type={'default'} onClick={handleCancel}>{t('i0222')}</Button>
            <Button type={'primary'} htmlType={'submit'} disabled={folderName.length === 0}>{t('i0223')}</Button>
          </ButtonGroup>
        </Form>

      </Modal>
    </InsertFolderSimpleModalWrapper>
  )
}

InsertFolderSimpleModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default inject('myDriveStore', 'commonStore')(observer(InsertFolderSimpleModal))