import styled, { css } from 'styled-components'

export const LayoutWrapper = styled.main`
  padding-top: 64px;
  min-height: 100vh;
  background-color: #fff;
`
export const ContentWrapper = styled.div`
  min-height: calc(100vh - 64px - 70px);
`
export const HelpFooterBarWrapper = styled.div`
  background: #fff;
  height: 70px;
  box-shadow: inset 0 1px 0 #E2E2EA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 15px;
  }

  .copyright {
    margin-bottom: 0;
    color: #858A8F;
    font-size: 14px;
    @media screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
      order: -1;
      margin-bottom: 15px;
    }

    li {
      &:not(:last-child) {
        display: flex;
        align-items: center;

        &:after {
          display: block;
          content: '-';
          margin: 0 5px;
        }
      }

      a {
        font-size: 14px;
        color: #313131;
        transition: all ease .3s;

        &:hover {
          color: #237BD3;
          transition: all ease .3s;
        }
      }
    }
  }
`