import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()

export const ProfileRequest = {

  cancelRequest: () => {
    source.cancel()
  },
  getMyProfile: () => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/user/profile`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
    })
  },

  getMyProfileNotShowSpinner: () => {
    return axios({
      method: 'get',
      url: `${apiUrl}/api/user/profile`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      disableSpinner: true
    })
  },

  updateMyProfile: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/user/update`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        name: payload?.name,
        email: payload?.email,
        lang: payload?.lang,
        s_email: payload?.s_email,
        s_noti: payload?.s_noti,
        s_noti_share: payload?.s_noti_share,
        s_not_access: payload?.s_not_access,
      },
    })
  },
  updateAvatar: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/user/update-avatar`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: payload,
      disableSpinner: true,
    })
  },

  getHistoryLogin:(page,per_page)=>{
    return axios({
      method:'get',
      baseURL:apiUrl,
      url:'/api/user/logs',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params:{
        page: page,
        per_page: per_page,
      }
    })
  }
}
