import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyCPeqq9t7gnXicsz6rMfolWo6dhtcH0Rd0",
  authDomain: "mobifone-drive.firebaseapp.com",
  projectId: "mobifone-drive",
  storageBucket: "mobifone-drive.appspot.com",
  messagingSenderId: "700112526360",
  appId: "1:700112526360:web:bd290f949cbfd31eb7b76d",
  measurementId: "G-FLJW3QREGG"
}
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig)
}

export default firebase