import React from 'react'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive/src'

const ContentWrapper = styled.div`
  padding: 18px;
  background-color: #fff;
  ${props => !props.isMobile && css`
    min-height: calc(100vh - 152px);
  `}
`

const PageContentWrapper = props => {

  const { children } = props
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <ContentWrapper isMobile={isMobile}>
      {children}
    </ContentWrapper>
  )
}

PageContentWrapper.propTypes = {}

export default PageContentWrapper
