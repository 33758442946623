import { observable, action } from 'mobx'

class PreviewOverlayStore {
  @observable visible = false
  @observable recordOverlay = {}

  @action setVisiblePreviewOverlay = () => {
    this.visible = !this.visible
  }
  @action closePreviewOverlay = () => {
    this.visible = false
  }
  @action setRecordOverlay = (record) => {
    this.recordOverlay = record
  }
}

export default new PreviewOverlayStore()