import { action, observable } from 'mobx'
import { BlockRequest } from '../requests/BlockRequest';

class BlockStore {

  @action blockItemDrive = (payload) => {
    return new Promise((resolve, reject) => {
      BlockRequest.blockItemDrive(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action unBlockItemDrive = (payload) => {
    return new Promise((resolve, reject) => {
      BlockRequest.unBlockItemDrive(payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)

      })
    })
  }


}

export default new BlockStore()