import { action, observable } from 'mobx'
import { TrashRequest } from '../requests/TrashRequest'

class TrashStore {

  @observable filesInTrash = []
  @observable deleteAllFiles = null
  @observable deleteFile = null
  @observable restoreFiles = null

  @observable trashSort = 'desc' // desc | asc
  @observable trashSortBy = 'trashed_at' // name | updated_at | 'trashed_at'

  @action setTrashSort = (sort) => {
    this.trashSort = sort
  }
  @action setTrashSortBy = (sortBy) => {
    this.trashSortBy = sortBy
  }

  @action CancelRequestToken = () => {
    TrashRequest.cancelRequest()
  }

  @action getFilesInTrash = (page, perPage, sort, sort_by) => {
    return new Promise((resolve, reject) => {
      TrashRequest.getFilesInTrash(page, perPage, sort, sort_by)
        .then(response => {
          this.filesInTrash = response.data.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteAllFilesInTrash = (payload) => {
    return new Promise((resolve, reject) => {
      TrashRequest.deleteAllFilesInTrash(payload)
        .then(response => {
          this.deleteFiles = response.data.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteFileInTrash = (payload) => {
    return new Promise((resolve, reject) => {
      TrashRequest.deleteFileInTrash(payload)
        .then(response => {
          this.deleteFile = response.data.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action restoreFile = (payload) => {
    return new Promise((resolve, reject) => {
      TrashRequest.restoreFile(payload)
        .then(response => {
          this.restoreFiles = response.data.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new TrashStore()
