import { APP_NAME } from '../utils/constant'

const en = {
  'i0001': 'Create',
  'i0002': 'My data',
  'i0003': 'Synchronize',
  'i0004': 'Shared with me',
  'i0005': 'Recently opened',
  'i0006': 'Favorite',
  'i0007': 'Offline',
  'i0008': 'Recycle',
  'i0009': 'Storage',
  'i0010': 'Used',
  'i0011': 'Buy storage',
  'i0012': 'Introduction',
  'i0013': 'Terms of use',
  'i0014': 'Privacy Policy',
  'i0015': 'Upload from',
  'i0016': 'An error occurred, please try again or contact admin.',
  'i0017': 'Format',
  'i0018': 'All formats',
  'i0019': 'Owner',
  'i0020': 'All',
  'i0021': 'I own',
  'i0022': 'Other own',
  'i0023': 'Storage',
  'i0024': 'Shared with me',
  'i0025': 'Favorite',
  'i0026': 'Offline',
  'i0027': 'Recycle',
  'i0028': 'Created/Uploaded Date',
  'i0029': 'All day',
  'i0030': 'Today',
  'i0031': '7 days ago',
  'i0032': '1 month ago',
  'i0033': 'Customize',
  'i0034': 'From',
  'i0035': 'Choose a date',
  'i0036': 'To',
  'i0037': 'Choose a date',
  'i0038': 'Edit date',
  'i0039': 'All days',
  'i0040': 'Today',
  'i0041': '7 days ago',
  'i0042': '1 month ago',
  'i0043': 'Customize',
  'i0044': 'From',
  'i0045': 'Choose a date',
  'i0046': 'To',
  'i0047': 'Choose a date',
  'i0048': 'Shared with',
  'i0049': 'Enter your name or phone number',
  'i0050': 'Set again',
  'i0051': 'Search',
  'i0052': 'Update successfully',
  'i0053': 'Storage',
  'i0054': 'Used',
  'i0055': 'Buy storage',
  'i0056': 'Language',
  'i0057': 'Vietnamese',
  'i0058': 'English',
  'i0059': 'Get notification of all changes related to the above content',
  'i0060': 'on the browser',
  'i0061': 'Get notification of all changes related to the above content',
  'i0062': 'via email',
  'i0063': 'Update',
  'i0064': 'Share',
  'i0065': 'Copy link',
  'i0066': 'Move',
  'i0067': 'Favorite',
  'i0068': 'Rename',
  'i0069': 'File lock',
  'i0070': 'View',
  'i0071': 'Download',
  'i0072': 'Delete',
  'i0073': 'Copied',
  'i0074': 'Copy',
  'i0075': 'No permission',
  'i0076': 'Information',
  'i0077': 'Type',
  'i0078': 'Folder',
  'i0079': 'Owners',
  'i0080': 'Created date',
  'i0081': 'Update person',
  'i0082': 'Update date',
  'i0083': 'People with permission',
  'i0084': 'Activity',

  'i0085': 'Share',
  'i0086': 'Copy link',
  'i0087': 'Move',
  'i0088': 'Favorite',
  'i0089': 'Remove from favorites',
  'i0090': 'Lock file',
  'i0091': 'Unlock file',
  'i0092': 'Rename',
  'i0093': 'View',
  'i0094': 'Download',
  'i0095': 'Delete',
  'i0096': `Sync to ${APP_NAME}`,
  'i0097': 'Restore',
  'i0098': 'Permanently deleted',
  'i0099': 'You can upload files and folders by',
  'i0100': 'drag and drop here.',
  'i0101': 'Successfully updated account information',
  'i0102': 'Please use images in png, jpg . format',
  'i0103': 'First and last name',
  'i0104': 'Enter your first and last name',
  'i0105': 'Phone number',
  'i0106': 'Enter phone number',
  'i0107': 'Enter email',
  'i0108': 'Update',
  'i0109': 'Update profile picture',
  'i0110': 'Save',
  'i0111': 'Cancel',
  'i0112': 'Change photo',
  'i0113': 'Are you sure to delete these files?',
  'i0114': 'Yes, delete',
  'i0115': 'Are you sure to delete these files',
  'i0116': 'Yes, delete',
  'i0117': 'Update',
  'i0118': 'Are you sure to lock the content? People who have shared the file will no longer be able to see the content.',
  'i0119': 'Yes, lock now',
  'i0120': 'Are you sure to delete these files',
  'i0121': 'Yes, delete',
  'i0122': 'Everyone who has access',
  'i0123': 'can see this file. Are you sure to move?',
  'i0124': 'Move',
  'i0125': 'Edit file name',
  'i0126': 'Please enter file name',
  'i0127': 'File name should not exceed 255 characters',
  'i0128': 'Enter file name',
  'i0129': 'Are you sure to delete these files',
  'i0130': 'Yes, delete',
  'i0131': 'You definitely want to do ?',
  'i0132': 'Confirm',
  'i0133': 'Are you sure to delete these files',
  'i0134': 'Yes, delete',
  'i0135': 'Update',
  'i0136': 'Are you sure to delete these files? People who have shared the file will no longer be able to see the file.',
  'i0137': 'Yes, lock now',
  'i0138': 'Are you sure to delete these files',
  'i0139': 'Are you sure to delete these files',
  'i0140': 'Everyone who has access',
  'i0141': 'can see this file. Are you sure to move?',
  'i0142': 'Move',
  'i0143': 'Name',
  'i0144': 'Name',
  'i0145': 'Update date',
  'i0146': 'Update date',
  'i0147': 'Owner',
  'i0148': 'Update person',
  'i0149': 'Storage',
  'i0150': 'Edit folder name',
  'i0151': 'Please enter a folder name',
  'i0152': 'Folder name should not exceed 40 characters',
  'i0153': 'Enter the folder name',
  'i0154': 'Cancel',
  'i0155': 'Upload folder',
  'i0156': 'Folder Number',
  'i0157': 'Are you sure to do?',
  'i0158': 'Confirm',
  'i0159': 'Cancel',
  'i0160': 'Are you sure to delete this folder',
  'i0161': 'Yes, delete',
  'i0162': 'Are you sure to delete this folder',
  'i0163': 'Yes, delete',
  'i0164': 'Update',
  'i0165': 'Are you sure to lock this content? People who have shared the file will no longer be able to see the content.',
  'i0166': 'Yes, lock now',
  'i0167': 'Are you sure to delete this folder?',
  'i0168': 'Yes, delete',
  'i0169': 'Everyone who has access',
  'i0170': 'can see this file. Are you sure to move?',
  'i0171': 'Move',
  'i0172': 'Edit folder name',
  'i0173': 'Please enter a folder name',
  'i0174': 'Minimum 1 character folder name',
  'i0175': 'Enter the folder name',
  'i0176': 'Update date',
  'i0177': 'Name',
  'i0178': 'Name',
  'i0179': 'Update date',
  'i0180': `To sync files/folders from Google Drive to ${APP_NAME}, please install the sync feature`,
  'i0181': 'here',
  'i0182': 'Folder',
  'i0183': 'Please connect to the Google drive you want to sync',
  'i0184': 'Agree',
  'i0185': 'Cancel',
  'i0186': 'Create group successfully',
  'i0187': 'Are you sure to delete this group?',
  'i0188': 'Yes, delete',
  'i0189': 'No',
  'i0190': 'Are you sure to delete this group?',
  'i0191': 'Yes, delete',
  'i0192': 'No',
  'i0193': 'Delete multiple groups successfully!',
  'i0194': 'Delete groups successfully!',
  'i0195': 'GROUP NAME',
  'i0196': 'MEMBER',
  'i0197': 'Create group',
  'i0198': 'Delete',
  'i0199': 'User information',
  'i0200': 'Login history',
  'i0201': 'Log out',
  'i0202': 'Help',
  'i0203': 'Log in',
  'i0204': 'Introduction',
  'i0205': 'Help',
  'i0206': 'Terms of use',
  'i0207': 'Privacy Policy',
  'i0208': 'DATE HOUR',
  'i0209': 'DEVICE',
  'i0210': 'POSITION',
  'i0211': 'Add member',
  'i0212': 'Add member, group member',
  'i0213': 'User',
  'i0214': 'Cancel',
  'i0215': 'Add',
  'i0216': 'Create folder successfully',
  'i0217': 'Create folder fail',
  'i0218': 'Create folder',
  'i0219': 'Please enter a folder name',
  'i0220': 'Minimum 1 character folder name',
  'i0221': 'Please enter a folder name',
  'i0222': 'Cancel',
  'i0223': 'Create',
  'i0224': 'Please enter the group name',
  'i0225': 'Enter group name',
  'i0226': 'Cancel',
  'i0227': 'Create',
  'i0228': 'Add new contacts',
  'i0229': 'Please enter the phone number',
  'i0230': 'Enter the phone number',
  'i0231': 'Cancel',
  'i0232': 'Create',
  'i0233': 'User information',
  'i0234': 'Login history',
  'i0235': 'Log out',
  'i0236': 'Search',
  'i0237': 'Help',
  'i0238': 'Install',
  'i0239': 'User',
  'i0240': 'Upload file',
  'i0241': 'Upload folder',
  'i0242': 'Create folder',
  'i0243': 'No previews yet',
  'i0244': 'Download',
  'i0245': 'User information',
  'i0246': 'Login history',
  'i0247': 'Log out',
  'i0248': 'View gridline',
  'i0249': 'View list',
  'i0250': 'My data',
  'i0251': 'Synchronize',
  'i0252': 'Shared with me',
  'i0253': 'Recently opened',
  'i0254': 'Favorite',
  'i0255': 'Offline',
  'i0256': 'Recycle',
  'i0257': 'Storage',
  'i0258': 'Used',
  'i0259': 'Buy storage',
  'i0260': 'All',
  'i0261': 'No permission yet',
  'i0262': 'Information',
  'i0263': 'Owner',
  'i0264': 'Created Date',
  'i0265': 'Updater',
  'i0266': 'Updated date',
  'i0267': 'People with permission',
  'i0268': 'Not shared yet',
  'i0269': 'Activity',
  'i0270': 'Unable to upload file that is too large',
  'i0271': 'Upload folder',
  'i0272': 'Computer',
  'i0273': 'Uploading',
  'i0274': 'folder',
  'i0275': 'file',
  'i0276': 'Among',
  'i0277': 'Create folder',
  'i0278': 'Fail',
  'i0279': 'Please enter a folder name',
  'i0280': 'Folder name should not exceed 40 characters',
  'i0281': 'Enter the folder name',
  'i0282': 'Save',
  'i0283': 'My folder/file',
  'i0284': 'There is no file in this folder ',
  'i0285': 'Create folder in',
  'i0286': 'my folder/file',
  'i0287': 'My data',
  'i0288': 'Synchronize',
  'i0289': 'Shared with me',
  'i0290': 'Favorite',
  'i0291': 'Create folder in',
  'i0292': 'my folder/file',
  'i0293': 'Move',
  'i0294': 'No notification',
  'i0295': 'Updated date',
  'i0296': 'Name',
  'i0297': 'Updated date',
  'i0298': 'Name',
  'i0299': `To sync files/folders from OneDrive to ${APP_NAME}, please install the sync feature`,
  'i0300': 'here',
  'i0301': 'Please connect to the One-drive you want to sync',
  'i0302': 'Package name',
  'i0303': 'Storage',
  'i0304': 'Postage',
  'i0305': 'Inspiration date',
  'i0306': 'No service pack information yet',
  'i0307': 'No expiration date information yet',
  'i0308': 'No storage information yet',
  'i0309': 'No pricing information yet',
  'i0310': 'Cancel service pack',
  'i0311': 'You can upload files and folders by dragging and dropping here.',
  'i0312': 'You can find files/folders shared to you by others here.',
  'i0313': 'You haven\'t opened any files recently.',
  'i0314': 'Favorite files/folders will help you find them easily when you need.',
  'i0315': 'The Recycle Bin stores files/folders you deleted. After',
  'i0316': 'day, the file/folder will automatically be permanently deleted.',
  'i0317': `To sync files/folders from Google Drive to ${APP_NAME}, install the sync feature here.`,
  'i0318': `To sync files/folders from OneDrive to ${APP_NAME}, install the sync feature here.`,
  'i0319': 'There are no files on this device yet. Select “Can be used offline” in the details of each file so that it can be opened when your device is offline',
  'i0320': 'No results were found for your keyword.',
  'i0321': 'No results were found that matches your search.',
  'i0322': 'You haven\'t added anyone to your contacts yet',
  'i0323': 'You haven\'t created any groups yet',
  'i0324': 'No members in this group yet',
  'i0325': 'Name',
  'i0326': 'Delete successfully!',
  'i0327': 'Added new contacts successfully',
  'i0328': 'Add friends',
  'i0329': 'Delete',
  'i0330': 'Yes, delete',
  'i0331': 'No',
  'i0332': 'Are you sure to remove this person from your contacts?',
  'i0333': 'Change',
  'i0334': 'Remove permissions',
  'i0335': 'Select permission',
  'i0336': 'Haven\'t shared with anyone yet',
  'i0337': 'Shared with',
  'i0338': 'and',
  'i0339': 'others',
  'i0340': 'Copied shared link to clipboard',
  'i0341': 'Only shared people can access this link.',
  'i0342': 'Anyone with this link can',
  'i0343': 'anytime',
  'i0344': 'to',
  'i0345': 'Please select permission',
  'i0346': 'Shared successfully',
  'i0347': 'Canceled sharing successfully',
  'i0348': 'Invalidate email',
  'i0349': 'Limit',
  'i0350': 'Public',
  'i0351': 'No result',
  'i0352': 'Email does not exist in the system.',
  'i0353': 'Press Enter to confirm sharing.',
  'i0354': 'Share with everyone',
  'i0355': 'Add member, group of member, email, phone number',
  'i0356': 'User',
  'i0357': 'User',
  'i0358': 'Users outside the system',
  'i0359': 'Not shared with other users yet',
  'i0360': 'Update',
  'i0361': 'Set permission',
  'i0362': 'Add a message to the person being shared',
  'i0363': 'Message',
  'i0364': 'Cancel',
  'i0365': 'Send',
  'i0366': 'Share link',
  'i0367': 'Change',
  'i0368': 'Copy',
  'i0369': 'Limit',
  'i0370': 'Public',
  'i0371': 'Use shared password',
  'i0372': 'The person being shared needs to enter a password to access the link.',
  'i0373': 'Create a password',
  'i0374': 'Update',
  'i0375': 'Time to share',
  'i0376': 'Change',
  'i0377': 'The person being shared can access the link',
  'i0378': 'Unlimited',
  'i0379': 'Limited time',
  'i0380': 'Please select the time',
  'i0381': 'Choose time',
  'i0382': 'Update',
  'i0383': 'Sync GoogleDrive successfully',
  'i0384': 'Sync GoogleDrive failed',
  'i0385': 'Sync OneDrive successfully',
  'i0386': 'Sync OneDrive fail',
  'i0387': 'Sync OneDrive successfully',
  'i0388': `Connect your Google Drive, OneDrive accounts to your ${APP_NAME} account to sync data.`,
  'i0389': 'Close connection',
  'i0390': 'Connect & Sync',
  'i0392': 'Viewer',
  'i0393': 'Editor',
  'i0394': 'Reviewer',
  'i0395': 'Viewer',
  'i0396': 'Reviewer',
  'i0397': 'Editor',
  'i0398': 'Set as owner',
  'i0399': 'Remove permission',
  'i0400': 'Update',
  'i0401': 'Full name',
  'i0402': 'Phone number',
  'i0403': 'Email',
  'i0404': 'No information',
  'i0405': 'Change a password',
  'i0406': 'Edit information',
  'i0407': 'All permissions are reserved',
  'i0408': 'Terms of use',
  'i0409': 'Privacy Policy',
  'i0410': 'Change password successfully, please login',
  'i0411': 'Phone number',
  'i0412': 'Please enter the phone number',
  'i0413': 'Enter your phone number',
  'i0414': 'Continue',
  'i0415': 'Enter the verification code just sent to the number',
  'i0416': 'Please enter the verification code',
  'i0417': 'Enter the verification code',
  'i0418': 'Resend code',
  'i0419': 'A new password',
  'i0420': 'Please enter a new password',
  'i0421': 'Enter your new password',
  'i0422': 'Re-enter your new password',
  'i0423': 'Please re-enter new password',
  'i0424': 'Re-entered password does not match',
  'i0425': 'Enter a new password',
  'i0426': 'Continue',
  'i0427': 'Hello',
  'i0428': 'Phone number',
  'i0429': 'Please enter your phone number',
  'i0430': 'Enter phone number',
  'i0431': 'Password',
  'i0432': 'Forgot password',
  'i0433': 'Please enter a password',
  'i0434': 'Enter password',
  'i0435': 'Log in',
  'i0436': 'Do not have an account',
  'i0437': 'Register',
  'i0438': 'Phone number',
  'i0439': 'Please enter the phone number',
  'i0440': 'Enter your phone number',
  'i0441': 'Full name',
  'i0442': 'Please enter your first and last name',
  'i0443': 'Name cannot exceed 40 characters',
  'i0444': 'Enter your first and last name',
  'i0445': 'Please enter your email',
  'i0446': 'Invalid email',
  'i0447': 'Enter your email address',
  'i0448': 'Password',
  'i0449': 'Please enter a password',
  'i0450': 'Enter password',
  'i0451': 'Re-enter password',
  'i0452': 'Please re-enter password password',
  'i0453': 'Re-entered password does not match',
  'i0454': 'Re-enter password ',
  'i0455': 'Register',
  'i0456': 'Forgot password',
  'i0457': 'Log in',
  'i0458': 'Sign up',
  'i0459': 'Change password successfully',
  'i0460': 'Change password',
  'i0461': 'Account',
  'i0462': 'Change Password',
  'i0463': 'Old password',
  'i0464': 'Please enter old password',
  'i0465': 'Enter old password',
  'i0466': 'A new password',
  'i0467': 'Please enter a new password',
  'i0468': 'Enter your new password',
  'i0469': 'Re-enter a new password',
  'i0470': 'Please re-enter new password',
  'i0471': 'Re-enter password does not match',
  'i0472': 'Enter a new password',
  'i0473': 'Update',
  'i0474': 'Details of question',
  'i0475': 'List of questions',
  'i0476': 'What can we help you?',
  'i0477': 'Help',
  'i0478': 'Enter the problem you need to help with',
  'i0479': 'Search results',
  'i0480': 'Question',
  'i0481': 'Setting',
  'i0482': 'Setting',
  'i0483': 'General settings',
  'i0484': 'Directory',
  'i0485': 'Group',
  'i0486': 'Synchronize data',
  'i0487': 'Canceled to update fix name {fileTypeName} successfully',
  'i0488': 'Undo',
  'i0489': 'Undo',
  'i0490': 'Undo',
  'i0491': 'Undo',
  'i0492': 'Successfully deleted',
  'i0493': 'Moved',
  'i0494': 'to',
  'i0495': 'successfully',
  'i0496': 'Successfully deleted',
  'i0497': 'Added file to favorites',
  'i0498': 'Removed file to favorites',
  'i0499': 'Moved',
  'i0500': 'to',
  'i0501': 'successfully',
  'i0502': 'folder',
  'i0503': 'file',
  'i0504': 'Updated fixing the name',
  'i0505': 'successfully',
  'i0507': 'Opened file',
  'i0508': 'Updated date',
  'i0509': 'Name',
  'i0510': 'You are granted permission to share',
  'i0511': 'DATE HOUR',
  'i0512': 'DEVICE',
  'i0513': 'POSITION',
  'i0514': 'Login history',
  'i0515': 'Introduction',
  'i0516': 'Moved to "Recycle Bins"',
  'i0517': 'Moved',
  'i0518': 'to',
  'i0519': 'successfully',
  'i0520': 'Moved to "Recycle Bins"',
  'i0521': 'Added file to favorites',
  'i0522': 'Removed file to favorites',
  'i0523': 'Moved',
  'i0524': 'to',
  'i0525': 'successfully',
  'i0526': 'folder',
  'i0527': 'file',
  'i0528': 'Updated fixing the name',
  'i0529': 'successfully',
  'i0530': 'Locked file',
  'i0531': 'Unlocked file',
  'i0532': 'You can upload files and folders by dragging and dropping here.',
  'i0533': 'Folder',
  'i0534': 'File',
  'i0535': 'Updating date',
  'i0536': 'Name',
  'i0537': 'Updating date',
  'i0538': 'Name',
  'i0539': 'My data',
  'i0540': 'Access permission',
  'i0541': 'You do not have permission to access this content',
  'i0542': 'Back to previous page',
  'i0543': 'Back to the homepage',
  'i0544': 'The page you are looking for does not exist!',
  'i0545': 'Back to previous page',
  'i0546': 'Back to the homepage',
  'i0547': 'Updating date',
  'i0548': 'Name',
  'i0549': 'Offline',
  'i0550': 'Name',
  'i0551': 'Updating date',
  'i0552': 'Name',
  'i0553': 'Updating date',
  'i0554': 'Hello',
  'i0555': 'Please enter OTP',
  'i0556': 'Privacy Policy',
  'i0557': 'Update account information',
  'i0558': 'Account',
  'i0559': 'Edit information',
  'i0560': 'Account information',
  'i0561': 'Account',
  'i0562': 'Account information',
  'i0563': 'Login history',
  'i0564': 'Moved to "Recycle Bins"',
  'i0565': 'Items removed',
  'i0566': 'Added file to favorites',
  'i0567': 'Removed file to favorites',
  'i0568': 'Removed',
  'i0569': 'to',
  'i0570': 'successfully',
  'i0571': 'folder',
  'i0572': 'file',
  'i0573': 'Updated fixing the name',
  'i0574': 'successfully',
  'i0575': 'Locked file',
  'i0576': 'Opened file',
  'i0577': 'Today',
  'i0578': 'Yesterday',
  'i0579': '7 days ago',
  'i0580': 'Name',
  'i0581': 'View date',
  'i0582': 'Update date',
  'i0583': 'Recently opened',
  'i0584': 'Search Results',
  'i0585': 'Moved to "Recycle Bins"',
  'i0586': '1 item removed',
  'i0587': 'File added to favorites',
  'i0588': 'Removed file from favorites',
  'i0589': 'Moved',
  'i0590': 'to',
  'i0591': 'successfully',
  'i0592': 'folder',
  'i0593': 'file',
  'i0594': 'Updated fixing the name',
  'i0595': 'successfully',
  'i0596': 'Locked file',
  'i0597': 'Opened file',
  'i0598': 'Sharing date',
  'i0599': 'Name',
  'i0600': 'Updating date',
  'i0601': 'Sharing date',
  'i0602': 'Name',
  'i0603': 'Updating date',
  'i0604': 'Folder',
  'i0605': 'File',
  'i0606': 'Shared with me',
  'i0607': 'Synchronize',
  'i0608': 'Terms of use',
  'i0609': 'Name',
  'i0610': 'File deletion time',
  'i0611': 'Name',
  'i0612': 'File updated time',
  'i0613': 'File deletion time',
  'i0614': 'To view this folder, you will need to restore the folder from your recycle bin.',
  'i0615': 'Are you sure to delete this folder?',
  'i0616': 'You will not be able to recover the folder once it has been permanently deleted',
  'i0617': 'Are you sure to delete this file?',
  'i0618': 'You will not be able to recover the file once it has been permanently deleted.',
  'i0619': 'Are you sure to delete this folder?',
  'i0620': 'You will not be able to recover the folder once it has been permanently deleted',
  'i0621': 'Are you sure to delete this file?',
  'i0622': 'You will not be able to recover the file once it has been permanently deleted',
  'i0623': 'Are you sure to delete these files/folders?',
  'i0624': 'You will not be able to recover them once they have been permanently deleted.',
  'i0625': 'Recycle Bins',
  'i0626': 'Folders/files in the recycle bin will automatically be permanently deleted after',
  'i0627': 'day',
  'i0628': 'Delete all in recycle bins',
  'i0629': 'Cancel',
  'i0630': 'Restore',
  'i0631': 'Permanently delete',
  'i0632': 'Are you sure to remove this person from the group?',
  'i0633': 'Yes, delete',
  'i0634': 'No',
  'i0635': 'No',
  'i0636': 'Delete group members successfully',
  'i0637': 'Are you sure to remove this person from the group?',
  'i0638': 'Yes, delete',
  'i0639': 'No',
  'i0640': 'Added new members to the group successfully',
  'i0641': 'Update group successfully',
  'i0642': 'Update group',
  'i0643': 'Manage group',
  'i0644': 'Update group',
  'i0645': 'Group name',
  'i0646': 'Enter group name',
  'i0647': 'Update',
  'i0648': 'Members list',
  'i0649': 'Add member',
  'i0650': 'Delete',
  'i0651': 'Remove permissions',
  'i0652': 'Update successfully',

  'i0653': 'Please send message to cancel package',
  'i0654': 'Send',
  'i0655': 'Please send message to get more data traffic',
  'i0656': 'Please cancel current package to get higher one',

  'i0657': 'Sync from Google Drive',
  'i0658': 'Sync from One Drive',
  'i0659': 'View Mode',
  'i0660': 'Grid View',
  'i0661': 'List View',
  'i0662': 'Connect your Google Drive, OneDrive accounts to your',
  'i0663': 'account to sync data.',
  'i0664': 'Please enter a password',
  'i0665': 'Minimum 3 characters is required.',
  'i0666': 'Not connected to Google Drive. Please connect first',
  'i0667': 'Not connected to One Drive. Please connect first',
  'i0668': 'Can not delete other people\'s content shared with you',
  'i0669': 'Notification',
  'i0670': '1 compressed file',
  'i0671': 'Add new group',
  'i0672': 'Notification',
  'i0673': 'Search',
  'i0674': 'Please enter a message for the person being shared',
  'i0675': 'members',
  'i0676': 'Upload canceled',
  'i0677': 'cancelled',
  'i0678': 'User canceled',
  'i0679': 'Cancel upload',
  'i0680': 'Your upload is incomplete. Do you want to cancel the upload?',
  'i0681': 'Continue uploading',
  'i0682': 'Reload',
  'i0683': 'Login',
  'i0684': 'No preview available',
  'i0685': 'Download',
  'i0686': 'An error occurred',
  'i0687': 'Please enter password to view file',
  'i0688': 'Unshared',
  'i0689': 'Create new directory',
  'i0690': 'Preparing to download',
  'i0691': 'Download canceled',
  'i0692': 'No content inside folder',
  'i0693': 'No data',
  'i0694': 'Directory',
  'i0695': 'Size',
  'i0696': 'Data sync succeeded,',
  'i0697': 'directories',
  'i0698': 'files synced',
  'i0699': 'Advanced Search',
  'i0700': 'Sort descending',
  'i0701': 'Sort ascending',
  'i0702': 'Download',
  'i0703': 'Message length exceeds 200 characters',
  'i0704': 'Login',
  'i0705': 'Cancelled zip file',
  'i0706': 'Uploaded successfully',
  'i0707': 'Syncing',
  'i0708': 'file',
  'i0709': 'I agree with',
  'i0710': 'terms of use',
  'i0711': 'Please choose to agree to terms of use',
  'i0712': 'Login',
  'i0713': 'Account',
  'i0714': 'Package',
  'i0715': 'Delete successful',
  'i0716': 'Upload canceled',
  'i0717': 'Out of capacity',
  'i0718': 'You do not have permission to access this content',
  'i0719': 'Maximum image size is 6Mb',
  'i0720': 'Please enter group name',
  'i0721': 'Get the mobiCloud',
  'i0722': 'The product is only applicable to MobiFone subscribers',
}
export default en