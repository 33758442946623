import React from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup } from '../../pages/TrashPage/TrashPageStyled'
import { Button, Modal } from 'antd'

const ConfirmModal = props => {
  const { visible, title, okText, cancelText, callback, onClose } = props
  return (
    <Modal
      onCancel={() => onClose()}
      footer={null}
      closable={null}
      wrapClassName={'custom-ant-modal'}
      visible={visible}>
      <p>
        {title}
      </p>
      <ButtonGroup>
        <Button type={'default'} onClick={() => onClose()}>{cancelText}</Button>
        <Button type={'primary'} onClick={() => callback()}>{okText}</Button>
      </ButtonGroup>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  callback: PropTypes.func,
  onClose: PropTypes.func,
}

export default ConfirmModal