import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()

export const BlockRequest = {

  cancelRequest: () => {
    source.cancel()
  },

  blockItemDrive: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/block`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        list_id: payload,
      },
    })
  },
  unBlockItemDrive: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/api/nodes/unblock`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        list_id: payload,
      },
    })
  },
}