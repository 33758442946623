import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { FormWrapper, LogoWrapper, OTPInputWrapper } from '../../../layouts/AuthLayout/AuthLayoutStyled'
import { Button, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import profileStore from '../../../stores/profileStore'

const OtpInputForm = props => {
  const { authenticationStore } = props

  const [validateForm] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()

  const [isTimerOver, setIsTimerOver] = useState(true)
  const [secondsCountdown, setSecondsCountdown] = useState(0)

  const handleClickBackLogin = () => {
    // history.push('/auth/login')
    history.goBack();
    authenticationStore.clearUserIdVerifying()

  }
  const handleSubmitOtp = (data) => {
    authenticationStore.verifyOtp(authenticationStore.userIdVerifying, data.otp)
      .then(response => {
        switch (response.error_code) {
          case 0:
            profileStore.getMyProfile()
            history.push(location.state?.from?.pathname || '/')
            break
          case 6:
            history.push('/auth/register')
            message.error(response.message)
            break
          default:
            message.error(response.message)
            break
        }

      })
      .catch(error => {
        switch (error.response.status) {
          case 401:
            break
          case 500:
            history.push('/auth/register')
          default:
            message.error(error.message)
            break
        }
      })
  }
  const getNewOTP = () => {
    setIsTimerOver(false)
    setSecondsCountdown(60)

    authenticationStore.resendOtp(authenticationStore.userIdVerifying)
      .then((response) => {
        if (response.error_code !== 0) {
          message.error(response.message)
        } else {
          message.success('Đã gửi lại mã OTP')
        }
      })
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      if (isTimerOver) return
      if (secondsCountdown === 1) {
        setIsTimerOver(true)
        return
      }
      setSecondsCountdown(secondsCountdown - 1)
    }, 1000)
    return () => clearInterval(timerID)
  })

  useEffect(() => {
    if (!authenticationStore.userIdVerifying) {
      history.push('/auth/login')
    }
  }, [authenticationStore.userIdVerifying])

  return (
    <FormWrapper>
      <img src={`${process.env.PUBLIC_URL}/assets/icons/backleft.svg`}
        width={30} height={30} alt={''}
        className={'back-login-icon'}
        onClick={handleClickBackLogin} />
      <LogoWrapper>
        <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_web.png`} alt={''} />
      </LogoWrapper>
      <Form
        style={{ marginTop: 30 }}
        form={validateForm}
        layout={'vertical'}
        onFinish={handleSubmitOtp}>
        <OTPInputWrapper>
          <Form.Item
            label={`${t('i0555')}`}
            name={'otp'}
            autoComplete='off'
            rules={[
              { required: true, message: t('i0416') },
            ]}
          >
            <Input placeholder={t('i0417')} className={'otp-input'} />
          </Form.Item>
          <a className={`otp-req-btn ${!isTimerOver && 'disabled'}`} onClick={getNewOTP}>
            {
              isTimerOver
                ? t('i0418')
                : `${t('i0418')} (${secondsCountdown}s)`
            }
          </a>
        </OTPInputWrapper>
        <Button type={'primary'} htmlType={'submit'} block className={'otp-btn'}>
          {t('i0426')}
        </Button>
      </Form>
    </FormWrapper>
  )
}

OtpInputForm.propTypes = {}

export default inject('authenticationStore')(observer(OtpInputForm))