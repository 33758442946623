import axios from 'axios'
import config from '../config'
import authenticationStore from '../stores/authenticationStore'
import notificationsStore from '../stores/notificationsStore'

const { apiUrl } = config

const source = axios.CancelToken.source()

export const NotificationsRequest = {

  cancelRequest: () => {
    source.cancel()
  },

  getNotificationList: (page, perPage) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/user/notifications`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        page: page,
        per_page: perPage,
        fcm_token: notificationsStore.fcmToken,
        topic: 'web',
      },
    })
  },
  getNotificationListNoSpinner: (page, perPage) => {
    return axios({
      method: 'get',
      baseURL: apiUrl,
      url: `/api/user/notifications`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      params: {
        page: page,
        per_page: perPage,
        fcm_token: notificationsStore.fcmToken,
        topic: 'web',
      },
      disableSpinner: true,
    })
  },
  updateFcmToken: (fcmToken) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      url: `/api/user/update-fcm-token`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        topic: 'web',
        fcm_token: fcmToken,
      },
    })
  },
  seenNotify: (noti_id) => {
    return axios({
      method: 'post',
      baseURL: apiUrl,
      url: `/api/user/seen-noti`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
      },
      data: {
        noti_id: noti_id,
      },
    })
  },
}