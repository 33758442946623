// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { PageHeader, PageWrapper } from './FolderPreviewPageStyled'
import { Helmet } from 'react-helmet/es/Helmet'
import ViewToggleComponent from '../../components/ViewToggleComponent'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Dropdown, Menu, message, Row, Modal, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import PageResult from '../../components/PageResult/PageResult'
import { SectionHeading, SortButton } from '../../components/CommonStyled/CommonStyled'
import FoldersGridView from '../../components/FoldersGridView/FoldersGridView'
import FilesGridView from '../../components/FilesGridView/FilesGridView'
import FilesListView from '../../components/FilesListView/FilesListView'
import { useMediaQuery } from 'react-responsive/src'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { toJS } from 'mobx'
import ModalInputPassword from '../../components/ModalInputPassword'
import { CONTEXT_MENU_ACTION } from '../../utils/constant'
import { InfoDetail, InfoHeader, ModalHeader } from '../../components/DataInfoModal/DataInfoModalStyled'
import { useTranslation } from 'react-i18next'
import SVGIcon from '../../components/SVGIcon/SVGIcon'

const FolderPreviewPage = props => {

  const { t } = useTranslation()
  const { filePreviewStore, selectionStore, commonStore, appConfigStore, authenticationStore } = props
  const { viewByGridMode } = commonStore
  const { selectionData, selectionIds } = selectionStore
  const { iconsByMimeTypeObj } = appConfigStore

  const isMobile = useMediaQuery({ maxWidth: 768 })
  const location = useLocation()
  const history = useHistory()
  const [nodeId, setNodeId] = useState('')
  const [fileError, setFileError] = useState(false)
  const [folderDetail, setFolderDetail] = useState({})
  const [visibleModalPass, setVisibleModalPass] = useState(false)
  const [filePassword, setFilePassword] = useState('')
  const { filePreviewList } = filePreviewStore
  const [files, setFiles] = useState([])
  const [folders, setFolders] = useState([])
  const [visibleModalInfo, setVisibleModalInfo] = useState(false)
  const [fileInfo, setFileInfo] = useState({})

  const checkPermissionFile = (node_id) => {
    filePreviewStore.checkFilePermission({ node_id: node_id }).then(res => {
      if (res.error_code === 0) {
        let data = res.data
        if (!data.is_secret && data.password_required) {
          setVisibleModalPass(true)
        } else if (!data.is_secret && !data.password_required) {
          getDetailFileUnauthenticated(node_id)
        } else if (data.is_secret) {
          history.push('/not-permission')
        }
      } else {
        setFileError(true)
        message.error(res.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }


  /** params
   * node_id:CurrentNode
   * password: Password file
   **/
  const getDetailFileUnauthenticated = (node_id, password) => {
    filePreviewStore.getDetailFileUnauthenticated({
      node_id: node_id,
      per_page: 10,
      page: 1,
      password: password,
      node_share_id: folderDetail.encrypt_id || node_id,
    }).then(res => {
      if (res.error_code === 0) {
        setFolderDetail(res.data)
        filePreviewStore.setFilePreviewBreadcrumbData(res.data)
        setVisibleModalPass(false)
        getListFileOnFolder(node_id, password, res.data.encrypt_id)
      } else {
        message.error(res.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }

  const onCloseModal = () => {
    setVisibleModalPass(false)
  }

  const onConFirmModal = (password) => {
    setFilePassword(password)
    getDetailFileUnauthenticated(nodeId, password)
  }

  const handleClickFolderOrBreadcrumb = (folder) => {
    filePreviewStore.setFilePreviewBreadcrumbData(folder)
    getListFileOnFolder(folder?.encrypt_id, filePassword, nodeId)
  }

  /** params
   * node_id:CurrentNode
   * password: Password file
   * node_share_id:root node Id
   **/
  const getListFileOnFolder = (node_id, password, node_share_id) => {
    filePreviewStore.getFileUnauthenticated({
      folder_id: node_id,
      per_page: 30,
      page: 1,
      password: password,
      node_share_id: node_share_id,
      sort: filePreviewStore.filePreviewSort,
      sort_by: filePreviewStore.filePreviewSortBy,
    }).then(res => {
      if (res.error_code === 0) {
        //nothink
      } else if (res.error_code === 99) {
        filePreviewStore.setFilePreviewBreadcrumbData(folderDetail)
        getListFileOnFolder(folderDetail.encrypt_id, password, folderDetail.encrypt_id)
      } else {
        message.error(res.message)
      }
    }).catch(error => {
      error?.response?.status != 401 && message.error(error.message)
    })
  }


  const handleChangeSort = (sortCurrent) => {
    filePreviewStore.setFilePreviewSort(sortCurrent)
  }
  const handleChangeSortBy = ({ key }) => {
    filePreviewStore.setFilePreviewSortBy(key)
  }

  const sortMenu = (
    <Menu onClick={handleChangeSortBy}>
      <Menu.Item key='created_at'>
        Ngày chia sẻ
      </Menu.Item>
      <Menu.Item key='name'>
        Tên
      </Menu.Item>
    </Menu>
  )
  const sortableTools = () => {
    return (
      <Col>
        <Row type={'flex'} gutter={15} justify={'space-between'} align={'middle'}>
          <Col>
            <Dropdown overlay={sortMenu} trigger={['click']}>
              <SortButton>
                {
                  filePreviewStore.filePreviewSortBy === 'name'
                    ? 'Tên' : filePreviewStore.filePreviewSortBy === 'created_at' ? 'Ngày chia sẻ' : 'Ngày cập nhật'
                }
              </SortButton>
            </Dropdown>

          </Col>
          <Col>
            <SortButton>
              {
                filePreviewStore.filePreviewSort === 'asc'
                  ? <Tooltip title={t('i0701')} placement={'bottom'}>
                    <ArrowUpOutlined onClick={() => handleChangeSort('desc')} />
                  </Tooltip>
                  : <Tooltip title={t('i0700')} placement={'bottom'}>
                    <ArrowDownOutlined onClick={() => handleChangeSort('asc')} />
                  </Tooltip>
              }
            </SortButton>
          </Col>
        </Row>
      </Col>
    )
  }

  const handleCallbackMobileAction = () => {

  }

  const handleCallbackAction = (action, itemTarget) => {
    switch (action) {
      case CONTEXT_MENU_ACTION.download:
        filePreviewStore.getPublicLinkDownloadFile({ node_id: selectionData[0].encrypt_id }).then(response => {
          if (response?.error_code === 0) {
            if (response.data?.url) {
              window.open(response.data?.url)
            }
          } else {
            message.error(response?.message)
          }
        }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        break
      case CONTEXT_MENU_ACTION.viewInfo:
        filePreviewStore.getDetailFileUnauthenticated({
          node_id: selectionData[0].encrypt_id,
          per_page: 10,
          page: 1,
          password: filePassword,
          node_share_id: filePreviewStore.filePreviewCurrentNode?.encrypt_id || selectionData[0].encrypt_id,
        }).then(res => {
          if (res.error_code === 0) {
            setFileInfo(res.data)
            setVisibleModalInfo(true)
          } else if (res.error_code === 99) {
            ///setFolderDetail(res.data)
            filePreviewStore.setFilePreviewBreadcrumbData(folderDetail)
            getListFileOnFolder(folderDetail.encrypt_id, filePassword, res.data.encrypt_id)
          } else {
            message.error(res.message)
          }
        }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    commonStore.setPageName('folder-preview')
  }, [])

  useEffect(() => {
    if (location.pathname.includes('/driver/file/') || location.pathname.includes('/driver/folders/')) {
      let split = location.pathname.replace(/^\/|\/$/g, '').split('/')
      let node_id = split[split.length - 1]
      setNodeId(node_id)
      if (authenticationStore.accessToken) {
        filePreviewStore.checkShareLinkFile({ node_id: node_id })
          .then(response => {
            if (response?.error_code === 0) {
              history.push('/shared-with-me')
            } else if (response?.error_code === 403) {
              history.push('/not-permission')
            } else {
              setFileError(true)
              message.error(response?.message)
            }
          })
      } else {
        checkPermissionFile(node_id)
        if (location.pathname.includes('folders')) {
          history.push(`/driver/folders/${node_id}`)
        }
      }
    }
  }, [location.pathname, authenticationStore.accessToken])

  useEffect(() => {
    if (!filePreviewList) return
    const tempFolder = [], tempFile = []
    filePreviewList.forEach(record => {
      if (record.type === 1) tempFolder.push(record)
      if (record.type === 2) tempFile.push(record)
    })
    setFiles(tempFile)
    setFolders(tempFolder)
  }, [filePreviewList])

  ////get list sort and sortBy
  useEffect(() => {
    let node_id = filePreviewStore.filePreviewCurrentNode?.encrypt_id
    let length = filePreviewStore.filePreviewBreadcrumbData.length
    if (length < 1) return
    let node_share_id
    if (length > 1) {
      node_share_id = filePreviewStore.filePreviewBreadcrumbData[length - 2].encrypt_id
    } else {
      node_share_id = filePreviewStore.filePreviewBreadcrumbData[0].encrypt_id
    }
    getListFileOnFolder(node_id, filePassword, node_share_id)
  }, [filePreviewStore.filePreviewSort, filePreviewStore.filePreviewSortBy])

  return (
    <>
      <Helmet>
        <title>Thông tin thư mục - mobiCloud</title>
      </Helmet>

      <PageWrapper>
        <ViewToggleComponent
          heading={filePreviewStore.filePreviewBreadcrumbData}
          onClickBreadcrumb={handleClickFolderOrBreadcrumb}
        />
        {
          filePreviewList.length === 0
            ? <PageResult type={'empty-shared-file-list'} />
            : viewByGridMode || isMobile
            ? (
              <>
                {
                  folders?.length > 0 && (
                    <>
                      <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                           justify={'space-between'} align={'middle'}>
                        <Col>
                          <SectionHeading style={{ marginBottom: 0 }}>Thư mục</SectionHeading>
                        </Col>
                        {sortableTools()}
                      </Row>
                      <FoldersGridView
                        folderList={folders}
                        onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                        callbackMobileAction={handleCallbackMobileAction}
                        callbackAction={handleCallbackAction}
                      />
                    </>
                  )
                }
                {
                  files?.length > 0 &&
                  <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                       justify={'space-between'} align={'middle'}>
                    <Col>
                      <SectionHeading style={{ marginBottom: 0 }}>Tệp</SectionHeading>
                    </Col>
                    {
                      folders?.length === 0 && sortableTools()
                    }
                  </Row>
                }
                <FilesGridView
                  fileList={files}
                  callbackMobileAction={handleCallbackMobileAction}
                  callbackAction={handleCallbackAction}
                />
              </>
            ) : !isMobile &&
            <FilesListView fileList={[...folders, ...files]} onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                           callbackAction={handleCallbackAction} />
        }
      </PageWrapper>
      <ModalInputPassword
        title={'Vui lòng nhập mật khẩu để xem tệp'}
        visible={visibleModalPass}
        closable={false}
        footer={null}
        onCloseModal={onCloseModal}
        onConFirmModal={onConFirmModal}
      />
      {/*Modal info*/}
      <Modal
        title={null} footer={null}
        visible={visibleModalInfo} centered={true}
        onCancel={() => {
          setFileInfo({})
          setVisibleModalInfo(false)
        }}
      >
        <ModalHeader>{t('i0076')}</ModalHeader>
        <InfoHeader>
          {
            (fileInfo.mimetype === 'folder' ||
              fileInfo.mimeType === 'application/vnd.google-apps.folder')
              ? <SVGIcon name={'folder'} />
              : (
                <SVGIcon
                  url={
                    iconsByMimeTypeObj[fileInfo.mimetype] ||
                    iconsByMimeTypeObj['default']
                  }
                  width={18} height={18} />
              )
          }
          <Tooltip title={fileInfo.name}>
            <span>{fileInfo.name}</span>
          </Tooltip>
        </InfoHeader>
        <InfoDetail>
          <dt>{t('i0077')}</dt>
          <dd>
            {fileInfo.type === 1 ? t('i0078') : fileInfo.mimetype}
          </dd>
          <dt>{t('i0079')}:</dt>
          <dd>
            {fileInfo.owner_name}
          </dd>
          <dt>{t('i0080')}:</dt>
          <dd>
            {fileInfo.created_at}
          </dd>
          <dt>{t('i0081')}:</dt>
          <dd>
            {fileInfo.updated_name}
          </dd>
          <dt>{t('i0082')}:</dt>
          <dd>
            {fileInfo.updated_at}
          </dd>
        </InfoDetail>
      </Modal>
    </>
  )
}

FolderPreviewPage.propTypes = {}

export default inject('filePreviewStore', 'selectionStore', 'commonStore', 'appConfigStore', 'authenticationStore')(observer(FolderPreviewPage))