import styled from 'styled-components'
import { Select } from 'antd'

export const InsertMemberIntoGroupModalWrapper = styled.div``
export const StyledTagsSelect = styled.div`
  position: relative;
  .custom-tags-select {
    background: #F9FAFC;
    border: 1px solid #F1F1F5;
    border-radius: 8px;
    width: 100%;

    &::placeholder {
      color: #A7A7A7;
    }

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      padding: 0 50px 0 5px;
      min-height: 50px;

      .ant-select-selection-item {
        padding: 5px 10px;
        height: auto;
        line-height: normal;
        margin: 4px;
        border: none;
        border-radius: 40px;
        background-color: #E8E8E8;
        min-height: 42px;
        align-items: center;

        p:last-of-type {
          display: none;
        }

        .ant-select-selection-item-content {
          margin-right: 10px;
        }

        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          color: black;

          * {
            line-height: 0;
          }
        }
      }
    }
  }

  .ant-spin {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

`
export const UserAvatar = styled.a`
  display: block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;

  img {
    width: 100%;
  }
`
export const UserInfo = styled.div`
  margin-right: auto;
  margin-left: 8px;

  p {
    margin-bottom: 0;

    &:first-child {
      color: #313131;
      font-size: 1.4rem;
    }

    &:last-child {
      color: #8C8C8C;
      font-size: 1.2rem;
    }
  }
`
export const SharingUserInfo = styled.div`
  display: flex;
  align-items: center;
`
export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  .ant-btn {
    margin: 0 8px;
    padding: 7px 35px;
    height: auto;
    line-height: unset;
    border-radius: 8px;

    &.ant-btn-primary {
      background-color: #237BD3;
      color: white;
    }

    &.ant-btn-default {
      background-color: #E2E2E2;
      color: #313131;
    }
  }
`