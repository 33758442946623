// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import ViewToggleComponent from '../../components/ViewToggleComponent/ViewToggleComponent'
import PageContentWrapper from '../../components/PageContentWrapper'
import PageResult from '../../components/PageResult'
import { ButtonGroup, DeleteNotice, SectionHeading } from './TrashPageStyled'
import FoldersGridView from '../../components/FoldersGridView'
import FilesGridView from '../../components/FilesGridView'
import { CONTEXT_MENU_ACTION } from '../../utils/constant'
import { Button, Col, Dropdown, Menu, message, Modal, notification, Row, Tooltip } from 'antd'
import { Helmet } from 'react-helmet/es/Helmet'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import FilesListView from '../../components/FilesListView/FilesListView'
import {
  CustomCustomNotificationMessage,
  CustomNotificationContent,
  SortButton,
} from '../../components/CommonStyled/CommonStyled'
import { useMediaQuery } from 'react-responsive/src'
import MobileViewDetail from '../../components/MobileViewDetail/MobileViewDetail'
import { useTranslation } from 'react-i18next'
import { toJS } from 'mobx'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'

const TrashPage = props => {

  const {
    commonStore,
    trashStore,
    selectionStore,
    appConfigStore,
    profileStore,
    searchStore,
    loadingAnimationStore,
  } = props
  const { selectionData, selectionIds, firstSelectionIndex, lastSelectionIndex, clickSessionId } = selectionStore
  const { filesInTrash } = trashStore
  const { viewByGridMode } = commonStore
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const { t } = useTranslation()

  const [files, setFiles] = useState([])
  const [folders, setFolders] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [contentModal, setContentModal] = useState('')
  const [actionKeyModal, setActionKeyModal] = useState('deleteFile')
  const [nodeId, setNodeId] = useState()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(1000)

  const onHandleClickSortMenu = ({ key }) => {
    trashStore.setTrashSortBy(key)
  }

  const sortMenu = (
    <Menu
      onClick={onHandleClickSortMenu}
      selectedKeys={trashStore.trashSortBy}
      className={'sort-menu'}>
      <Menu.Item key="name">
        {t('i0609')}
      </Menu.Item>
      <Menu.Item key="updated_at">
        {t('i0612')}
      </Menu.Item>
      <Menu.Item key="trashed_at">
        {t('i0613')}
      </Menu.Item>
    </Menu>
  )

  const sortDropDown = () => {
    return (
      <Row type={'flex'} gutter={15} justify={'space-between'} align={'middle'}>
        <Col>
          <Dropdown overlay={sortMenu} trigger={['click']}>
            <SortButton>
              {
                trashStore.trashSortBy === 'name'
                  ? t('i0611')
                  : trashStore.trashSortBy === 'updated_at'
                    ? t('i0612')
                    : t('i0613')
              }
            </SortButton>
          </Dropdown>
        </Col>
        <Col>
          <SortButton>
            {
              trashStore.trashSort === 'asc'
                ? <Tooltip title={t('i0701')} placement={'bottomRight'}>
                  <ArrowUpOutlined onClick={() => trashStore.setTrashSort('desc')}/>
                </Tooltip>
                : <Tooltip title={t('i0700')} placement={'bottomRight'}>
                  <ArrowDownOutlined onClick={() => trashStore.setTrashSort('asc')}/>
                </Tooltip>
            }
          </SortButton>
        </Col>
      </Row>
    )
  }
  const getFilesInTrash = () => {
    return trashStore.getFilesInTrash(page, perPage, trashStore.trashSort, trashStore.trashSortBy)
      .then(response => {
        if (response.error_code !== 0) {
          message.error(response.message)
        }
        return response
      })
      .catch(error => {
        error.response.status != 401 && message.error(error.message)
        return error
      })
  }
  const handleClickFolderOrBreadcrumb = (folder) => {
    setActionKeyModal('restore')
    setNodeId(folder.id)
    setContentModal(<>{t('i0614')}</>)
    setModalVisible(true)
  }
  const handleCallbackAction = (action, itemTarget) => {
    if (selectionIds.length > 1) {
      switch (action) {
        case CONTEXT_MENU_ACTION.restore:
          trashStore.restoreFile({ list_id: selectionIds })
            .then(response => {
              if (response.error_code !== 0) {
                message.error(response.message)
              } else {
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {response.data.message}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successRollbackTrash',
                })
                getFilesInTrash()
                resetState()
              }
            }).catch(error => {
            error?.response?.status != 401 && message.error(error.message)
          })
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          let contentModal = itemTarget === 'folder'
            ? (
              <>
                {t('i0615')}<br/>
                {t('i0616')}
              </>
            )
            : (
              <>
                {t('i0617')}<br/>
                {t('i0618')}
              </>
            )
          setActionKeyModal('delete')
          setNodeId(selectionData[0]?.id)
          setContentModal(contentModal)
          setModalVisible(true)
          break
        default:
          break
      }
    } else {
      switch (action) {
        case CONTEXT_MENU_ACTION.restore:
          trashStore.restoreFile({ list_id: selectionIds })
            .then(response => {
              if (response.error_code !== 0) {
                message.error(response.message)
              } else {
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {response.data.message}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successRollbackTrash',
                })
                getFilesInTrash()
                resetState()
              }
            }).catch(error => {
            error?.response?.status != 401 && message.error(error.message)
          })
          break
        case CONTEXT_MENU_ACTION.permanentlyDelete:
          let contentModal = itemTarget === 'folder'
            ? (
              <>
                {t('i0619')}<br/>
                {t('i0620')}
              </>
            )
            : (
              <>
                {t('i0621')}<br/>
                {t('i0622')}
              </>
            )
          setActionKeyModal('delete')
          setNodeId(selectionData[0]?.id)
          setContentModal(contentModal)
          setModalVisible(true)
          break
        default:
          break
      }
    }
  }
  const onHandleEmptyTrash = _ => {
    if (filesInTrash.length === 0) return
    setActionKeyModal('delete_all')
    setContentModal(<>{t('i0623')}<br/>
      {t('i0624')}</>)
    setModalVisible(true)
  }
  const resetState = _ => {
    setActionKeyModal('')
    setModalVisible(false)
    setContentModal('')
    setNodeId('')
  }
  const onConfirmAction = _ => {
    switch (actionKeyModal) {
      case 'restore':
        trashStore.restoreFile({ list_id: selectionIds })
          .then(response => {
            if (response.error_code !== 0) {
              message.error(response.message)
            } else {
              notification.info({
                className: 'custom-notification-share',
                closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                width={15}
                                height={15}/>,
                icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                           height={20}/>,
                message: <CustomNotificationContent>
                  <CustomCustomNotificationMessage>
                    {response.data.message}
                  </CustomCustomNotificationMessage>
                </CustomNotificationContent>,
                description: null,
                placement: 'bottomLeft',
                duration: 6,
                key: 'successRollbackTrash',
              })
              getFilesInTrash()
              resetState()
            }
          }).catch(error => {
          error?.response?.status != 401 && message.error(error.message)
        })
        break
      case 'delete_all':
        trashStore.deleteAllFilesInTrash({})
          .then(response => {
            if (response.error_code !== 0) {
              message.error(response.message)
            } else {
              resetState()
              loadingAnimationStore.showSpinner(true)
              setTimeout(() => {
                Promise.all([
                  getFilesInTrash(),
                  profileStore.getMyProfileNotShowSpinner(),
                ]).then(_ => {
                  searchStore.clearTemporarySearchList()
                  loadingAnimationStore.showSpinner(false)
                  notification.info({
                    className: 'custom-notification-share',
                    closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                    width={15}
                                    height={15}/>,
                    icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                               height={20}/>,
                    message: <CustomNotificationContent>
                      <CustomCustomNotificationMessage>
                        {t('i0715')}
                      </CustomCustomNotificationMessage>
                    </CustomNotificationContent>,
                    description: null,
                    placement: 'bottomLeft',
                    duration: 6,
                    key: 'successDeleteAll',
                  })
                }).catch(error => {
                  message.success(response.data.message)
                })
              }, response.data.time_loading * 1000)
            }
          })
          .catch(error => {
            error.response.status != 401 && message.error(error.message)
          })
        break
      case 'delete':
        trashStore.deleteFileInTrash({ list_id: selectionIds })
          .then(response => {
            if (response.error_code !== 0) {
              message.error(response.message)
            } else {
              resetState()
              Promise.all([
                getFilesInTrash(),
                profileStore.getMyProfileNotShowSpinner(),
              ]).then(() => {
                searchStore.clearTemporarySearchList()
                notification.info({
                  className: 'custom-notification-share',
                  closeIcon: <img src={`${process.env.PUBLIC_URL}/assets/icons/icon-cancel-white.svg`} alt={''}
                                  width={15}
                                  height={15}/>,
                  icon: <img src={`${process.env.PUBLIC_URL}/assets/icons/check-circle.svg`} alt={''} width={20}
                             height={20}/>,
                  message: <CustomNotificationContent>
                    <CustomCustomNotificationMessage>
                      {t('i0715')}
                    </CustomCustomNotificationMessage>
                  </CustomNotificationContent>,
                  description: null,
                  placement: 'bottomLeft',
                  duration: 6,
                  key: 'successDelete',
                })
              })
                .catch(error => {
                  message.success(response.data.message)
                })
            }
          })
          .catch(error => {
            error.response.status != 401 && message.error(error.message)
          })
        break
      default:
        break
    }
  }
  const onHandleCloseModal = () => {
    resetState()
  }
  const handleClearSelection = event => {
    if (event.target.closest('.selectable-item')) return
    if (event.target.closest('.context-menu')) return
    selectionStore.clearSelectionIndex()
  }

  useEffect(() => {
    getFilesInTrash()
  }, [trashStore.trashSortBy, trashStore.trashSort])

  const deleteFunction = (event) => {
    if (selectionIds?.length > 0) {
      if (event.keyCode === 46) {
        handleCallbackAction(CONTEXT_MENU_ACTION.permanentlyDelete, undefined)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', deleteFunction, false)
    return () => {
      document.removeEventListener('keydown', deleteFunction, false)
    }
  }, [selectionIds])

  useEffect(() => {
    if (!filesInTrash) return
    const tempFolder = [], tempFile = []
    filesInTrash.forEach(record => {
      if (record.type === 1) tempFolder.push(record)
      if (record.type === 2) tempFile.push(record)
    })

    tempFolder.forEach((item, index) => {
      item.realIndex = index
    })
    tempFile.forEach((item, index) => {
      item.realIndex = index + tempFolder.length
    })

    setFiles(tempFile)
    setFolders(tempFolder)
  }, [filesInTrash])

  useEffect(() => {
    if (firstSelectionIndex === null || lastSelectionIndex === null) return
    let fileShiftSelect = files.filter(item => item.realIndex >= firstSelectionIndex && item.realIndex <= lastSelectionIndex)
    let folderShiftSelect = folders.filter(item => item.realIndex >= firstSelectionIndex && item.realIndex <= lastSelectionIndex)
    let newSelectionItems = [...folderShiftSelect, ...fileShiftSelect]
    selectionStore.setSelectionData(newSelectionItems)
  }, [firstSelectionIndex, lastSelectionIndex, files, folders, clickSessionId])

  useEffect(() => {
    return () => {
      selectionStore.setLastSelectionIndex(null)
      selectionStore.setFirstSelectionIndex(null)
      selectionStore.clearSelectionIndex()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('i0008')}</title>
      </Helmet>
      <ViewToggleComponent
        heading={[
          {
            name: t('i0625'),
            id: null,
          },
        ]}
        onClickBreadcrumb={() => null}/>
      <div onClick={event => handleClearSelection(event)}>
        <PageContentWrapper>
          <DeleteNotice>
            {t('i0626')} {appConfigStore.systemConfig?.time_in_trash} {t('i0627')}.
            <a style={{ marginLeft: 10 }} onClick={onHandleEmptyTrash}>{t('i0628')}</a>
          </DeleteNotice>
          {
            filesInTrash.length === 0
              ? <PageResult type={'empty-trash'}/>
              : viewByGridMode || isMobile
                ? (
                  <>
                    {
                      folders?.length > 0 && (
                        <>
                          <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                               justify={'space-between'} align={'middle'}>
                            <Col>
                              <SectionHeading style={{ marginBottom: 0 }}>{t('i0533')}</SectionHeading>
                            </Col>
                            <Col>
                              {sortDropDown()}
                            </Col>
                          </Row>
                          <FoldersGridView
                            folderList={folders}
                            onDoubleClickFolder={handleClickFolderOrBreadcrumb}
                            callbackAction={handleCallbackAction}
                            callbackMobileAction={() => {
                            }}
                          />
                        </>
                      )
                    }
                    {
                      files?.length > 0 && (
                        <Row type={'flex'} gutter={15} style={{ marginBottom: 16 }}
                             justify={'space-between'} align={'middle'}>
                          <Col>
                            <SectionHeading style={{ marginBottom: 0 }}>{t('i0534')}</SectionHeading>
                          </Col>
                          <Col>
                            {folders.length < 1 && sortDropDown()}
                          </Col>
                        </Row>
                      )
                    }
                    <FilesGridView fileList={files}
                                   callbackMobileAction={() => {
                                   }}
                                   callbackAction={handleCallbackAction}/>
                  </>
                ) : !isMobile && (
                <FilesListView
                  fileList={[...folders, ...files]}
                  callbackAction={handleCallbackAction}
                />
              )
          }
        </PageContentWrapper>
      </div>
      <Modal
        onCancel={() => setModalVisible(false)}
        footer={null} closable={null}
        wrapClassName={'custom-ant-modal'}
        visible={modalVisible}
        maskClosable={false}
      >
        {/*<p>*/}
        {/*  Bạn có chắc chắn muốn xoá tất cả tệp/thư mục trong thùng rác?<br />*/}
        {/*  Bạn sẽ không thể khôi phục lại chúng sau khi đã xoá vĩnh viễn.*/}
        {/*</p>*/}
        <p>
          {contentModal}
        </p>
        <ButtonGroup>
          <Button type={'default'} onClick={onHandleCloseModal}>{t('i0629')}</Button>
          <Button type={'primary'}
                  onClick={onConfirmAction}>{actionKeyModal === 'restore' ? t('i0630') : t('i0631')} </Button>
        </ButtonGroup>
      </Modal>
    </>
  )
}

TrashPage.propTypes = {}

export default inject(
  'trashStore',
  'commonStore',
  'selectionStore',
  'appConfigStore',
  'profileStore',
  'searchStore',
  'loadingAnimationStore',
)(observer(TrashPage))
